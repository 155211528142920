import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import { _getDocuments, updateDocument } from '@/@core/queries/documents';
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'

export default function useDocumentList() {
  const { showErrorMessage } = useNotifications();
  const { handleError, openDocument } = useCommon()
  const documents = ref([])
  const userData = store.state?.userStore?.userData

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const perPage = ref(10)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const isLoading = ref(true)

  const fetchDocuments = () => {
    isLoading.value = true
    store
      .dispatch('organization-process/index', {
        status: statusFilter.value,
        query: _getDocuments,
        variables: {
          input: {
            limit: perPage.value,
            total: currentPage.value === 1,
            offset: (currentPage.value * perPage.value) - perPage.value,
            query: {},
            search: searchQuery.value,
            sortBy: sortBy.value,
            sortOrder: isSortDirDesc.value ? -1 : 1,
            client_id: ( userData.role != "admin" ) ? userData.client.$oid : null
          }
        }
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const {
          DocumentsData: {
            documents: w,
            totalRecords: total
          }
        } = response.data.data || {}
        documents.value = w
        if (currentPage.value === 1) totalDocuments.value = total
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.document_list_fetch_error') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteDocument = (id, callback) => {
    store
      .dispatch('organization-process/delete', {
          query: updateDocument,
          variables: { query: { _id: id }, data: { deleted: true } 
        },
      })
      .then(() => {
        callback(true)
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.something_went_wrong'))
      })
  }

  watch([currentPage, perPage, statusFilter], () => {
    fetchDocuments()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchDocuments()
  })

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    totalDocuments,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    documents,
    statusFilter,
    deleteDocument,
    isLoading,
    openDocument,
  }
}