<template lang="pug">
div
    b-button(
        type="button",
        variant="warning",
        size="sm"
        class="mr-1"
        @click="activateFileSelector"
    )
        | {{ $t('message.upload_evidence') }}
    input(
        type="file",
        ref="fileSelector"
        style="display: none",
        accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        @change="changeFileSelected"
    )
    small(v-if="documentFile.name")
        | {{ documentFile.name }}
</template>
    
<script>
    import { ref } from '@vue/composition-api/dist/vue-composition-api'
    
    export default {
      name: 'Form',
    
      components: {
      },
        
      setup(props, { emit }) {
        const form = ref(null)
        const fileSelector = ref(null)
        const documentFile = ref({})
    
        const activateFileSelector = () => {
          fileSelector.value.click()
        }
    
        const changeFileSelected = (e) => {
          documentFile.value = e.target.files[0]
          emit('fileToUpload', documentFile.value)
        }
        
        return {
          form,
          fileSelector,
          documentFile,
          activateFileSelector,
          changeFileSelected,
        }
      },
    }
</script>
    
<style scoped>

</style>
    