<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Institution
        b-col(md="6")
          b-form-group(:label="$t('label.Institution')", :label-for="$t('label.Institution')", description="")
            validation-provider(:name="$t('label.institution')", rules="required", v-slot="{ errors }")
              b-form-input#name(v-model="data.institution", trim,:disabled="readOnly", :placeholder="$t('placeholder.institution')")
              small(class="text-danger") {{ errors[0] }}
        // Institution Servant
        b-col(md="6")
          b-form-group(:label="$t('label.InstitutionServant')", :label-for="$t('label.institutionServant')", description="")
            validation-provider(:name="$t('label.institutionServant')", rules="required", v-slot="{ errors }")
              b-form-input#name(v-model="data.institutionServant", trim,:disabled="readOnly", :placeholder="$t('placeholder.institutionServant')")
              small(class="text-danger") {{ errors[0] }}
      b-row
        // Topic
        b-col(md="6")
          b-form-group(:label="$t('label.Topic')", :label-for="$t('label.topic')", description="")
            validation-provider(:name="$t('label.topic')", rules="required", v-slot="{ errors }")
              b-form-input#name(v-model="data.topic", trim,:disabled="readOnly", :placeholder="$t('placeholder.topic')")
              small(class="text-danger") {{ errors[0] }}
        // Document
        b-col(md="6")
          b-form-group(:label="$t('label.document')", :label-for="$t('label.document')", description="")
            validation-provider(:name="$t('label.document')", v-slot="{ errors }")

            b-button.mr-1(
              v-if="data.documentKey"
              type="button"
              variant="success"
              @click="openDocument(data.documentKey)"
            )
              | {{ $t('message.open') }}

            b-button.mr-1(
              v-else-if="isSubmitting"
              type="button"
              variant="success"
              :disabled="true"
            )
              | {{ $t('message.noDocument') }}

            
            b-button.mr-1(
              v-if="!isSubmitting"
              type="button",
              variant="info",
              @click="activateFileSelector"
            )
              | {{ $t('message.upload') }}
            input(
              type="file",
              ref="fileSelector"
              style="display: none",
              accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              @change="changeFileSelected"
            )
            small(v-if="documentFile.name")
              | {{ documentFile.name }}
        b-col(md="3")
      b-row
        b-col(md="12")
          // Supervisor
          b-form-group(:label="$t('label.date')", :label-for="$t('label.date')", description="")
            validation-provider(:name="$t('label.date')", rules="required", v-slot="{ errors }")
              b-form-datepicker(v-model="data.date",:disabled="readOnly", :placeholder="$t('placeholder.date')")
              small(class="text-danger") {{ errors[0] }}

      b-row
        b-col.text-right(cols="12")
          b-button(variant="primary", v-if="!isSubmitting", @click="validateAndSubmit")
            | {{ $t('message.submit') }}
</template>

<script>
import vSelect from 'vue-select'
import { onMounted, ref, toRefs } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import awsConnection from '@/views/habit/aws';
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import store from '@/store'
import useCommon from '../../useCommon'

export default {
  name: 'Form',

  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { openDocument } = useCommon()

    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const form = ref(null)
    const fileSelector = ref(null)
    const { singleUpload } = awsConnection()
    const documentFile = ref({})

    const userData = store.state?.userStore?.userData;

    const validateForm = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateForm()
        .then(() => emit('submit', toRefs(props.data), toRefs(documentFile.value)))
        .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    onMounted(async () => {

    })
    const activateFileSelector = () => {
      fileSelector.value.click()
    }
    const changeFileSelected = (e) => {
      documentFile.value = e.target.files[0]
    }
    

    return {
      required,
      form,
      validateAndSubmit,
      openDocument,
      documentFile,
      activateFileSelector,
      changeFileSelected,
      fileSelector
    }
  },
}
</script>

<style scoped>
</style>