// eslint-disable-next-line no-underscore-dangle
export const _getDocuments = `query DocumentsData($input: DocumentsDatum!){
  DocumentsData(input: $input) {
      totalRecords
      documents {
        _id
        name
        key
      }
  }
}`

export const getDocument = `query getDocument($query: DocumentQueryInput){
  document(query: $query) {
    _id
    name
  }
}`

export const addDocument = `mutation insertOneDocument($data: DocumentInsertInput!){
  insertOneDocument(data: $data) {
    _id
  }
}`

export const updateDocument = `mutation updateOneDocument($query: DocumentQueryInput!, $data: DocumentUpdateInput!) {
  updateOneDocument(query: $query, set: $data) {
    _id
  }
}
`