<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>{{ $t(`${includeSubtitle ? 'Evaluations Global Compliance' : 'ActualVsTheoreticalProgress'}`) }}</b-card-title>
        <b-card-sub-title v-if="includeSubtitle" class="m-0">{{ $t('ActualVsTheoreticalProgress') }}</b-card-sub-title>
      </div>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        :height="283"
        :data="this.series"
        :options="horizontalBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentHorizontalBarChart from '@/views/habit/charts-components/ChartjsComponentHorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    progressData: {
      type: Object,
      required: true,
    },
    cursorPointer: {
      type: Boolean,
      default: false,
    },
    includeSubtitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          onHover: (event, chartElement) => {
            event.target.style.cursor = this.cursorPointer && chartElement[0] ? 'pointer' : 'default';
          },
          onClick: (c, i) => {
            let e = i[0];
            if (!e) return
            
            this.$emit('goToGlobalDashboard')
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            mode: "point",
            callbacks: {
              title: function(tooltipItems, data) {
                return ''
              },
              label: function(tooltipItem, data) {
                return ''
              },
              afterBody: function(tooltipItem, data) {
                const lines = [
                  `${data.datasets[1].label}: ${data.datasets[1].data[0].x}%`,
                  `${data.datasets[0].label}: ${data.datasets[0].data[0]}%`,
                  `${i18n.t('Observadas')}: ${data.datasets[0].totalDone || 0}`,
                ]

                if (data.datasets[0].pendingToMatchTheoretical) {
                  lines.push(`${i18n.t('pendingToMatchTheoreticalProgress')}: ${data.datasets[0].pendingToMatchTheoretical || 0}`)
                }

                return lines
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // display: false,
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
            // Override default behaviour for legend onClick
            onClick: function(e, legendItem) {}
          },
          scales: {
            xAxes: [
              {
                stacked: false,
                display: true,
                gridLines: {
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                  borderColor: 'transparent',
                  color: 'rgba(200, 200, 200, 0.2)',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                  fontColor: '#6e6b7b',
                  precision: 0,
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
        // data: {
        //   labels: ['MON', 'TUE', 'WED ', 'THU', 'FRI', 'SAT', 'SUN'],
        //   datasets: [
        //     {
        //       data: [710, 350, 470, 580, 230, 460, 120],
        //       backgroundColor: $themeColors.info,
        //       borderColor: 'transparent',
        //       barThickness: 15,
        //     },
        //   ],
        // },
      },
      // rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  computed: {
    series() {
      return {
        datasets: [
          {
            label: i18n.t('TheoreticalProgress'),
            data: [this.progressData.theoretical],
            backgroundColor: $themeColors.info,
            borderColor: 'transparent',
            barThickness: 30,
            totalDone: this.progressData.totalDone,
            pendingToMatchTheoretical: this.progressData.pendingToMatchTheoretical,
            order: 2,
          },
          {
            type: 'scatter',
            label: i18n.t('ActualProgress'),
            data: [{ x: this.progressData.actual, y: 0 }],
            backgroundColor: $themeColors.danger,
            borderColor: 'transparent',
            pointStyle: 'rectRot',
            pointRadius: 7,
            pointHoverRadius: 9,
            order: 1,
          }
        ],
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
