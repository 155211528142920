<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }" @click="mqShallShowLeftSidebar = false" />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckCircleIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input :value="searchQuery" :disabled="true" @input="updateRouteQuery" />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <!-- <div class="dropdown">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div> -->
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
        <draggable v-show="!isLoading" v-model="tasks" handle=".draggable-task-handle" tag="ul"
          class="todo-task-list media-list">
          <li v-for="task in tasks" :key="task._id" class="todo-item" :class="{ 'completed': task.completed }"
            @click="handleTaskClick(task)">
            <feather-icon icon="MoreVerticalIcon" class="draggable-task-handle d-inline" />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox :checked="task.completed" @click.native.stop @change="updateTaskIsCompleted(task)" />
                  <span class="todo-title">{{ task.note }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge v-for="tag in task.tags" :key="tag" pill :variant="`light-${resolveTagVariant(tag)}`"
                    class="text-capitalize">
                    {{ $t(`domain.${tag}`) }}
                  </b-badge>
                </div>
                <small :class="`text-nowrap mr-1 ${setDateColor(task.dueDate, task.completed)}`">
                  {{ formatDate(task.dueDate, { month: 'short', day: 'numeric' }) }}
                </small>
                <b-avatar v-if="task.assignee" size="32" :variant="`light-${resolveAvatarVariant(task.tags)}`"
                  :text="avatarText(task.assignee.name)" />
                <!-- :src="task.assignee.avatar"    optional attribute inside b-avatar-->
                <b-avatar v-else size="32" variant="light-secondary">
                  <feather-icon icon="UserIcon" size="16" />
                </b-avatar>
              </div>
            </div>

          </li>
        </draggable>
        <div class="no-results" :class="{ 'show': !isLoading && !tasks.length }">
          <h5>{{ commitmentFunctionality ? $t('message.no_commitments_found') : $t('message.no_improvements_found') }}
          </h5>
        </div>
        <loading v-if="isLoading" :active="true" :is-full-page="false" color="#498ceb" />
        <!-- <div
          class="no-results"
          :class="{'show': isLoading}"
        >
          <h5>{{ $t('message.loading_improvements') }}</h5>
        </div> -->
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar v-model="isTaskHandlerSidebarActive" :task="task" :clear-task-data="clearTaskData"
      :domains="domains" :optionsMetadata="optionsMetadata" handlerId="tab" @remove-task="removeTask" @add-task="addTaskAndRefetch"
      @update-task="updateTask" />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar :task-tags="domains" :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{ 'show': mqShallShowLeftSidebar }" @close-left-sidebar="mqShallShowLeftSidebar = false" />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch, computed, onMounted, onUnmounted } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import useCommonTodo from './useCommonTodo'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import awsConnection from '@/views/habit/aws';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import useCommon from "@/views/organization/useCommon";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    Loading,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const isLoading = ref(true)
    const categoryFilter = ref("all")
    const tagFilter = ref("all")
    const now = new Date()
    const nowPlusOneWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)

    const commitmentFunctionality = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
      : false

    const { resolveTagVariant, resolveAvatarVariant, getEmailTemplate, addTask } = useCommonTodo()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { singleUpload, sendEmail } = awsConnection()

    const { getDomains, getDatesFromRange } = useCommonDashboards()
    const domains = computed(() => getDomains(clientId))

    const { getMetadataForDropDown, metadataNotMapped, handleError } = useCommon()

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // Reset store states for filters
    store.commit('app-todo/SET_SELECTED_LOCATIONS', [])
    store.commit('app-todo/SET_SELECTED_WORKERS', [])
    store.commit('app-todo/SET_SELECTED_DUE_DATES', "")
    store.commit('app-todo/SET_SELECTED_INSTANCES', [])
    store.commit('app-todo/SET_SELECTED_INSTANCE_LEADERS', [])
    store.commit('app-todo/SET_SELECTED_ZONES', [])

    // Fetch tasks and get metadata on mounted
    onMounted(() => {
      fetchTasks()
      getMetadataForDropDown({ category: "improvement", option: "notMapped" })
    })

    // UnRegister on leave (can't unregister because of 'Add improvement' button in navbar)
    // onUnmounted(() => {
    //   if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    // })

    const { route, router } = useRouter()
    // const routeSortBy = computed(() => route.value.query.sort)
    // const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    
    watch(routeParams, val => {
      isLoading.value = true
      categoryFilter.value = val.filter ? val.filter : "all"
      tagFilter.value = val.tag ? val.tag : "all"
      fetchTasks()
    })

    const locationFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedLocations)
    const workerFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedWorkers)
    const dueDateFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedDueDates)
    const instanceFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedInstances)
    const instanceLeaderFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedInstanceLeaders)
    const zoneFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedZones)

    watch([locationFilter, workerFilter, dueDateFilter, instanceFilter, instanceLeaderFilter, zoneFilter], () => {
      isLoading.value = true
      fetchTasks()
    })

    const tasks = ref([])

    // const sortOptions = [
    //   'latest',
    //   'title-asc',
    //   'title-desc',
    //   'assignee',
    //   'due-date',
    // ]
    // const sortBy = ref(routeSortBy.value)
    // watch(routeSortBy, val => {
    //   if (sortOptions.includes(val)) sortBy.value = val
    //   else sortBy.value = val
    // })
    // const resetSortAndNavigate = () => {
    //   const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

    //   delete currentRouteQuery.sort

    //   router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    // }

    let blankTask = {
      _id: null,
      client_id: { link: clientId },
      note: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      completed: false,
      deleted: false,
      important: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = userData.username
        })
        blankTask.metadata = val
        if (!task.value._id) task.value = { ...task.value, metadata: val }
      }
    })

    const fetchTasks = () => {
      const dueDates = getDatesFromRange(dueDateFilter.value)

      let query = {
        category: categoryFilter.value,
        tag: tagFilter.value,
        locations: locationFilter.value,
        workers: workerFilter.value,
        dueDates: dueDates,
        instances: instanceFilter.value,
        instanceLeaders: instanceLeaderFilter.value,
        zones: zoneFilter.value,
      }

      store.dispatch('app-todo/fetchTasks', query)
        .then(response => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)

          // const tasksUnsorted = response.data?.data?.improvements?.map(e => {
          //   if (!e.associatedCase) return e
          //   return {
          //     ...e,
          //     associatedCase: e.associatedCase._id
          //   }
          // })

          const tasksUnsorted = response.data?.data?.improvements || []
          tasksUnsorted.sort(function (a, b) {
            if (b.completed && !a.completed) return -1
            if (!b.completed && a.completed) return 1
            return 0
          })
          tasks.value = tasksUnsorted
        
          // Calculate and update statistic card values in store
          let completedTasks = 0
          let pendingTasks = 0
          let overdueTasks = 0
          let dateNow = new Date()
          tasksUnsorted.forEach(e => {
            let compareDate = e.dueDate ? new Date(e.dueDate) : null
            if (e.completed) completedTasks++
            else if (compareDate && (compareDate < dateNow)) overdueTasks++
            else pendingTasks++
          })
          const totalTasks = completedTasks + pendingTasks + overdueTasks
          const statisticCardsData = {
            completed: completedTasks,
            pending: pendingTasks,
            overdue: overdueTasks,
            total: totalTasks
          }
          store.commit('app-todo/SET_STATISTIC_CARDS_DATA', statisticCardsData)
        })
        .catch((error) => {
          console.log(error)
          handleError({ error, defaultMessage: commitmentFunctionality ? i18n.t('message.err_commitment_list') : i18n.t('message.err_improvement_list') })
        })
        .finally(() => isLoading.value = false)
    }

    const addTaskAndRefetch = (val) => {
      addTask(val)
        .then(() => fetchTasks())
        .catch((error) => console.log(error))
    }

    const updateTask = (taskData, index, action) => {
      store.dispatch('app-todo/updateTask', taskData)
        .then((response) => {
          if (response.data.errors) throw new Error()
          if (!action.includes("FromList")) {
            if (action === "delete") showSuccessMessage(commitmentFunctionality ? i18n.t('message.commitment_deleted') : i18n.t('message.improvement_deleted'))
            else showSuccessMessage(commitmentFunctionality ? i18n.t('message.commitment_updated') : i18n.t('message.improvement_updated'))
          }

          // Refetch tasks to get updated list
          fetchTasks()

          // Send email to assignee with the details of the improvement opportunity updated
          if (taskData.assignee?.email) {
            const updatedState = action === "delete"
              ? "deleted"
              : action.startsWith("complete")
                ? "completed"
                : "updated"
            const subject = commitmentFunctionality
              ? updatedState === "deleted"
                ? i18n.t('message.commitment_deleted')
                : updatedState === "completed"
                  ? i18n.t('message.commitment_completed')
                  : i18n.t('message.commitment_updated')
              : updatedState === "deleted"
                ? i18n.t('message.improvement_opportunity_deleted')
                : updatedState === "completed"
                  ? i18n.t('message.improvement_opportunity_completed')
                  : i18n.t('message.improvement_opportunity_updated')
            let bodyData = {
              name: taskData.assignee?.name,
              title: taskData.note,
              tags: taskData.tags?.map(e => i18n.t(`domain.${e}`)).join(" / "),
              creator: userData.fullName,
              dueDate: `${taskData.dueDate?.slice(8, 10)}/${taskData.dueDate?.slice(5, 7)}/${taskData.dueDate?.slice(0, 4)}`,
              description: taskData.description?.replace(/<[^>]*>/g, ''),
              commitmentFunctionality,
              updatedState,
              completed: taskData.completed,
              deleted: taskData.deleted,
            }
            if (taskData.metadata?.length) {
              taskData.metadata.forEach(e => {
                if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
                Object.assign(bodyData, { [e.name]: e.answer })
              })
            }
            const body = getEmailTemplate(bodyData)

            sendEmail([taskData.assignee.email], subject, body)
              .then((response) => {
                if (response.MessageId) showSuccessMessage(commitmentFunctionality ? i18n.t('message.email_send_commitment_success') : i18n.t('message.email_send_improvement_success'))
              })
              .catch((err) => {
                console.log(err)
                showErrorMessage(commitmentFunctionality ? i18n.t('message.email_send_commitment_error') : i18n.t('message.email_send_improvement_error'))
              })
          }
        })
        .catch((error) => {
          showErrorMessage(commitmentFunctionality ? i18n.t('message.commitment_update_error') : i18n.t('message.improvement_update_error'))
          console.log(error)
        })
    }

    const removeTask = (taskData) => {
      if (!taskData.deleted) {
        taskData.deleted = true
        updateTask(taskData, -1, "delete")
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    // const taskTags = [
    //   { title: 'Team', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'team' } } },
    //   { title: 'Low', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'low' } } },
    //   { title: 'Medium', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'medium' } } },
    //   { title: 'High', color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'high' } } },
    //   { title: 'Update', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'update' } } },
    // ]

    // Search Query
    const searchQuery = ref("")
    // const searchQuery = ref(routeQuery.value)
    // watch(routeQuery, val => {
    //   searchQuery.value = val
    // })
    // eslint-disable-next-line no-use-before-define
    // watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = () => { }
    // const updateRouteQuery = val => {
    //   const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

    //   if (val) currentRouteQuery.q = val
    //   else delete currentRouteQuery.q

    //   router.replace({ name: route.name, query: currentRouteQuery })
    // }

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.completed = !taskData.completed
      const action = taskData.completed ? "completeFromList" : "incompleteFromList"
      updateTask(taskData, -1, action)
    }

    const setDateColor = (taskDate, completed) => {
      if (completed) return "text-success"
      const date = new Date(taskDate)
      if (date < now) return "text-danger"
      if (date > nowPlusOneWeek) return "text-muted"
      return "text-warning"
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      removeTask,
      addTaskAndRefetch,
      updateTask,
      clearTaskData,
      // taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      // resetSortAndNavigate,
      isLoading,
      domains,
      setDateColor,
      optionsMetadata,
      commitmentFunctionality,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
