<template>
  <div>
    <b-form-group
      class="mt-1"
      label-for="boolInput"
    >
      <label
        v-if="type != 'statement'"
        v-html="name"
      />
  
      <!-- Boolean -->
      <div v-if="type === 'bool'" class="d-flex justify-content-start align-items-center">
        <b-form-radio-group
          v-model="answer"
          :options="options"
          @change="answerChanged"
        />

        <!-- Upload file -->
        <upload-file-button class="ml-1" @fileToUpload="(file) => $emit('fileToUpload', file)" />
    
        <!-- Add improvement -->
        <b-button
          v-if="allowAddImprovement"
          class="ml-1"
          size="sm"
          :variant="behaviourHasImprovement ? 'warning' : 'info'"
          @click="isImprovementHandlerSidebarActive = true"
        >
          {{ behaviourHasImprovement ? $t('update_improvement') : $t('add_improvement') }}
        </b-button>
      </div>
  
      <!-- Rating -->
      <div v-if="type === 'rating'" class="d-flex justify-content-start align-items-center">
        <b-form-rating
          v-model="answer"
          size="lg"
          inline="inline"
          no-border="no-border"
          variant="warning"
          @change="answerChanged"
        />
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          :disabled="ratingNA === 'N/A'"
          @click="setRatingNA"
        >
          N/A
        </b-button>
        <strong v-show="answer || ratingNA" :style="{ fontSize: '1.15rem' }" class="mr-1">
          {{ answer ? answer : ratingNA }}
        </strong>
  
        <!-- Upload file -->
        <upload-file-button @fileToUpload="(file) => $emit('fileToUpload', file)" />
      </div>
  
      <!-- Text -->
      <b-form-textarea
        v-if="type === 'text'"
        v-model="answer"
        rows="3"
        @change="answerChanged"
      />
  
      <!-- Statement -->
      <div v-if="type === 'statement'">
        <b-card
          v-html="showSeeMoreButton && showShortStatement ? shortStatement : name"
          class="mb-0"
        />
        <b-button
          v-if="showSeeMoreButton"
          size="sm"
          :variant="showShortStatement ? 'info' : 'warning'"
          @click="showShortStatement = !showShortStatement"
        >
          {{ showShortStatement ? $t('message.see_more') : $t('message.see_less') }}
        </b-button>
      </div>

      <!-- Default text field for comments -->
      <b-form-input
        v-model="comment"
        class="mt-1"
        :placeholder="$t('placeholder.optionalComment')"
        @change="commentChanged"
      />
    </b-form-group>
  
    <!-- <commitment-handler-sidebar
      v-model="isCommitmentHandlerSidebarActive"
      :commitment="commitment"
      :behaviourHasCommitment="behaviourHasCommitment"
      :clear-commitment-data="clearCommitmentData"
      handlerId="commitment"
      @update-commitment="updateCommitment"
      @remove-commitment="removeCommitment"
    /> -->

    <todo-task-handler-sidebar
      v-model="isImprovementHandlerSidebarActive"
      :task="improvement"
      :behaviourHasImprovement="behaviourHasImprovement"
      :clear-task-data="clearImprovementData"
      @add-task="updateImprovement"
      @update-task="updateImprovement"
      @remove-task="removeImprovement"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BFormGroup, BFormRadioGroup, BFormRating, BFormTextarea, BButton } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import UploadFileButton from '@/views/habit/confirmation/shared/UploadFileButton.vue'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRating,
    BFormTextarea,
    BButton,
    TodoTaskHandlerSidebar,
    UploadFileButton,
  },

  props: {
    behaviour: {
      type: Object,
      required: true,
    },
    allowAddImprovement: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      required: true,
    },
    activityName: {
      type: String,
      required: true,
    },
    excludeNAOption: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: this.behaviour.name,
      type: this.behaviour.type,
      answer: this.behaviour.answer,
      comment: this.behaviour.comment,
    }
  },

  computed: {
    shortStatement() {
      if (this.type === 'statement') {
        return this.name.slice(0, 100) + "..."
      }
    },
    showSeeMoreButton() {
      return this.type === 'statement' && this.name?.length > 100 && !this.name?.startsWith("<p><a href")
    }
  },

  methods: {
    answerChanged(answer) {
      if (this.type === 'rating') {
        if (!answer) return
        this.ratingNA = ''
      }
      this.$emit('answered', answer)
    },
    commentChanged(comment) {
      this.$emit('commented', comment)
    },
  },

  setup(props, { emit }) {
    const answer = ref(null)
    const ratingNA = ref('')
    const showShortStatement = ref(true)
    const today = new Date()
    const comment = ref('')

    const options = [
      { text: i18n.t('message.yes'), value: 'true' },
      { text: 'No', value: 'false' },
      { text: 'N/A', value: 'unknown' },
    ]

    if (props.excludeNAOption) options.pop()

    const setRatingNA = () => {
      answer.value = null
      ratingNA.value = 'N/A'
      emit('answered', ratingNA.value)
    }

    // Improvement sidebar
    const isImprovementHandlerSidebarActive = ref(false)
    const behaviourHasImprovement = ref(false)

    const blankImprovement = {
      client_id: { link: props.clientId },
      note: '',
      assignee: '',
      createdAt: today,
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      activity: props.activityName,
      behaviour: {
        name: props.behaviour.name,
        type: props.behaviour.type,
      },
      // objective: '',
    }

    const improvement = ref(JSON.parse(JSON.stringify(blankImprovement)))

    const clearImprovementData = () => {
      // improvement.value = JSON.parse(JSON.stringify(blankImprovement))
    }

    const updateImprovement = (improvementData) => {
      // Wait untill the sidebar closing transition is finished
      setTimeout(() => {
        behaviourHasImprovement.value = true
        emit('improvementModified', improvementData)
      }, 100)
    }

    const removeImprovement = () => {
      isImprovementHandlerSidebarActive.value = false

      // Wait untill the sidebar closing transition is finished
      setTimeout(() => {
        behaviourHasImprovement.value = false
        improvement.value = JSON.parse(JSON.stringify(blankImprovement))
        emit('improvementRemoved')
      }, 100)
    }

    return {
      answer,
      ratingNA,
      options,
      setRatingNA,
      showShortStatement,
      isImprovementHandlerSidebarActive,
      improvement,
      clearImprovementData,
      updateImprovement,
      removeImprovement,
      behaviourHasImprovement,
      comment,
    }
  },
}
</script>