<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true"
          :roleFilterShow="true"
          :dateRangeFilterShow="true"
          :workerFilterShow="true",
          :creatorFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter"
        )
  
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!recognitionsTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_recognitions_found') }}

    div(v-else)
      b-row
        b-col(sm="4")
          statistic-card-vertical(:statistic="recognitionsTotal")

      b-row
        b-col(lg="12")
          bar-chart-worker(
            v-if="workerRecognitions.labels && workerRecognitions.labels.length",
            :workers-data="workerRecognitions"
          )
        b-col(lg="12")
          bar-chart-creator(
            v-if="creatorRecognitions.labels && creatorRecognitions.labels.length",
            :creators-data="creatorRecognitions"
          )
        b-col(lg="12")
          bar-chart-location(
            v-if="locationRecognitions.labels && locationRecognitions.labels.length",
            :locations-data="locationRecognitions"
          )
        b-col(cols="12")
          heat-map-recognitions(
            v-if="recognitionsPerDay.length",
            :recognitions-per-day-data="recognitionsPerDay"
          )

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.date') }}
              th {{ $t('message.tableHeader.worker') }}
              //- th {{ $t('message.tableHeader.supervisors') }}
              th {{ $t('message.tableHeader.location') }}
              th {{ $t('message.tableHeader.motive') }}
              th {{ $t('message.tableHeader.note') }}
              th {{ $t('message.tableHeader.created_by') }}
              th(v-if="userRole === 'consultant'") {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(recognition, index) in recognitions", :key="index")
              b-td {{ recognition.dateString }}
              b-td {{ recognition.worker }}
              //- b-td {{ recognition.supervisors }}
              b-td {{ recognition.location }}
              b-td {{ recognition.motive }}
              b-td {{ recognition.note }}
              b-td {{ recognition.created_by }}
              b-td(v-if="userRole === 'consultant'")
                user-button(@clicked="router.push({name: 'apps-users-view', params: {id: recognition.workerId}})")
                delete-button(v-b-modal="`modal ${index}`")
                b-modal(
                  :id="`modal ${index}`",
                  centered,
                  no-close-on-backdrop,
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')",
                  :ok-title="$t('message.delete')",
                  :cancel-title="$t('message.cancel')",
                  @ok="deleteRecognition(recognition.id, deletedSuccess)",
                )
                  | {{ $t('message.confirm_delete_recognition') }}

      b-button(:disabled="!recognitions.length")
        json-excel(:data="recognitions", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>

<script>
import { getRecognitions, updateRecognition } from "@/@core/queries/recognitions";
import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import store from "@/store";
import useNotifications from "@/composables/useNotifications";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BModal, VBModal } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import StatisticCardVertical from './charts/StatisticCardVertical.vue'
import BarChartWorker from './charts/ChartjsHorizontalBarChartWorker.vue'
import BarChartCreator from './charts/ChartjsHorizontalBarChartCreator.vue'
import BarChartLocation from './charts/ChartjsHorizontalBarChartLocation.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import router from '@/router'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import useCommon from '@/views/organization/useCommon'
import HeatMapRecognitions from './charts/ApexHeatMapRecognitions.vue'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BModal,
    JsonExcel,
    StatisticCardVertical,
    BarChartWorker,
    BarChartCreator,
    BarChartLocation,
    Filters,
    UserButton,
    DeleteButton,
    Loading,
    HeatMapRecognitions,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError } = useCommon()

    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const clientId = userRole !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const recognitions = ref([]);
    const recognitionsTotal = ref(0);
    const workerRecognitions = ref({})
    const creatorRecognitions = ref({})
    const locationRecognitions = ref({})
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const roleFilter = ref([]);
    const workerFilter = ref([]);
    const creatorFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    // const monthFilter = ref(now.getMonth())
    // const yearFilter = ref(now.getFullYear())
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const recognitionsPerDay = ref([])
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const excelFields = ref({
      [i18n.t('message.tableHeader.date')]: "dateString",
      [i18n.t('message.tableHeader.worker')]: "worker",
      // [i18n.t('message.tableHeader.supervisors')]: "supervisors",
      [i18n.t('message.tableHeader.location')]: "location",
      [i18n.t('message.tableHeader.motive')]: "motive",
      [i18n.t('message.tableHeader.note')]: "note",
      [i18n.t('message.tableHeader.created_by')]: "created_by",
      [i18n.t('message.tableHeader.id')]: "id",
    })
    const { getDatesFromRange } = useCommonDashboards()

    async function listRecognitions() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)
      
      let conditions = {
        client_id: { _id: clientId },
        worker: {},
        date_gte: startFilter,
        date_lt: endFilter,
        OR: [
          { deleted: false },
          { deleted_exists: false },
        ]
      };

      if (locationFilter.value.length > 0) {
        const locationsFilterQuery = locationFilter.value.map(e => ({locations_in: {_id: e}}))
        conditions.worker.AND = [{OR: locationsFilterQuery}]
      }

      if (zoneFilter.value.length > 0) {
        const zonesFilterQuery = zoneFilter.value.map(e => ({locations_in: {zone: e}}))
        if (conditions.worker.AND) conditions.worker.AND.push({OR: zonesFilterQuery})
        else conditions.worker.AND = [{OR: zonesFilterQuery}]
      }

      if (agencyFilter.value.length > 0) {
        const agenciesFilterQuery = agencyFilter.value.map(e => ({locations_in: {agency: e}}))
        if (conditions.worker.AND) conditions.worker.AND.push({OR: agenciesFilterQuery})
        else conditions.worker.AND = [{OR: agenciesFilterQuery}]
      }

      if (roleFilter.value.length > 0) {
        conditions.worker.roles_in = roleFilter.value
      }

      if (workerFilter.value.length > 0) {
        conditions.worker._id_in = workerFilter.value
      }

      if (creatorFilter.value.length > 0) {
        conditions.created_by = {
          _id_in: creatorFilter.value,
        };
      }

      axios
        .post("/graphql", {
          query: getRecognitions,
          variables: { query: conditions, limit: 15000, sortBy: "DATE_ASC" },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          parseRecognitionData(data, startFilter, endFilter)
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_recognitions_list') })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    // Data for adherence XLSX File
    function parseRecognitionData(data, startFilter, endFilter) {
      let dailyRecognitionsData = { total: 0 }
      let dynamicDate = startFilter

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = dynamicDate.getTimezoneOffset() / 60
      dynamicDate.setHours(dynamicDate.getHours() + timezoneOffsetHours)
      endFilter.setHours(endFilter.getHours() + timezoneOffsetHours)

      while (dynamicDate < endFilter) {
        const dateToAdd = dynamicDate.toLocaleDateString(`${default_language || 'en'}-US`)
        dailyRecognitionsData[dateToAdd] = { recognitions: 0 }
        dynamicDate = new Date(dynamicDate.getFullYear(), dynamicDate.getMonth(), dynamicDate.getDate() + 1)
      }

      let recognitionData = []
      let workerData = {}
      let creatorData = {}
      let locationData = {}
      let dailyLocationData = {}
      let workerMotives = new Set()
      let creatorMotives = new Set()
      let locationMotives = new Set()
      let addRutField = false

      for (const recognition of data.data.recognitions) {
        const date = new Date(recognition.date)
        date.setHours(date.getHours() + timezoneOffsetHours)
        const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)
        const worker = recognition.worker?.name
        const workerId = recognition.worker?._id
        const creator = recognition.created_by?.name
        const creatorId = recognition.created_by?._id
        const locations = recognition.worker?.locations
        const rut = recognition.worker?.rut
        const motive = recognition.motive?.label
        recognitionData.push({
          date,
          dateString,
          worker: worker,
          // supervisors: recognition.worker?.supervisors.map(s => s.name).join(" / "),
          location: locations?.map(e => e.location).join(" / "),
          motive: motive,
          note: recognition.note,
          created_by: creator,
          id: recognition._id,
          rut: rut,
          points: 1000,
          workerId: workerId
        });

        // Data for charts
        // Bar chart
        if (worker) updateObjectData(workerData, worker, workerId, workerMotives, motive)
        if (creator) updateObjectData(creatorData, creator, creatorId, creatorMotives, motive)
        if (locations?.length) {
          for (const location of locations) {
            if (locationFilter.value.length && !locationFilter.value.includes(location._id)) continue
            updateObjectData(locationData, location.location, location._id, locationMotives, motive)

            // Heat map
            if (!dailyLocationData.hasOwnProperty(location.location)) {
              dailyLocationData[location.location] = JSON.parse(JSON.stringify(dailyRecognitionsData))
              dailyLocationData[location.location].id = location._id
            }
            dailyLocationData[location.location][dateString].recognitions++
            dailyLocationData[location.location].total++
          }
        }

        // Add RUT excel field if there is any worker with RUT
        if (rut && !addRutField) addRutField = true
      }

      recognitions.value = recognitionData
      recognitionsTotal.value = recognitionData.length
      if (addRutField) {
        excelFields.value = {
          [i18n.t('message.tableHeader.rut')]: "rut",
          [i18n.t('message.tableHeader.points')]: "points",
          [i18n.t('message.tableHeader.motive')]: "motive",
          [i18n.t('message.tableHeader.worker')]: "worker",
          ...excelFields.value
        }
      }

      // Set charts data
      workerRecognitions.value = setChartsData(workerData, workerMotives)
      creatorRecognitions.value = setChartsData(creatorData, creatorMotives)
      locationRecognitions.value = setChartsData(locationData, locationMotives)
    
      // Heat map
      const heatMapData = []

      for (const location in dailyLocationData) {
        const heatMapLocationData = {
          name: location,
          data: [],
          total: dailyLocationData[location].total,
          locationId: dailyLocationData[location].id
        }

        delete dailyLocationData[location].total
        delete dailyLocationData[location].id

        for (const date in dailyLocationData[location]) {
          heatMapLocationData.data.push({
            x: date,
            y: dailyLocationData[location][date].recognitions,
          })
        }

        heatMapData.push(heatMapLocationData)
      }

      // Sort heat map data
      heatMapData.sort(function (a, b) {
        if (b.total === a.total) {
          if (a.name > b.name) return -1
          if (a.name < b.name) return 1
          return 0
        }
        return a.total - b.total
      })

      recognitionsPerDay.value = heatMapData
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      workerFilter.value = data.workerFilter;
      creatorFilter.value = data.creatorFilter;
      // monthFilter.value = data.monthFilter;
      // yearFilter.value = data.yearFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listRecognitions();
    }

    const deletedSuccess = (success) => {
      if (success) {
        listRecognitions();
        showSuccessMessage(i18n.t('message.recognition_remove'));
      } else {
        showErrorMessage(i18n.t('message.recognition_remove_error'));
      }
    };

    const deleteRecognition = (id, callback) => {
      store
        .dispatch('recognitionStoreModule/deleteRecognition', {
          query: updateRecognition,
          variables: { query: { _id: id }, data: { deleted: true } },
        })
        .then(() => {
          callback(true)
        })
        .catch(() => {
          callback(false)
        })
    }

    const updateObjectData = (object, property, propertyId, motivesSet, motive) => {
      if (object.hasOwnProperty(property)) {
        if (motive) {
          if (object[property].hasOwnProperty(motive)) object[property][motive]++
          else object[property][motive] = 1
          motivesSet.add(motive)
        }
        else {
          if (object[property].hasOwnProperty("_others")) object[property]["_others"]++
          else object[property]["_others"] = 1
          motivesSet.add("_others")
        }
        object[property]["_total"]++
      }
      else {
        if (motive) {
          object[property] = {
            [motive]: 1,
            _total: 1,
            _id: propertyId
          }
          motivesSet.add(motive)
        }
        else {
          object[property] = {
            _others: 1,
            _total: 1,
            _id: propertyId
          }
          motivesSet.add("_others")
        }
      }
    }

    const setChartsData = (objectData, motivesSet) => {
      let dataSorted = Object.entries(objectData)

      dataSorted.sort(function (a, b) {
        return b[1]._total - a[1]._total
      })

      const dataLimit = 10 // Display only the first 10 values in the chart
      const limit = dataSorted.length < dataLimit ? dataSorted.length : dataLimit
      let labels = []
      let labelIds = []
      let values = [...motivesSet].map(e => {
        return {
          motiveLabel: e,
          motiveValues: []
        }
      })

      for (let i = 0; i < limit; i++) {
        labels.push(dataSorted[i][0])
        labelIds.push(dataSorted[i][1]._id)
        values.forEach((e, index) => {
          if (dataSorted[i][1].hasOwnProperty(e.motiveLabel)) {
            values[index].motiveValues.push(dataSorted[i][1][e.motiveLabel])
          }
          else {
            values[index].motiveValues.push(0)
          }
        })
      }

      return {
        labels: labels,
        data: values,
        additionalInfo: {
          labelIds: labelIds,
        }
      }
    }
    
    onMounted(() => {
      listRecognitions()
    })

    return {
      updateFilter,
      recognitions,
      recognitionsTotal,
      workerRecognitions,
      creatorRecognitions,
      locationRecognitions,
      deletedSuccess,
      deleteRecognition,
      excelFields,
      userRole,
      router,
      isLoading,
      zone_agency_filter,
      recognitionsPerDay,
    };
  },
};
</script>
