import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getSingleMetadata, updateMetadata } from '@/@core/queries/metadata'
import i18n from '@/libs/i18n'

export default function useMetadataEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const singleMetadata = ref({})
  const isLoading = ref(false)

  async function show() {
    isLoading.value = true
    store
      .dispatch('metadataStoreModule/show', {
        query: getSingleMetadata,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        const metadataData = data?.data?.metadatum
        if (metadataData?.name) metadataData.nameId = { title: i18n.t(`metadata.${metadataData.name}`), value: metadataData._id }
        if (metadataData?.options) metadataData.optionSelected = metadataData.options[route.value.params.index]
        singleMetadata.value = metadataData
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.metadata_fetch_error'))
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  function update(data) {
    isLoading.value = true
    if (data.options?.value) data.options.value[route.value.params.index] = data.optionSelected?.value
    const metadataOptions = Array.from(new Set(data.options.value))

    store
      .dispatch('metadataStoreModule/update', {
        query: updateMetadata,
        variables: { query: { _id: route.value.params.id }, data: { options: metadataOptions } },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.metadata_option_updated'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.metadata_option_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-metadata-list' })
        isLoading.value = false
      })
  }

  return {
    update,
    show,
    singleMetadata,
    isLoading,
  }
}
