import i18n from '@/libs/i18n'
import { $themeColors } from '@themeConfig'
import axios from "@axios";
import { updateMeeting } from "@core/queries/meeting";
import useNotifications from '@/composables/useNotifications'

export default function useCommonDashboards() {

    const { showErrorMessage } = useNotifications()
    const { positive_happiness_index } = JSON.parse(localStorage.getItem('clientData') || '{}')

    const dateFromObjectId = objectId => {
        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000)
    }
  
    const processAdherence = (score, total) => {
        if (!total) return 0
        return Math.round(score / total * 1000) / 10
    }

    const getDomains = (clientId) => {
        // Corona and Arauco has its own specific domains
        // TODO: Update database with a new collection --> Domain
        if (clientId === '62a3747812694da63fff7eb6') {
            return [
                { value: 'people', text: i18n.t('domain.people'), label: i18n.t('domain.people'), color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'people' } } },
                { value: 'tools_team', text: i18n.t('domain.tools_team'), label: i18n.t('domain.tools_team'), color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'tools_team' } } },
                { value: 'processes', text: i18n.t('domain.processes'), label: i18n.t('domain.processes'), color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'processes' } } },
                { value: 'positive_client_voice', text: i18n.t('domain.positive_client_voice'), label: i18n.t('domain.positive_client_voice'), color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'positive_client_voice' } } },
                { value: 'negative_client_voice', text: i18n.t('domain.negative_client_voice'), label: i18n.t('domain.negative_client_voice'), color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'negative_client_voice' } } },
            ]
        }
        if (clientId === '62cc396b2fb0f0e9a211e513') {
            return [
                { value: 'safety', text: i18n.t('domain.safety'), label: i18n.t('domain.safety'), color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'safety' } } },
                { value: 'processes_productivity', text: i18n.t('domain.processes_productivity'), label: i18n.t('domain.processes_productivity'), color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'processes_productivity' } } },
                { value: 'assets_management', text: i18n.t('domain.assets_management'), label: i18n.t('domain.assets_management'), color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'assets_management' } } },
                { value: 'quality', text: i18n.t('domain.quality'), label: i18n.t('domain.quality'), color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'quality' } } },
                { value: 'sustainability', text: i18n.t('domain.sustainability'), label: i18n.t('domain.sustainability'), color: 'secondary', route: { name: 'apps-todo-tag', params: { tag: 'sustainability' } } },
                { value: 'costs', text: i18n.t('domain.costs'), label: i18n.t('domain.costs'), color: 'dark', route: { name: 'apps-todo-tag', params: { tag: 'costs' } } },
                { value: 'rdp', text: i18n.t('domain.rdp'), label: i18n.t('domain.rdp'), color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'rdp' } } },
            ]
        }
        return [
            { value: 'people', text: i18n.t('domain.people'), label: i18n.t('domain.people'), color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'people' } } },
            { value: 'place', text: i18n.t('domain.place'), label: i18n.t('domain.place'), color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'place' } } },
            { value: 'processes', text: i18n.t('domain.processes'), label: i18n.t('domain.processes'), color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'processes' } } },
            { value: 'customer_need', text: i18n.t('domain.customer_need'), label: i18n.t('domain.customer_need'), color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'customer_need' } } },
        ]
    }

    const colorPalette = [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.info,
        $themeColors.danger,
        $themeColors.secondary,
        $themeColors.dark,
    ]

    const getDatesFromRange = (range) => {
        if (!range) return [null, null]
        const datesArray = range.split(" ")
        const dashSeparator = datesArray[0][2] === "-" || datesArray[0][1] === "-"
        const startArray = dashSeparator ? datesArray[0].split("-") : datesArray[0].split("/")
        const endArray = datesArray[2] ? (dashSeparator ? datesArray[2].split("-") : datesArray[2].split("/")) : startArray
        const startFilter = new Date(Date.UTC(startArray[2], startArray[1] - 1, startArray[0]))
        const endFilter = new Date(Date.UTC(endArray[2], endArray[1] - 1, parseInt(endArray[0]) + 1))
        return [startFilter, endFilter]
    }

    const dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const monthsShort = [
        i18n.t('monthShort.january'),
        i18n.t('monthShort.february'),
        i18n.t('monthShort.march'),
        i18n.t('monthShort.april'),
        i18n.t('monthShort.may'),
        i18n.t('monthShort.june'),
        i18n.t('monthShort.july'),
        i18n.t('monthShort.august'),
        i18n.t('monthShort.september'),
        i18n.t('monthShort.october'),
        i18n.t('monthShort.november'),
        i18n.t('monthShort.december')
    ]

    const updateLocationConditions = (items, category, query) => {        
        const filterQuery = items.map(e => ({locations_in: {[category]: e}}))
        query.OR[0].attendee.AND.push({OR: filterQuery})
        query.OR[1].attendee.AND.push({OR: filterQuery})
        query.OR[2].AND.push({organizer: {OR: filterQuery}})
    }

    const updateDailyDialogueWithKey = (dailyDialogueId, key) => {
        // eslint-disable-next-line import/no-named-as-default-member
        axios
          .post('/graphql', {
            query: updateMeeting,
            variables: {
              query: { _id: dailyDialogueId },
              data: { imageKey: key },
            },
          })
          .then(() => {})
          .catch((error) => {
            console.log(error)
            showErrorMessage(i18n.t('message.daily_dialogue_update_error'))
          })
    }

    const sumTimes = (time1, time2) => {
        const splitTime1 = time1.split(':')
        const splitTime2 = time2.split(':')

        let hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0])
        let minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1])
        let second = parseInt(splitTime1[2]) + parseInt(splitTime2[2])

        // Fix seconds, example: 400:60:124 -> 400:62:04
        minute = minute + (Math.floor(second/60)) // 124 / 60 = 2.07 -> +2 minutes
        second = Math.floor(second%60) // 124 % 60 = 4 -> 4 seconds

        // Fix minutes, example: 400:62:04 -> 401:02:04
        hour = hour + (Math.floor(minute/60)) // 62 / 60 = 1.03 -> +1 hour
        minute = Math.floor(minute%60) // 62 % 60 = 2 -> 2 minutes
        
        // Formatting fields
        hour = (hour < 10 ? "0" : "") + hour
        minute = (minute < 10 ? "0" : "") + minute
        second = (second < 10 ? "0" : "") + second

        return `${hour}:${minute}:${second}`;
    }

    const getItemsPaginated = (items, currentPage, perPage) => {
        const lastIndex = currentPage * perPage
        const firstIndex = lastIndex - perPage
        return items.slice(firstIndex, lastIndex)
    }

    const happinessIndex = (happy, neutral, sad) => {
        if (!happy && !neutral && !sad) return 0

        // The happiness index calculation depends on the client
        const dividend = positive_happiness_index ? happy + neutral / 2 : happy - sad

        return Math.round(dividend / (happy + neutral + sad) * 1000) / 10
    }

    return {
        dateFromObjectId,
        processAdherence,
        getDomains,
        colorPalette,
        getDatesFromRange,
        dateDiffInDays,
        monthsShort,
        updateLocationConditions,
        updateDailyDialogueWithKey,
        sumTimes,
        getItemsPaginated,
        happinessIndex,
    }
}