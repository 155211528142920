<template lang="pug">
div
  Form(:data="control", :isSubmitting="isSubmitting", @submit="update")
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
import useControlEdit from './useControlEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { show, update, control, isSubmitting } = useControlEdit()

    onMounted(show)

    return {
      control,
      update,
      isSubmitting,
    }
  },
}
</script>
