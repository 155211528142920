<template>
  <div>
    <b-sidebar
      id="sidebar-problem-solving-handler"
      sidebar-class="sidebar-lg"
      :visible="isProblemSolvingHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-problem-solving-handler-sidebar-active', val)"
      @hidden="clearForm(); resetVariables()"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="problemSolvingLocal._id"
            size="sm"
            :variant="problemSolvingLocal.completed ? 'outline-success' : 'outline-secondary'"
            @click="problemSolvingLocal.completed = !problemSolvingLocal.completed"
          >
            {{ problemSolvingLocal.completed ? $t('completed') : $t('mark_complete') }}
          </b-button>
          <h5
            v-else
            class="mb-0"
          >
            {{ $t('add_problem_solving') }}
          </h5>
          <div>
            <feather-icon
              v-show="problemSolvingLocal._id && !problemSolvingLocal.deleted"
              icon="TrashIcon"
              class="cursor-pointer"
              v-b-modal="handlerId"
            />
            <b-modal
              :id="handlerId"
              centered
              no-close-on-backdrop
              modal-class="modal-danger"
              ok-variant="danger"
              cancel-variant="outline-secondary"
              :title="$t('message.confirm_action')"
              :ok-title="$t('message.delete')"
              :cancel-title="$t('message.cancel')"
              @ok="$emit('remove-problemSolving', problemSolvingLocal, commitmentsToDelete); hide();"
            >
              {{ $t('message.confirm_delete_problem_solving') }}
            </b-modal>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': problemSolvingLocal.important }"
              @click="problemSolvingLocal.important = !problemSolvingLocal.important"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- ID -->
            <validation-provider
              v-if="problemSolvingLocal._id"
              #default="validationContext"
              :name="$t('label.id_RdP')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.id_RdP')"
                label-for="problem-solving-id"
              >
                <b-form-input
                  id="problem-solving-id"
                  v-model="problemSolvingLocal._id"
                  :state="getValidationState(validationContext)"
                  disabled
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.problem_statement')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.problem_statement')"
                label-for="problem-solving-title"
              >
                <b-form-input
                  id="problem-solving-title"
                  v-model="problemSolvingLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('placeholder.problem_statement')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Leader -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.leader_RdP')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.leader_RdP')"
                label-for="leader"
              >
                <v-select
                  v-model="problemSolvingLocal.leader"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="workersTotalNotMapped"
                  label="name"
                  class="assignee-selector"
                  input-id="leader"
                >

                  <template #option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Add commitment -->
            <b-form-group
              :label="$t('label.commitments')"
              label-for="task-commitments"
            >
              <ul class="list-group mb-1">
                <li
                  v-for="(commitment, index) in problemSolvingLocal.improvements"
                  :key="index"
                  class="list-group-item d-flex justify-content-between align-items-center pt-0 pb-0"
                >
                  <span>{{ commitment.note.length > 30 ? commitment.note.slice(0, 30) + "..." : commitment.note }}</span>
                  <div>
                    <EditButton @clicked="handleCommitmentEdit(commitment, index)"/>
                    <DeleteButton v-b-modal="'commitment list' + index"/>
                    <b-modal
                      :id="'commitment list' + index"
                      centered
                      no-close-on-backdrop
                      modal-class="modal-danger"
                      ok-variant="danger"
                      cancel-variant="outline-secondary"
                      :title="$t('message.confirm_action')"
                      :ok-title="$t('message.delete')"
                      :cancel-title="$t('message.cancel')"
                      @ok="removeCommitment(commitment, index);"
                    >
                      {{ $t('message.confirm_delete_commitment') }}
                    </b-modal>
                  </div>
                </li>
              </ul>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                type="button"
                @click="isCommitmentSidebarActive = true"
              >
                {{ $t('add_commitment') }}
              </b-button>
            </b-form-group>

            <hr>
            
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ problemSolvingLocal._id ? $t('message.update') : $t('message.add') }}
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('message.reset') }}
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>

        <!-- Commitment Sidebar -->
        <commitment-sidebar
          v-model="isCommitmentSidebarActive"
          :commitment="commitment"
          :commitmentIndex="commitmentIndex"
          :clear-commitment-data="clearCommitmentData"
          handlerId="commitment"
          @remove-commitment="removeCommitment"
          @add-commitment="addCommitment"
          @update-commitment="updateCommitment"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BModal, VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, onMounted, watch, ref } from '@vue/composition-api'
import useProblemSolvingHandler from './useProblemSolvingHandler'
import useCommon from '@/views/organization/useCommon'
import i18n from '@/libs/i18n'
import store from '@/store'
import CommitmentSidebar from './CommitmentSidebar.vue'
import axios from '@axios'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import { updateImprovement } from '@/@core/queries/improvements'
import useNotifications from '@/composables/useNotifications'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,

    // 3rd party packages
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // Other
    CommitmentSidebar,
    EditButton,
    DeleteButton,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isProblemSolvingHandlerSidebarActive',
    event: 'update:is-problem-solving-handler-sidebar-active',
  },
  props: {
    isProblemSolvingHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    problemSolving: {
      type: Object,
      required: true,
    },
    clearProblemSolvingData: {
      type: Function,
      required: true,
    },
    handlerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const {
      problemSolvingLocal,
      resetProblemSolvingLocal,
      onSubmit,
      commitmentsToUpdate,
      commitmentsToDelete,
    } = useProblemSolvingHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetProblemSolvingLocal, props.clearProblemSolvingData)

    const isCommitmentSidebarActive = ref(false)
    const commitmentIndex = ref(-1)
    const now = new Date()
    
    const { getWorkersTotalForDropDown, workersTotalNotMapped, getMetadataForDropDown, metadataNotMapped } = useCommon()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const workerId = userData.worker_id.$oid;

    let blankCommitment = {
      _id: null,
      client_id: { link: clientId },
      note: '',               // Action
      description: '',        // Root cause
      assignee: null,         // Responsible
      dueDate: new Date(),
      deleted: false,
    }
    const commitment = ref(JSON.parse(JSON.stringify(blankCommitment)))
    const clearCommitmentData = () => {
      commitment.value = JSON.parse(JSON.stringify(blankCommitment))
      commitmentIndex.value = -1
    }

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default values for commitment metadata
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          if (e.name === "instance") e.answer = "RdP"
        })
        blankCommitment.metadata = val
        if (!commitment.value._id) commitment.value = {...commitment.value, metadata: val}
      }
    })

    const handleCommitmentEdit = (commitmentData, index) => {
      commitment.value = commitmentData
      commitmentIndex.value = index
      isCommitmentSidebarActive.value = true
    }

    const addCommitment = commitmentData => {
      problemSolvingLocal.value.improvements.push(commitmentData)
    }

    const updateCommitment = (commitmentData, index) => {
      problemSolvingLocal.value.improvements[index] = commitmentData

      if (commitmentData._id) {
        let commitmentDataCopy = {...commitmentData}

        if (commitmentDataCopy.assignee) {
          commitmentDataCopy.assignee = { link: commitmentDataCopy.assignee._id }
        }

        commitmentDataCopy.dueDate = commitmentDataCopy.dueDate ? new Date(`${commitmentDataCopy.dueDate.slice(0, 10)} 12:00:00`) : null

        commitmentsToUpdate.value.push(commitmentDataCopy)
      }
    }

    const removeCommitment = (commitmentData, index) => {
      problemSolvingLocal.value.improvements.splice(index, 1)
      if (commitmentData._id) {
        commitmentData.deleted = true
        commitmentsToDelete.value.push(commitmentData)
      }
    }

    const resetVariables = () => {
      // Close Commitment Sidebar if open
      isCommitmentSidebarActive.value = false
      
      // Empty commitmentsToUpdate and commitmentsToDelete arrays
      commitmentsToUpdate.value = []
      commitmentsToDelete.value = []
    }

    onMounted(async () => {
      await getWorkersTotalForDropDown("notMapped")
      await getMetadataForDropDown({category: "improvement", option: "notMapped"})
    })

    return {
      // Add New
      problemSolvingLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Workers dropdown
      workersTotalNotMapped,

      resetVariables,
      isCommitmentSidebarActive,
      commitment,
      clearCommitmentData,
      removeCommitment,
      addCommitment,
      updateCommitment,
      handleCommitmentEdit,
      commitmentIndex,
      commitmentsToDelete,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
