<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="PenToolIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              :disabled="true"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>

      <!-- Problem Solving List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-show="!isLoading"
          v-model="problemSolvings"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="problemSolving in problemSolvings"
            :key="problemSolving._id"
            class="todo-item"
            :class="{ 'completed': problemSolving.completed }"
            @click="handleProblemSolvingClick(problemSolving)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="problemSolving.completed"
                    @click.native.stop
                    @change="updateProblemSolvingIsCompleted(problemSolving)"
                  />
                  <span class="todo-title">{{ problemSolving.title }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <!-- <div class="badge-wrapper mr-1">
                  <b-badge
                    v-for="tag in problemSolving.tags"
                    :key="tag"
                    pill
                    :variant="`light-${resolveTagVariant(tag)}`"
                    class="text-capitalize"
                  >
                    {{ $t(`domain.${tag}`) }}
                  </b-badge>
                </div> -->
                <!-- <small :class="`text-nowrap mr-1 ${setDateColor(problemSolving.dueDate, problemSolving.completed)}`">
                  {{ formatDate(problemSolving.dueDate, { month: 'short', day: 'numeric'}) }}
                </small> -->
                <b-avatar
                  v-if="problemSolving.leader"
                  size="32"
                  variant="light-primary"
                  :text="avatarText(problemSolving.leader.name)"
                />
                  <!-- :src="problemSolving.assignee.avatar"    optional attribute inside b-avatar-->
                <b-avatar
                  v-else
                  size="32"
                  variant="light-secondary"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </div>
          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !isLoading && !problemSolvings.length}"
        >
          <h5>{{ $t('message.no_problem_solvings_found') }}</h5>
        </div>
        <loading
          v-if="isLoading"
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        />
        <!-- <div
          class="no-results"
          :class="{'show': isLoading}"
        >
          <h5>{{ $t('message.loading_improvements') }}</h5>
        </div> -->
      </vue-perfect-scrollbar>
    </div>

    <!-- Problem Solving Handler -->
    <problem-solving-handler-sidebar
      v-model="isProblemSolvingHandlerSidebarActive"
      :problemSolving="problemSolving"
      :clear-problem-solving-data="clearProblemSolvingData"
      handlerId="problem-solving"
      @remove-problemSolving="removeProblemSolving"
      @add-problemSolving="addProblemSolving"
      @update-problemSolving="updateProblemSolving"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <problem-solving-left-sidebar
        :is-problem-solving-handler-sidebar-active.sync="isProblemSolvingHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch, computed, onMounted, onUnmounted } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ProblemSolvingLeftSidebar from './ProblemSolvingLeftSidebar.vue'
import problemSolvingStoreModule from './problemSolvingStoreModule'
import ProblemSolvingHandlerSidebar from './ProblemSolvingHandlerSidebar.vue'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import useCommon from "@/views/organization/useCommon";
import { getProblemSolving } from "@/@core/queries/problem-solving"
import { updateImprovement } from '@/@core/queries/improvements'
import axios from '@axios'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    Loading,

    // App SFC
    ProblemSolvingLeftSidebar,
    ProblemSolvingHandlerSidebar,
  },
  setup() {
    const PROBLEM_SOLVING_STORE_MODULE_NAME = 'problem-solving'

    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const workerId = userData.worker_id.$oid;
    const username = userData.username;
    const isLoading = ref(true)
    const categoryFilter = ref("all")
    // const tagFilter = ref("all")
    const now = new Date()
    const nowPlusOneWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)

    const { resolveTagVariant, resolveAvatarVariant, getEmailTemplate } = useCommonTodo()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { singleUpload, sendEmail } = awsConnection()
    const { handleError } = useCommon()

    // Register module
    if (!store.hasModule(PROBLEM_SOLVING_STORE_MODULE_NAME)) store.registerModule(PROBLEM_SOLVING_STORE_MODULE_NAME, problemSolvingStoreModule)

    // Reset store states for filters
    store.commit('problem-solving/SET_SELECTED_LOCATIONS', [])
    store.commit('problem-solving/SET_SELECTED_WORKERS', [])
    
    const { route, router } = useRouter()

    const routeParamsQuery = route.value.query.originId
    // Fetch problemSolvings and get metadata on mounted
    onMounted(() => {
      fetchProblemSolvings()
      if (routeParamsQuery) {
        axios
          .post('/graphql', {
            query: getProblemSolving,
            variables: { query: { _id: routeParamsQuery } }
          })
          .then((response) => {
            if (response.data.errors) throw new Error(response.data.errors[0].message)
            problemSolving.value = response?.data?.data?.problem_solving
            isProblemSolvingHandlerSidebarActive.value = true
          })
          .catch((err) => {
            console.log(err)
            showErrorMessage(i18n.t('message.no_problem_solvings_found'))
          })
      }
    })

    // UnRegister on leave (can't unregister because of 'Add improvement' button in navbar)
    // onUnmounted(() => {
    //   if (store.hasModule(PROBLEM_SOLVING_STORE_MODULE_NAME)) store.unregisterModule(PROBLEM_SOLVING_STORE_MODULE_NAME)
    // })

    // const routeSortBy = computed(() => route.value.query.sort)
    // const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    
    watch(routeParams, val => {
      isLoading.value = true
      categoryFilter.value = val.filter ? val.filter : "all"
      // tagFilter.value = val.tag ? val.tag : "all"
      fetchProblemSolvings()
    })

    const locationFilter = computed(() => store.state[PROBLEM_SOLVING_STORE_MODULE_NAME].selectedLocations)
    const workerFilter = computed(() => store.state[PROBLEM_SOLVING_STORE_MODULE_NAME].selectedWorkers)

    watch([locationFilter, workerFilter], () => {
      isLoading.value = true
      fetchProblemSolvings()
    })

    const problemSolvings = ref([])

    let blankProblemSolving = {
      _id: null,
      client_id: { link: clientId },
      title: '',
      leader: {_id: workerId, name: username},
      deleted: false,
      completed: false,
      important: false,
      improvements: [],
    }
    const problemSolving = ref(JSON.parse(JSON.stringify(blankProblemSolving)))
    const clearProblemSolvingData = () => {
      problemSolving.value = JSON.parse(JSON.stringify(blankProblemSolving))
    }

    const fetchProblemSolvings = () => {
      let query = {
        category: categoryFilter.value,
        // tag: tagFilter.value,
        locations: locationFilter.value,
        workers: workerFilter.value,
      }

      store.dispatch('problem-solving/fetchProblemSolvings', query)
        .then(response => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          problemSolvings.value = response.data?.data?.problem_solvings
        })
        .catch((error) => {
          console.log(error)
          handleError({ error, defaultMessage: i18n.t('message.err_problem_solving_list') })
        })
        .finally(() => isLoading.value = false)
    }

    // TODO: Modularize this function as it's the same used in src > @core > layouts > components > app-navbar > AppNavbarVerticalLayout.vue
    const addProblemSolving = val => {
      store.dispatch('problem-solving/addProblemSolving', {...val, singleUpload})
        .then((response) => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          showSuccessMessage(i18n.t('message.problem_solving_created'))
          
          // Refetch problem solvings to get updated list
          fetchProblemSolvings()

          // Send email to assignee with the details of the commitments created
          if (val.improvements?.length) {
            val.improvements.forEach(i => {
              if (i.assignee?.email) {
                const subject = i18n.t('message.commitment_assigned')
                let bodyData = {
                  name: i.assignee?.name,
                  title: i.note,
                  creator: username,
                  dueDate: `${i.dueDate.slice(8, 10)}/${i.dueDate.slice(5, 7)}/${i.dueDate.slice(0, 4)}`,
                  description: i.description?.replace(/<[^>]*>/g, ''),
                  commitmentFunctionality: true,
                  fromProblemSolving: true
                }
                if (i.metadata?.length) {
                  i.metadata.forEach(e => {
                    if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
                    Object.assign(bodyData, {[e.name]: e.answer})
                  })
                }
                const body = getEmailTemplate(bodyData)
                
                sendEmail([i.assignee.email], subject, body)
                  .then((response) => {
                    if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_commitment_success'))
                  })
                  .catch((err) => {
                    console.log(err)
                    showErrorMessage(i18n.t('message.email_send_commitment_error'))
                  })
              }
            })
          }
          // Get problem solving to obtain the improvements and update each one to add origin and origin_id from problem solving
          const problemSolvingId = response?.data?.data?.insertOneProblem_solving?._id
          if (problemSolvingId) {
            axios
              .post('/graphql', {
                query: getProblemSolving,
                variables: { query: {_id: problemSolvingId} }
              })
              .then((response) => {
                if (response.data.errors) throw new Error(response.data.errors[0].message)
                const improvementsToUpdate = response?.data?.data?.problem_solving?.improvements || []
                improvementsToUpdate.forEach(element => {
                  axios
                    .post('/graphql', {
                      query: updateImprovement,
                      variables: { query: { _id : element._id }, data: { origin_id: problemSolvingId, origin: "problem_solving" } }
                    })
                    .then((response) => {
                      if (response.data.errors) throw new Error(response.data.errors[0].message)
                    })
                    .catch((err) => {
                      console.log(err)
                      showErrorMessage(i18n.t('message.improvement_update_error'))
                    })
                });
              })
              .catch((err) => {
                console.log(err)
                showErrorMessage(i18n.t('message.improvement_update_error'))
              })
          }
        })
        .catch((error) => {
          showErrorMessage(i18n.t('message.problem_solving_error'))
          console.log(error)
        })
    }

    const updateProblemSolving = (problemSolvingData, action, commitmentsToUpdate = [], commitmentsToDelete = []) => {
      store.dispatch('problem-solving/updateProblemSolving', {...problemSolvingData, singleUpload})
        .then((response) => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          if (action !== "complete") showSuccessMessage(i18n.t('message.problem_solving_updated'))

          fetchProblemSolvings()

          // Send email to assignee with the details of the commitments created and updated
          const allImprovements = problemSolvingData.improvements
            ? [...problemSolvingData.improvements, ...commitmentsToDelete]
            : [...commitmentsToDelete]

          if (allImprovements.length) {
            for (const i of allImprovements) {
              // If improvement is not new or flagged for update/delete or assigne doesn/t have email then continue (don't send email)
              if (i._id && !commitmentsToUpdate.find(e => e._id === i._id) && !commitmentsToDelete.find(e => e._id === i._id) || !i.assignee?.email) {
                continue
              }

              const updatedState = !i._id
                ? "added"
                : commitmentsToDelete.find(e => e._id === i._id)
                  ? "deleted"
                  : "updated"

              const subject = updatedState === "added"
                ? i18n.t('message.commitment_assigned')
                : updatedState === "deleted"
                  ? i18n.t('message.commitment_deleted')
                  : i18n.t('message.commitment_updated')

              let bodyData = {
                name: i.assignee?.name,
                title: i.note,
                creator: username,
                dueDate: `${i.dueDate.slice(8, 10)}/${i.dueDate.slice(5, 7)}/${i.dueDate.slice(0, 4)}`,
                description: i.description?.replace(/<[^>]*>/g, ''),
                commitmentFunctionality: true,
                fromProblemSolving: true,
                updatedState,
                completed: i.completed,
                deleted: i.deleted,
              }
              if (i.metadata?.length) {
                i.metadata.forEach(e => {
                  if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
                  Object.assign(bodyData, {[e.name]: e.answer})
                })
              }
              const body = getEmailTemplate(bodyData)
              
              sendEmail([i.assignee.email], subject, body)
                .then((response) => {
                  if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_commitment_success'))
                })
                .catch((err) => {
                  console.log(err)
                  showErrorMessage(i18n.t('message.email_send_commitment_error'))
                })
            }
          }
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.problem_solving_update_error'))
        })
    }

    const removeProblemSolving = (problemSolvingData, commitmentsToDelete) => {
      store.dispatch('problem-solving/removeProblemSolving', { problemSolvingData, commitmentsToDelete })
        .then((response) => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          showSuccessMessage(i18n.t('message.problem_solving_deleted'))

          fetchProblemSolvings()
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.problem_solving_delete_error'))
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isProblemSolvingHandlerSidebarActive = ref(false)

    // Search Query
    const searchQuery = ref("")
    // const searchQuery = ref(routeQuery.value)
    // watch(routeQuery, val => {
    //   searchQuery.value = val
    // })
    // eslint-disable-next-line no-use-before-define
    // watch([searchQuery, sortBy], () => fetchProblemSolvings())
    const updateRouteQuery = () => {}
    // const updateRouteQuery = val => {
    //   const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

    //   if (val) currentRouteQuery.q = val
    //   else delete currentRouteQuery.q

    //   router.replace({ name: route.name, query: currentRouteQuery })
    // }

    const handleProblemSolvingClick = problemSolvingData => {
      problemSolving.value = problemSolvingData
      isProblemSolvingHandlerSidebarActive.value = true
    }

    // Single Problem Solving isCompleted update
    const updateProblemSolvingIsCompleted = problemSolvingData => {
      // eslint-disable-next-line no-param-reassign
      problemSolvingData.completed = !problemSolvingData.completed
      updateProblemSolving(problemSolvingData, "complete")
    }

    // const setDateColor = (problemSolvingDate, completed) => {
    //   if (completed) return "text-success"
    //   const date = new Date(problemSolvingDate)
    //   if (date < now) return "text-danger"
    //   if (date > nowPlusOneWeek) return "text-muted"
    //   return "text-warning"
    // }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      problemSolving,
      problemSolvings,
      removeProblemSolving,
      addProblemSolving,
      updateProblemSolving,
      clearProblemSolvingData,
      searchQuery,
      fetchProblemSolvings,
      perfectScrollbarSettings,
      updateRouteQuery,
      // resetSortAndNavigate,
      isLoading,
      // setDateColor,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isProblemSolvingHandlerSidebarActive,

      // Click Handler
      handleProblemSolvingClick,

      // Filters
      formatDate,
      avatarText,

      // Single Problem Solving isCompleted update
      updateProblemSolvingIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      routeParamsQuery
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
