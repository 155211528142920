// Amazon Web Services

import axios from 'axios'
import axiosIns from '@axios'
import aws from 'aws-sdk'
import useNotifications from '@/composables/useNotifications'
import i18n from "@/libs/i18n";
import endpoints from '@/libs/endpoints'
import { getSecretValues } from '@/@core/queries/values';

export default function awsConnection() {

  const { showErrorMessage } = useNotifications()

  // AWS Configuration
  const getConfig = (accessKey, secretAccessKey) => {
    const config = {
      accessKeyId: accessKey,
      secretAccessKey: secretAccessKey,
      region: process.env.VUE_APP_AWS_REGION,
    };
  
    return config;
  }

  // Access keys from Realm Values
  const getAccessKeys = async (valueNames) => {
    try {
      const response = await axiosIns.post('/graphql', {
        query: getSecretValues,
        variables: { input: { valueNames } },
      })
      if (response.data.errors) throw new Error(response.data.errors[0].message)
  
      const { SecretValues: { secrets } } = response.data.data || {}

      return secrets
    } catch (error) {
      console.log(error)
    }
  }

  // S3 Buckets

  const valueNamesS3 = [
    process.env.VUE_APP_AWS_S3_ACCESS_KEY_VALUE_NAME,
    process.env.VUE_APP_AWS_S3_SECRET_ACCESS_KEY_VALUE_NAME
  ]

  const getS3Config = (accessKey, secretAccessKey) => {
    aws.config.update({
      accessKeyId: accessKey,
      secretAccessKey: secretAccessKey,
    })
  
    return new aws.S3({
      signatureVersion: 'v4',
      region: process.env.VUE_APP_AWS_REGION,
    })
  }

  const singleUpload = async (fileInfo, destinationFolder) => {
    try {
      // Get S3 access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesS3)

      // S3 configuration
      const s3 = getS3Config(accessKey, secretAccessKey)

      const key = destinationFolder + '/' + Date.now() + '-' + fileInfo.name.replace(/\s/g, '-')
      const params = {
        Bucket: endpoints.aws_bucket,
        Key: key,
        Expires: 10,
        ContentType: fileInfo.type,
      }
      const url = s3.getSignedUrl('putObject', params)

      return new Promise((resolve, reject) => {
        axios
          .put(url, fileInfo.base64 ? fileInfo.base64 : fileInfo, {
            headers: {
              'Content-Type': fileInfo.type,
            },
          })
          .then(result => {
            const bucketUrl = decodeURIComponent(result.request.responseURL).split(key)[0]
            result.key = key
            result.fullPath = bucketUrl + key
            resolve(key)
          })
          .catch(() => {
            showErrorMessage(i18n.t('message.upload_file_error'))
            reject()
          })
      })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.upload_file_error'))
    }
  }
    
  const getFile = async (bucket, key) => {
    try {
      // Get S3 access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesS3)

      // S3 configuration
      const s3 = getS3Config(accessKey, secretAccessKey)

      return new Promise((resolve, reject) => {
        s3.getObject(
          { Bucket: bucket, Key: key },
          function (error, data) {
            if (error != null) {
              reject(error)
            } else {
              let base64Img = new TextDecoder().decode(data.Body)
              resolve(base64Img)
            }
          }
        )
      })
    } catch (error) {
      console.log(error)
    }
  }

  // SES (Amazon Simple Email Service)

  const valueNamesSES = [
    process.env.VUE_APP_AWS_SES_ACCESS_KEY_VALUE_NAME,
    process.env.VUE_APP_AWS_SES_SECRET_ACCESS_KEY_VALUE_NAME
  ]

  const sendEmail = async (emailList, subject, body) => {
    try {
      // Get SES access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesSES)
  
      // SES configuration
      const AWS_SES = new aws.SES(getConfig(accessKey, secretAccessKey))
  
      const params = {
        Source: process.env.VUE_APP_AWS_SOURCE_EMAIL,
        Destination: {
          ToAddresses: emailList,
        },
        ReplyToAddresses: [],
        Message: {
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: body,
            },
          },
          Subject: {
            Charset: 'UTF-8',
            Data: subject,
          }
        },
        // Send emails using the SES default dedicated IP addresses pool.
        ConfigurationSetName: process.env.VUE_APP_AWS_CONFIGURATION_SET,
      };
  
      return AWS_SES.sendEmail(params).promise();
    } catch (error) {
      console.log(error)
    }
  };

  // SQS (Simple Queue Service)

  const valueNamesSQS = [
    process.env.VUE_APP_AWS_SQS_ACCESS_KEY_VALUE_NAME,
    process.env.VUE_APP_AWS_SQS_SECRET_ACCESS_KEY_VALUE_NAME
  ]

  const getMessagesFromQueue = async () => {
    // Get SQS access keys from Realm Values
    const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesSQS)
  
    // SQS configuration
    const AWS_SQS = new aws.SQS(getConfig(accessKey, secretAccessKey))
  
    const params = {
      QueueUrl: process.env.VUE_APP_AWS_SQS_QUEUE_URL,
      MaxNumberOfMessages: 1, // Valid values: 1 to 10. Default: 1.
      VisibilityTimeout: 10, // 30
      WaitTimeSeconds: 0, // Set to 0 for short polling
    };
  
    // APLICAR RECURSIVIDAD
    AWS_SQS.receiveMessage(params, (err, data) => {
      console.log("message received")
      if (err) {
        console.error('Error receiving message:', err);
      } else if (data.Messages) {
        const message = data.Messages[0];
        console.log('Received message:', message);
        // if (!message) continueMessageSearch = false
  
        // Process the message data as needed
  
        // Delete the message from the queue when processing is complete
        // if (!message) return

        // const deleteParams = {
        //   QueueUrl: process.env.VUE_APP_AWS_SQS_QUEUE_URL,
        //   ReceiptHandle: message.ReceiptHandle,
        // };

        // AWS_SQS.deleteMessage(deleteParams, (deleteErr) => {
        //   if (deleteErr) {
        //     console.error('Error deleting message:', deleteErr);
        //   } else {
        //     console.log('Message deleted from the queue.');
        //   }
        // });
      }
    });
  }

  return {
    singleUpload,
    getFile,
    sendEmail,
    getMessagesFromQueue
  }
}