import axios from '@axios'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getLocation, updateLocation } from '@/@core/queries/locations'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'

export default function useClientEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()

  const { route, router } = useRouter()
  const location = ref({})

  async function show() {
    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: getLocation,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        location.value = data.data.location
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.err_client_fetch'))
      })
  }

  function update(data) {
    const payload = {}

    // eslint-disable-next-line no-unused-expressions
    data.location.value ? (payload.location = data.location.value) : null

    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: updateLocation,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.client_updated'))
        router.push({ name: 'organization-location-list' })
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.err_client_update'))
      })
  }

  return {
    location,
    update,
    show,
  }
}
