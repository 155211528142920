import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getRole, updateRole } from '@/@core/queries/roles'
import i18n from '@/libs/i18n'

export default function useRoleEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router, route } = useRouter()
  const item = ref(null)
  const isSubmitting = ref(false)

  const update = async dataObject => {
    isSubmitting.value = true
    dataObject.subroles = { link: dataObject.subroles }

    store
      .dispatch('roleStoreModule/update', { query: updateRole, variables: { query: { _id: route.value.params.id }, data: dataObject } })
      .then(() => {
        showSuccessMessage(i18n.t('message.form_success'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.role_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-role-list' })
        isSubmitting.value = false
      })
  }

  const show = async () => {
    isSubmitting.value = true

    store
      .dispatch('roleStoreModule/show', {
        query: getRole,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(response => {
        item.value = {...response.data.data.role, subroles: response.data.data.role.subroles?.map(({ _id }) => _id)}
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.role_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  return {
    update,
    show,
    item,
    isSubmitting,
  }
}
