<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('complianceByLocation') }}</b-card-title>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        :height="400"
        :data="this.series"
        :options="horizontalBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentHorizontalBarChart from '@/views/habit/charts-components/ChartjsComponentHorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import { EventBus } from "@/views/habit/EventBus.js"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    locationData: {
      type: Object,
      required: true,
    },
    emitToParentComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          hover: {
            mode: "point",
            animationDuration: 0,
          },
          onHover: function(event, chartElement) {
            event.target.style.cursor = chartElement.length ? 'pointer' : 'default';

            // If mouse is over a bar
            if (chartElement.length) {
              // Erase the text value on the bar dataset so that it doesn't overlap with the tooltip
              const datasetIndexHovered = chartElement[0]._datasetIndex
              const datasetHovered = this.chart.data.datasets[datasetIndexHovered]
              datasetHovered.numericalValuesVariable[chartElement[0]._index] = ''

              // This is for the case when the user hovers from one dataset to the other in the same bar so the previous dataset value is restored
              const datasetIndexNotHovered = datasetIndexHovered ? 0 : 1
              const datasetNotHovered = this.chart.data.datasets[datasetIndexNotHovered]
              datasetNotHovered.numericalValuesVariable[chartElement[0]._index] = datasetNotHovered.numericalValuesFixed[chartElement[0]._index]
            } else {
              // Restore all text values on bars
              this.chart.data.datasets.forEach(e => {
                e.numericalValuesVariable = [...e.numericalValuesFixed]
              })
            }
            this.chart.update()
          },
          onClick: (c, i) => {
            let e = i[0];
            if (!e) return
            // var x_value = this.data.labels[e._index];
            // var y_value = this.data.datasets[0].data[e._index];
            const locationId = this.series._additionalInfo.locationIds[e._index]
            if (this.emitToParentComponent) this.$emit('filterByArea', locationId)
            else EventBus.$emit("filterByLocation", locationId)
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: true,
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            mode: "point",
            callbacks: {
              label: function(tooltipItem, data) {
                return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.xLabel}%`
              },
              afterBody: function(tooltipItem, data) {
                return `${i18n.t('message.tableHeader.quantity')}: ${data.datasets[tooltipItem[0].datasetIndex].numericalValuesFixed[tooltipItem[0].index] || 0}`
              }
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 25,
                  fontColor: '#6e6b7b',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: i18n.t('label.compliance'),
                  fontSize: 14
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
          animation: {
            duration: 1,
            onComplete: function () {
              const chartInstance = this.chart;
              const ctx = chartInstance.ctx;
              ctx.font = Chart.helpers.fontString(14, 'bold', Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              ctx.fillStyle = '#ffffff';
 
              this.data.datasets.forEach(function (dataset, i) {
                const meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  const data = dataset.data[index];
                  if (data) {
                    const valueToDisplay = dataset.numericalValuesVariable[index]
                    ctx.fillText(valueToDisplay, (bar._model.x + bar._model.base) / 2, bar._model.y + 7.8);
                  }
                });
              });
            }
          }
        },
      },
    }
  },
  computed: {
    series() {
      const pendingPercentages = this.locationData.compliance?.map(e => 100 - e) || []
      return {
        labels: this.locationData.location,
        datasets: [
          {
            label: i18n.t('Realizadas'),
            data: this.locationData.compliance,
            backgroundColor: $themeColors.success,
            borderColor: 'transparent',
            barThickness: 15,
            numericalValuesFixed: this.locationData.totalDone,
            numericalValuesVariable: [...this.locationData.totalDone]    // This is used in hover to avoid overlap between value on bar and tooltip
          },
          {
            label: i18n.t('Pendientes'),
            data: pendingPercentages,
            backgroundColor: $themeColors.warning,
            borderColor: 'transparent',
            barThickness: 15,
            numericalValuesFixed: this.locationData.totalPending,
            numericalValuesVariable: [...this.locationData.totalPending]    // This is used in hover to avoid overlap between value on bar and tooltip
          },
        ],
        _additionalInfo: {
          locationIds: this.locationData.additionalInfo.locationIds
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
