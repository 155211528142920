<template lang="pug">
div
  Form(
    :data="singleMetadata",
    :metadataFieldDisabled="false",
    :isLoading="isLoading",
    :uploadMode="true"
    @submit="upload"
  )
</template>

<script>
import { ref } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useMetadataUpload from './useMetadataUpload'

export default {
  components: {
    Form,
  },

  setup() {
    const { upload, singleMetadata, isLoading } = useMetadataUpload()

    return {
      singleMetadata,
      upload,
      isLoading,
    }
  },
}
</script>
