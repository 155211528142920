<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('complianceByLocation') }}</b-card-title>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="this.series"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '@/views/habit/charts-components/ChartjsComponentBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import { EventBus } from "@/views/habit/EventBus.js";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  props: {
    complianceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      latestBarChart: {
        // data: {
        //   labels: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12'],
        //   datasets: [
        //     {
        //       data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 230, 280, 190],
        //       backgroundColor: '#28dac6',
        //       borderColor: 'transparent',
        //     },
        //   ],
        // },
        options: {
          onClick: function (c, i) {
            let e = i[0];
            if (!e) return
            // var x_value = this.data.labels[e._index];
            // var y_value = this.data.datasets[0].data[e._index];
            const locationId = this.data._additionalInfo.locationIds[e._index]
            EventBus.$emit("filterByLocation", locationId);
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // display: true,
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function(tooltipItem, data) {
                if (data.datasets[tooltipItem.datasetIndex].type === "line") {
                  return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%'
                }
                return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              }
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                id: "left-y-axes",
                type: "linear",
                position: "left",
                stacked: true,
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  min: 0,
                  precision: 0,
                  fontColor: '#6e6b7b',
                },
                scaleLabel: {
                  display: true,
                  labelString: i18n.t('label.confirmations'),
                  fontSize: 14
                },
              },
              {
                id: "right-y-axes",
                type: "linear",
                position: "right",
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: 25,
                  min: 0,
                  max: 100,
                  fontColor: '#6e6b7b',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: i18n.t('label.compliance'),
                  fontSize: 14
                },
              },
            ],
          },
        },
      },
      // rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  computed: {
    series() {
      return {
        labels: this.complianceData.labels,
        datasets: [
          {
            label: i18n.t('Realizadas'),
            data: this.complianceData.data.done,
            yAxisID: 'left-y-axes',
            backgroundColor: $themeColors.success,
            borderColor: 'transparent',
            barThickness: 15,
            order: 2,
          },
          {
            label: i18n.t('Pendientes'),
            data: this.complianceData.data.pending,
            yAxisID: 'left-y-axes',
            backgroundColor: $themeColors.warning,
            borderColor: 'transparent',
            barThickness: 15,
            order: 2,
          },
          {
            label: i18n.t('Vencidas'),
            data: this.complianceData.data.overdue,
            yAxisID: 'left-y-axes',
            backgroundColor: $themeColors.danger,
            borderColor: 'transparent',
            barThickness: 15,
            order: 2,
          },
          {
            type: 'line',
            label: i18n.t('label.compliance'),
            data: this.complianceData.data.compliance,
            yAxisID: 'right-y-axes',
            backgroundColor: $themeColors.info,
            borderColor: $themeColors.info,
            lineTension: 0,
            fill: false,
            pointRadius: 5,
            order: 1,
          },
        ],
        _additionalInfo: {
          locationIds: this.complianceData.additionalInfo.locationIds
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
