export default [
/*  {
    header: 'Apps',
    roles: {
      admin: ["read", "write"],
      consultant: ["read", "write"],
      supervisor: ["read", "write"],
      client: ["read", "write"],
    }
  },
  {
    title: 'PC Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
    roles: {
      admin: ['read', 'write'],
      consultant: ['read', 'write'],
      supervisor: ['read', 'write'],
      client: ['read', 'write'],
    },
  }, */
  {
    title: 'PC Team',
    icon: 'UsersIcon',
    children: [
      {
        title: 'PC Team',
        route: 'apps-pc-team',
      },
      {
        title: 'PC Programming',
        route: 'apps-programming-team',
      },
    ],
  },
  // {
  //   title: 'Daily Dialogue',
  //   route: 'habit-meeting-new',
  //   icon: 'CoffeeIcon',
  // },
  /* {
    title: 'Continuous Improvement',
    route: 'organization-case-list',
    icon: 'CheckCircleIcon',
  }, */
  {
    title: 'Problem Solving',
    route: 'apps-problem-solving',
    icon: 'PenToolIcon',
  },
  {
    title: 'Clients',
    route: 'apps-clients-list',
    icon: 'UserIcon',
    roles: {
      admin: ['read', 'write'],
    },
  },
]
