<template>
  <b-card>
    <b-row>
      <b-col sm="8" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-crime-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Crime') }}
        </b-button>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refCrimeListTable"
          :items="crimes"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(actions)="data">
            <div class="flex flex-column">
              <!-- <ViewButton /> -->
              <EditButton @clicked="router.push({name: 'organization-crime-edit', params: { id: data.item._id }})"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteCrime(data.item._id)"
              >
                {{ $t('message.confirm_delete_crime') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5, 10, 15, 20, 25, 30, 40, 50, 100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + (crimes.length ? 1 : 0)}`, to: `${crimes.length + ((currentPage * perPage) - perPage)}`, total: `${totalCrimes}` }) }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalCrimes"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BBadge, BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'

import { onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
// import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useCrimeList from './useCrimeList'


export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    // ViewButton,
    DeleteButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: [],
    }
  },
  setup() {
    const { router } = useRouter()
    const {
      fetchCrimes,
      tableColumns,
      perPage,
      currentPage,
      totalCrimes,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      statusFilter,
      deleteCrime,
      refCrimeListTable,
      crimes,
      isLoading,
    } = useCrimeList()

    onMounted(() => {
      fetchCrimes()
    })

    return {
      router,
      fetchCrimes,
      tableColumns,
      perPage,
      currentPage,
      totalCrimes,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      statusFilter,
      deleteCrime,
      refCrimeListTable,
      crimes,
      isLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
