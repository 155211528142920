<template>
    <b-card>
      <b-row>
        <b-col md="4" class="pb-1">
          <b-button
            variant="outline-primary"
            :to="{ name: 'organization-lobby-add' }"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('message.Add_Audience') }}
          </b-button>
        </b-col>
  
        <!-- <b-col md="4"  class="pb-1">
          <b-button
            variant="outline-info"
          >
            <feather-icon
              icon="UploadIcon"
              class="mr-50"
            />
          </b-button>
        </b-col> -->
  
        <b-col md="4">
          <b-form-group>
            <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            />
          </b-form-group>
        </b-col>
  
          <!-- <b-col>
            <filters 
              :roleFilterShow="true"
              :processFilterShow="true"
            />
          </b-col> -->
  
        <b-col cols="12">
          <b-table
            ref="refConfirmationPlanningTable"
            :items="audiences"
            hover
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty=""
            :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(actions)="data">
              <div class="flex flex-column">
                <!-- <ViewButton /> -->
                <ViewButton @clicked="router.push({name: 'organization-lobby-view', params: { id: data.item._id }})"/>
                <EditButton @clicked="router.push({name: 'organization-lobby-edit', params: { id: data.item._id }})"/>
                <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
                <b-modal
                  :id="`modal ${data.item._id}`"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  :ok-title="$t('message.delete')"
                  :cancel-title="$t('message.cancel')"
                  @ok="deleteAudience(data.item._id, deletedSuccess)"
                >
                </b-modal>
              </div>
            </template>

          </b-table>
        </b-col>
  
        <b-col
          cols="12"
          class="d-flex justify-content-between flex-wrap"
        >
          <div class="mb-1">
            <b-form-group class="mb-0">
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="[5, 10, 15, 20, 25, 30, 40, 50, 100]"
                class="w-50"
              />
            </b-form-group>
          </div>
  
          <div class="mb-1">
          <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + (audiences.length ? 1 : 0)}`, to: `${audiences.length + ((currentPage * perPage) - perPage)}`, total: `${totalAudiences}` }) }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalAudiences"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
        </b-col>
      </b-row>
      
      <!-- Sidebar Overlay: Bulk Events Upload -->
      <div
        class="body-content-overlay"
      />
    </b-card>
  </template>
  
<script>
import { BBadge, BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import i18n from '@/libs/i18n/index.js'
import useNotifications from '@/composables/useNotifications'

import Filters from "@/views/organization/Filters.vue";
import useAudienceList from './useAudienceList'

export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    ViewButton,
    DeleteButton,
    vSelect,
    Filters,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data(){
    return {
      items:[],
    }
  },
  setup(){
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { router } = useRouter()
    const {
      fetchAudiences,
      audiences,
      tableColumns,
      perPage,
      currentPage,
      totalAudiences,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteAudience,
      isLoading,
    } = useAudienceList()
    onMounted(()=>{
      fetchAudiences();
    });

    const deletedSuccess = success => {
      if(success){
        fetchAudiences();
        showSuccessMessage(i18n.t('message.worker_remove'))
      } else {
        showErrorMessage(i18n.t('message.something_went_wrong'))
      }
    }
    return {
      router,
      audiences,
      totalAudiences,
      fetchAudiences,
      tableColumns,
      searchQuery,
      perPage,
      currentPage,
      deletedSuccess,
      sortBy,
      isLoading,
      isSortDirDesc,
      deleteAudience
    }
  },

  }
</script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .process-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  
  .form-group {
    label {
      font-size: 0.85rem;
      font-weight: 400;
    }
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  