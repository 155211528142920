<template lang="pug">
div
  Form(:data="document", :isSubmitting="isSubmitting", @submit="create")
</template>

<script>
import { ref } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useDocumentAdd from './useDocumentAdd'

export default {
  components: {
    Form,
  },

  setup() {
    const { create, document, isSubmitting } = useDocumentAdd()

    return {
      document,
      create,
      isSubmitting,
    }
  },
}
</script>
