import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { addAudience, updateAudience } from '@/@core/queries/audience'
import { useRouter } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import awsConnection from '@/views/habit/aws';
import useCommonAudience from '../useCommonAudience'

export default function   useAudienceAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const documentFile = ref({})
  const userData = store.state?.userStore?.userData
  const isSubmitting = ref(false)
  const { singleUpload } = awsConnection()
  const {updateAudienceWithKey} = useCommonAudience()


  function create(data,documentFile) {
    isSubmitting.value = true   
    const payload = {}
    console.log('data: (search document)',documentFile)
    if (data.topic?.value) payload.topic = data.topic.value
    if (data.createdBy?.value) payload.createdBy = { link: data.createdBy.value }
    // if (data.documentKey?.value) payload.documentKey = data.documentKey.value
    if (data.institution?.value) payload.institution = data.institution.value
    if (data.institutionServant?.value) payload.institutionServant = data.institutionServant?.value
    if (data.date?.value){
      const date = data.date.value
      const tempDate = new Date(date);
      payload.date = tempDate.toISOString();
    }
    payload.deleted = false
    store
      .dispatch('audienceStoreModule/createAudience', { query: addAudience, variables: { data: payload } })
      .then(async response => {
        const audience_id = response.data.data.insertOneAudience._id
        const destinationFolder = `${userData.client.$oid}/documents`
        if (documentFile){
          singleUpload(documentFile, destinationFolder)
          .then((key) =>{
            updateAudienceWithKey(audience_id, key)
            router.push({ name: 'organization-lobby-list' })
            isSubmitting.value = false
          })
          .catch((err) => {
            showErrorMessage(err.message)
            router.push({ name: 'organization-lobby-list' })
            isSubmitting.value = false
          })
        }else{
          showSuccessMessage(i18n.t('message.audience_added'))
          router.push({ name: 'organization-lobby' })
          isSubmitting.value = false
        }
      })
      .catch((err) => {
        console.log(err)
        showErrorMessage(i18n.t('message.audience_error'))
        isSubmitting.value = false
      })
  }

  return {
    create,
    isSubmitting,
  }
}
