<template lang="pug">
div
  Form(:data="audience", :isSubmitting="isSubmitting", :isPCUploadVisible="true", @submit="create")
</template>
    
<script>
import { ref } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useAudienceAdd from './useAudienceAdd'

export default {
  components: {
    Form,
  },

  setup() {
    const { create, isSubmitting } = useAudienceAdd()

    const audience = ref({ isPCUploadEnabled: false, privileges: "employee" })

    return {
      audience,
      create,
      isSubmitting,
    }
  },
}
</script>
    