// import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
// import { queryProcess, updateProcess } from '@/@core/queries/process'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useProcessEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router, route } = useRouter()
  const { getItemsWithAggregate, updateItem, ObjectId } = realmConnection()
  const item = ref(null)
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'process'

  const show = async () => {
    isSubmitting.value = true

    try {
      const pipeline = [
        { $match: query },
        { $lookup: { from: 'crime', localField: 'crimes', foreignField: '_id', pipeline: [ { $project: { name: 1 } } ], as: 'crimesData' } },
        { $lookup: { from: 'location', localField: 'areas', foreignField: '_id', pipeline: [ { $project: { location: 1 } } ], as: 'areasData' } },
        { $lookup: { from: 'worker', localField: 'responsible', foreignField: '_id', pipeline: [ { $project: { name: 1 } } ], as: 'responsibleData' } },
        { $lookup: { from: 'worker', localField: 'leadResponsible', foreignField: '_id', pipeline: [ { $project: { name: 1 } } ], as: 'leadResponsibleData' } },
        { $lookup: { from: 'worker', localField: 'evaluator', foreignField: '_id', pipeline: [ { $project: { name: 1 } } ], as: 'evaluatorData' } },
        { $lookup: { from: 'worker', localField: 'evaluated', foreignField: '_id', pipeline: [ { $project: { name: 1 } } ], as: 'evaluatedData' } },
      ]
    
      const items = await getItemsWithAggregate({ collection, pipeline })
      if (!items[0]) return

      items[0].crimes = items[0].crimesData?.map(({ name, _id }) => ({ title: name, value: String(_id) })) || []
      items[0].areas = items[0].areasData?.map(({ location, _id }) => ({ title: location, value: String(_id) })) || []
      items[0].controls = items[0].controls?.map(e => String(e)) || []
      items[0].responsible = items[0].responsibleData[0] ? { title: items[0].responsibleData[0].name, value: String(items[0].responsibleData[0]._id) } : null
      items[0].leadResponsible = items[0].leadResponsibleData[0] ? { title: items[0].leadResponsibleData[0].name, value: String(items[0].leadResponsibleData[0]._id) } : null
      items[0].evaluator = items[0].evaluatorData[0] ? { title: items[0].evaluatorData[0].name, value: String(items[0].evaluatorData[0]._id) } : null
      items[0].evaluated = items[0].evaluatedData[0] ? { title: items[0].evaluatedData[0].name, value: String(items[0].evaluatedData[0]._id) } : null
      item.value = items[0]
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.data_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async payload => {
    isSubmitting.value = true

    payload.crimes = payload.crimes?.map(e => ObjectId(e.value)) || []
    payload.areas = payload.areas?.map(e => ObjectId(e.value)) || []
    payload.controls = payload.controls?.map(e => ObjectId(e)) || []
    payload.responsible = payload.responsible ? ObjectId(payload.responsible) : null
    payload.leadResponsible = payload.leadResponsible ? ObjectId(payload.leadResponsible) : null
    payload.evaluator = payload.evaluator ? ObjectId(payload.evaluator) : null
    payload.evaluated = payload.evaluated ? ObjectId(payload.evaluated) : null

    try {
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.process_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_update_error'))
    } finally {
      router.push({ name: 'organization-process-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    item,
    isSubmitting,
  }
}
