<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @locations="updateLocations"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!meetings_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_daily_dialogues_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="meetings_total")
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="happiness_index")
        b-col(sm="3")
          statistic-card-vertical-compliance(:statistic="compliance")
      
      b-row
        b-col(lg="6")
          bar-chart-location(
            v-if="locationDialogues.labels && locationDialogues.labels.length",
            :locations-data="locationDialogues"
          )
        b-col(lg="6")
          bar-chart-happiness(
            v-if="locationHappiness.labels && locationHappiness.labels.length",
            :happiness-data="locationHappiness"
          )
      b-row
        b-col(cols="12")
          heat-map-dialogues(
            v-if="dialoguesPerDay.length",
            :dialogues-per-day-data="dialoguesPerDay"
          )

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('happiness.date') }}
              th {{ $t('happiness.start_time') }}
              th {{ $t('happiness.end_time') }}
              th {{ $t('happiness.created_by') }}
              th {{ $t('happiness.location') }}
              th {{ $t('happiness.happy') }}
              th {{ $t('happiness.neutral') }}
              th {{ $t('happiness.sad') }}
              th {{ $t('happiness.mood') }}
              th {{ $t('happiness.image') }}
              th(v-if="userRole === 'consultant'") {{ $t('happiness.action') }}
            b-tr(v-for="(meeting, index) in meetings", :key="index")
              b-td {{ meeting.dateString }}
              b-td {{ meeting.start_time }}
              b-td {{ meeting.end_time }}
              b-td {{ meeting.created_by }}
              b-td {{ meeting.location }}
              b-td {{ meeting.happy }}
              b-td {{ meeting.neutral }}
              b-td {{ meeting.sad }}
              b-td {{ meeting.happiness_index_text() }}
              b-td(v-if="meeting.imageKey")
                b-button(
                    size="sm",
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                    type="button",
                    variant="primary",
                    @click="openImage(meeting.imageKey)"
                  )
                    | {{ $t('message.open') }}
              b-td(v-else) {{ $t('happiness.no_image') }}
              b-td(v-if="userRole === 'consultant'")
                delete-button(v-b-modal="`modal ${index}`")
                b-modal(
                  :id="`modal ${index}`",
                  centered,
                  no-close-on-backdrop,
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')",
                  :ok-title="$t('message.delete')",
                  :cancel-title="$t('message.cancel')",
                  @ok="deleteMeeting(meeting.id, deletedSuccess, meeting.improvements, meeting.recognitions)",
                )
                  | {{ $t('message.confirm_delete_daily_dialogue') }}

      b-button(:disabled="!meetings.length")
        json-excel(:data="meetings", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>

<script>
import { getMeetings, updateMeeting } from "@/@core/queries/meeting";
import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import store from "@/store";
import useNotifications from "@/composables/useNotifications";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BModal, VBModal } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import StatisticCardVerticalCompliance from './charts/StatisticCardVerticalCompliance.vue'
import BarChartLocation from './charts/ChartjsHorizontalBarChartLocation.vue'
import BarChartHappiness from './charts/ChartjsHorizontalBarChartHappiness.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import Ripple from 'vue-ripple-directive'
import awsConnection from '@/views/habit/aws';
import endpoints from '@/libs/endpoints'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
import { updateImprovement } from "@/@core/queries/improvements";
import { updateRecognition } from "@/@core/queries/recognitions";
import useCommon from '@/views/organization/useCommon'
import { get } from 'idb-keyval';
import HeatMapDialogues from './charts/ApexHeatMapDialogues.vue'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BModal,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    StatisticCardVerticalCompliance,
    BarChartLocation,
    BarChartHappiness,
    Filters,
    Loading,
    DeleteButton,
    HeatMapDialogues,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError } = useCommon()
    const { getFile } = awsConnection()
    const { getDatesFromRange, dateDiffInDays, happinessIndex } = useCommonDashboards()
    const meetings = ref([]);
    const meetings_total = ref(0);
    const happiness_index = ref(0)
    const compliance = ref(0)
    const locations = ref([])
    get('locations').then(response => {
      if (response) locations.value = response
      else if (localStorage.locations) locations.value = JSON.parse(localStorage.locations)
    })
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const locationDialogues = ref({})
    const locationHappiness = ref({})
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const dialoguesPerDay = ref([])
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const excelFields = ref({
      [i18n.t('happiness.date')]: "dateString",
      [i18n.t('happiness.start_time')]: "start_time",
      [i18n.t('happiness.end_time')]: "end_time",
      [i18n.t('happiness.created_by')]: "created_by",
      [i18n.t('happiness.location')]: "location",
      [i18n.t('happiness.happy')]: "happy",
      [i18n.t('happiness.neutral')]: "neutral",
      [i18n.t('happiness.sad')]: "sad",
      [i18n.t('happiness.mood')]: "happiness_index",
      [i18n.t('happiness.id')]: "id",
    })

    async function listMeetings() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      let conditions = {
        client_id: { _id: client_id },
        deleted_ne: true,
        date_gte: startFilter,
        date_lt: endFilter,
      };

      if (locationFilter.value.length > 0) {
        conditions.location = {_id_in: locationFilter.value}
      }

      if (zoneFilter.value.length > 0) {
        if (conditions.location) conditions.location.zone_in = zoneFilter.value
        else conditions.location = {zone_in: zoneFilter.value}
      }

      if (agencyFilter.value.length > 0) {
        if (conditions.location) conditions.location.agency_in = agencyFilter.value
        else conditions.location = {agency_in: agencyFilter.value}
      }

      axios
        .post("/graphql", {
          query: getMeetings,
          variables: { query: conditions, limit: 10000, sortBy: "DATE_ASC" },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          parseMeetingData(data, startFilter, endFilter)
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_daily_dialogues_list') })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    function parseMeetingData(data, startFilter, endFilter) {
      let dailyDialoguesData = { total: 0 }
      let dynamicDate = startFilter

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = dynamicDate.getTimezoneOffset() / 60
      dynamicDate.setHours(dynamicDate.getHours() + timezoneOffsetHours)
      endFilter.setHours(endFilter.getHours() + timezoneOffsetHours)

      while (dynamicDate < endFilter) {
        const dateToAdd = dynamicDate.toLocaleDateString(`${default_language || 'en'}-US`)
        dailyDialoguesData[dateToAdd] = { dialogues: 0, images: 0, mood: 0, improvements: 0, recognitions: 0 }
        dynamicDate = new Date(dynamicDate.getFullYear(), dynamicDate.getMonth(), dynamicDate.getDate() + 1)
      }

      let meetings_data = []
      let happiness_data = {happy: 0, neutral: 0, sad: 0}
      let locationData = {}
      let locationDataDaily = {}

      for (const meeting of data.data.meetings) {
        const date = new Date(meeting.date)
        date.setHours(date.getHours() + timezoneOffsetHours)
        const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)
        const location = meeting.location?.location
        const locationId = meeting.location?._id
        const mood = meeting.happy + meeting.neutral + meeting.sad
        const imageKey = meeting.imageKey
        const improvements = meeting.improvements
        const recognitions = meeting.recognitions
        meetings_data.push({
            date,
            dateString,
            start_time: meeting.start,
            end_time: meeting.end,
            created_by: meeting.created_by?.name,
            location: location,
            happy: meeting.happy,
            neutral: meeting.neutral,
            sad: meeting.sad,
            happiness_index: happinessIndex(meeting.happy, meeting.neutral, meeting.sad),
            happiness_index_text: function() {
              if (!this.happy && !this.neutral && !this.sad) return "-"
              return `${this.happiness_index}%`
            },
            imageKey,
            improvements,
            recognitions,
            id: meeting._id,
        });
        happiness_data.happy += meeting.happy
        happiness_data.neutral += meeting.neutral
        happiness_data.sad += meeting.sad

        // Data for charts
        if (location) {
          // Bar chart
          if (locationData.hasOwnProperty(location)) {
            locationData[location].dialogues++
            locationData[location].happy += meeting.happy
            locationData[location].neutral += meeting.neutral
            locationData[location].sad += meeting.sad
          } else {
            locationData[location] = {
              dialogues: 1,
              happy: meeting.happy,
              neutral: meeting.neutral,
              sad: meeting.sad,
              id: locationId,
            }
          }

          // Heat map
          if (!locationDataDaily.hasOwnProperty(location)) {
            locationDataDaily[location] = JSON.parse(JSON.stringify(dailyDialoguesData))
            locationDataDaily[location].id = locationId
          }
          locationDataDaily[location][dateString].dialogues++
          locationDataDaily[location][dateString].images += imageKey ? 1 : 0
          locationDataDaily[location][dateString].mood += mood
          locationDataDaily[location][dateString].improvements += improvements?.length || 0
          locationDataDaily[location][dateString].recognitions += recognitions?.length || 0
          locationDataDaily[location].total++
        }
      }

      // Global values
      meetings.value = meetings_data
      meetings_total.value = meetings_data.length
      happiness_index.value = (!happiness_data.happy && !happiness_data.neutral && !happiness_data.sad)
        ? "-"
        : `${happinessIndex(happiness_data.happy, happiness_data.neutral, happiness_data.sad)}%`

      // Compliance
      const qLocations = locationFilter.value.length ? locationFilter.value.length : locations.value.length
      // const filterDate = new Date(yearFilter.value, monthFilter.value + 1, 0)   // Last day of month of the filter date
      let qDays = 0
      // if (monthFilter.value === now.getMonth() && yearFilter.value === now.getFullYear()) qDays = now.getDate()
      // else if (filterDate < now) qDays = filterDate.getDate()
      if (endFilter < now) qDays = dateDiffInDays(startFilter, endFilter)
      else if (startFilter < now) qDays = dateDiffInDays(startFilter, now)
      compliance.value = complianceIndex(meetings_total.value, qLocations, qDays)

      // Sort charts data
      for (const location in locationData) {
        locationData[location].happinessIndex = happinessIndex(locationData[location].happy, locationData[location].neutral, locationData[location].sad)
      }

      let locationDataSortedByDialogues = Object.entries(locationData)
      let locationDataSortedByHappiness = Object.entries(locationData)

      locationDataSortedByDialogues.sort(function (a, b) {
        return b[1].dialogues - a[1].dialogues
      })

      locationDataSortedByHappiness.sort(function (a, b) {
        return b[1].happinessIndex - a[1].happinessIndex
      })

      const dataLimit = 10 // Display only the first 10 values in the chart
      const dialogueslimit = locationDataSortedByDialogues.length < dataLimit ? locationDataSortedByDialogues.length : dataLimit
      const happinessLimit = locationDataSortedByHappiness.length < dataLimit ? locationDataSortedByHappiness.length : dataLimit
      let locationDialoguesLabels = []
      let locationDialoguesValues = []
      let locationDialoguesIds = []
      let locationHappinessLabels = []
      let locationHappinessValues = []
      let locationHappinessIds = []

      for (let i = 0; i < dialogueslimit; i++) {
        locationDialoguesLabels.push(locationDataSortedByDialogues[i][0])
        locationDialoguesValues.push(locationDataSortedByDialogues[i][1].dialogues)
        locationDialoguesIds.push(locationDataSortedByDialogues[i][1].id)
      }

      for (let i = 0; i < happinessLimit; i++) {
        locationHappinessLabels.push(locationDataSortedByHappiness[i][0])
        locationHappinessValues.push(locationDataSortedByHappiness[i][1].happinessIndex)
        locationHappinessIds.push(locationDataSortedByHappiness[i][1].id)
      }

      locationDialogues.value = {
        labels: locationDialoguesLabels,
        data: locationDialoguesValues,
        additionalInfo: {
          locationIds: locationDialoguesIds,
        }
      }

      locationHappiness.value = {
        labels: locationHappinessLabels,
        data: locationHappinessValues,
        additionalInfo: {
          locationIds: locationHappinessIds,
        }
      }

      // Heat map
      const heatMapData = []

      for (const location in locationDataDaily) {
        const heatMapLocationData = {
          name: location,
          data: [],
          total: locationDataDaily[location].total,
          locationId: locationDataDaily[location].id
        }

        delete locationDataDaily[location].total
        delete locationDataDaily[location].id

        for (const date in locationDataDaily[location]) {
          heatMapLocationData.data.push({
            x: date,
            y: locationDataDaily[location][date].dialogues,
            images: locationDataDaily[location][date].images,
            mood: locationDataDaily[location][date].mood,
            improvements: locationDataDaily[location][date].improvements,
            recognitions: locationDataDaily[location][date].recognitions
          })
        }

        heatMapData.push(heatMapLocationData)
      }

      // Sort heat map data
      heatMapData.sort(function (a, b) {
        if (b.total === a.total) {
          if (a.name > b.name) return -1
          if (a.name < b.name) return 1
          return 0
        }
        return a.total - b.total
      })

      dialoguesPerDay.value = heatMapData
    }

    function complianceIndex(dialogues, locations, days) {
        if (!locations || !days) return "-"
        return `${Math.round(dialogues / (locations * days) * 1000) / 10}%`
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;
      
      listMeetings();
    }

    function updateLocations(data) {
      if (!locations.value.length) {
        locations.value = data;
        listMeetings();
      }
    }

    const openImage = async (imageKey) => {
      let w = window.open("");
      w.document.write(`<h2>${i18n.t('message.loading_image')}</h2>`);
      try {
        const imgFromAWS = await getFile(endpoints.aws_bucket, imageKey)
        let image = new Image();
        image.src = imgFromAWS;

        w.document.body.innerHTML = "";
        w.document.write(image.outerHTML);
      } catch (error) {
        showErrorMessage(i18n.t('message.err_open_image'))
      }
    }

    const deletedSuccess = (success) => {
      if (success) {
        listMeetings();
        showSuccessMessage(i18n.t('message.daily_dialogue_remove'));
      } else {
        showErrorMessage(i18n.t('message.daily_dialogue_remove_error'));
      }
    };

    const deleteMeeting = (id, callback, improvements, recognitions) => {
      axios
        .post('/graphql', {
          query: updateMeeting,
          variables: { query: { _id: id }, data: { deleted: true } }
        })
        .then(() => {
          callback(true)

          // Delete improvements associated with daily dialogue
          if (improvements?.length) {
            improvements.forEach(e => {
              axios
                .post('/graphql', {
                  query: updateImprovement,
                  variables: { query: { _id: e._id }, data: { deleted: true } }
                })
                .then(() => {})
                .catch((error) => {
                  console.log(error)
                })
            });
          }

          // Delete recognitions associated with daily dialogue
          if (recognitions?.length) {
            recognitions.forEach(e => {
              axios
                .post('/graphql', {
                  query: updateRecognition,
                  variables: { query: { _id: e._id }, data: { deleted: true } }
                })
                .then(() => {})
                .catch((error) => {
                  console.log(error)
                })
            });
          }
        })
        .catch(() => {
          callback(false)
        })
    }

    onMounted(() => {
      listMeetings()
    })

    return {
      meetings,
      meetings_total,
      happiness_index,
      compliance,
      updateFilter,
      updateLocations,
      excelFields,
      openImage,
      locationDialogues,
      locationHappiness,
      isLoading,
      zone_agency_filter,
      userRole,
      deletedSuccess,
      deleteMeeting,
      dialoguesPerDay,
    };
  },
};
</script>
