<template>
  <div>
    <!-- Title -->
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
      >
        <h5 class="mb-0">
          {{ $t('message.behaviour_title') }}
          <br>
          <small
            class="text-muted"
          >{{ $t('message.behaviour_desc') }}
          </small>
        </h5>
      </b-col>
    </b-row>

    <!-- Loader -->
    <loading v-if="isLoading" :active="true" :is-full-page="false" color="#498ceb" />

    <!-- Behaviours -->
    <div v-else>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >
        <b-row
          v-for="(control, index) in controlsWithBehaviours"
          :key="index"
          ref="row"
          class="border-bottom mb-1"
        >
          <b-col md="12">
            <h6>
              {{ $t('label.activity') }} :
              <span class="text-primary font-medium-3" >
                {{ control.name }}
              </span>
            </h6>
          </b-col>

          <b-col md="12">
            <b-row
              v-for="(behaviour, loopIndex) in control.behavioursData"
              :key="loopIndex"
            >
              <!-- Behaviour -->
              <b-col md="8">
                <b-form-group :label="$t('message.behaviour_no', 0, { count: loopIndex + 1 })" :label-for="$t('label.behaviour')">
                  <quill-editor v-model="behaviour.name" :options="editorOption" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Type -->
              <b-col md="2">
                <b-form-group :label="$t('label.Type')" :label-for="$t('label.Type')">
                  <v-select
                    v-model="behaviour.type"
                    label="title"
                    :disabled="true"
                    :options="types"
                    :reduce="(type) => type.value"
                  />
                </b-form-group>
              </b-col>

              <!-- Expected answer -->
              <b-col v-if="behaviour.type === 'bool'" md="2">
                <b-form-group :label="$t('label.expected_answer')" :label-for="$t('label.expected_answer')">
                  <v-select
                    v-model="behaviour.expectedAnswer"
                    label="title"
                    :disabled="true"
                    :options="yesNoOptions"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col md="1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mb-2"
                  size="sm"
                  @click="removeItem(index, loopIndex)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25 px-0"
                  />
                  <span>{{ $t('message.delete') }}</span>
                </b-button>
              </b-col> -->
            </b-row>
          </b-col>
          <!-- <b-col mf="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :class="{ 'mb-2': items.length > 0 }"
              variant="primary"
              @click="repeatAgain(index, row.mainTitle, '', { value: 'bool', title: $t('behaviourTypes.bool') }, { value: true, title: $t('message.yes') })"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('message.Add_New_Behaviour_to_Activity') }}</span>
            </b-button>
          </b-col> -->
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import _ from 'lodash'
import { yesNoOptions, types } from '@/constants'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  directives: {
    Ripple,
  },
  components: {
    GlobalSingleInput,
    quillEditor,
    vSelect,
    Loading,
  },
  mixins: [heightTransition],
  props: {
    // eslint-disable-next-line vue/require-valid-default-prop
    data: { type: Array, default: [] },
    // eslint-disable-next-line vue/require-valid-default-prop
    addedActivities: { type: Array, default: [] },
  },
  setup(props, { emit }) {
    const { getItemsWithAggregate, ObjectId } = realmConnection()
    const controlsWithBehaviours = ref([])
    const isLoading = ref(false)
    const editorOption = {
      modules: {
        toolbar: ['bold', 'italic', 'underline', { 'font': [] }],
      },
      placeholder: i18n.t('placeholder.behaviour'),
    }

    return {
      controlsWithBehaviours,
      isLoading,
      getItemsWithAggregate,
      ObjectId,
      editorOption,
      types,
      yesNoOptions,
    }
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    /* data(value) {
      if (value.length) {
        value.forEach(val => {
          setTimeout(log)
          const index = _.find(this.items, { mainTitle: val.activity })
          console.log('index'.index)
        /!*  val.behaviours.forEach(behaviour => {
            this.repeatAgain(index, behaviour, val.activity)
          })*!/
        //  this.repeatAgain(qualification)
        })
        /!* this.items.forEach((item, index) => {
          this.items[index].singleRow.rowInputs[0].value = value[index]
        })
        this.trAddHeight(value.length * 90) *!/
      }
    }, */
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeatAgain(index, activity, value = null, typeValue = null, inverseScoreLogic = null) {
      let { length } = this.items[index].singleRow
      // eslint-disable-next-line no-plusplus
      length = ++length
      this.items[index].singleRow.push({
        rowInputs: [
          {
            md: 7,
            label: i18n.tc('message.behaviour_no', 0, {count: `${index + 1}.${length}`}),
            type: 'quill',
            key: `behaviour_${index + 1}.${length}`,
            rules: 'required',
            placeholder: i18n.t('placeholder.activity'),
            value: value ?? '',
          },
          {
            md: 2,
            label: i18n.tc('message.type_no', 0, {count: `${index + 1}.${length}`}),
            type: 'select',
            key: `behaviour_${index + 1}.${length}_type`,
            rules: 'required',
            options: types,
            placeholder: i18n.t('placeholder.type'),
            value: typeValue ?? '',
          },
          {
            md: 2,
            label: i18n.tc('message.expected_answer_no', 0, {count: `${index + 1}.${length}`}),
            type: 'select',
            key: `behaviour_${index + 1}.${length}_false_positive`,
            rules: '',
            options: yesNoOptions,
            placeholder: i18n.t('label.select_yes_no'),
            value: inverseScoreLogic ?? '',
          }
        ],
      })

      this.$nextTick(() => {
        this.trAddHeight(100)
      })
    },
    removeItem(index, itemIndex) {
      this.items[index].singleRow.splice(itemIndex, 1)
      this.trAddHeight(-100)

      // Update labels of behaviours
      // If there are no more behaviours in the activity, add a new blank behaviour
      if (!this.items[index].singleRow.length) this.repeatAgain(this.items[index].notDeletedIndex, '', '', { value: 'bool', title: i18n.t('behaviourTypes.bool') }, { value: true, title: i18n.t('message.yes') })
      else {
        this.items[index].singleRow.forEach((e, i) => {
          e.rowInputs[0].label = i18n.tc('message.behaviour_no', 0, { count: `${this.items[index].notDeletedIndex + 1}.${i + 1}` })
          e.rowInputs[1].label = i18n.tc('message.type_no', 0, { count: `${this.items[index].notDeletedIndex + 1}.${i + 1}` })
          e.rowInputs[2].label = i18n.tc('message.expected_answer_no', 0, { count: `${this.items[index].notDeletedIndex + 1}.${i + 1}` })
        })
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        let totalRows = 0
        // eslint-disable-next-line no-return-assign
        this.items.forEach(item => (totalRows += item.singleRow.length))
        this.trSetHeight(
          this.$refs.form.scrollHeight
            + totalRows * 120
            + this.items.length * 50,
        )
      })
    },
    getData() {
      const behaviours = []

      // eslint-disable-next-line array-callback-return
      this.items.map(item => {
        if (!item.deleted) {
          const temFormat = {
            activity: item.mainTitle,
            behaviours: item.singleRow.map(singleRow => ({
              type: singleRow.rowInputs[1].value?.value,
              name: singleRow.rowInputs[0].value,
              inverseScoreLogic: singleRow.rowInputs[2].value ? !singleRow.rowInputs[2].value.value : false,
            })),
          }
          behaviours.push(temFormat)
        }
      })
      return { behaviours }
    },
    async createForm(controls) {
      this.isLoading = true

      try {
        const query = { _id: { $in: controls?.map(e => this.ObjectId(e)) || [] } }

        const pipeline = [
          { $match: query },
          {
            $lookup: {
              from: 'behaviour',
              localField: 'behaviours',
              foreignField: '_id',
              pipeline: [
                {
                  $project: { name: 1, type: 1, expectedAnswer: 1 },
                },
              ],
              as: 'behavioursData',
            },
          },
          // This next stage sorts the controlsData array in the same order as the controls array
          {
            $addFields: {
              behavioursData: {
                $map: {
                  input: "$behaviours",
                  as: "behaviourId",
                  in: { $arrayElemAt: [ "$behavioursData", { $indexOfArray: ["$behavioursData._id", "$$behaviourId"] } ] }
                }
              }
            }
          },
        ]
      
        const items = await this.getItemsWithAggregate({ collection: 'control', pipeline })

        // Create a map of control IDs to their index in the 'controls' array to keep track of the sort order
        const controlsIndexMap = new Map(controls.map((id, index) => [id, index]))

        // Sort the items based on the index of their `id` in the `controls` array
        items.sort((a, b) => controlsIndexMap.get(String(a._id)) - controlsIndexMap.get(String(b._id)))

        this.controlsWithBehaviours = items
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.data_fetch_error'))
      } finally {
        this.isLoading = false
      }

      // const itemsCount = this.items.length
      // this.addedActivities.forEach((activity, index) => {
      //   if (this.items[index]) {
      //     this.items[index].mainTitle = activity.singleRow.rowInputs[0].value
      //     this.items[index].deleted = activity.singleRow.rowInputs[0].deleted
      //     this.items[index].notDeletedIndex = activity.singleRow.rowInputs[0].notDeletedIndex
      //     this.items[index].singleRow.forEach((e, i) => {
      //       e.rowInputs[0].label = i18n.tc('message.behaviour_no', 0, { count: `${activity.singleRow.rowInputs[0].notDeletedIndex + 1}.${i + 1}` })
      //       e.rowInputs[1].label = i18n.tc('message.type_no', 0, { count: `${activity.singleRow.rowInputs[0].notDeletedIndex + 1}.${i + 1}` })
      //       e.rowInputs[2].label = i18n.tc('message.expected_answer_no', 0, { count: `${activity.singleRow.rowInputs[0].notDeletedIndex + 1}.${i + 1}` })
      //     })
      //   } else {
      //     const payload = {
      //       mainTitle: activity.singleRow.rowInputs[0].value,
      //       deleted: activity.singleRow.rowInputs[0].deleted,
      //       notDeletedIndex: activity.singleRow.rowInputs[0].notDeletedIndex,
      //       singleRow: [
      //         {
      //           rowAttributes: {},
      //           rowInputs: [
      //             {
      //               md: 7,
      //               label: i18n.tc('message.behaviour_no', 0, {count: `${activity.singleRow.rowInputs[0].notDeletedIndex + 1}.1`}),
      //               type: 'quill',
      //               key: `behaviour_${index + 1}.1`,
      //               rules: 'required',
      //               placeholder: i18n.t('placeholder.activity'),
      //               value: '',
      //             },
      //             {
      //               md: 2,
      //               label: i18n.tc('message.type_no', 0, {count: `${activity.singleRow.rowInputs[0].notDeletedIndex + 1}.1`}),
      //               type: 'select',
      //               key: `behaviour_${index + 1}.1_type`,
      //               rules: 'required',
      //               options: types,
      //               placeholder: i18n.t('placeholder.type'),
      //               value: { value: 'bool', title: i18n.t('behaviourTypes.bool') },
      //             },
      //             {
      //               md: 2,
      //               label: i18n.tc('message.expected_answer_no', 0, {count: `${activity.singleRow.rowInputs[0].notDeletedIndex + 1}.1`}),
      //               type: 'select',
      //               key: `behaviour_${index + 1}.1_false_positive`,
      //               rules: '',
      //               options: yesNoOptions,
      //               placeholder: i18n.t('label.select_yes_no'),
      //               value: { value: true, title: i18n.t('message.yes') },
      //             },
      //           ],
      //         },
      //       ],
      //     }
      //     this.items.push(payload)
      //   }
      // })
      // if (!itemsCount && this.data.length > 0) {
      //   this.data.forEach((val, idx) => {
      //     if (!this.items[idx].deleted) {
      //       this.items[idx].singleRow = []
      //       val.behaviours.forEach(behaviour => {
      //         this.repeatAgain(
      //           idx,
      //           val.activity,
      //           behaviour.name,
      //           _.find(types.value, { value: behaviour.type }),
      //           _.find(yesNoOptions.value, { value: !behaviour.inverseScoreLogic }),
      //         )
      //       })
      //     }
      //   })
      // }

      // // if (this.addedActivities.length !== this.items.length) {
      // //   this.items = this.items.filter(row => this.addedActivities.includes(row.mainTitle))
      // // }
      // this.initTrHeight()
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
