<template lang="pug">
    div
      Form(:data="audience", :isSubmitting="isSubmitting", @submit="update")
    </template>
    
    <script>
    import { onMounted } from '@vue/composition-api'
    import Form from '../shared/Form.vue'
    // eslint-disable-next-line import/no-cycle
    import useAudienceEdit from './useAudienceEdit'
    
    export default {
      components: {
        Form,
      },
    
      setup() {
        const { show, update, audience, isSubmitting } = useAudienceEdit()
    
        onMounted(show)
    
        return {
          audience,
          update,
          isSubmitting,
        }
      },
    }
    </script>
    