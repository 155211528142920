// Required during insertion if we have not set the keys it will add to the payload
export const insertDefaultPayLoad = {
  code: '',
  description: '',
  name: '',
  responsibilities: [],
  qualifications: [],
  deleted: false,
}

export const queryProcesses = `query processes($query: ProcessQueryInput, $limit: Int, $sortBy: ProcessSortByInput){
    processes(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      name
      code
      goal
      description
      activities {
        name
        behaviours {
          name
          type
          expectedAnswer
        }
      }
      requireImage
      metadata {
        _id
        name
        type
      }
    }
  }
  `

export const _getProcesses = `query ProcessesData($input: ProcessesDatum!){
  ProcessesData(input: $input) {
    totalRecords
    processes {
      _id
      name
      goal
      description
      activities {
        name
        behaviours {
          name
          type
        }
      }
      requireImage
      metadata
      metadataData {
        _id
        name
        type
      }
      crimesData {
        name
      }
    }
  }
}`

export const queryProcess = `query process($query: ProcessQueryInput){
    process(query: $query) {
      _id
      name
      code
      goal
      assumptions
      areas {
        _id
        location
      }
      riskProcess
      role {
        _id
        name
      },
      responsible {
        _id
        name
      },
      leadResponsible {
        _id
        name
      },
      evaluator {
        _id
        name
      },
      evaluated {
        _id
        name
      },
      description,
      activities {
        name
        behaviours {
          name
          type
          expectedAnswer
        }
      }
      requireImage
      metadata {
        _id
        name
        type
        options
      }
      crimes {
        _id
        name
      }
      controls {
        _id
        name
        behaviours {
          name
          type
          expectedAnswer
        }
      }
    }
  }
  `

export const addProcess = `mutation insertOneProcess($data: ProcessInsertInput!){
  insertOneProcess(data: $data) {
    _id
  }
}
`

export const updateProcess = `mutation updateOneProcess($query: ProcessQueryInput, $data: ProcessUpdateInput!) {
  updateOneProcess(query: $query, set: $data) {
    _id,
  }
}
`

export default queryProcesses
