<template lang="pug">
div
  b-row(v-show="isSubmitting")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )
  Form(
    v-show="!isSubmitting",
    :data="caseData",
    :isSubmitting="isSubmitting",
    :updateActiveTab="updateActiveTab",
    :readOnly="true"
  )
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
import useCaseEdit from '../edit/useCaseEdit'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Form,
    Loading,
  },

  setup() {
    const { show, caseData, isSubmitting, updateActiveTab } = useCaseEdit()

    onMounted(show)

    return {
      caseData,
      isSubmitting,
      updateActiveTab,
    }
  },
}
</script>
