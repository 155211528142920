<template>
  <div>
    <form-wizard
      color="#498ceb"
      :title="null"
      :subtitle="null"
      layout="vertical"
      :finish-button-text="$t('message.submit')"
      :back-button-text="$t('message.previous')"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content
        :title="$t('label.identification')"
        :before-change="validateIdentificationForm"
      >
        <validation-observer ref="identificationTab" tag="form">
          <Identification ref="identification" :data="data" />
        </validation-observer>
      </tab-content>

      <tab-content
        :title="$t('label.responsibilities')"
        :before-change="validateResponsibilitiesForm"
      >
        <validation-observer ref="responsibilitiesTab" tag="form">
          <Responsibilities
            ref="responsibilities"
            :responsibilities="data ? data.responsibilities : []"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :title="$t('label.qualifications')"
        :before-change="validateQualificationsForm"
      >
        <validation-observer ref="qualificationsTab" tag="form">
          <Qualifications
            ref="qualifications"
            :qualifications="data ? data.qualifications : []"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :title="$t('label.subroles')"
      >
          <Subroles
            ref="subroles"
            :subroles="data ? data.subroles : []"
            @input="updateSubroles"
          />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import Identification from "@/views/organization/role/shared/Identification";
import Responsibilities from "@/views/organization/role/shared/Responsibilities";
import Qualifications from "@/views/organization/role/shared/Qualifications";
import Subroles from "@/views/organization/role/shared/Subroles";
import { ref } from "@vue/composition-api";

export default {
  components: {
    Identification,
    Responsibilities,
    Qualifications,
    Subroles,
    FormWizard,
    TabContent,
    ValidationObserver,
  },

  props: ["data", "isSubmitting"],

  setup(props, { emit }) {
    const _id = ref(null);
    const identification = ref(null);
    const responsibilities = ref(null);
    const qualifications = ref(null);
    const subroles = ref(null);
    const identificationTab = ref(null);
    const responsibilitiesTab = ref(null);
    const qualificationsTab = ref(null);

    const updateSubroles = (payload) => {
      subroles.value = payload
    }

    const formSubmitted = async () => {
      if (props.isSubmitting) return

      const payload = {
        ...identification.value.getData(),
        ...responsibilities.value.getData(),
        ...qualifications.value.getData(),
        subroles: subroles.value.subrolesData,
      };

      emit("submit", payload);
    };

    const validateIdentificationForm = async () =>
      new Promise((resolve, reject) => {
        identificationTab.value.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });

    const validateResponsibilitiesForm = async () =>
      new Promise((resolve, reject) => {
        responsibilitiesTab.value.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });

    const validateQualificationsForm = async () =>
      new Promise((resolve, reject) => {
        qualificationsTab.value.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    return {
      formSubmitted,
      identification,
      responsibilities,
      qualifications,
      subroles,
      updateSubroles,
      identificationTab,
      responsibilitiesTab,
      qualificationsTab,
      validateIdentificationForm,
      validateResponsibilitiesForm,
      validateQualificationsForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
