import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import { updateMetadata, getSingleMetadata } from '@/@core/queries/metadata';
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'

export default function useMetadataList() {
  const { showErrorMessage } = useNotifications();
  const { handleError } = useCommon()
  const refMetadataListTable = ref(null)
  const metadataOptions = ref([])
  const metadataOptionsMapped = ref([])
  const metadataOptionsFiltered = ref([])
  const metadataOptionsPaginated = ref([])

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'option',
        label: i18n.t('message.tableHeader.option'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions'),
      },
    ]
  })

  const perPage = ref(10)
  const totalMetadata = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('option')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)
  const isDisabled = ref(true)
  const metadataFilter = ref("");

  const refetchData = () => {
    if (refMetadataListTable.value) {
      refMetadataListTable.value.refresh()
    }
  }

  const fetchMetadataOptions = () => {
    isLoading.value = true
    if (!metadataFilter.value) {
      resetMetadataLists()
      isLoading.value = false
      return
    }
    store
      .dispatch('organization-metadata/fetchMetadataOptions', {
        query: getSingleMetadata,
        variables: { query: { _id: metadataFilter.value } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        if (!metadataFilter.value) return
        metadataOptions.value = data?.data?.metadatum?.options
        metadataOptionsMapped.value = metadataOptions.value?.map((e, i) => ({ index: i, option: e }))
        isSortDirDesc.value = false
        searchQuery.value = ""
        sortMetadata()
        filterAndPaginate()
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.metadata_fetch_error') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteMetadata = (index, callback) => {
    metadataOptions.value?.splice(index, 1)
    store
      .dispatch('organization-metadata/deleteMetadata', {
        query: updateMetadata,
        variables: { query: { _id: metadataFilter.value }, data: { options: metadataOptions.value } }
      })
      .then(() => {
        callback(true)
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.something_went_wrong'))
      })
  }

  const sortMetadata = () => {
    metadataOptionsMapped.value?.sort(function (a, b) {
      if (a.option > b.option) {
        if (isSortDirDesc.value) return -1
        return 1
      }
      if (a.option < b.option) {
        if (isSortDirDesc.value) return 1
        return -1
      }
      return 0
    })
  }

  const filterAndPaginate = () => {
    metadataOptionsFiltered.value = searchQuery.value
      ? metadataOptionsMapped.value?.filter(e => e.option?.toLowerCase().includes(searchQuery.value.toLowerCase()))
      : metadataOptionsMapped.value
    if (currentPage.value !== 1) currentPage.value = 1
    else metadataOptionsPaginated.value = metadataOptionsFiltered.value?.slice((currentPage.value * perPage.value) - perPage.value, currentPage.value * perPage.value)
  }

  const resetMetadataLists = () => {
    metadataOptions.value = []
    metadataOptionsMapped.value = []
    metadataOptionsFiltered.value = []
    metadataOptionsPaginated.value = []
    currentPage.value = 1
  }

  watch([currentPage, perPage], () => {
    metadataOptionsPaginated.value = metadataOptionsFiltered.value?.slice((currentPage.value * perPage.value) - perPage.value, currentPage.value * perPage.value)
  })

  watch(isSortDirDesc, () => {
    sortMetadata()
    filterAndPaginate()
  })

  watch(searchQuery, () => {
    filterAndPaginate()
  })

  return {
    fetchMetadataOptions,
    tableColumns,
    perPage,
    currentPage,
    totalMetadata,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMetadataListTable,
    refetchData,
    deleteMetadata,
    isLoading,
    metadataFilter,
    metadataOptionsFiltered,
    metadataOptionsPaginated,
    isDisabled,
  }
}