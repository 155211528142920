import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useBehaviourAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItem, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const behaviour = ref({ type: 'bool', expectedAnswer: true })
  const isSubmitting = ref(false)
  const collection = 'behaviour'

  const create = async (data) => {
    isSubmitting.value = true

    try {
      const payload = {
        client_id: ObjectId(userData.client.$oid),
        name: data.name.value,
        type: data.type.value,
      }

      if (payload.type === 'bool') payload.expectedAnswer = data.expectedAnswer.value

      await createItem({ collection, payload })

      showSuccessMessage(i18n.t('message.behaviour_added'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.behaviour_add_error'))
    } finally {
      router.push({ name: 'organization-behaviour-list' })
      isSubmitting.value = false
    }
  }

  return {
    create,
    behaviour,
    isSubmitting,
  }
}
