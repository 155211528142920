import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import axios from "@axios";
import useNotifications from '@/composables/useNotifications'
import { queryConfirmationPlanningGroupByRole, queryConfirmationPlannings } from '@/@core/queries/confirmation-planning'
import { getWorkersWithRoles } from '@/@core/queries/workers'
import { addManyEvents, removeManyEvents } from '@/@core/queries/calendar'
import i18n from '@/libs/i18n'
import { useRouter } from '@core/utils/utils'
import useCommon from '@/views/organization/useCommon'

export default function useEventsUpload(props, emit) {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { formatDate } = useCommon()
  const { router } = useRouter()
  const projectStartDate = ref(new Date())
  const filterBy = ref("workers")
  // const filterOptions = ref([
  //   { title: i18n.t('label.workers'), value: "workers" },
  //   { title: i18n.t('label.Locations'), value: "roles/locations" }
  // ])
  // const selectedWorkers = ref([])
  const selectedRoles = ref([])
  const selectedLocations = ref([])
  const includeWeekends = ref(false)
  const clientFunctionality = localStorage.getItem("clientData") ? JSON.parse(localStorage.clientData) : false
  const getUserData = store.state?.userStore?.userData
  const client_id = getUserData.client?.$oid
  const worker_id = getUserData.worker_id?.$oid

  // ------------------------------------------------
  // Bulk event upload by risk
  // ------------------------------------------------
  const bulkEventsUploadByRisk = async () => {
    const confirmationPlannings = await getConfirmationsPlannings(client_id)
    
    // Adjust date format
    const formattedStartDate = formatDate(projectStartDate.value)
    
    try {
      await deleteFutureEvents([], formattedStartDate)

      await createEventsByRisk(confirmationPlannings, formattedStartDate)
    } catch (error) {
      console.log(error)
    }
  }

  // ------------------------------------------------
  // Bulk event upload
  // ------------------------------------------------
  const bulkEventsUpload = async (workers, pushRoute = true) => {
    const filterData = {
      workers,
      roles: [...selectedRoles.value],
      locations: [...selectedLocations.value],
    }

    // Adjust date format
    const formattedStartDate = formatDate(projectStartDate.value)
    
    try {
      const workersWithRoles = await getWorkersRoles(filterBy.value, filterData, client_id)

      const rolesData = await getConfirmationsPlanningsGroupByRole(client_id)
      
      const roleCycles = generateRoleCycles(rolesData)

      let workersIds = []
      let workersData = []

      for (const worker of workersWithRoles) {
        if (!worker.roles?.length) continue
        workersIds.push(worker._id)
        for (const role of worker.roles) {
          if (!roleCycles.hasOwnProperty(role)) {
            console.log(`Could not find role with ID ${role} in the list of roles`)
            continue
          }

          const personalCycle = generatePersonalCycle(roleCycles[role])

          workersData.push({
            ...worker,
            personalCycle,
          })
        }
      }

      await deleteFutureEvents(workersIds, formattedStartDate)

      await createEvents(workersData, client_id, worker_id, formattedStartDate)

      if (pushRoute) {
        if (clientFunctionality.default_view_pc_team) router.push({ name: 'apps-pc-team' })
        else if (clientFunctionality.default_view_daily_dialogue) router.push({ name: 'habit-meeting-new' })
        else router.push({ name: 'apps-calendar' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getWorkersRoles = (filterBy, filterData, clientId) => {
    let conditions
    if (filterBy === "workers") {
      conditions = { _id_in: filterData.workers }
    } else {
      conditions = {
        client_id: { _id: clientId },
        AND: [{ OR: [{ deleted: false }, { deleted_exists: false }] }]
      }

      if (filterData.locations.length > 0) {
        const locationsFilterQuery = filterData.locations.map(e => {
          return {
            locations_in: {
              _id: e
            }
          }
        })
        conditions.AND.push({OR: locationsFilterQuery})
      }

      if (filterData.roles.length > 0) {
        conditions.roles_in = filterData.roles
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: getWorkersWithRoles,
          variables: { query: conditions, limit: 10000 },
        })
        .then(({data}) => {
          if (!data.data?.workers?.length) {
            showErrorMessage(i18n.t('message.err_no_employees_found'))
            reject("Employees not found")
          }

          resolve(data.data.workers)
        })
        .catch((error) => {
          showErrorMessage(i18n.t('message.err_worker_list'));
          reject(error)
        })
    })
  }

  const getConfirmationsPlannings = (client_id) => {
    const conditions = {
      client_id: { _id: client_id },
      deleted_ne: true
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: queryConfirmationPlannings,
          variables: { query: conditions, limit: 10000 },
        })
        .then(({data}) => {
          if (!data.data?.confirmation_plannings?.length) {
            showErrorMessage(i18n.t('message.err_empty_confirmation_planning'))
            reject("Confirmation Plannings not found")
          }

          resolve(data.data.confirmation_plannings)
        })
        .catch((error) => {
          showErrorMessage(i18n.t('message.err_confirmation_planning'));
          reject(error)
        })
    })
  }

  const getConfirmationsPlanningsGroupByRole = (client_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: queryConfirmationPlanningGroupByRole,
          variables: {
            input: {
              client_id: client_id ? client_id : null,
            },
          },
        })
        .then(async response => {
          const {
            ConfirmationPlanningGroupedBy: {
              rolesData: rolesData,
            },
          } = response.data.data || {}

          if (!rolesData.length) {
            showErrorMessage(i18n.t('message.err_empty_confirmation_planning'))
            reject("Confirmations plannings not found")
          }

          resolve(rolesData)
        })
        .catch((error) => {
          showErrorMessage(i18n.t('message.err_confirmation_planning'));
          reject(error)
        })
    })
  }

  const generateRoleCycles = (rolesData) => {
    let roleCycles = {}
    rolesData.forEach(role => {
      const lengthIteration = role.planning[0].confirmationsPerYear
      let cycle = []
      for (let i = 0; i < lengthIteration; i++) {
        role.planning.forEach(plan => {
          if (plan.confirmationsPerYear > 0) {
            cycle.push(plan.process)
            plan.confirmationsPerYear--
          }
        })
      }
      roleCycles[role._id] = cycle
    })
    return roleCycles
  }

  const generatePersonalCycle = (roleCycle) => {
    const cycleLength = roleCycle.length
    const index = Math.floor(Math.random() * cycleLength)
    const firstPart = roleCycle.slice(0, index)
    const secondPart = roleCycle.slice(index, cycleLength)
    const personalCycle = [...secondPart, ...firstPart]
    return personalCycle
  }

  const deleteFutureEvents = (workersIds, projectStartDate) => {
    const condition = {
      start_gte: projectStartDate,
      extendedProps: {
        calendar_ne: "Realizadas",
      },
    }

    if (workersIds?.length) condition.attendee = { _id_in: workersIds }
    
    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: removeManyEvents,
          variables: { query: condition },
        })
        .then(({data}) => {
          if (data.errors) throw new Error(data.errors[0].message)
          const eventsDeleted = data.data?.deleteManyEvents?.deletedCount
          showSuccessMessage(`${eventsDeleted} ${i18n.t('message.old_events_deleted')}`)
          resolve()
        })
        .catch((error) => {
          showErrorMessage(i18n.t('message.err_deleting_old_events'));
          reject(error)
        })
    })
  }

  const createEvents = (workersData, client_id, worker_id, projectStartDate) => {
    let events = []

    let now = new Date()
    let firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    now = now.getTime()
    firstDayOfNextMonth = firstDayOfNextMonth.getTime()

    const projectStartYear = projectStartDate.getFullYear()
    const projectStartMonth = projectStartDate.getMonth()
    const projectStartDay = projectStartDate.getDate()

    workersData.forEach(worker => {
      const cycleLength = worker.personalCycle.length
      const monthlyEvents = cycleLength === 12
      const timeSpan = Math.floor(365 / cycleLength)
      const eventDay = Math.floor(Math.random() * 31 + 1)
      const cycleStartMonth = eventDay >= projectStartDay ? projectStartMonth : projectStartMonth + 1

      for (let i = 0; i < cycleLength; i++) {
        const processId = worker.personalCycle[i]
        let eventStartDate = monthlyEvents
          ? new Date(projectStartYear, cycleStartMonth + i, eventDay, 12)
          : new Date(projectStartYear, cycleStartMonth, eventDay + i * timeSpan, 12)

        // Check month
        let correctMonth
        if (monthlyEvents) {
          correctMonth = (cycleStartMonth + i) % 12
          if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(0)
        }

        // Check weekend
        if (!includeWeekends.value) {
          const dayOfWeek = eventStartDate.getDay()
          if (dayOfWeek === 0) {
            eventStartDate.setDate(eventStartDate.getDate() + 1)
            if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(-2)
          }
          else if (dayOfWeek === 6) {
            eventStartDate.setDate(eventStartDate.getDate() - 1)
            if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(eventStartDate.getDate() + 3)
          }
        }

        let eventEndDate = new Date(eventStartDate)
        eventEndDate.setMinutes(30)
  
        const eventData = {
          client_id: { link: client_id },
          process: { link: processId },
          attendee: { link: worker._id },
          organizer: { link: worker.supervisors?.length ? worker.supervisors[0]._id : worker_id },
          start: eventStartDate,
          end: eventEndDate,
          extendedProps: {
            calendar: setEventStatus(now, firstDayOfNextMonth, eventStartDate.getTime()),
          },
        }
        
        events.push(eventData)
      }
    })

    // Upload events
    return new Promise((resolve, reject) => {
      axios
      .post('/graphql', {
        query: addManyEvents,
        variables: { data: events },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
        const eventsCreated = data.data?.insertManyEvents?.insertedIds?.length
        showSuccessMessage(`${eventsCreated ? eventsCreated : 0} ${i18n.t('message.new_events_created')}`)
        resolve()
      })
      .catch((error) => {
        showErrorMessage(i18n.t('message.err_creating_new_events'))
        reject(error)
      })
    })
  }

  const createEventsByRisk = (confirmationPlannings, projectStartDate) => {
    let events = []

    let now = new Date()
    let firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    now = now.getTime()
    firstDayOfNextMonth = firstDayOfNextMonth.getTime()

    const projectStartYear = projectStartDate.getFullYear()
    const projectStartMonth = projectStartDate.getMonth()
    const projectStartDay = projectStartDate.getDate()

    confirmationPlannings.forEach(cp => {
      const cycleLength = cp.confirmationsPerYear
      const monthlyEvents = cycleLength === 12
      const timeSpan = Math.floor(365 / cycleLength)
      const eventDay = Math.floor(Math.random() * 31 + 1)
      const cycleStartMonth = eventDay >= projectStartDay ? projectStartMonth : projectStartMonth + 1

      for (let i = 0; i < cycleLength; i++) {
        const processId = cp.process._id
        let eventStartDate = monthlyEvents
          ? new Date(projectStartYear, cycleStartMonth + i, eventDay, 12)
          : new Date(projectStartYear, cycleStartMonth, eventDay + i * timeSpan, 12)

        // Check month
        let correctMonth
        if (monthlyEvents) {
          correctMonth = (cycleStartMonth + i) % 12
          if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(0)
        }

        // Check weekend
        if (!includeWeekends.value) {
          const dayOfWeek = eventStartDate.getDay()
          if (dayOfWeek === 0) {
            eventStartDate.setDate(eventStartDate.getDate() + 1)
            if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(-2)
          }
          else if (dayOfWeek === 6) {
            eventStartDate.setDate(eventStartDate.getDate() - 1)
            if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(eventStartDate.getDate() + 3)
          }
        }

        let eventEndDate = new Date(eventStartDate)
        eventEndDate.setMinutes(30)
  
        const eventData = {
          client_id: { link: client_id },
          process: { link: processId },
          organizer: { link: cp.process?.evaluator?._id || worker_id },
          start: eventStartDate,
          end: eventEndDate,
          extendedProps: {
            calendar: setEventStatus(now, firstDayOfNextMonth, eventStartDate.getTime()),
          },
        }

        if (cp.process?.evaluated?._id) eventData.attendee = { link: cp.process.evaluated._id }
        
        events.push(eventData)
      }
    })

    // Upload events
    return new Promise((resolve, reject) => {
      axios
      .post('/graphql', {
        query: addManyEvents,
        variables: { data: events },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
        const eventsCreated = data.data?.insertManyEvents?.insertedIds?.length
        showSuccessMessage(`${eventsCreated ? eventsCreated : 0} ${i18n.t('message.new_events_created')}`)
        resolve()
      })
      .catch((error) => {
        showErrorMessage(i18n.t('message.err_creating_new_events'))
        reject(error)
      })
    })
  }

  const setEventStatus = (now, firstDayOfNextMonth, eventStart) => {
    if (eventStart >= firstDayOfNextMonth) return "Futuras"
    if (eventStart < now) return "Vencidas"
    return "Pendientes"
  }

  return {
    bulkEventsUpload,
    bulkEventsUploadByRisk,
    projectStartDate,
    // filterBy,
    // filterOptions,
    // selectedWorkers,
    // selectedRoles,
    // selectedLocations,
    includeWeekends,
  }
}
