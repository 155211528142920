export const queryConfirmation = `query confirmation($query: ConfirmationQueryInput){
  confirmation(query: $query) {
    _id
    total
    score
    process {
      _id
      name
      code
      goal
      description
      requireImage
    }
    activities {
      name
      behaviours {
        name
        type
        answer
        expectedAnswer
        evidenceKey
        comment
      }
      score
      total
    }
    worker {
      _id
      name
      email
    }
    imageKey
    duration
    supervisor {
      _id
      name
    }
    metadata {
      name
      type
      options
      answer
    }
    date
  }
}
`

export const queryConfirmationsScore = `query confirmations($query: ConfirmationQueryInput, $limit: Int, $sortBy: ConfirmationSortByInput){
  confirmations(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    total
    score
    process {
      _id
      name
    }
    activities {
      name
      score
      total
      behaviours {
        name
        answer
        type
        expectedAnswer
      }
    }
    worker {
      _id
      name
      locations {
        location
      }
    }
    duration
    supervisor {
      _id
      name
      locations {
        location
      }
    }
    metadata {
      name
      answer
    }
    date
  }
}
`

export const queryConfirmations = `query confirmations($query: ConfirmationQueryInput, $limit: Int, $sortBy: ConfirmationSortByInput){
  confirmations(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    total
    score
    process {
      name
    }
    supervisor {
      name
    }
    date
  }
}
`

export const updateConfirmation = `mutation updateOneConfirmation($query: ConfirmationQueryInput, $data: ConfirmationUpdateInput!) {
  updateOneConfirmation(query: $query, set: $data) {
    _id,
  }
}
`

export const confirmProcess = `mutation insertOneConfirmation($data: ConfirmationInsertInput!) {
  insertOneConfirmation(data: $data) {
    _id
  }
}
`
export const _getPendingConfirmations = `query PendingConfirmationsData($input: PendingConfirmationsDatum!){
  PendingConfirmationsData(input: $input) {
    totalRecords
    pendingConfirmations {
      _id
      date
      workerName {
        name
      }
      processName {
        name
      }
    }
  }
}
`

export const deleteConfirmation = `mutation deleteOneConfirmation($query: ConfirmationQueryInput!) {
  deleteOneConfirmation(query: $query) {
    _id
  }
}
`