import axios from '@axios'
import { ref, set } from '@vue/composition-api'
import useNotifications from '@/composables/useNotifications'
import { queryConfirmation } from '@/@core/queries/confirmations'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import { getImprovementNotes } from '@/@core/queries/improvements'

export default function useConfirmationView() {
  const { showErrorMessage } = useNotifications()
  const { route } = useRouter()

  const confirmation = ref({})
  const isLoading = ref(true)

  const show = async () => {
    axios
      .post('/graphql', {
        query: queryConfirmation,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        confirmation.value = data.data?.confirmation || {}
        confirmation.value.activities?.forEach(e => set(e, 'showBehaviours', false))

        // Fetch cases generated in this evaluation
        axios
          .post('/graphql', {
            query: getImprovementNotes,
            variables: { query: { confirmation: { _id: route.value.params.id } } },
          })
          .then(({ data }) => {
            if (data.errors) throw new Error(data.errors[0].message)

            if (data.data?.improvements?.length) {
              for (const caseGenerated of data.data.improvements) {
                if (!caseGenerated.activity || !caseGenerated.behaviour) continue

                for (const activity of confirmation.value.activities) {
                  if (caseGenerated.activity !== activity.name) continue

                  for (const behaviour of activity.behaviours) {
                    if (caseGenerated.behaviour.name !== behaviour.name) continue

                    set(behaviour, 'caseId', caseGenerated._id)

                    break
                  }

                  break
                }
              }
            }
          })
          .catch((error) => {
            console.log(error)
            showErrorMessage(i18n.t('message.err_improvement_list'))
          })
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.confirmation_fetch_error'))
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const activityScore = (activity) => {
    if (!activity.total) return "N/A"
    const ratio = (activity.score / activity.total) * 100
    return `${ratio.toFixed(1)} %`
  }

  return {
    show, confirmation, activityScore, isLoading
  }
}
