<template>
  <div>
    <b-row
      v-for="(row,index) in inputs"
      :key="index"
    >
      <b-col
        v-for="(singleInput,loopIndex) in row.singleRow.rowInputs"
        :key="loopIndex"
        :md="singleInput.md"
      >
        <GlobalSingleInput
          :key="loopIndex"
          :input="singleInput"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import _ from 'lodash'
import i18n from '@/libs/i18n'

export default {
  name: i18n.t('label.identification'),
  components: {
    GlobalSingleInput,
  },
  props: ['data', 'workers', 'roles'],
  setup(props) {
    const workersForDropDown = ref([])
    const inputs = reactive([
      // {
      //   singleRow:
      //       {
      //         rowAttributes: {},
      //         rowInputs: [
      //           {
      //             label: i18n.t('label.executing_role'),
      //             type: 'select',
      //             key: 'role',
      //             rules: '',
      //             placeholder: i18n.t('placeholder.role'),
      //             value: '',
      //             options: props.roles,
      //             multiple: false,
      //           },
      //         ],
      //       },
      // },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.lead_responsible'),
                  type: 'select',
                  key: 'leadResponsible',
                  rules: '',
                  placeholder: i18n.t('placeholder.worker'),
                  value: '',
                  description: i18n.t('message.lead_responsible_desc'),
                  options: props.workers,
                },
                {
                  md: 6,
                  label: i18n.t('label.responsible'),
                  type: 'select',
                  key: 'responsible',
                  rules: '',
                  placeholder: i18n.t('placeholder.worker'),
                  value: '',
                  description: i18n.t('message.responsible_desc'),
                  options: props.workers,
                },
              ],
            },
      },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.evaluator'),
                  type: 'select',
                  key: 'evaluator',
                  rules: '',
                  placeholder: i18n.t('placeholder.worker'),
                  value: '',
                  description: i18n.t('message.evaluator_desc'),
                  options: props.workers,
                },
                {
                  md: 6,
                  label: i18n.t('label.evaluated'),
                  type: 'select',
                  key: 'evaluated',
                  rules: '',
                  placeholder: i18n.t('placeholder.worker'),
                  value: '',
                  description: i18n.t('message.evaluated_desc'),
                  options: props.workers,
                },
              ],
            },
      },
    ])

    const getData = () => ({ raci: convertInputsToKeyValuePair(inputs) })

    watch(() => props.workers, data => {
      workersForDropDown.value = data.map(worker => ({ value: worker.value, title: worker.title }))
      inputs.forEach((row, index) => {
        row.singleRow.rowInputs.forEach((input, inputIndex) => {
          if (inputs[index].singleRow.rowInputs[inputIndex].key !== 'role') inputs[index].singleRow.rowInputs[inputIndex].options = workersForDropDown.value
        })
      })
    }, { deep: true, immediate: true })

    watch(() => props.roles, data => {
      inputs[0].singleRow.rowInputs[0].options = data
    }, { deep: true, immediate: true })

    watch(() => props.data, data => {
      setTimeout(() => {
        if (data) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(data)) {
            inputs.forEach(row => {
              if (_.find(row.singleRow.rowInputs, { key })) {
                _.find(row.singleRow.rowInputs, { key }).value = value
              }
            })
          }
        }
      }, 1000)
    }, { deep: true, immediate: true })

    return {
      inputs,
      getData,
    }
  },
}
</script>

<style scoped>

</style>
