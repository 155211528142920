<template>
  <b-card>
    <b-row>
      <b-col sm="8" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-behaviour-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Behaviour') }}
        </b-button>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refBehaviourListTable"
          :items="behaviours"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(name)="data">
            <span v-html="data.item.name" class="behaviourClass"></span>
          </template>

          <template #cell(type)="data">
            <span>
              {{ data.item.typeComputed.value }}
            </span>
          </template>

          <template #cell(expectedAnswer)="data">
            <span>
              {{ data.item.expectedAnswerComputed.value }}
            </span>
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <!-- <ViewButton /> -->
              <EditButton @clicked="router.push({ name: 'organization-behaviour-edit', params: { id: data.item._id } })"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteBehaviour(data.item._id)"
              >
                {{ $t('message.confirm_delete_behaviour') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="perPageOptions"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalBehaviours"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BBadge, BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'

import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
// import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useBehaviourList from './useBehaviourList'


export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    // ViewButton,
    DeleteButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: [],
    }
  },
  setup() {
    const { router } = useRouter()
    const {
      fetchBehaviours,
      tableColumns,
      perPage,
      currentPage,
      totalBehaviours,
      showingMessage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteBehaviour,
      behaviours,
      isLoading,
    } = useBehaviourList()

    onMounted(() => {
      fetchBehaviours()
    })

    return {
      router,
      fetchBehaviours,
      tableColumns,
      perPage,
      currentPage,
      totalBehaviours,
      showingMessage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteBehaviour,
      behaviours,
      isLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
  .form-group {
    label {
      font-size: 0.85rem;
      font-weight: 400;
    }
  }
  .behaviourClass ::v-deep p {
    margin: 0px;
  }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
