import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getSingleMetadata, updateMetadata } from '@/@core/queries/metadata'
import i18n from '@/libs/i18n'

export default function useMetadataUpload() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const singleMetadata = ref({})
  const isLoading = ref(false)

  function upload(data) {
    isLoading.value = true
    const optionsToUpload = data.itemList?.value || []
    // Get metadata options
    store
      .dispatch('metadataStoreModule/show', {
        query: getSingleMetadata,
        variables: { query: { _id: data.nameId.value } },
      })
      .then(response => {
        const actualMetadataOptions = response?.data?.data?.metadatum?.options
        const newMetadataOptions = Array.from(new Set([...actualMetadataOptions, ...optionsToUpload]))

        // Update metadata options with new options added
        store
          .dispatch('metadataStoreModule/update', {
            query: updateMetadata,
            variables: { query: { _id: data.nameId.value }, data: { options: newMetadataOptions } },
          })
          .then(() => {
            showSuccessMessage(i18n.t('message.metadata_uploaded'))
          })
          .catch(() => {
            showErrorMessage(i18n.t('message.metadata_upload_error'))
          })
          .finally(() => {
            router.push({ name: 'organization-metadata-list' })
            isLoading.value = false
          })
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.metadata_upload_error'))
        router.push({ name: 'organization-metadata-list' })
        isLoading.value = false
      })
  }

  return {
    upload,
    singleMetadata,
    isLoading
  }
}
