import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { addLocation, insertDefaultPayLoad } from '@/@core/queries/locations'
import i18n from '@/libs/i18n'

export default function useLocationAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const userData = store.state?.userStore?.userData
  const location = ref({})
  const isSubmitting = ref(false)

  function create(data) {
    isSubmitting.value = true
    const payload = {}
    if (data.location?.value) payload.location = data.location.value
    if (data.zone?.value) payload.zone = data.zone.value
    if (data.agency?.value) payload.agency = data.agency.value
    payload.client_id = (userData.role !== 'admin') ? { link: userData.client?.$oid } : null

    store
      .dispatch('locationStoreModule/addLocation', {
        query: addLocation,
        variables: { data: Object.assign(insertDefaultPayLoad, payload) },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.location_added'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.location_add_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-location-list' })
        isSubmitting.value = false
      })
  }

  return {
    create,
    location,
    isSubmitting,
  }
}
