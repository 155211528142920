import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import i18n from '@/libs/i18n';
import realmConnection from '@/views/habit/realm'

export default function useBehaviourList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { getItemsWithAggregate, updateItem, updateItems, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const behaviours = ref([])
  const collection = 'behaviour'

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'type', label: i18n.t('label.Type'), sortable: true },
      { key: 'expectedAnswer', label: i18n.t('label.expected_answer'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const perPage = ref(10)
  const totalBehaviours = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchBehaviours = async () => {
    isLoading.value = true

    try {
      const filter = {
        client_id: ObjectId(userData.client.$oid),
        deleted: { $ne: true }
      }
      
      if (searchQuery.value) filter.name = { $regex: searchQuery.value, $options: 'i' }
    
      const pipeline = [
        { $match: filter },
        { $project: { name: 1, type: 1, expectedAnswer: 1 } },
        { $facet: {
            count: [ { $count: 'totalItems' } ],
            pagination: [
              { $sort: { [sortBy.value]: isSortDirDesc.value ? -1 : 1, _id: isSortDirDesc.value ? -1 : 1 } },
              { $skip: (currentPage.value * perPage.value) - perPage.value || 0 },
              { $limit: perPage.value || 10 }
            ]
          }
        }
      ]
    
      const items = await getItemsWithAggregate({ collection, pipeline })

      items[0]?.pagination?.forEach(e => {
        // Set type
        e.typeComputed = computed(() => e.type ? i18n.t(`behaviourTypes.${e.type}`) : '')

        // Set expected answer
        e.expectedAnswerComputed = computed(() => e.type !== 'bool' ? '' : e.expectedAnswer ? i18n.t('message.yes') : i18n.t('message.no'))
      })

      behaviours.value = items[0]?.pagination || []
      totalBehaviours.value = items[0]?.count[0]?.totalItems || 0
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.behaviour_list_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  const deleteBehaviour = async (id) => {
    try {
      const query = { _id: ObjectId(id) }
      const payload = { deleted: true }
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.behaviour_remove'))
      fetchBehaviours()

      // Delete behaviour from the controls where it is associated
      const pullQuery = { behaviours: ObjectId(id) }
      const pullAction = { $pull: pullQuery }
      await updateItems({ collection: 'control', query: pullQuery, action: pullAction })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.something_went_wrong'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (behaviours.value.length ? 1 : 0)
    const to = behaviours.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalBehaviours.value })
  })

  watch([currentPage, perPage], () => {
    fetchBehaviours()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchBehaviours()
  })

  return {
    fetchBehaviours,
    tableColumns,
    perPage,
    currentPage,
    totalBehaviours,
    showingMessage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    behaviours,
    deleteBehaviour,
    isLoading,
  }
}