export const createActionPlan = `mutation insertOneAction_plan($data: Action_planInsertInput!){
  insertOneAction_plan(data: $data) {
    _id
  }
}`

export const updateActionPlan = `mutation updateOneAction_plan($query: Action_planQueryInput!, $data: Action_planUpdateInput!) {
  updateOneAction_plan(query: $query, set: $data) {
    _id
  }
}`

export const deleteActionPlan = `mutation deleteOneAction_plan($query: Action_planQueryInput!) {
  deleteOneAction_plan(query: $query) {
    _id
  }
}`