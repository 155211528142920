import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import organization from './routes/organization'
import habit from './routes/habit'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...apps,
    ...organization,
    ...habit,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const pagesUserNotLogged = new Set(['auth-login', 'auth-forgot-password', 'auth-reset-password', 'error-404', 'employee-survey', 'complaint-new', 'complaint-follow-up'])

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (isLoggedIn) {
    // If logged in and can't navigate => not authorized
    if (!canNavigate(to)) return next({ name: 'misc-not-authorized' })

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn) {
      // const userData = getUserData()
      // next(getHomeRouteForLoggedInUser(userData ? userData?.role : null))
      const { role } = store.state?.userStore?.userData
      const clientFunctionality = localStorage.getItem("clientData") ? JSON.parse(localStorage.clientData) : false
      if (clientFunctionality.default_view_pc_team) next({ name: 'apps-pc-team' })
      else if (clientFunctionality.default_view_daily_dialogue) next({ name: 'habit-meeting-new' })
      else next({ name: role === 'employee' ? 'organization-complaint-list' : 'habit-general-dashboard' })
    }
  }
  else if (!pagesUserNotLogged.has(to.name)) {
    // Redirect to login if not logged in, storing the actual path to redirect afterwards
    const actualPath = window.location.pathname;
    return next({ name: 'auth-login', query: { from: actualPath } });
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
