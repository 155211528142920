let endpoints = {
  app_id: "",
  graphql_endpoint: "",
  aws_bucket: "",
  database_name: ""
}

switch (process.env.NODE_ENV) {
  case 'development':
    endpoints.app_id = process.env.VUE_APP_APP_ID_DEVELOPMENT;
    endpoints.graphql_endpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT_DEVELOPMENT;
    endpoints.aws_bucket = process.env.VUE_APP_AWS_BUCKET_DEVELOPMENT;
    endpoints.database_name = process.env.VUE_APP_DATABASE_NAME_DEVELOPMENT;
    break
  case 'production':
    endpoints.app_id = process.env.VUE_APP_APP_ID_PRODUCTION;
    endpoints.graphql_endpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT_PRODUCTION;
    endpoints.aws_bucket = process.env.VUE_APP_AWS_BUCKET_PRODUCTION;
    endpoints.database_name = process.env.VUE_APP_DATABASE_NAME_PRODUCTION;
    break
  case 'staging':
    endpoints.app_id = process.env.VUE_APP_APP_ID_STAGING;
    endpoints.graphql_endpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT_STAGING;
    endpoints.aws_bucket = process.env.VUE_APP_AWS_BUCKET_STAGING;
    endpoints.database_name = process.env.VUE_APP_DATABASE_NAME_STAGING;
    break
  default:
    endpoints.app_id = process.env.VUE_APP_APP_ID_DEVELOPMENT;
    endpoints.graphql_endpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT_DEVELOPMENT;
    endpoints.aws_bucket = process.env.VUE_APP_AWS_BUCKET_DEVELOPMENT;
    endpoints.database_name = process.env.VUE_APP_DATABASE_NAME_DEVELOPMENT;
    break
}

export default {
  app_id: endpoints.app_id,
  graphql_endpoint: endpoints.graphql_endpoint,
  aws_bucket: endpoints.aws_bucket,
  database_name: endpoints.database_name,
}