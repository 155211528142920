import { ref, watch } from '@vue/composition-api'
import axios from '@axios'
import { updateImprovement } from '@/@core/queries/improvements'

export default function useProblemSolvingHandler(props, emit) {
  // ------------------------------------------------
  // problemSolvingLocal
  // ------------------------------------------------
  const problemSolvingLocal = ref(JSON.parse(JSON.stringify(props.problemSolving.value)))
  const resetProblemSolvingLocal = () => {
    problemSolvingLocal.value = JSON.parse(JSON.stringify(props.problemSolving.value))
  }
  watch(props.problemSolving, () => {
    resetProblemSolvingLocal()
  })

  const commitmentsToUpdate = ref([])
  const commitmentsToDelete = ref([])

  const onSubmit = () => {
    const problemSolvingData = JSON.parse(JSON.stringify(problemSolvingLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    props.problemSolving.value._id = props.routeParamsQuery ? props.routeParamsQuery : props.problemSolving.value._id
    if (props.problemSolving.value._id) emit('update-problemSolving', problemSolvingData.value, "update", commitmentsToUpdate.value, commitmentsToDelete.value)
    else emit('add-problemSolving', problemSolvingData.value)

    // Update commitments
    if (commitmentsToUpdate.value.length) {
      commitmentsToUpdate.value.forEach(e => {
        axios
          .post('/graphql', {
            query: updateImprovement,
            variables: { query: { _id: e._id }, data: e }
          })
          .then(() => {})
          .catch((error) => {
            console.log(error)
          })
      })
    }

    // Delete commitments
    if (commitmentsToDelete.value.length) {
      commitmentsToDelete.value.forEach(e => {
        axios
          .post('/graphql', {
            query: updateImprovement,
            variables: { query: { _id: e._id }, data: { deleted: true } }
          })
          .then(() => {})
          .catch((error) => {
            console.log(error)
          })
      })
    }

    // Close sidebar
    emit('update:is-problem-solving-handler-sidebar-active', false)
  }

  return {
    problemSolvingLocal,
    resetProblemSolvingLocal,
    onSubmit,
    commitmentsToUpdate,
    commitmentsToDelete,
  }
}
