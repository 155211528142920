// Required during insertion if we have not set the keys it will add to the payload
export const insertDefaultPayLoad = {
  code: '',
  description: '',
  name: '',
  responsibilities: [],
  qualifications: [],
  deleted: false,
  client_id: null,
}

const query = `query roles($query: RoleQueryInput, $limit: Int, $sortBy: RoleSortByInput){
    roles(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      name
    }
  }
  `

// eslint-disable-next-line no-underscore-dangle
export const _getRoles = `query RolesData($input: RolesDatum!){
    RolesData(input: $input) {
        totalRecords
        roles {
          _id
          code
          description
          name
          qualifications
          responsibilities
        }
    }
  }`

export const getRole = `query getRole($query: RoleQueryInput){
    role(query: $query) {
      _id
      code
      description
      name
      qualifications
      responsibilities
      subroles {
        _id
      }
    }
  }`

export const updateRole = `mutation updateOneRole($query: RoleQueryInput!, $data: RoleUpdateInput!) {
    updateOneRole(query: $query, set: $data) {
      _id
    }
  }
  `

export const addRole = `mutation insertOneRole($data: RoleInsertInput!){
    insertOneRole(data: $data) {
      _id
      name
      code
      description
      responsibilities
      qualifications
    }
  }`

export const deleteOneRole = `mutation deleteOneRole($query: RoleQueryInput!) {
    deleteOneRole(query: $query) {
      _id
      name
      code
    }
  }
  `

export default query
