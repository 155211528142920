<template>
  <b-card
    class="text-center"
    style="height: 12.5rem"
    :role="isButton ? 'button' : ''"
    @click="goToDashboard"
  >
    <b-avatar
      class="mb-1"
      variant="light-success"
      size="45"
    >
      <feather-icon
        size="21"
        icon="UserPlusIcon"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span>{{ $t('label.adherence_adjusted') }}</span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
 components: {
   BCard,
   BAvatar,
 },
 props: {
   statistic: {
     type: [Number, String],
     required: true,
   },
    isButton: {
      type: Boolean,
      default: false,
    },
 },
  setup(props, { emit }) {
    const goToDashboard = () => emit('goToDashboard')

    return {
      goToDashboard,
    }
  },
}
</script>
