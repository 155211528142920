<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!--div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"-->
      
      <!-- Bookmarks Container -->
      <!--bookmarks /-->
    <!--/div-->

    <!-- Add improvement -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      size="sm"
      class="d-none d-lg-block"
      @click="router.push({name:'organization-case-add'})"

    >
      {{ commitmentFunctionality ? $t('add_commitment') : $t('add_improvement') }}
    </b-button>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      :domains="domains"
      :optionsMetadata="optionsMetadata"
      handlerId="navbar"
      @add-task="addOrStoreTask"
    />

    <!-- Add recognition -->
    <!-- <b-button
      v-if="isOnline"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      size="sm"
      class="d-none d-lg-block ml-2"
      @click="isRecognitionHandlerSidebarActive = true"
    >
      {{ $t('add_recognition') }}
    </b-button> -->

    <!-- Recognition Handler -->
    <!-- <recognition-handler-sidebar
      v-if="isOnline"
      v-model="isRecognitionHandlerSidebarActive"
      :recognition="recognition"
      :clear-recognition-data="clearRecognitionData"
      :motives="motives"
      @add-recognition="createRecognition"
    /> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <add-to-home-screen />
      <locale :clientId="clientId" />
      <add-improvement
        class="d-block d-lg-none"
        @add-improvement="isTaskHandlerSidebarActive = true"
      />
      <!-- <add-recognition
        v-if="isOnline"
        class="d-block d-lg-none"
        @add-recognition="isRecognitionHandlerSidebarActive = true"
      /> -->
      <dark-Toggler class="d-none d-lg-block" />
      <!--search-bar /-->
      <!--cart-dropdown /-->
      <!--notification-dropdown /-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import AddImprovement from './components/AddImprovement.vue'
// import AddRecognition from './components/AddRecognition.vue'
import Ripple from 'vue-ripple-directive'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
// import RecognitionHandlerSidebar from '@/views/habit/recognition/add-recognition/RecognitionHandlerSidebar.vue'
import todoStoreModule from '@/views/apps/todo/todoStoreModule'
import { ref, computed, watch, onUnmounted, onMounted } from '@vue/composition-api'
// import router from '@/router/index'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import awsConnection from '@/views/habit/aws';
import AddToHomeScreen from '@/views/habit/AddToHomeScreen.vue';
import useCommon from "@/views/organization/useCommon";
// import { insertRecognition } from '@/@core/queries/recognitions'
// import axios from '@axios'
import onlineUpdate from '@/views/habit/onlineUpdate'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BButton,
    TodoTaskHandlerSidebar,
    // RecognitionHandlerSidebar,
    AddImprovement,
    // AddRecognition,
    AddToHomeScreen,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    const now = new Date()

    const isOnline = computed(() => store.state.app.isOnline)
    const offlineMode = computed(() => store.state.app.offlineMode)

    const { uploadStorage } = onlineUpdate()
    const { singleUpload } = awsConnection()
    const { getEmailTemplate, addTask } = useCommonTodo()

    const {router} = useRouter()

    const commitmentFunctionality = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
      : false

    const { getMetadataForDropDown, metadataNotMapped, getSupervisorsForDropDown, supervisorsOnlyNames, getMotivesForDropDown, motives, checkStorageData, updateStorageData, storeDataToUpload } = useCommon()

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // Reset store states for metadata filters in continuous improvement tab
    store.commit('app-todo/SET_CLIENT_HAS_METADATA', false)
    store.commit('app-todo/SET_OPTIONS_METADATA', {})

    const handleOnlineStatus = async () => {
      // If user is in offline mode then do nothing
      if (offlineMode.value) return

      // Upload forms that were stored while being offline
      await uploadStorage()

      // Set isOnline variable in store to true
      store.commit('app/UPDATE_ONLINE_STATUS', true)
      showSuccessMessage(i18n.t('message.online_mode'))

      // Fetch data from database and update storage for offline use
      updateStorageData()
    }

    const handleOfflineStatus = () => {
      // If user is in offline mode then do nothing
      if (offlineMode.value) return

      // Set isOnline variable in store to false
      store.commit('app/UPDATE_ONLINE_STATUS', false)
      showErrorMessage(i18n.t('message.offline_mode'))

      // Check if there is data in storage to be used while being offline
      checkStorageData()
    }

    onMounted(() => {
      // Set button visibility
      // router.onReady(() => {
      //   setAddImprovementVisibility(router.currentRoute.name)
      // })

      // Update store value if user is offline
      if (!navigator.onLine) handleOfflineStatus()

      // Add event listener for connectivity status detection
      window.addEventListener('online', handleOnlineStatus)
      window.addEventListener('offline', handleOfflineStatus)

      getSupervisorsForDropDown("onlyNames")
      getMetadataForDropDown({category: "improvement", option: "notMapped"})
      getMetadataForDropDown({type: "select", option: "notMapped"})
      getMotivesForDropDown()

      // Invoke get functions to update data in browser storage
      updateStorageData()
    })

    // UnRegister and remove event listeners on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOfflineStatus)
    })

    const isTaskHandlerSidebarActive = ref(false)
    // const isRecognitionHandlerSidebarActive = ref(false)

    const toggleTaskHandlerSidebar = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].toggleTaskHandlerSidebar)

    watch(toggleTaskHandlerSidebar, () => {
      if (toggleTaskHandlerSidebar.value) {
        isTaskHandlerSidebarActive.value = true
      }
    })

    // const isVisible = ref(false)

    // const { route } = useRouter()
    // const routeName = computed(() => route.value.name)

    // watch(routeName, val => {
    //   setAddImprovementVisibility(val)
    // })

    // const setAddImprovementVisibility = (routeName) => {
    //   if (routeName && routeName.startsWith('apps-todo')) isVisible.value = false
    //   else isVisible.value = true
    // }

    const { showSuccessMessage, showErrorMessage } = useNotifications()
    
    const userData = store.state?.userStore?.userData;
    const workerId = userData.role !== "admin" ? userData.worker_id.$oid : null;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;

    const { getDomains } = useCommonDashboards()
    const domains = computed(() => getDomains(clientId))

    let blankTask = {
      _id: null,
      client_id: { link: clientId },
      note: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      completed: false,
      deleted: false,
      important: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    // let blankRecognition = {
    //   client_id: { link: clientId },
    //   date: new Date(),
    //   worker: null,
    //   motive: null,
    //   note: '',
    //   created_by: { link: workerId },
    // }
    // const recognition = ref(JSON.parse(JSON.stringify(blankRecognition)))
    // const clearRecognitionData = () => {
    //   recognition.value = JSON.parse(JSON.stringify(blankRecognition))
    // }

    const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        // Update state in store to allow metadata filters to be visible
        store.commit('app-todo/SET_CLIENT_HAS_METADATA', true)
        
        val.forEach(e => {
          // Set default creation date
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = userData.username
          // Update optionsMetadata state in store for metadata dropdowns
          if (e.name !== "instance_leader") {     // instance_leader options are filled with the payload from getSupervisorsForDropDown function
            optionsMetadata.value[e.name] = e.options
          }
        })
        blankTask.metadata = val
        if (!task.value._id) task.value = {...task.value, metadata: val}
      }
    })

    watch(supervisorsOnlyNames, val => {
      optionsMetadata.value["instance_leader"] = val
    })

    // const closeSidebar = () => {
    //   isTaskHandlerSidebarActive.value = false
    // }

    const addOrStoreTask = (val) => {
      if (isOnline.value) addTask(val)
      else {
        storeDataToUpload('improvementsToUpload', val)
        showSuccessMessage(i18n.t('message.form_stored'))
      }
    }

    // const createRecognition = val => {
    //   val.date = val.date ? new Date(`${val.date.slice(0, 10)} 12:00:00`) : null

    //   if (val.worker) {
    //     val.worker = { link: val.worker._id }
    //   }

    //   if (val.motive) {
    //     val.motive = { link: val.motive._id }
    //   }

    //   const query = {
    //     query: insertRecognition,
    //     variables: { data: val },
    //   }

    //   axios
    //     .post('/graphql', query)
    //     .then(response => {
    //       if (response.data.errors) throw new Error()
    //       showSuccessMessage(i18n.t('message.recognition_created'))
    //     })
    //     .catch((error) => {
    //       showErrorMessage(i18n.t('message.recognition_error'))
    //       console.log(error)
    //     })
    // }

    return {
      isTaskHandlerSidebarActive,
      // isRecognitionHandlerSidebarActive,
      // isVisible,
      isOnline,
      // closeSidebar,
      task,
      // recognition,
      clearTaskData,
      // clearRecognitionData,
      domains,
      motives,
      addOrStoreTask,
      // createRecognition,
      optionsMetadata,
      clientId,
      commitmentFunctionality,
      router
    }
  }
}
</script>
