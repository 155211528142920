import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useControlEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const control = ref({})
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'control'

  const show = async () => {
    isSubmitting.value = true

    try {
      const item = await getItem({ collection, query })
      item.behaviours = item.behaviours?.map(e => String(e)) || []
      control.value = item
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.control_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async (data) => {
    isSubmitting.value = true

    try {
      const payload = {
        name: data.name,
        behaviours: data.behaviours?.map(e => ObjectId(e)) || []
      }

      const action = { $set: payload }
      
      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.control_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.control_update_error'))
    } finally {
      router.push({ name: 'organization-control-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    control,
    isSubmitting,
  }
}
