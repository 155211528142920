<template>
  <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="flat-info"
      class="btn-icon rounded-circle"
      v-b-tooltip.hover.v-info.top="$t('Profile')"
      @click="$emit('clicked')"
  >
    <feather-icon icon="UserIcon" />
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'UserButton',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
}
</script>

<style scoped>

</style>
