<template>
  <b-card
    :role="isButton ? 'button' : ''"
    :class="`text-center border-${isSelected || isHovered ? variant : 'white'}`"
    @click="filter"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <b-avatar
      class="mb-1"
      :variant="`light-${variant}`"
      :size="avatarSize"
    >
      <feather-icon
        :size="iconSize"
        :icon="iconName"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span>{{ $t(`${i18nVariable}`) }}</span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
    avatarSize: {
      type: String,
      default: "45",
    },
    iconSize: {
      type: String,
      default: "21",
    },
    iconName: {
      type: String,
      default: "CheckIcon",
    },
    i18nVariable: {
      type: String,
      required: true,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isHovered = ref(false)

    const filter = () => emit('filter')

    return {
      isHovered,
      filter,
    }
  },
}
</script>
