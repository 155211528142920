import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import workerStoreModule from '@/views/organization/worker/workerStoreModule'
import roleStoreModule from '@/views/organization/role/roleStoreModule'
import processStoreModule from '@/views/organization/process/processStoreModule'
import locationStoreModule from '@/views/organization/location/locationStoreModule'
import metadataStoreModule from '@/views/organization/metadata/metadataStoreModule'
import confirmationPlanningStoreModule from '@/views/organization/confirmation-planning/confirmationPlanningStoreModule'
import improvementStoreModule from '@/views/habit/improvement/improvementStoreModule'
import recognitionStoreModule from '@/views/habit/recognition/recognitionStoreModule'
import audienceStoreModule from '@/views/organization/lobby/audienceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userStoreModule from '@/views/apps/user/userStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    workerStoreModule,
    roleStoreModule,
    processStoreModule,
    locationStoreModule,
    metadataStoreModule,
    confirmationPlanningStoreModule,
    improvementStoreModule,
    recognitionStoreModule,
    audienceStoreModule,
    userStore: userStoreModule,
  },
  strict: process.env.DEV,
})
