export const getProblemSolvings = `query problem_solvings($query: Problem_solvingQueryInput, $limit: Int, $sortBy: Problem_solvingSortByInput){
  problem_solvings(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    title
    leader {
      _id
      name
    }
    deleted
    completed
    important
    improvements {
      _id
      note
      description
      dueDate
      completed
      deleted
      imageKey
      assignee {
        _id
        name
        email
      }
      metadata {
        name
        type
        answer
      }
    }
  }
}`

export const getProblemSolving = `query problem_solving($query: Problem_solvingQueryInput){
  problem_solving(query: $query) {
    _id
    title
    leader {
      _id
      name
    }
    improvements {
      _id
      note
      origin
      origin_id
      description
      dueDate
      assignee {
        _id
        name
      }
    }
  }
}`


export const addProblemSolving = `mutation insertOneProblem_solving($data: Problem_solvingInsertInput!){
  insertOneProblem_solving(data: $data) {
    _id
  }
}`

export const updateProblemSolving = `mutation updateOneProblem_solving($query: Problem_solvingQueryInput, $data: Problem_solvingUpdateInput!) {
  updateOneProblem_solving(query: $query, set: $data) {
    _id
  }
}`