<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      
      <calendar-sync :isOnline="isOnline" @refreshCalendar="refreshCalendar"/>

      <b-button block variant="primary" @click="goToProcessList" class="mb-1">
        {{ $t('newConfirmation') }}
      </b-button>

      <b-button v-if="commitmentFunctionality && isOnline" block variant="warning" @click="createNewInstance" class="mb-1">
        {{ $t('newInstance') }}
      </b-button>

      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('message.calendars') }}</span>
        </h5>
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
          :disabled="!isOnline"
        >
          {{ $t('message.View_All') }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
            :disabled="!isOnline"
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ $t(item.label) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" /> -->
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import CalendarSync from '../calendar-sync/CalendarSync.vue'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    CalendarSync
},
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    isOnline: {
      type: Boolean,
      require: true,
    },
  },
  setup(props, { emit }) {

    const { calendarOptions, selectedCalendars, checkAll } = useCalendarSidebar()

    const { router } = useRouter()

    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality

    const refreshCalendar = () => emit('refreshCalendar')

    const goToProcessList = () => router.push({ name: 'organization-process-list' })

    const createNewInstance = () => emit('newInstance')

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
      commitmentFunctionality,
      refreshCalendar,
      goToProcessList,
      createNewInstance,
    }
  },
}
</script>
