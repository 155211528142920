<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :zoneFilterShow="zoneAgencyFilter"
          :agencyFilterShow="zoneAgencyFilter"
          :locationFilterShow="true",
          :roleFilterShow="true",
          :processFilterShow="true",
          :workerFilterShow="true",
          :observerFilterShow="true",
          :dateRangeFilterShow="true",
          @changeFilter="updateFilter"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )
        
  div(v-else)
    b-row(v-if="!confirmations_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_confirmations_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="behaviours_total")
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="adherence_index")
        b-col(sm="3")
          statistic-card-vertical-confirmations(:statistic="confirmations_total")

      //- b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.behaviour') }}
              th {{ $t('message.tableHeader.activity') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.score') }}
              th {{ $t('message.tableHeader.total') }}
              th {{ $t('message.tableHeader.adherence') }}
            b-tr(v-for="(behaviour, index) in behaviours", :key="index")
              b-td(v-html="behaviour.behaviour", class="behaviourClass")
              b-td {{ behaviour.activity }}
              b-td {{ behaviour.process }}
              b-td {{ behaviour.score }}
              b-td {{ behaviour.total }}
              b-td {{ behaviour.adherenceText }}

      b-card
        b-table(
          :items="behaviours"
          :fields="tableHeaders"
          hover
          responsive
        )
          template(#cell(adherence)="data")
            span {{ data.item.adherenceText }}

      b-button(:disabled="!behaviours.length")
        json-excel(:data="behaviours", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>

<script>
import { queryConfirmationsScore } from "@/@core/queries/confirmations";
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import useNotifications from "@/composables/useNotifications";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import StatisticCardVerticalConfirmations from './charts/StatisticCardVerticalConfirmations.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import { useRouter } from '@core/utils/utils'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from "@/store";
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    StatisticCardVerticalConfirmations,
    Filters,
    ViewButton,
    Loading,
  },
  setup() {
    const { showErrorMessage } = useNotifications();
    const { handleError } = useCommon()
    const { getItems, ObjectId } = realmConnection()
    
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const behaviours = ref([]);
    const behaviours_total = ref(0);
    const confirmations_total = ref(0);
    const adherence_index = ref("-");
    const locationFilter = ref([]);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const workerFilter = ref([]);
    const observerFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false
    const excelFields = ref({
      [i18n.t('message.tableHeader.behaviour')]: "behaviour",
      [i18n.t('message.tableHeader.score')]: "score",
      [i18n.t('message.tableHeader.total')]: "total",
      [i18n.t('message.tableHeader.adherence')]: "adherence",
    })

    // Table Headers
    const tableHeaders = computed(() => {
      return [
        {
          key: 'behaviour',
          label: i18n.t('message.tableHeader.behaviour'),
          sortable: true,
        },
        {
          key: 'score',
          label: i18n.t('message.tableHeader.score'),
          sortable: true,
        },
        {
          key: 'total',
          label: i18n.t('message.tableHeader.total'),
          sortable: true,
        },
        {
          key: 'adherence',
          label: i18n.t('message.tableHeader.adherence'),
          sortable: true,
        },
      ]
    })

    const { router } = useRouter()
    const { getDatesFromRange } = useCommonDashboards()

    const getBehaviours = async () => {
      isLoading.value = true

      try {
        const query = {
          client_id: ObjectId(clientId),
          deleted: { $ne: true }
        }

        const items = await getItems({ collection: 'behaviour', query })

        behaviours.value = items?.map(e => ({ behaviour: e.name?.replace(/<[^>]*>/g, '') })) || []
        behaviours_total.value = items?.length || 0

        getConfirmations()
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.data_fetch_error'))
        isLoading.value = false
      }
    }

    async function getConfirmations() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      let conditions = {
        client_id: { _id: clientId },
        worker: {},
        date_gte: startFilter,
        date_lt: endFilter,
        pending_ne: true,
      };

      if (locationFilter.value.length > 0) {
        const locationsFilterQuery = locationFilter.value.map(e => ({locations_in: {_id: e}}))
        conditions.worker.AND = [{OR: locationsFilterQuery}]
      }

      if (zoneFilter.value.length > 0) {
        const zonesFilterQuery = zoneFilter.value.map(e => ({locations_in: {zone: e}}))
        if (conditions.worker.AND) conditions.worker.AND.push({OR: zonesFilterQuery})
        else conditions.worker.AND = [{OR: zonesFilterQuery}]
      }

      if (agencyFilter.value.length > 0) {
        const agenciesFilterQuery = agencyFilter.value.map(e => ({locations_in: {agency: e}}))
        if (conditions.worker.AND) conditions.worker.AND.push({OR: agenciesFilterQuery})
        else conditions.worker.AND = [{OR: agenciesFilterQuery}]
      }

      if (roleFilter.value.length > 0) {
        conditions.worker.roles_in = roleFilter.value
      }

      if (processFilter.value.length > 0) {
        conditions.process = {
          _id_in: processFilter.value,
        };
      }

      if (workerFilter.value.length > 0) {
        conditions.worker._id_in = workerFilter.value
      }

      if (observerFilter.value.length > 0) {
        conditions.supervisor = {
          _id_in: observerFilter.value
        }
      }

      axios
        .post("/graphql", {
          query: queryConfirmationsScore,
          variables: { query: conditions, limit: 10000 },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          parseConfirmationData(data)
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    function parseConfirmationData(data) {
      // Reset values for each behaviour
      behaviours.value.forEach(e => {
        e.score = "-"
        e.total = "-"
        e.adherence = "-"
        e.adherenceText = "-"
      })

      confirmations_total.value = data.data.confirmations?.length || 0
      let adherence_data = {score: 0, total: 0}

      for (const confirmation of data.data.confirmations) {
        for (const activity of confirmation.activities) {
          for (const behaviour of activity.behaviours) {
            if ((behaviour.type === "bool" || behaviour.type === "rating") && behaviour.answer && behaviour.answer !== "unknown" && behaviour.answer !== "N/A") {
              for (const b of behaviours.value) {
                if (behaviour.name?.replace(/<[^>]*>/g, '') !== b.behaviour) continue

                if (behaviour.type === "bool") {
                  if (behaviour.answer === "true" && behaviour.expectedAnswer || behaviour.answer === "false" && !behaviour.expectedAnswer) {
                    b.score = b.score === "-" ? 1 : b.score + 1
                    adherence_data.score += 1
                  }
                  if (behaviour.answer === "true" || behaviour.answer === "false") {
                    if (b.score === "-") b.score = 0
                    b.total = b.total === "-" ? 1 : b.total + 1
                    adherence_data.total += 1
                  }
                } else {
                  b.score = b.score === "-" ? parseInt(behaviour.answer) : b.score + parseInt(behaviour.answer)
                  b.total = b.total === "-" ? 5 : b.total + 5
                  adherence_data.score += parseInt(behaviour.answer)
                  adherence_data.total += 5
                }
                if (b.total !== "-") {
                  b.adherence = behaviourAdherence(b.score, b.total)
                  b.adherenceText = `${b.adherence}%`
                }
              }
            }
          }
        }
      }

      behaviours.value.sort(function (a, b) {
        if (a.adherence === b.adherence) return b.total - a.total
        if (b.adherence === "-" && a.adherence !== "-") return -1
        return a.adherence - b.adherence
      })

      adherence_index.value = adherence_data.total === 0
        ? "-"
        : `${behaviourAdherence(adherence_data.score, adherence_data.total)}%`
    }

    function behaviourAdherence(score, total) {
      return Math.round(score / total * 1000) / 10
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      workerFilter.value = data.workerFilter;
      observerFilter.value = data.observerFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      getConfirmations();
    }

    onMounted(getBehaviours);

    return {
      updateFilter,
      behaviours,
      behaviours_total,
      confirmations_total,
      adherence_index,
      excelFields,
      router,
      isLoading,
      zoneAgencyFilter,
      tableHeaders,
    };
  },
};
</script>

<style scoped>
  .behaviourClass ::v-deep p {
    margin: 0px;
  }
</style>