<template lang="pug">
b-row
  b-col(cols="12")
    b-card-group.p-0(deck)
      // Vencidas
      b-card(v-show="!isLoading && data.vencidas > 0" bg-variant="transparent", border-variant="danger")
        b-card-body.p-0.text-center
          b-card-title.text-danger {{ data.vencidas }}
          b-card-text
            | {{ $t('message.tableHeader.vencidas') }}

      // Pendientes
      b-card(v-show="!isLoading && data.pendientes > 0" bg-variant="transparent", border-variant="warning")
        b-card-body.p-0.text-center
          b-card-title.text-warning {{ data.pendientes }}
          b-card-text
            | {{ $t('message.tableHeader.pendientes') }}

      // Completadas
      b-card(v-show="!isLoading && data.realizadas > 0" bg-variant="transparent", border-variant="success")
        b-card-body.p-0.text-center
          b-card-title.text-success {{ data.realizadas }}
          b-card-text
            | {{ $t('message.tableHeader.realizadas') }}
      
      // Futuras
      b-card(v-show="!isLoading && data.futuras > 0" bg-variant="transparent", border-variant="info")
        b-card-body.p-0.text-center
          b-card-title.text-info {{ data.futuras }}
          b-card-text
            | {{ $t('message.tableHeader.futuras') }}

      // En espera
      b-card(v-show="!isLoading && data.enEspera > 0" bg-variant="transparent", border-variant="warning")
        b-card-body.p-0.text-center
          b-card-title.text-warning {{ data.enEspera }}
          b-card-text
            | {{ $t('message.tableHeader.enEspera') }}

      // Loader
      b-card.py-1(v-show="isLoading" bg-variant="transparent", border-variant="secondary")
        b-card-body.text-center
          loading(
            :active="true" 
            :is-full-page="false"
            color="#498ceb"
            :opacity="0"
          )
</template>

<script>
import { ref, onMounted, watch } from "@vue/composition-api";
import { BCardBody, BCardTitle, BCardText, BCardGroup } from "bootstrap-vue";
// import useCalendarStatus from "./useCalendarStats";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  props: {
    events: {
      type: Array,
      default: () => ([]),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    Loading,
  },

  setup(props) {
    //const { data, fetchEvents } = useCalendarStatus()
    const data = ref({
      vencidas: 0,
      pendientes: 0,
      realizadas: 0,
      futuras: 0,
      enEspera: 0
    });

    function calcStats() {
      data.value.vencidas = props.events.filter(
        (event) => event.extendedProps.calendar === "Vencidas" && !event.confirmation
      ).length;
      data.value.pendientes = props.events.filter(
        (event) => event.extendedProps.calendar === "Pendientes" && !event.confirmation
      ).length;
      data.value.realizadas = props.events.filter(
        (event) => event.extendedProps.calendar === "Realizadas"
      ).length;
      data.value.futuras = props.events.filter(
        (event) => event.extendedProps.calendar === "Futuras" && !event.confirmation
      ).length;
      data.value.enEspera = props.events.filter(
        (event) => event.extendedProps.calendar !== "Realizadas" && event.confirmation
      ).length;
    }

    onMounted(calcStats)
    watch(props, () => calcStats());

    return {
      data,
    };
  },
};
</script>
