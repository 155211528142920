<template lang="pug">
.habit-confirmation-wrapper
  b-row(v-if="isLoading")
    b-col(cols="12")
      b-card.py-5
        b-card-body.text-center
          loading(
            :active="true"
            :is-full-page="false"
            color="#498ceb"
          )

  div(v-else)
    b-row(v-if="!confirmation.process")
      h1 {{ $t('message.process_not_found') }}

    div(v-else)
      b-row
        b-col(cols="12")
          b-card(:title="processName", :sub-title="confirmation.process.goal")
            b-card-text
              div(v-if="confirmation.process.description")
                | {{ confirmation.process.description }}
              div(v-if="confirmation.worker")
                | {{ $t('message.confirmation_for') }} {{ confirmation.worker.name }}
                span(v-if="confirmation.supervisor")
                  |  {{ $t('message.done_by') }} {{ confirmation.supervisor.name }}
              div(v-if="confirmation.duration")
                | {{ $t('message.duration') }}: {{ confirmation.duration }}
              b-row.mt-2
                b-col(cols="12")
                  b-card-group.p-0(deck, v-if="confirmation.total")
                    b-card(
                      bg-variant="transparent",
                      border-variant= "success",
                      )
                      b-card-body.p-0.text-center
                        b-card-title.text-success {{ activityScore(confirmation) }}
                        b-card-text
                          | {{ $t('message.tableHeader.cumplimiento') }}

                    b-card(
                      bg-variant="transparent",
                      border-variant= "info",
                      )
                      b-card-body.p-0.text-center
                        b-card-title.text-info {{ confirmation.score }}
                        b-card-text
                          | {{ $t('message.tableHeader.score') }}

                    b-card(
                      bg-variant="transparent",
                      border-variant= "warning",
                      )
                      b-card-body.p-0.text-center
                        b-card-title.text-warning {{ confirmation.total }}
                        b-card-text
                          | {{ $t('message.tableHeader.total') }}

      // Metadata
      b-row(v-if="confirmation.metadata && confirmation.metadata.length")
          b-col(cols="12")
            b-card(:title="$t('aditional_information')")
              b-card-text
                b-form-group(
                  v-for="(category, index) in confirmation.metadata", :key="index"
                  :label="$t(`metadata.${category.name}`)",
                  :label-for="$t(`metadata.${category.name}`)",
                  description=""
                )
                  v-select(
                    v-if="category.type === 'select'",
                    v-model="category.answer",
                    :disabled="true"
                  )
                  b-form-input(
                    v-if="category.type === 'input'",
                    v-model="category.answer",
                    :disabled="true"
                  )

      // Activities
      b-row(v-for="(activity, idx) in confirmation.activities", :key="idx")
        b-col(cols="12")
          b-card(:title="activity.name", :sub-title="activity.goal")
            b-card-text
              | {{ activity.description }}

            b-row
              b-col(cols="12")
                b-card-group.p-0(deck, v-if="activity.total")
                  b-card(
                    bg-variant="transparent",
                    border-variant= "success",
                    )
                    b-card-body.p-0.text-center
                      b-card-title.text-success {{ activityScore(activity) }}
                      b-card-text
                        | {{ $t('message.tableHeader.cumplimiento') }}

                  b-card(
                    bg-variant="transparent",
                    border-variant= "info",
                    )
                    b-card-body.p-0.text-center
                      b-card-title.text-info {{ activity.score }}
                      b-card-text
                        | {{ $t('message.tableHeader.score') }}

                  b-card(
                    bg-variant="transparent",
                    border-variant= "warning",
                    )
                    b-card-body.p-0.text-center
                      b-card-title.text-warning {{ activity.total }}
                      b-card-text
                        | {{ $t('message.tableHeader.total') }}
            
            b-row
              b-col(cols="12")
                b-button(
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                  type="button",
                  :variant="activity.showBehaviours ? 'warning' : 'primary'",
                  class="mt-1 mb-1",
                  @click="activity.showBehaviours = !activity.showBehaviours"
                )
                  | {{ activity.showBehaviours ? $t('message.hide_behaviours') : $t('message.see_behaviours') }}
                  
            b-row(v-for="(behaviour, index) in activity.behaviours" :key="index")
              b-col(v-show="activity.showBehaviours || behaviour.type === 'text'", cols="12")
                behaviour-question(:behaviour="behaviour")

      // Image
      b-row(v-if="confirmation.imageKey")
        b-col(cols="12")
          b-card(:title="$t('message.confirmation_image')")
            b-card-text
              b-button.mr-1(
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                type="button",
                variant="primary",
                @click="openImage(confirmation.imageKey)"
              )
                | {{ $t('message.open') }}
</template>

<script>
import { onMounted } from '@vue/composition-api/dist/vue-composition-api'
import { BRow, BCol, BCard, BCardText, BButton, BFormGroup, BFormSelect, BCardGroup, BCardBody, BCardTitle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BehaviourQuestion from './BehaviourQuestion.vue'
import useConfirmationView from './useConfirmationView'
import i18n from '@/libs/i18n'
import Loading from 'vue-loading-overlay'
import useCommon from "@/views/organization/useCommon";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormSelect,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BehaviourQuestion,
    vSelect,
    Loading,
  },

  directives: {
    Ripple,
  },

  computed: {
    processName() {
      return i18n.t('message.process') + (this.confirmation?.process?.name ? `: ${this.confirmation.process.name}` : '')
    },
  },

  setup() {
    const { show, confirmation, activityScore, isLoading } = useConfirmationView()
    const { openImage } = useCommon()

    onMounted(async () => {
      await show()
    })

    return {
      confirmation,
      activityScore,
      openImage,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
.form-group {
  label {
    font-size: 1.2rem;
    font-weight: 200;
  }
}
</style>
