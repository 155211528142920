import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import {getAudience,updateAudience} from '@/@core/queries/audience'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws';
import useCommonAudience from '../useCommonAudience'


export default function useAudienceEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const userData = store.state?.userStore?.userData
  const { route, router } = useRouter()
  const audience = ref({})
  const isSubmitting = ref(false)
  const { singleUpload } = awsConnection()
  const {updateAudienceWithKey} = useCommonAudience()


  async function show() {
    isSubmitting.value = true
    store
      .dispatch('audienceStoreModule/showAudience', {
        query: getAudience,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        audience.value = data.data.audience
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.audience_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  function update(data,documentFile) {
    isSubmitting.value = true
    const payload = {}
    if (data.institution?.value) payload.institution = data.institution.value
    if (data.institutionServant?.value) payload.institutionServant = data.institutionServant.value
    if (data.topic?.value) payload.topic = data.topic.value
    if (data.date?.value) payload.date = data.date.value

    store
      .dispatch('audienceStoreModule/updateAudience', {
        query: updateAudience,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then((response) => {
        const audience_id = response.data.data.updateOneAudience._id
        const destinationFolder = `${userData.client.$oid}/documents`
        if (documentFile){
          console.log("documentFileif", documentFile)
          singleUpload(documentFile, destinationFolder)
          .then((key) =>{
            console.log('key',key)
            updateAudienceWithKey(audience_id, key)
            router.push({ name: 'organization-lobby-list' })
            isSubmitting.value = false
          })
          .catch((err) => {
            console.log(err)
            showErrorMessage(err.message)
            router.push({ name: 'organization-lobby-list' })
            isSubmitting.value = false
          })
        }else{
          showSuccessMessage(i18n.t('message.audience_updated'))
          router.push({ name: 'organization-lobby' })
          isSubmitting.value = false
        }

        showSuccessMessage(i18n.t('message.audience_updated'))
      })
      .catch((err) => {
        showErrorMessage(i18n.t('message.audience_update_error'))
        console.log(err)
      })
      .finally(() => {
        router.push({ name: 'organization-lobby-list' })
        isSubmitting.value = false
      })
  }

  return {
    update,
    show,
    audience,
    isSubmitting,
  }
}
