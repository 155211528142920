<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :dateRangeFilterShow="true",
          @changeFilter="updateFilter",
        )
  
  b-row(v-if="isLoading || isLoadingCases || isLoadingComplaints || isLoadingConf || isLoadingComm")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)

    b-row
      //- Global Compliance
      b-col(md="6")
        bar-chart-progress-fixed(:progress-data="progressCompliance", :cursorPointer="true", :includeSubtitle="true", @goToGlobalDashboard="goToGlobalDashboard")
        bar-chart-compliance-fixed(:locationData="locationData", :emitToParentComponent="true", @filterByArea="filterByArea")

      b-col(md="6")
        //- Cases
        donut-chart-state(
          v-if="caseStatesData.labels && caseStatesData.labels.length"
          :data="caseStatesData"
          i18nTitleVariable="casesByState"
          customHeight="339"
          @filterByState="filterByState"
        )

        //- Complaints
        donut-chart-state(
          v-if="complaintStatesData.labels && complaintStatesData.labels.length"
          :data="complaintStatesData"
          i18nTitleVariable="complaintsByState"
          customHeight="439"
          @filterByState="filterByState"
        )
    
    //- Process Adherence and Commitments
    b-card(no-body)
      b-card-title(class="p-2 mb-0 text-center")
        | {{ $t('Adherence') }}
  
    b-row
      b-col(md="2")
        statistic-card-vertical-total(:statistic="confirmations_total", :isButton="true", customHeight="12.5rem", @goToDashboard="goToAdherenceDashboard")
      b-col(md="2")
        statistic-card-vertical-index(:statistic="adherence_index", :isButton="true", customHeight="12.5rem", @goToDashboard="goToAdherenceDashboard")
      //- b-col(md="2")
        statistic-card-adherence(:statistic="adherenceTotal", :isButton="true", @goToDashboard="goToCommitmentDashboard")
      b-col(md="2")
        statistic-card-adjusted(:statistic="adherenceAdjusted", :isButton="true", @goToDashboard="goToCommitmentDashboard")
      b-col(md="2")
        statistic-card-commitments(:statistic="commitmentsGenerated", :isButton="true", @goToDashboard="goToCommitmentDashboard")
      b-col(md="2")
        statistic-card-compliance(:statistic="commitmentCompliance", :isButton="true", @goToDashboard="goToCommitmentDashboard")
      b-col(md="2")
        statistic-card-not-generated(:statistic="commitmentsNotGenerated", :isButton="true", @goToDashboard="goToCommitmentDashboard")
      
    b-row
      b-col(md="6")
        bar-chart-process(
          v-if="adherenceByProcess.labels && adherenceByProcess.labels.length",
          :process-data="adherenceByProcess",
          :emitToParentComponent="true",
          @filterByProcess="filterByProcess"
        )
      b-col(md="6")
        bar-chart-commitments(
          v-if="commitmentsData.labels && commitmentsData.labels.length",
          :commitments-data="commitmentsData",
          :emitToParentComponent="true",
          @filterByWorker="filterByWorker"
        )
</template>

<script>
import { onMounted, ref, computed, watch } from "@vue/composition-api/dist/vue-composition-api"
import axios from "@axios"
import useNotifications from "@/composables/useNotifications"
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BCardHeader, BCardTitle, BPagination } from "bootstrap-vue"
import { queryCasesData } from '@/@core/queries/cases'
import { queryComplaintsData } from '@/@core/queries/complaints'
import { queryConfirmationsScore } from "@/@core/queries/confirmations"
import { queryCommitmentReport } from "@/@core/queries/commitments"
import JsonExcel from "vue-json-excel"
import BarChartProgressFixed from '@/views/habit/confirmation/dashboard/charts/ChartjsHorizontalBarChartProgressFixed.vue'
import BarChartComplianceFixed from '@/views/habit/confirmation/dashboard/charts/ChartjsHorizontalBarChartComplianceFixed.vue'
import BarChartProcess from '@/views/habit/adherence/processes/charts/ChartjsBarChartProcess.vue'
import BarChartCommitments from '@/views/habit/adherence/commitment/charts/ChartjsHorizontalBarChartCommitments.vue'
import DonutChartState from '@/views/organization/case/list/charts/ApexDonutChartState.vue'
import StatisticCardVerticalTotal from '@/views/habit/adherence/processes/charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from '@/views/habit/adherence/processes/charts/StatisticCardVerticalIndex.vue'
import StatisticCardAdherence from '@/views/habit/adherence/commitment/charts/StatisticCardVerticalAdherence.vue'
import StatisticCardAdjusted from '@/views/habit/adherence/commitment/charts/StatisticCardVerticalAdjusted.vue'
import StatisticCardCommitments from '@/views/habit/adherence/commitment/charts/StatisticCardVerticalCommitments.vue'
import StatisticCardCompliance from '@/views/habit/adherence/commitment/charts/StatisticCardVerticalCompliance.vue'
import StatisticCardNotGenerated from '@/views/habit/adherence/commitment/charts/StatisticCardVerticalNotGenerated.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import store from "@/store"
import useCommon from '@/views/organization/useCommon'
import { get } from 'idb-keyval'
import { complianceCalculationFixedNumber } from '@/constants'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import { useRouter } from '@core/utils/utils'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BCardHeader,
    BCardTitle,
    BPagination,
    JsonExcel,
    BarChartProgressFixed,
    BarChartComplianceFixed,
    BarChartProcess,
    BarChartCommitments,
    DonutChartState,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    StatisticCardAdherence,
    StatisticCardAdjusted,
    StatisticCardCommitments,
    StatisticCardCompliance,
    StatisticCardNotGenerated,
    Filters,
    Loading,
    ViewButton,
    UserButton,
  },
  setup(props) {
    const userData = store.state?.userStore?.userData
    const userRole = userData.role
    const userId = userData.worker_id != null ? userData.worker_id.$oid : null
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const { username, email } = userData
    const { invokeFunction } = realmConnection()
    const { router } = useRouter()
    const { getDatesFromRange, dateDiffInDays, processAdherence } = useCommonDashboards()
    const { handleError } = useCommon()
    const progressCompliance = ref({})
    const locationData = ref({})
    const locationFilter = ref([])
    const roleFilter = ref([])
    const processFilter = ref([])
    const workerFilter = ref([])
    const supervisorFilter = ref([])
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const perPage = ref(50)
    const currentPage = ref(1)

    const caseStatesData = ref({})
    const complaintStatesData = ref({})
    const isLoadingCases = ref(true)
    const isLoadingComplaints = ref(true)

    const confirmations_total = ref(0)
    const adherence_index = ref(0)
    const adherenceByProcess = ref({})
    const isLoadingConf = ref(true)
    
    const adherenceTotal = ref(0)
    const adherenceAdjusted = ref(0)
    const commitmentsGenerated = ref(0)
    const commitmentCompliance = ref(0)
    const commitmentsNotGenerated = ref(0)
    const commitmentsData = ref({})
    const isLoadingComm = ref(true)

    // Global Compliance

    const listWorkers = async () => {
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)
      isLoading.value = true

      const query = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        userId: userId,
        privileges: userRole,
        client_id: clientId,
        date_gte: startFilter,
        date_lt: endFilter,
        locations: locationFilter.value,
        roles: roleFilter.value,
        processes: processFilter.value,
        supervisors: supervisorFilter.value,
      }

      try {
        const items = await invokeFunction({ name: 'confirmationsForWorkers', arg: query })
        const workersInside = items?.workers || []

        parseWorkersData(workersInside, startFilter, endFilter)
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false
      }
    }

    const parseWorkersData = (workersInside, startFilter, endFilter) => {
      let supportTrackerTotal = 0
      let supportTrackerTotalDone = 0
      let supportTrackerTotalPending = 0
      let supportTrackerTotalMeta = 0
      let totalWorkersForPagination = 0

      for(let element of workersInside){
        totalWorkersForPagination = totalWorkersForPagination + 1

        if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 31) element.meta = complianceCalculationFixedNumber
        else if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 62 && Math.floor(dateDiffInDays(startFilter, endFilter)) > 31) element.meta = (complianceCalculationFixedNumber)*2
        else element.meta = (complianceCalculationFixedNumber) *3

        element.pending = Math.max(element.meta - element.confirmationsCount, 0)
        
        if(element.pending === 0) {
          element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
          if(element.compliance === "0.0") element.compliance = 0.0
          element.compliance = `${element.compliance}%`
        } 
        else if(element.confirmationsCount <= 0) {
          element.compliance = `${0}%`
        }
        else {
          element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
          if(element.compliance === "100.0") element.compliance = 100
          element.compliance = `${element.compliance}%`
        }
        supportTrackerTotalDone = supportTrackerTotalDone + element.confirmationsCount
        supportTrackerTotalMeta = supportTrackerTotalMeta + element.meta
        supportTrackerTotalPending = Math.max(supportTrackerTotalMeta - supportTrackerTotalDone, 0)
        supportTrackerTotal =  supportTrackerTotalDone + supportTrackerTotalPending
      }

      workersInside.sort((a, b) => {
        const complianceA = parseFloat(a.compliance)
        const complianceB = parseFloat(b.compliance)

        return complianceA - complianceB
      })
      
      const actualProgress = supportTrackerTotal
        ? Math.round((supportTrackerTotalDone / supportTrackerTotalMeta) * 100)
        : 0

      const theoreticalProgress = endFilter < now
        ? 100
        : startFilter > now
          ? 0
          : Math.round(dateDiffInDays(startFilter, now) / dateDiffInDays(startFilter, endFilter) * 100)

      progressCompliance.value = {
        actual: actualProgress,
        theoretical: theoreticalProgress,
        totalDone: supportTrackerTotalDone,
        pendingToMatchTheoretical: Math.max(0, Math.round(theoreticalProgress / 100 * supportTrackerTotalMeta) - supportTrackerTotalDone)
      }

      const barChartData = {
        location: [],
        totalDone: [],
        totalPending: [],
        totalMeta: [],
        compliance: [],
      }

      const locationMap = {}

      for (const worker of workersInside) {
        // let location = worker.locationsData[0]?.location || ""
        for(const element of worker.locationsData){
          if(locationFilter.value.length && !locationFilter.value.includes(String(element._id))){
            continue
          }
          let name = element.location
          if(locationMap.hasOwnProperty(name)){
            locationMap[name] = {
              totalDone: locationMap[name].totalDone + worker.confirmationsCount,
              pending: locationMap[name].pending + worker.pending,
              meta: locationMap[name].meta + worker.meta,
              compliance: ((locationMap[name].totalDone / locationMap[name].meta)*100).toFixed(1),
              id: locationMap[name].id
            }
          } else {
            locationMap[name] = {
              totalDone: worker.confirmationsCount,
              pending: worker.pending,
              meta: worker.meta,
              compliance: worker.compliance,
              id: String(element._id)
            }
          }
        }
      }

      let locationComplianceDataSorted = Object.entries(locationMap)

      let locationComplianceLabels = []
      let locationComplianceValues = []
      let locationComplianceValuesDone = []
      let locationComplianceValuesPending = []
      let locationComplianceValuesMeta = []
      let locationIdValues = []

      locationComplianceDataSorted.forEach(e => {
        locationComplianceLabels.push(e[0])
        locationComplianceValuesDone.push(e[1].totalDone)
        locationComplianceValuesPending.push(e[1].pending)
        locationComplianceValuesMeta.push(e[1].meta)
        let compliance = Math.round((e[1].totalDone / e[1].meta) * 100)
        locationComplianceValues.push(compliance)
        locationIdValues.push(e[1].id)
      })

      barChartData.location = locationComplianceLabels
      barChartData.totalDone = locationComplianceValuesDone
      barChartData.totalPending = locationComplianceValuesPending
      barChartData.totalMeta = locationComplianceValuesMeta
      barChartData.compliance = locationComplianceValues
      
      const complianceIndices = barChartData.compliance.map((_, index) => index)
      complianceIndices.sort((a, b) => {
        return parseFloat(barChartData.compliance[a]) - parseFloat(barChartData.compliance[b])
      })
      barChartData.location = complianceIndices.map(index => barChartData.location[index])
      barChartData.totalDone = complianceIndices.map(index => barChartData.totalDone[index])
      barChartData.totalPending = complianceIndices.map(index => barChartData.totalPending[index])
      barChartData.totalMeta = complianceIndices.map(index => barChartData.totalMeta[index])
      barChartData.compliance = complianceIndices.map(index => barChartData.compliance[index])
      barChartData.additionalInfo = {
        locationIds: complianceIndices.map(index => locationIdValues[index])
      }
      
      locationData.value = barChartData
    }

    const goToGlobalDashboard = () => {
      router.push({ name: 'habit-confirmation-dashboard' })
    }

    const filterByArea = (areaId) => {
      router.push({ name: 'habit-confirmation-dashboard', query: { areaId } })
    }

    // Cases

    const fetchCases = () => {
      isLoadingCases.value = true

      const input = {
        client_id: clientId,
        workers: workerFilter.value,
        search: '',
        sortBy: 'createdAt',
        sortOrder: -1,
        limit: perPage.value,
        offset: (currentPage.value * perPage.value) - perPage.value,
        state: 'total',
        stage: null,
        onlyPendingTasks: false,
        workerId: userId
      }

      axios
        .post('/graphql', {
          query: queryCasesData,
          variables: { input },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)

          const { CasesData: { casesPaginated, casesStats } } = data.data || {}

          caseStatesData.value = {
            labels: casesStats.stateCounts.labels,
            values: casesStats.stateCounts.values,
            total: casesStats.totalCount
          }
        })
        .catch(error => {
          console.log(error)
          handleError({ error, defaultMessage: i18n.t('message.err_improvement_opportunities_list') })
        })
        .finally(() => {
          isLoadingCases.value = false
        })
    }

    const fetchComplaints = () => {
      isLoadingComplaints.value = true

      const input = {
        client_id: clientId,
        // workers: workerFilter.value,
        search: '',
        sortBy: 'createdAt',
        sortOrder: -1,
        limit: perPage.value,
        offset: (currentPage.value * perPage.value) - perPage.value,
        state: 'total',
        stage: null,
        onlyPendingTasks: false,
        workerId: userId,
        workerName: username,
        workerEmail: email
      }

      axios
        .post('/graphql', {
          query: queryComplaintsData,
          variables: { input },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)

          const { ComplaintsData: { complaintsPaginated, complaintsStats } } = data.data || {}

          complaintStatesData.value = {
            labels: complaintsStats.stateCounts.labels,
            values: complaintsStats.stateCounts.values,
            total: complaintsStats.totalCount
          }
        })
        .catch(error => {
          console.log(error)
          handleError({ error, defaultMessage: i18n.t('message.err_complaints_list') })
        })
        .finally(() => {
          isLoadingComplaints.value = false
        })
    }

    const filterByState = (state, origin, category) => {
      router.push({ name: `organization-${category}-list`, query: { state } })
    }

    // Process Adherence

    const listConfirmations = async () => {
      isLoadingConf.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      let conditions = {
        client_id: { _id: clientId },
        worker: {},
        date_gte: startFilter,
        date_lt: endFilter,
        pending_ne: true
      }

      // if (locationFilter.value.length > 0) {
      //   const locationsFilterQuery = locationFilter.value.map(e => ({locations_in: {_id: e}}))
      //   conditions.worker.AND = [{OR: locationsFilterQuery}]
      // }

      // if (roleFilter.value.length > 0) {
      //   conditions.worker.roles_in = roleFilter.value
      // }

      // if (processFilter.value.length > 0) {
      //   conditions.process = {
      //     _id_in: processFilter.value,
      //   }
      // }

      // if (workerFilter.value.length > 0) {
      //   conditions.worker._id_in = workerFilter.value
      // }

      // if (observerFilter.value.length > 0) {
      //   conditions.supervisor = {
      //     _id_in: observerFilter.value
      //   }
      // }

      axios
        .post("/graphql", {
          query: queryConfirmationsScore,
          variables: { query: conditions, limit: 10000 },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          parseConfirmationData(data, startFilter)
        })
        .catch((error) => {
          console.log(error)
          handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
        })
        .finally(() => {
          isLoadingConf.value = false
        })
    }

    const parseConfirmationData = (data, startFilter) => {
      let confirmationCount = 0
      let adherenceData = {score: 0, total: 0}
      let processData = {}

      for (const confirmation of data.data.confirmations) {
        if (confirmation.total) {
          const processName = confirmation.process?.name
          const processId = confirmation.process?._id
          const score = confirmation.score
          const total = confirmation.total
          
          confirmationCount++
          adherenceData.score += score
          adherenceData.total += total

          // Data for bar charts
          if (processName) updateObjectData(processData, processName, processId, score, total)
        }
      }
      confirmations_total.value = confirmationCount
      adherence_index.value = adherenceData.total === 0
        ? "-"
        : `${processAdherence(adherenceData.score, adherenceData.total)}%`

      // Set charts data
      adherenceByProcess.value = setChartsData(processData, "desc")
    }

    const updateObjectData = (object, property, propertyId, score, total) => {
      if (object.hasOwnProperty(property)) {
        object[property].score += score
        object[property].total += total
        object[property].confirmations += 1
      }
      else object[property] = {
        score: score,
        total: total,
        confirmations: 1,
        id: propertyId
      }
    }

    const setChartsData = (objectData, sortDirection, dataLimit) => {
      for (const property in objectData) {
        objectData[property].adherence = processAdherence(objectData[property].score, objectData[property].total)
      }

      let dataSorted = Object.entries(objectData)

      dataSorted.sort(function (a, b) {
        if (b[1].score === 0 && a[1].score === 0) return sortDirection === "desc" ? a[1].total - b[1].total : b[1].total - a[1].total
        return sortDirection === "desc" ? b[1].adherence - a[1].adherence : a[1].adherence - b[1].adherence
      })

      const limit = dataLimit < dataSorted.length ? dataLimit : dataSorted.length

      let labels = []
      let adherenceValues = []
      let confirmationValues = []
      let idValues = []

      for (let i = 0; i < limit; i++) {
        labels.push(dataSorted[i][0])
        adherenceValues.push(dataSorted[i][1].adherence)
        confirmationValues.push(dataSorted[i][1].confirmations)
        idValues.push(dataSorted[i][1].id)
      }

      return {
        labels: labels,
        data: {
          adherence: adherenceValues,
          confirmations: confirmationValues
        },
        additionalInfo: {
          ids: idValues,
        }
      }
    }

    const goToAdherenceDashboard = () => {
      router.push({ name: 'habit-adherence-dashboard' })
    }

    const filterByProcess = (processId) => {
      router.push({ name: 'habit-adherence-dashboard', query: { processId } })
    }

    // Commitments

    const listCommitments = async () => {
      isLoadingComm.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      const input = {
        clientId,
        userId,
        privileges: userRole,
        locations: locationFilter.value,
        roles: roleFilter.value,
        processes: processFilter.value,
        workers: workerFilter.value,
        date_gte: startFilter,
        date_lt: endFilter
      }

      axios
        .post("/graphql", {
          query: queryCommitmentReport,
          variables: { input },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          const workersWithCommitments = data?.data?.CommitmentReport?.commitments || []
          parseCommitmentData(workersWithCommitments)
        })
        .catch((error) => {
          console.log(error)
          handleError({ error, defaultMessage: i18n.t('message.err_worker_list') })
        })
        .finally(() => {
          isLoadingComm.value = false
        })

    }

    const parseCommitmentData = (workersWithCommitments) => {
      const labels = []
      const processAdherenceValues = []
      const increasedAdherenceValues = []
      const unresolvedGapValues = []
      const gapWithoutCommitmentValues = []
      const workerIds = []

      let totalScoreCount = 0
      let totalTotalCount = 0
      let adjustedScoreCount = 0
      let unresolvedGapCount = 0
      let commitmentsGeneratedCount = 0
      let commitmentsNotGeneratedCount = 0

      for (const worker of workersWithCommitments) {
        const behavioursFailed = []

        for (const e of worker.confirmationsData) {          
          if (worker.averageAdherence === 1) continue

          e.activities?.forEach(a => {
            a.behaviours?.forEach(b => {
              if (b.type === "bool" && (b.answer === "true" && !b.expectedAnswer) || (b.answer === "false" && b.expectedAnswer)) {
                behavioursFailed.push({
                  activity: a.name,
                  behaviour: b.name
                })
              }
            })
          })
          
          e.cases?.forEach(c => {
            for (const behaviourFailed of behavioursFailed) {
              if (behaviourFailed.activity === c.activity && behaviourFailed.behaviour === c.behaviour?.name) {
                if (c.stage === 'end') {
                  behaviourFailed.status = "success"
                } else {
                  behaviourFailed.status = "pending"
                }
                break
              }
            }
          })
        }

        const increasedAdherence = behavioursFailed.filter(e => e.status === "success").length
        const unresolvedGap = behavioursFailed.filter(e => e.status === "pending").length
        const gapWithoutCommitment = worker.totalTotal - worker.totalScore - increasedAdherence - unresolvedGap

        labels.push(worker.name)
        processAdherenceValues.push(processAdherence(worker.totalScore, worker.totalTotal))
        increasedAdherenceValues.push(processAdherence(increasedAdherence, worker.totalTotal))
        unresolvedGapValues.push(processAdherence(unresolvedGap, worker.totalTotal))
        gapWithoutCommitmentValues.push(processAdherence(gapWithoutCommitment, worker.totalTotal))
        workerIds.push(worker._id)

        totalScoreCount += worker.totalScore
        totalTotalCount += worker.totalTotal
        adjustedScoreCount += increasedAdherence
        unresolvedGapCount += unresolvedGap
        commitmentsGeneratedCount += increasedAdherence + unresolvedGap
        commitmentsNotGeneratedCount += behavioursFailed.length - increasedAdherence - unresolvedGap
      }

      adherenceTotal.value = `${processAdherence(totalScoreCount, totalTotalCount)}%`
      adherenceAdjusted.value = `${processAdherence(totalScoreCount + adjustedScoreCount, totalTotalCount)}%`
      commitmentsGenerated.value = commitmentsGeneratedCount
      commitmentCompliance.value = `${processAdherence(adjustedScoreCount, adjustedScoreCount + unresolvedGapCount)}%`
      commitmentsNotGenerated.value = commitmentsNotGeneratedCount

      commitmentsData.value = {
        labels: labels.slice(0,10),
        data: {
          processAdherenceValues: processAdherenceValues.slice(0, 10),
          increasedAdherenceValues: increasedAdherenceValues.slice(0, 10),
          unresolvedGapValues: unresolvedGapValues.slice(0, 10),
          gapWithoutCommitmentValues: gapWithoutCommitmentValues.slice(0, 10)
        },
        additionalInfo: {
          workerIds
        }
      }
    }

    const goToCommitmentDashboard = () => {
      router.push({ name: 'habit-commitment-dashboard' })
    }

    const filterByWorker = (workerId) => {
      router.push({ name: 'habit-commitment-dashboard', query: { workerId } })
    }

    const updateFilter = (data) => {
      dateRangeFilter.value = data.dateRangeFilter

      listWorkers()
      listConfirmations()
      listCommitments()
    }

    onMounted(() => {
      listWorkers()
      fetchCases()
      fetchComplaints()
      listConfirmations()
      listCommitments()
    })

    return {
      progressCompliance,
      updateFilter,
      isLoading,
      isLoadingCases,
      isLoadingComplaints,
      isLoadingConf,
      isLoadingComm,
      locationData,
      router,
      confirmations_total,
      adherence_index,
      adherenceByProcess,
      adherenceTotal,
      adherenceAdjusted,
      commitmentsGenerated,
      commitmentCompliance,
      commitmentsNotGenerated,
      commitmentsData,
      caseStatesData,
      complaintStatesData,
      goToGlobalDashboard,
      filterByArea,
      filterByState,
      filterByProcess,
      filterByWorker,
      goToAdherenceDashboard,
      goToCommitmentDashboard,
    }
  },
}
</script>