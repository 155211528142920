<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          AddCo
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <!-- <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          /> -->
          <vuexy-logo :size="200"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to AddCo! 👋
          </b-card-title>

          <add-to-home-screen :responsive="false" class="mt-2 mb-2"/>

          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

        <!--
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!- - divider - ->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!- - social buttons - ->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        -->

          <!-- Loading -->
          <loading
            v-if="isLoading"
            :active="true" 
            :is-full-page="false"
            color="#498ceb"
          />
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import axios from '@axios'
import { getWorker } from "@/@core/queries/workers";
import { getClient } from "@/@core/queries/clients";
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddToHomeScreen from '@/views/habit/AddToHomeScreen.vue';
import i18n from '@/libs/i18n'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import useNotifications from '@/composables/useNotifications'
import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    AddToHomeScreen,
    Loading,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      isLoading: false
    }
  },
  setup() {
    const { route } = useRouter()
    const { showErrorMessage } = useNotifications()

    onMounted(() => {
      if (route.value.query.session === "expired") {
        showErrorMessage(i18n.t('message.session_expired'))
      }
    })
    
    return {}
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(async () => {
              try {
                const {
                  custom_data: {
                    role, ability, extras, username, worker_id = null, client_id = null, project = ''
                  },
                  deleted = false
                } = useJwt.realm.currentUser.customData
                const { id, accessToken, refreshToken } = useJwt.realm.currentUser
                if (deleted) {
                  const errors = { email: ["Invalid Email"], password: ["Invalid Password"]}
                  this.$refs.loginForm.setErrors(errors)
                  useJwt.logout()
                  this.isLoading = false
                  return false;
                }
                useJwt.setToken(accessToken)
                useJwt.setRefreshToken(refreshToken)

                // if (role == 'supervisor' || role == 'consultant') {
                  // Get worker data
                  let worker = worker_id ? worker_id.$oid : null
                  axios
                    .post("/graphql", {
                      query: getWorker,
                      variables: { query: { _id: worker } },
                    })
                    .then(async(response) => {
                      const workerData = response.data?.data?.worker
                      const clientId = workerData.client_id?._id
                      const userData = {
                        id,
                        fullName: username || '',
                        username,
                        avatar: require('@/assets/images/avatars/13-small.png'),
                        email: this.userEmail,
                        role,
                        ability,
                        extras,
                        worker_id,
                        project,
                        client: clientId ? {$oid: clientId} : null,
                        locations: (workerData.locations?.length)
                          ? workerData.locations.map(e => {
                              return {
                                title: e.location,
                                value: e._id
                              }
                            })
                          : null,
                        isComplaintManager: workerData.isComplaintManager
                      }
                      localStorage.setItem('userData', JSON.stringify(userData))

                      // Get client data
                      axios
                        .post("/graphql", {
                          query: getClient,
                          variables: { query: { _id: clientId } },
                        })
                        .then(async(response) => {
                          if (response.data.errors) throw new Error(response.data.errors[0].message)
                          const clientData = response.data?.data?.client
                          if (clientData?.default_language) this.$i18n.locale = clientData.default_language
                          localStorage.setItem('clientData', JSON.stringify(clientData))
                          // const pcTeam = clientData?.default_view_pc_team ? '/apps/pc-team' : false
                          // const dailyDialogue = clientData?.default_view_daily_dialogue ? '/habit/meeting/new' : false
                          // const calendarView = clientData?.default_view_pc_team === false && clientData?.default_view_daily_dialogue === false ? '/habit/general/dashboard' : false
                          this.$router.replace(this.$route.query.from || clientData.homePagePath || (role === 'employee' && '/organization/complaint/list') || '/habit/general/dashboard' )
                            .then(() => {
                              this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                  title: `${i18n.t('Welcome')} ${username || ''}`,
                                  icon: 'CoffeeIcon',
                                  variant: 'success',
                                  text: `${i18n.t('Successful Login As')} ${i18n.t(`userRoles.${role}`)}. ${i18n.t('Start To Explore')}!`,
                                },
                              })
                            })
                            .catch((err) => {
                              console.log(err);
                              this.isLoading = false
                            })
                        })
                        .catch((err) => {
                          console.log(err);
                          this.isLoading = false
                        })

                      this.$ability.update(ability || [])
                      this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', extras?.eCommerceCartItemsCount || 0)
                      this.$store.commit('userStore/updateUserData', userData)
                    })
                    .catch((err) => {
                      console.log(err);
                      this.isLoading = false
                    });
                // } else {
                //   const userData = {
                //     id,
                //     fullName: username || '',
                //     username,
                //     avatar: require('@/assets/images/avatars/13-small.png'),
                //     email: this.userEmail,
                //     role,
                //     ability,
                //     extras,
                //     worker_id,
                //     project,
                //     client: client_id || null
                //   }
                  
                //   localStorage.setItem('userData', JSON.stringify(userData))
                //   this.$ability.update(ability || [])

                //   this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', extras?.eCommerceCartItemsCount || 0)
                //   this.$store.commit('userStore/updateUserData', userData)

                //   this.$router.replace(this.$route.query.from || '/habit/general/dashboard')
                //     .then(() => {
                //       this.$toast({
                //         component: ToastificationContent,
                //         position: 'top-right',
                //         props: {
                //           title: `Welcome ${username || ''}`,
                //           icon: 'CoffeeIcon',
                //           variant: 'success',
                //           text: `You have successfully logged in as ${role}. Now you can start to explore!`,
                //         },
                //       })
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //       this.isLoading = false
                //     })
                // }
              }
              catch(e) {
                console.error(e)
                this.isLoading = false
              }
            })
            .catch(error => {
              console.log(error)
              const errors = {
                password: [], email: [],
              }
              if (error.errorCode === 'InvalidPassword') {
                errors.password = [error.error]
              }
              if (error.errorCode === 'InvalidEmail') {
                errors.email = [error.error]
              }
              this.$refs.loginForm.setErrors(errors)
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
