import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
// import { addProcess } from '@/@core/queries/process'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import realmConnection from '@/views/habit/realm'

export default function useProcessAdd() {
  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItem, ObjectId } = realmConnection()
  const isSubmitting = ref(false)
  const collection = 'process'

  const storeData = async payload => {
    // isSubmitting.value = true

    payload.client_id = ObjectId(userData.client.$oid)
    payload.crimes = payload.crimes?.map(e => ObjectId(e.value)) || []
    payload.areas = payload.areas?.map(e => ObjectId(e.value)) || []
    payload.controls = payload.controls?.map(e => ObjectId(e)) || []
    payload.responsible = payload.responsible ? ObjectId(payload.responsible) : null
    payload.leadResponsible = payload.leadResponsible ? ObjectId(payload.leadResponsible) : null
    payload.evaluator = payload.evaluator ? ObjectId(payload.evaluator) : null
    payload.evaluated = payload.evaluated ? ObjectId(payload.evaluated) : null

    try {
      await createItem({ collection, payload })
      showSuccessMessage(i18n.t('message.form_success'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_error'))
    } finally {
      router.push({ name: 'organization-process-list' })
      isSubmitting.value = false
    }
  }

  return {
    storeData,
    isSubmitting,
  }
}
