<template lang="pug">
div
  Form(
    :data="singleMetadata",
    :metadataFieldDisabled="true",
    :isLoading="isLoading",
    :uploadMode="false",
    @submit="update"
  )
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useMetadataEdit from './useMetadataEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { show, update, singleMetadata, isLoading } = useMetadataEdit()

    onMounted(show)

    return {
      singleMetadata,
      update,
      isLoading,
    }
  },
}
</script>
