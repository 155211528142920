import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useControlAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItem, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const control = ref({})
  const isSubmitting = ref(false)
  const collection = 'control'

  const create = async (data) => {
    isSubmitting.value = true

    try {
      const payload = {
        client_id: ObjectId(userData.client.$oid),
        name: data.name,
        behaviours: data.behaviours?.map(e => ObjectId(e)) || []
      }

      await createItem({ collection, payload })

      showSuccessMessage(i18n.t('message.control_added'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.control_add_error'))
    } finally {
      router.push({ name: 'organization-control-list' })
      isSubmitting.value = false
    }
  }

  return {
    create,
    control,
    isSubmitting,
  }
}
