<template>
    <div>
      <b-button
        v-if="deferredPrompt"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :size="responsive ? 'sm' : 'md'"
        :block="!responsive"
        :class="responsive ? 'd-none d-lg-block' : 'd-block'"
        @click="clickCallback"
      >
        {{ $t('install_app') }}
      </b-button>
      <b-nav-item
        :class="responsive ? 'd-block d-lg-none' : 'd-none'"
        @click="clickCallback"
      >
        <feather-icon
          v-if="deferredPrompt"
          size="21"
          icon="DownloadIcon"
        />
      </b-nav-item>
    </div>
  </template>
  
  <script>
  import { BButton, BNavItem } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    name: 'AddToHomeScreen',
    directives: {
      Ripple,
    },
    components: {
      BButton,
      BNavItem
    },
    props: {
      responsive: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      deferredPrompt: null,
    }),
    mounted() {
      this.captureEvent()
    },
    methods: {
      captureEvent() {
        window.addEventListener('beforeinstallprompt', (e) => {
          // ! Prevent Chrome 67 and earlier from automatically showing the prompt
          e.preventDefault()
          // Stash the event so it can be triggered later..
          this.deferredPrompt = e
        })
      },
      clickCallback() {
        // Show the prompt
        this.deferredPrompt.prompt()
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            // Call another function?
          }
          this.deferredPrompt = null
        })
      },
    },
  }
  </script>