<template lang="pug">
b-col(md="12")
  b-form.repeater-form(
    ref="form",
    :style="{ height: trHeight }",
    @submit.prevent="repeateAgain"
  )
    // Row Loop
    b-row.mt-0(
      v-for="(item, index) in items",
      :id="item.id",
      :key="item.id",
      ref="row"
    )
      // Attendee Name
      b-col(md="4")
        b-form-group(
          :label="$t('message.worker')",
          label-for="v-recognition-worker"
        )
          validation-provider(:name="`${$t('message.worker')} ${index + 1}`", rules="required", v-slot="{ errors }")
            v-select#v-recognition-worker(
              v-model="item.worker",
              :options="workers",
              label="name"
              @change="emitUpdateRecognitions"
            )
            small(:class="errors.length ? 'text-danger' : 'text-secondary '", :style="{opacity: errors.length || item.worker ? 1 : 0}")
              | {{ errors[0] || `${$t('label.Location')}: ${item.worker && item.worker.locations.map(e => e.location).join(' / ') || ''}` }}
      b-col(md="2")
        b-form-group(:label="$t('message.motive')", label-for="v-recognition-motive")
          validation-provider(:name="`${$t('message.motive')} ${index + 1}`", rules="required", v-slot="{ errors }")
            b-form-select#v-recognition-motive(
              v-model="item.motive",
              :options="motives",
              :selectable="(option) => !option.value.includes('select_value')",
              value-field="_id"
              text-field="label"
              @change="emitUpdateRecognitions"
            )
            small(class="text-danger", :style="{opacity: errors.length}") {{ errors[0] || "error" }}
      b-col(md="4")
        b-form-group(:label="$t('message.note')", label-for="v-recognition-note")
          validation-provider(:name="`${$t('message.note')} ${index + 1}`", rules="required", v-slot="{ errors }")
            b-form-input#v-recognition-note(
              v-model="item.note",
              @change="emitUpdateRecognitions"
            )
            small(class="text-danger", :style="{opacity: errors.length}") {{ errors[0] || "error" }}
      // remove button
      b-col.mb-50(md="2")
        b-button.mt-0.mt-md-2(
          v-ripple.400="'rgba(234, 84, 85, 0.15)'",
          variant="outline-danger",
          @click="removeItem(index)"
        )
          feather-icon.mr-25(icon="XIcon")
  b-button(
    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
    variant="primary",
    @click="repeateAgain"
  )
    feather-icon.mr-25(icon="PlusIcon")
    span {{ $t('message.Add_Recognition') }}
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    ValidationProvider,
  },

  props: {
    workers: {
      type: Array,
      required: true,
    },
    motives: {
      type: Array,
      required: true,
    },
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      emails: [
        { value: 'jtguzman@addval.cl', text: 'JT Guzman' },
        { value: 'dherrmann@addval.cl', text: 'D Herrmann' },
        { value: 'ggomez@addval.cl', text: 'G Gomez' },
      ],
      items: [],
      nextTodoId: 1,
    }
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.emitUpdateRecognitions()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    emitUpdateRecognitions() {
      this.$emit('update-recognitions', this.items)
    },
  },
}
</script>
