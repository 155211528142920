import { ref } from '@vue/composition-api'
import store from '@/store'
import axios from "@axios";
import useNotifications from '@/composables/useNotifications'
import { getWorkersWithSupervisors, updateWorker } from '@/@core/queries/workers'
import i18n from '@/libs/i18n'

export default function useSupervisorsAssignation(props, emit) {
  const { showSuccessMessage, showErrorMessage } = useNotifications()

  const selectedSupervisorsToAssign = ref([])
  const selectedSupervisorSameHierarchy = ref("")
  const selectedRoles = ref([])
  const selectedLocations = ref([])
  const isSubmitting = ref(false)

  const getUserData = store.state?.userStore?.userData
  const client_id = getUserData.client?.$oid

  // ------------------------------------------------
  // Supervisors Assignation
  // ------------------------------------------------
  const assignSupervisors = async () => {
    isSubmitting.value = true
    
    const filterData = {
      supervisor: selectedSupervisorSameHierarchy.value,
      roles: [...selectedRoles.value],
      locations: [...selectedLocations.value],
    }
    
    try {
      const workersWithSupervisors = await getWorkersSupervisors(filterData, client_id)

      const updatePromises = workersWithSupervisors.map(worker => {
        const originalSupervisors = worker.supervisors ? worker.supervisors.map(e => e._id) : []
        const updatedSupervisors = Array.from(new Set([...originalSupervisors, ...selectedSupervisorsToAssign.value]))

        return new Promise((resolve, reject) => {
          axios
            .post('/graphql', {
              query: updateWorker,
              variables: { query: { _id: worker._id }, data: { supervisors: { link: updatedSupervisors } } }
            })
            .then(({data}) => {
              if (data.errors) {
                showErrorMessage(`${i18n.t('message.err_assigning_supervisors_to')} ${worker.name}`)
                throw new Error(data.errors[0].message)
              }
              resolve(1)
            })
            .catch((error) => {
              console.log(error)
              resolve(0)
            })
        })
      })

      Promise.all(updatePromises)
        .then(values => {
          const workersUpdatedCount = values.reduce((acc, element) => acc + element)
          if (workersUpdatedCount) {
            showSuccessMessage(i18n.t('message.supervisors_assigned_to_employees', 0, {count: workersUpdatedCount}))
          }
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    } finally {
      // Close sidebar
      emit('update:is-supervisors-assignation-sidebar-active', false)

      isSubmitting.value = false
    }
  }

  const getWorkersSupervisors = (filterData, clientId) => {
    let conditions = {
      client_id: { _id: clientId },
      deleted_ne: true,
    }

    if (filterData.supervisor) {
      conditions.supervisors_in = {_id: filterData.supervisor}
    }

    if (filterData.roles.length) {
      conditions.roles_in = filterData.roles
    }

    if (filterData.locations.length) {
      const locationsFilterQuery = filterData.locations.map(e => ({ _id: e }))
      conditions.locations_in = locationsFilterQuery
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: getWorkersWithSupervisors,
          variables: { query: conditions },
        })
        .then(({data}) => {
          if (!data.data?.workers?.length) {
            showErrorMessage(i18n.t('message.err_no_employees_found'))
            reject("Employees not found")
          }

          resolve(data.data.workers)
        })
        .catch((error) => {
          showErrorMessage(i18n.t('message.err_worker_list'));
          reject(error)
        })
    })
  }

  return {
    assignSupervisors,
    selectedSupervisorsToAssign,
    selectedSupervisorSameHierarchy,
    selectedRoles,
    selectedLocations,
    isSubmitting,
  }
}
