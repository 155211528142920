import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { queryCommitment, updateCommitment } from '@/@core/queries/commitments'
import { updateEvent } from '@/@core/queries/calendar'
import axios from '@axios'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'


export default function useCommitmentNew() {
  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError, formatDate, createCommitment } = useCommon()
  const { router, route } = useRouter()

  const commitment = ref({ activity: '', behaviour: {} })
  const event = ref({})
  const selectedWorker = ref('')
  const selectedSupervisor = ref('')
  const isSubmitting = ref(false)
  const { commitmentId, workerId, eventId } = route.value.params

  // if (userData.role === 'supervisor' || userData.role === 'consultant') {
    selectedSupervisor.value = userData.worker_id.$oid
  // }

  selectedWorker.value = workerId || ''
  event.value.date = new Date()

  const show = async () => {
    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: queryCommitment,
        variables: { query: { _id: commitmentId } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        commitment.value = data.data.commitment
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.commitment_fetch_error') })
      })
  }

  // eslint-disable-next-line no-shadow
  const submit = (commitment) => {
    isSubmitting.value = true

    axios
      .post('/graphql', {
        query: updateCommitment,
        variables: {
          query: { _id: commitmentId },
          data: {
            behaviour: {
              name: commitment.behaviour.name,
              type: commitment.behaviour.type,
              answer: commitment.behaviour.answer
            },
            completed: true
          },
        },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)

        showSuccessMessage(i18n.t('message.commitment_updated'))

        updateEventOfCommitment()

        if (commitment.behaviour.newCommitment) {
          const newComm = commitment.behaviour.newCommitment
          newComm.assignee = { link: newComm.assignee }
          newComm.supervisor = { link: selectedSupervisor.value }
          newComm.dueDate = newComm.dueDate ? new Date(`${newComm.dueDate.slice(0, 10)} 12:00:00`) : null
          newComm.confirmation = commitment.confirmation ? { link: commitment.confirmation._id } : null
    
          createCommitment(newComm)
        }

        router.push({ name: 'habit-commitment-view', params: { id: commitmentId } })  
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.commitment_update_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  const updateEventOfCommitment = async () => {
    const payload = {
      extendedProps: { calendar: "Realizadas" }
    }

    const startDateTime = formatDate(event.value.date)
    const endDateTime = formatDate(event.value.date, 13)

    payload.start = startDateTime
    payload.end = endDateTime

    axios
      .post('/graphql', {
        query: updateEvent,
        variables: {
          query: { _id: eventId },
          data: payload,
        },
      })
      .then(() => {})
      .catch(() => {
        showErrorMessage(i18n.t('message.event_update_error'))
      })
  }

  return {
    userData,
    show,
    submit,
    commitment,
    event,
    selectedWorker,
    selectedSupervisor,
    isSubmitting,
  }
}
