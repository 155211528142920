import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import { _getAudiences, updateAudience } from '@/@core/queries/audience'


export default function useUsersList() {
  // Use toast
  // Table Handlers
  const tableColumns = 
  computed(()=>{
    return [
      { key: 'institution', label: i18n.t('label.Institution'), sortable: true },
      { key: 'institutionServant', label:  i18n.t('label.InstitutionServant'), sortable: true },
      { key: 'date', label:  i18n.t('label.date'), sortable: true },
      { key: 'topic', label:  i18n.t('label.Topic'), sortable: true },
      { key: 'actions', label: i18n.t('message.tableHeader.actions'), sortable:false }
    ]
  })
  const audiences = ref([])
  const perPage = ref(10)
  const totalAudiences = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const audienceQuery = ref('')
  const sortBy = ref('institution')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const isLoading = ref(true)
  const roleFilter = ref([]);
  const {
    // eslint-disable-next-line camelcase
    role, worker_id = null, client = null,
  } = store.state?.userStore?.userData

  const fetchAudiences = () => {
    isLoading.value = true

    store
      .dispatch('audienceStoreModule/fetchAudiences', {
        query: _getAudiences,
        variables:{
          input:{
            limit: perPage.value,
            total: currentPage.value === 1,
            offset: (currentPage.value * perPage.value) - perPage.value,
            search: searchQuery.value,
            sortBy: sortBy.value,
            sortOrder: isSortDirDesc.value ? -1 : 1,
          }
        }
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const {
          AudiencesData:{
            audiences:w,
            totalRecords:total
          }
        } = response.data.data || {};
        const audienceCorrectedDate = w.map(audience=>{
          const newAud = {...audience};
          if (newAud.date){
            const dateObj = new Date(newAud.date);
            if(!isNaN(dateObj)){
              newAud.date = dateObj.toLocaleDateString();
            }
          }
          return newAud;
        })
        audiences.value = audienceCorrectedDate;
        if (currentPage.value === 1) totalAudiences.value = total
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_worker_list') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteAudience = (audienceId, callback) => {
    store
        .dispatch('audienceStoreModule/deleteAudience',{
          query:updateAudience,
          variables: {query:{_id:audienceId}, data:{deleted:true}},
        }).then(()=>{
          callback(true)
        }).catch(()=>{
          callback(false)
          showErrorMessage(i18n.t('message.something_went_wrong'))
        });
        
  }
 
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  watch([currentPage, perPage, statusFilter], () => {
    fetchAudiences()
  })

  watch([audienceQuery, searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchAudiences()
  }) 
  return {
    fetchAudiences,
    audiences,
    tableColumns,
    deleteAudience,
    perPage,
    currentPage,
    totalAudiences,
    searchQuery,
    // Extra Filters
    statusFilter,
    tableColumns,
    sortBy,
    isLoading,
    isSortDirDesc
  }
}
