import axios from '@axios'
// import { updateImprovement } from '@/@core/queries/improvements'
import { updateAudience } from '@/@core/queries/audience'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import store from '@/store'
import awsConnection from '@/views/habit/aws';

export default function useCommonAudience (){
    const userData = store.state?.userStore?.userData;
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { singleUpload } = awsConnection()

    const updateAudienceWithKey = (audienceId, key) => {
      store
      .dispatch('audienceStoreModule/updateAudience', { 
        query: updateAudience, 
        variables: {
          query: { _id: audienceId },
          data: {
            documentKey: key,
          },
        },
       })
      .then(async response => {
      }).catch(err =>console.log(err))  
      }

    return{
      updateAudienceWithKey
    }
}