<template>
  <b-card no-body>
    <b-card-header>
      <!-- title -->
      <b-card-title>{{ $t('confirmationsDonePerSupervisorAndPerProcess') }}</b-card-title>

      <!-- dropdown -->
      <!-- <b-dropdown
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
          />
        </template>
        <b-dropdown-item>
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item>
          Last Month
        </b-dropdown-item>
        <b-dropdown-item>
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      <!--/ dropdown -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="heatmap"
        :height="this.height"
        :options="heatMapChart.options"
        :series="this.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { EventBus } from "@/views/habit/EventBus.js"
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    VueApexCharts,
    BCardTitle,
    BDropdown,
    BDropdownItem,
  },
  props: {
    confirmationsPerDayData: {
      type: Array,
      required: true,
    },
    commitmentFunctionality: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      heatMapChart: {
        options: {
          tooltip: {
            followCursor: false,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `
                  <div style="padding: 10px">
                    <div>
                      <span>${i18n.t('tooltip.supervisor')}: </span>
                      <span style="font-weight: bold">${w.globals.seriesNames[seriesIndex]}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.process')}: </span>
                      <span style="font-weight: bold">${w.globals.labels[dataPointIndex]}</span>
                    </div>
                    <div>
                      <span>${i18n.t('Realizadas')}: </span>
                      <span style="font-weight: bold">${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                  </div>
                `
              )
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#ffffff']
            }
          },
          legend: {
            show: false,
            // position: 'bottom',
          },
          // colors: ['#7367F0'],
          stroke: {
            width: 1,
            colors: ['#d3d3d3']
          },
          plotOptions: {
            heatmap: {
              enableShades: false,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    // name: '0-10',
                    color: '#ffffff',
                  },
                  // {
                  //   from: 1,
                  //   to: 1,
                  //   // name: '10-20',
                  //   color: this.commitmentFunctionality ? $themeColors.success : $themeColors.warning,
                  // },
                  {
                    from: 1,
                    to: 1000,
                    // name: '10-20',
                    color: $themeColors.success,
                  },
                ],
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
              show: false,
            },
            events: {
              click: function(event, chartContext, config) {
                if (config.seriesIndex < 0 || config.dataPointIndex < 0) return
                const supervisorId = config.config.series[config.seriesIndex].supervisorId
                const processId = config.config.series[config.seriesIndex].data[config.dataPointIndex].processId
                EventBus.$emit("filterBySupervisorAndProcess", supervisorId, processId)
              },
              dataPointMouseEnter: function(event) {
                event.srcElement.style.cursor = "pointer"
              }
            }
          },
        },
      },
    }
  },
  computed: {
    height() {
      return 150 + 20 * this.confirmationsPerDayData.length
    },
    series() {
      return this.confirmationsPerDayData.slice(0, 50)
    },
  }
}
</script>