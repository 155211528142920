<template>
  <div class="app-content content"
    :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />

    <!-- Filter for Todo module (Continuous Improvement) -->
    <div v-if="routeName.startsWith('apps-todo')">
      <b-row>
        <b-col>
          <b-card no-body class="pt-2 px-2">
            <filters :locationFilterShow="true" :workerFilterShow="true" :dateRangeFilterShow="true"
              :resetDateButtonShow="true" :instanceFilterShow="clientHasMetadata"
              :instanceLeaderFilterShow="clientHasMetadata" :zoneImprovementFilterShow="clientHasMetadata"
              @changeFilter="updateTodoFilter" />
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="3">
          <statistic-card-vertical-total :statistic="statisticCardsData.total === undefined ? '-' : statisticCardsData.total"></statistic-card-vertical-total>
        </b-col>
        <b-col sm="3">
          <statistic-card-vertical-completed :statistic="statisticCardsData.completed === undefined ? '-' : statisticCardsData.completed"></statistic-card-vertical-completed>
        </b-col>
        <b-col sm="3">
          <statistic-card-vertical-pending :statistic="statisticCardsData.pending === undefined ? '-' : statisticCardsData.pending"></statistic-card-vertical-pending>
        </b-col>
        <b-col sm="3">
          <statistic-card-vertical-overdue :statistic="statisticCardsData.overdue === undefined ? '-' : statisticCardsData.overdue"></statistic-card-vertical-overdue>
        </b-col>
      </b-row>
    </div>

    <!-- Filter for Problem Solving module -->
    <b-row v-if="routeName.startsWith('apps-problem-solving')">
      <b-col>
        <b-card no-body class="pt-2 px-2">
          <filters :locationFilterShow="true" :workerFilterShow="true" @changeFilter="updateProblemSolvingFilter" />
        </b-card>
      </b-col>
    </b-row>

    <transition :name="routerTransition" mode="out-in">
      <div class="content-area-wrapper" :class="contentWidth === 'boxed' ? 'container p-0' : null">
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <portal-target name="content-renderer-sidebar-left" slim />
        <div class="content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BCard } from 'bootstrap-vue'
import Filters from "@/views/organization/Filters.vue";
import store from '@/store'
import { computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalCompleted from './charts/StatisticCardVerticalCompleted.vue'
import StatisticCardVerticalPending from './charts/StatisticCardVerticalPending.vue'
import StatisticCardVerticalOverdue from './charts/StatisticCardVerticalOverdue.vue'

export default {
  components: {
    AppBreadcrumb,
    BCard,
    Filters,
    StatisticCardVerticalCompleted,
    StatisticCardVerticalPending,
    StatisticCardVerticalTotal,
    StatisticCardVerticalOverdue
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()
    const { route } = useRouter()
    const routeName = route.value.name

    const clientHasMetadata = computed(() => store.state['app-todo'].clientHasMetadata)
    const statisticCardsData = computed(() => store.state['app-todo'].statisticCardsData)

    const updateTodoFilter = (data) => {
      store.commit('app-todo/SET_SELECTED_LOCATIONS', data.locationFilter)
      store.commit('app-todo/SET_SELECTED_WORKERS', data.workerFilter)
      store.commit('app-todo/SET_SELECTED_DUE_DATES', data.dateRangeFilter)
      store.commit('app-todo/SET_SELECTED_INSTANCES', data.instanceFilter)
      store.commit('app-todo/SET_SELECTED_INSTANCE_LEADERS', data.instanceLeaderFilter)
      store.commit('app-todo/SET_SELECTED_ZONES', data.zoneImprovementFilter)
    }

    const updateProblemSolvingFilter = (data) => {
      store.commit('problem-solving/SET_SELECTED_LOCATIONS', data.locationFilter)
      store.commit('problem-solving/SET_SELECTED_WORKERS', data.workerFilter)
    }

    return {
      routerTransition,
      contentWidth,
      updateTodoFilter,
      updateProblemSolvingFilter,
      clientHasMetadata,
      statisticCardsData,
      routeName,
    }
  },
}
</script>

<style></style>
