import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import {getAudience} from '@/@core/queries/audience'
import i18n from '@/libs/i18n'


export default function useAudienceView() {
  const {  showErrorMessage } = useNotifications()
  const { route } = useRouter()
  const audience = ref({})
  const isSubmitting = ref(true)


  async function show() {
    store
      .dispatch('audienceStoreModule/showAudience', {
        query: getAudience,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        audience.value = data.data.audience
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.audience_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = true
      })
  }
  function update(data,documentFile) {

  }


  return {
    show,
    audience,
    isSubmitting
  }
}
