import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { updateWorker, _getWorkers, getWorkersWithSupervisors } from '@/@core/queries/workers'
import locationsQuery from '@/@core/queries/locations'
import { checkIfWorkerExistsInUser, updateUser } from '@/@core/queries/user'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'

export default function useWorkerList() {
  const { showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const refWorkerListTable = ref(null)
  const workers = ref([])
  const {
    // eslint-disable-next-line camelcase
    role, worker_id = null, client = null,
  } = store.state?.userStore?.userData

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'name',
        label: i18n.t('message.tableHeader.name'),
        sortable: true,
      },
      {
        key: 'email',
        label: i18n.t('message.tableHeader.email'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions')
      },
    ]
  })
  const perPage = ref(10)
  const totalWorkers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const locationQuery = ref('')
  const locations = ref([])
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const isLoading = ref(true)

  const locationFilter = ref([]);
  const roleFilter = ref([]);

  const refetchData = () => {
    if (refWorkerListTable.value) {
      refWorkerListTable.value.refresh()
    }
  }

  const excelFields = ref({
    [i18n.t('message.tableHeader.name')]: "name",
    [i18n.t('Email')]: "email",
    [i18n.t('label.Locations')]: "locationsData",
    [i18n.t('label.Privileges')]: "privileges",
    [i18n.t('label.Roles')]: "rolesData",
    [i18n.t('message.tableHeader.supervisor')]: "supervisorsData",
  })

  const workersTotal = ref([])

  // const getLocations = () => {
  //   store
  //     .dispatch('organization-worker/getLocations', { query: locationsQuery, variables: { query: { deleted: false } } })
  //     .then(response => {
  //       locations.value = response.data?.data?.locations?.map(({ location, _id }) => ({ name: location, value: _id })) || []
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }

  const fetchWorkers = () => {
    isLoading.value = true
    let supervisor = ''
    let input = {}
    
    if (role === 'supervisor') {
      supervisor = worker_id.$oid
      input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        query: {},
        search: searchQuery.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        location: locationFilter.value,
        roles: roleFilter.value,
        supervisor,
        client_id: (role !== 'admin') ? client.$oid : null,
      }
    } else {
      input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        query: {},
        search: searchQuery.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        location: locationFilter.value,
        roles: roleFilter.value,
        client_id: (role !== 'admin') ? client.$oid : null,
      }
    }
    
    store
      .dispatch('organization-worker/fetchWorkers', {
        status: statusFilter.value,
        query: _getWorkers,
        variables: {
          input,
        },
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const {
          WorkersData: {
            workers: w,
            workersTotal: z,
            totalRecords: total,
          },
        } = response.data.data || {}
        workers.value = w
        const modifiedWorkers = z.map(worker => {
          let location = ""
          let roles = ""
          let supervisors = ""       
          if(worker.locationsData.length >= 2){
            location = worker.locationsData.map(location => location.location).join(" / ")
          } else {
            location = worker.locationsData[0]?.location || "";
          }
          if(worker.rolesData.length >= 2){
            roles = worker.rolesData.map(role => role.name).join(" / ")
          } else {
            roles = worker.rolesData[0]?.name || "";
          }
          if(worker.supervisorsData.length >= 2){
            supervisors = worker.supervisorsData.map(supervisor => supervisor.name).join(" / ")
          } else {
            supervisors = worker.supervisorsData[0]?.name || "";
          }
          return {
            ...worker,
            locationsData: location,
            rolesData: roles,
            supervisorsData: supervisors
          };
        });
        workersTotal.value = modifiedWorkers
        if (currentPage.value === 1) totalWorkers.value = total
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_worker_list') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteWorker = (userId, callback) => {
    store
      .dispatch('organization-worker/deleteWorker', {
        query: updateWorker,
        variables: { query: { _id: userId }, data: { deleted: true } },
      })
      .then(() => {
        // Check if worker is an existing user and update it
        store
          .dispatch('organization-worker/checkUserExist', {
            query: checkIfWorkerExistsInUser,
            variables: { query: { custom_data: { worker_id: userId } } },
          })
          .then(() => {
            store
              .dispatch('organization-worker/updateUser', {
                query: updateUser,
                variables: { query: { custom_data: { worker_id: userId } }, data: { deleted: true } },
              })
              .then(() => {
                callback(true)
              })
              .catch(() => {
                callback(false)
                showErrorMessage(i18n.t('message.err_worker_list'))
              })
          })
          .catch(() => {
            callback(true)
          })

        // Check if worker supervises other workers and remove it from the list of those workers' supervisors
        store
          .dispatch('organization-worker/getWorkersWithSupervisors', {
            query: getWorkersWithSupervisors,
            variables: { query: { supervisors_in: { _id: userId } } },
          })
          .then(({data}) => {
            if (data.data?.workers?.length) {
              data.data.workers.forEach(worker => {
                if (worker.supervisors) {
                  const supervisorsFiltered = worker.supervisors.reduce((acc, e) => {
                    if (e._id !== userId) acc.push(e._id);
                    return acc;
                  }, [])

                  store
                    .dispatch('organization-worker/update', {
                      query: updateWorker,
                      variables: { query: { _id: worker._id }, data: { supervisors: { link: supervisorsFiltered } } },
                    })
                    .then(({data}) => {
                      if (data.errors) {
                        throw new Error(data.errors[0].message)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }

              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.err_worker_list'))
      })
  }

  watch([currentPage, perPage, statusFilter], () => {
    fetchWorkers()
  })

  watch([locationQuery, searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchWorkers()
  })

  return {
    fetchWorkers,
    tableColumns,
    perPage,
    currentPage,
    totalWorkers,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWorkerListTable,
    workers,
    statusFilter,
    refetchData,
    deleteWorker,
    locationQuery,
    locations,
    // getLocations,
    locationFilter,
    roleFilter,
    isLoading,
    role,
    excelFields,
    workersTotal
  }
}
