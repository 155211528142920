import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { addDocument, updateDocument } from '@/@core/queries/documents'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws';

export default function useDocumentAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { singleUpload } = awsConnection()
  const userData = store.state?.userStore?.userData
  const document = ref({})
  const isSubmitting = ref(false)

  const create = (data, documentFile) => {
    isSubmitting.value = true
    const payload = {}
    if (data.name?.value) payload.name = data.name.value
    payload.client_id = (userData.role !== 'admin') ? { link: userData.client?.$oid } : null

    store
      .dispatch('processStoreModule/add', {
        query: addDocument,
        variables: { data: payload },
      })
      .then((response) => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)

        // Upload file to AWS and then update the document in MongoDB with the AWS file key
        if (documentFile.name) {
          const documentId = response.data.data.insertOneDocument._id

          const destinationFolder = `${userData.client.$oid}/documents`
          singleUpload(documentFile, destinationFolder)
            .then((key) => updateDocumentWithKey(documentId, key))
            .catch((err) => {
              console.log(err)
              showErrorMessage(i18n.t('message.document_add_error'))
              router.push({ name: 'organization-document-list' })
              isSubmitting.value = false
            })
        }
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.document_add_error'))
        router.push({ name: 'organization-document-list' })
        isSubmitting.value = false
      })
  }

  const updateDocumentWithKey = (documentId, key) => {
    store
      .dispatch('processStoreModule/update', {
        query: updateDocument,
        variables: { query: { _id: documentId }, data: { key } },
      })
      .then((response) => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        showSuccessMessage(i18n.t('message.document_added'))
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.document_add_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-document-list' })
        isSubmitting.value = false
      })
  }


  return {
    create,
    document,
    isSubmitting
  }
}
