<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('adherenceByProcess') }}</b-card-title>
    </b-card-header>

    <b-card-body>
      <chartjs-component-bar-chart
        :height="420"
        :data="this.series"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '@/views/habit/charts-components/ChartjsComponentBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import { EventBus } from "@/views/habit/EventBus.js"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  props: {
    processData: {
      type: Object,
      required: true,
    },
    emitToParentComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      latestBarChart: {
        // data: {
        //   labels: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12'],
        //   datasets: [
        //     {
        //       data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 230, 280, 190],
        //       backgroundColor: '#28dac6',
        //       borderColor: 'transparent',
        //     },
        //   ],
        // },
        options: {
          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },
          onClick: (c, i) => {
            let e = i[0];
            if (!e) return
            // var x_value = this.data.labels[e._index];
            // var y_value = this.data.datasets[0].data[e._index];
            const processId = this.series._additionalInfo.processIds[e._index]
            if (this.emitToParentComponent) this.$emit('filterByProcess', processId)
            else EventBus.$emit("filterByProcess", processId)
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // display: false,
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function(tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%'
              }
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: 25,
                  min: 0,
                  max: 100,
                  fontColor: '#6e6b7b',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: i18n.t('label.adherence'),
                  fontSize: 14
                },
              },
            ],
          },
        },
      },
      // rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  computed: {
    series() {
      return {
        labels: this.processData.labels,
        datasets: [
          {
            data: this.processData.data.adherence,
            label: i18n.t('label.adherence'),
            backgroundColor: $themeColors.info,
            borderColor: 'transparent',
            barThickness: 15,
          },
          // {
          //   type: 'line',
          //   data: this.processData.data.confirmations,
          //   label: i18n.t('label.confirmations'),
          //   yAxisID: 'right-y-axes',
          //   backgroundColor: $themeColors.warning,
          //   borderColor: $themeColors.warning,
          //   lineTension: 0,
          //   fill: false,
          //   pointRadius: 5,
          //   order: 1,
          // },
        ],
        _additionalInfo: {
          processIds: this.processData.additionalInfo.ids
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
