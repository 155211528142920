<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :locationFilterShow="true"
          :roleFilterShow="true"
          :workerFilterShow="true"
          :workerDefaultValue="routeWorker"
          :monthFilterShow="true"
          :yearFilterShow="true"
          @changeFilter="updateFilter"
        )
  
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!eventsTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_confirmations_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="eventsTotal")
        b-col(sm="3")
          statistic-card-vertical-pending(:statistic="eventsPending")
        b-col(sm="3")
          statistic-card-vertical-done(:statistic="eventsDone")

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.name') }}
              th {{ $t('message.tableHeader.observer') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.date') }}
              th {{ $t('message.tableHeader.status') }}
              th {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(event, index) in events", :key="index")
              b-td {{ event.extendedProps.attendee && event.extendedProps.attendee.name }}
              b-td {{ event.observer }}
              b-td {{ event.extendedProps.process.name }}
              b-td {{ event.date }}
              b-td(:class="event.extendedProps.calendar === 'Realizadas' ? 'text-success' : 'text-warning'") {{ event.computedStatus && event.computedStatus.value }}
              b-td
                navigation-button(v-if="event.extendedProps.calendar && event.extendedProps.calendar !== 'Realizadas'" @clicked="openEventHandlerSidebar(event)")
                view-button(v-if="event.extendedProps.calendar === 'Realizadas'" variant="flat-primary" @clicked="router.push({ name: 'habit-confirmation-view', params: { id: event.extendedProps.confirmation._id } })")
  
  //- Sidebar Overlay: Event Handler
  div(
    class="body-content-overlay"
    :class="{'show': isCalendarOverlaySidebarActive}"
    @click="isCalendarOverlaySidebarActive = false"
  )
  calendar-event-handler(
    v-model="isEventHandlerSidebarActive"
    :event="event"
    :clear-event-data="clearEventData"
    :isOnline="isOnline"
    @remove-event="removeEvent"
    @update-event="updateEvent"
  )
</template>

<script>
import { queryEvents, updateEvent } from "@/@core/queries/calendar";
import { onMounted, ref, computed, onUnmounted } from "@vue/composition-api/dist/vue-composition-api";
import { useRouter } from '@core/utils/utils'
import axios from "@axios";
import store from "@/store";
import useNotifications from "@/composables/useNotifications";
import { BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue";
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalPending from './charts/StatisticCardVerticalPending.vue'
import StatisticCardVerticalDone from './charts/StatisticCardVerticalDone.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CalendarEventHandler from '@/views/apps/calendar/calendar-event-handler/CalendarEventHandler.vue'
import calendarStoreModule from '@/views/apps/calendar/calendarStoreModule'
import useCommon from '@/views/organization/useCommon'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    StatisticCardVerticalTotal,
    StatisticCardVerticalPending,
    StatisticCardVerticalDone,
    Filters,
    ViewButton,
    NavigationButton,
    Loading,
    CalendarEventHandler,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userId = userData.worker_id != null ? userData.worker_id.$oid : null
    const userLocationsQuery = userData.locations?.map(e => ({ _id: e.value }))

    const { handleError } = useCommon()
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { router, route } = useRouter()
    const events = ref([]);
    const eventsDone = ref(0)
    const eventsPending = ref(0)
    const eventsTotal = ref(0)
    const locationFilter = ref([]);
    const roleFilter = ref([]);
    const routeWorker = route.value.params.id
    const workerFilter = ref(routeWorker ? [routeWorker] : [])
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const monthFilter = ref(now.getMonth())
    const yearFilter = ref(now.getFullYear())
    const isLoading = ref(true)
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const isOnline = computed(() => store.state.app.isOnline)
    const isEventHandlerSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // Add event listener for connectivity status detection
    onMounted(() => {
      listEvents()
    })

    // UnRegister and remove event listeners on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    });

    const blankEvent = {
      title: '',
      start: '',
      extendedProps: {
        calendar: '',
        process: '',
        attendee: '',
        confirmation: '',
      },
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    const updateLocationConditions = (items, category, query) => {
      const filterQuery = items.map(e => ({locations_in: {[category]: e}}))
      query.OR[0].attendee.AND.push({OR: filterQuery})
      query.OR[1].attendee.AND.push({OR: filterQuery})
      query.OR[2].AND.push({organizer: {OR: filterQuery}})
      if (query.OR[3]) query.OR[3].attendee.AND.push({OR: filterQuery})
    }

    async function listEvents() {
      isLoading.value = true

      const firstDayOfCurrentMonth = new Date(yearFilter.value, monthFilter.value, 1)
      const firstDayOfNextMonth = new Date(yearFilter.value, monthFilter.value + 1, 1)

      const query = {
        client_id: { _id: client_id},
        start_gte: firstDayOfCurrentMonth,
        start_lt: firstDayOfNextMonth,
        deleted_ne: true,
        OR: [
          { attendee: { AND: [{ deleted_ne: true }] } },
          { 
            attendee: { AND: [{ deleted: true }] },
            extendedProps: { calendar: "Realizadas"}
          },
          { AND: [{ attendee_exists: false }]}
        ],
      }

      if (userRole === 'supervisor') {
        query.OR[0].attendee.AND.push({supervisors_in: {_id: userId}})
        query.OR[1].attendee.AND.push({supervisors_in: {_id: userId}})
        query.OR[2].AND.push({organizer: {locations_in: userLocationsQuery}})
        query.OR[3] = {attendee: {AND: [{_id: userId}]}}
      }

      if (locationFilter.value.length > 0) updateLocationConditions(locationFilter.value, "_id", query)
      if (zoneFilter.value.length > 0) updateLocationConditions(zoneFilter.value, "zone", query)
      if (agencyFilter.value.length > 0) updateLocationConditions(agencyFilter.value, "agency", query)

      if (roleFilter.value.length > 0) {
        query.attendee = {roles_in: roleFilter.value}
      }

      if (workerFilter.value.length > 0) {
        if (query.attendee) query.attendee._id_in = workerFilter.value
        else query.attendee = {_id_in: workerFilter.value}
      }

      axios
        .post("/graphql", {
          query: queryEvents,
          variables: { query, limit: 10000, sortBy: "START_ASC" },
        })
        .then(({ data }) => {
          parseEventData(data)
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    function parseEventData(data) {
      let eventsData = []
      let eventsByStatus = { Realizadas: 0, Pendientes: 0 }

      for (const event of data.data.events) {
        const date = event.start ? new Date(event.start) : undefined
        const status = event.extendedProps?.calendar
        const process = event.process?.name
        const attendee = event.attendee?.name

        const payload = {
          id: event._id,
          start: date,
          date: date?.toLocaleDateString(`${default_language || 'en'}-US`),
          title: attendee ? `${attendee} - ${process}` : `${process}`,
          extendedProps: {
            calendar: status,
            process: {
              name: process,
              _id: event.process?._id
            }
          },
          computedStatus: computed(() => i18n.t(status === "Realizadas" ? "done" : "message.tableHeader.pending_status")),
          observer: event.confirmation?.supervisor?.name
        }

        if (attendee) {
          payload.extendedProps.attendee = {
            name: attendee,
            _id: event.attendee._id
          }
        }

        if (event.confirmation) {
          payload.extendedProps.confirmation = {
            _id: event.confirmation._id,
          }
        }

        eventsData.push(payload)

        if (status === "Realizadas") eventsByStatus["Realizadas"] += 1;
        else eventsByStatus["Pendientes"] += 1;
      }

      eventsDone.value = eventsByStatus["Realizadas"]
      eventsPending.value = eventsByStatus["Pendientes"]
      eventsTotal.value = eventsByStatus["Realizadas"] + eventsByStatus["Pendientes"]

      eventsData.sort(function (a, b) {
        // Done events go after overdue and pending events
        if (a.extendedProps.calendar === "Realizadas" && b.extendedProps.calendar !== "Realizadas") return 1
        if (a.extendedProps.calendar !== "Realizadas" && b.extendedProps.calendar === "Realizadas") return -1
        return 0
      })

      events.value = eventsData
    }

    const openEventHandlerSidebar = (eventData) => {
      event.value = {...eventData}
      isEventHandlerSidebarActive.value = true
    }

    const updateEvent = eventData => {
      const eventCopy = {...eventData}
      delete eventCopy.date
      delete eventCopy.computedStatus
      delete eventCopy.observer

      store.dispatch('calendar/updateEvent', { event: eventCopy })
        .then(response => {
          if (response.data?.errors) throw new Error(response.data.errors[0].message)
          showSuccessMessage(i18n.t('message.Event_updated'))
          listEvents()
        })
        .catch(error => {
          console.log(error)
          showErrorMessage(i18n.t('message.event_update_error'))
        })
        .finally(() => {
          isEventHandlerSidebarActive.value = false
        })
    }

    const removeEvent = ({ eventData, deletedJustification, improvementsToDelete }) => {
      store.dispatch('calendar/removeEvent', { eventData, deletedJustification, deletedBy: userId, improvementsToDelete})
        .then((response) => {
          if (response.data?.errors) throw new Error(response.data.errors[0].message)
          showSuccessMessage(i18n.t('message.Event_removed'))
          listEvents()
        })
        .catch(error => {
          console.log(error)
          showErrorMessage(i18n.t('message.event_remove_error'))
        })
        .finally(() => {
          isEventHandlerSidebarActive.value = false
        })
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      workerFilter.value = data.workerFilter;
      monthFilter.value = data.monthFilter;
      yearFilter.value = data.yearFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listEvents();
    }

    return {
      events,
      eventsDone,
      eventsPending,
      eventsTotal,
      updateFilter,
      userRole,
      isLoading,
      router,
      isOnline,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      removeEvent,
      isEventHandlerSidebarActive,
      openEventHandlerSidebar,
      routeWorker
    };
  },
};
</script>

<style lang="scss" scoped>
</style>