export const addMeeting = `mutation insertOneMeeting($data:  MeetingInsertInput!){
  insertOneMeeting(data: $data) {
    _id
  }
}`

export const getMeetings =  `query meetings($query: MeetingQueryInput, $limit: Int, $sortBy: MeetingSortByInput){
  meetings(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    location {
      _id
      location
    }
    improvements {
      _id
      domain
      note
      deleted
      completed
      metadata {
        name
        answer
      }
    }
    created_by {
      _id
      name
    }
    date
    start
    end
    happy
    neutral
    sad
    imageKey
    recognitions {
      _id
    }
  }
}`

export const updateMeeting = `mutation updateOneMeeting($query: MeetingQueryInput, $data: MeetingUpdateInput!) {
  updateOneMeeting(query: $query, set: $data) {
    _id,
  }
}
`