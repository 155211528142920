<template lang="pug">
.habit-confirmation-wrapper
  b-row(v-if="!commitment")
    h1 {{ $t('message.commitment_not_found') }}

  div(v-if="commitment")
    b-row
      b-col(cols="12")
        b-card(:title="commitmentName")
          b-card-text
            div(v-if="commitment.assignee")
              | {{ $t('message.evaluation_for') }} {{ commitment.assignee.name }}
              span(v-if="commitment.supervisor")
                |  {{ $t('message.done_by') }} {{ commitment.supervisor.name }}

    // Activity/Behaviour
    b-row(v-if="commitment.activity")
      b-col(cols="12")
        b-card(:title="commitment.activity")                
          b-row
            b-col(cols="12")
              behaviour-question(:behaviour="commitment.behaviour")
</template>

<script>
import { onMounted } from '@vue/composition-api/dist/vue-composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormSelect,
  BCardGroup,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BehaviourQuestion from '@/views/habit/confirmation/view/BehaviourQuestion.vue'
// eslint-disable-next-line import/no-cycle
import useCommitmentView from './useCommitmentView'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormSelect,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BehaviourQuestion,
    vSelect,
  },

  directives: {
    Ripple,
  },

  computed: {
    commitmentName() {
      return i18n.t('commitment') + `: ${this.commitment.title}`
    },
  },

  setup() {
    const { show, commitment } = useCommitmentView()

    onMounted(async () => {
      await show()
    })

    return {
      show,
      commitment,
    }
  },
}
</script>

<style lang="scss">
.form-group {
  label {
    font-size: 1.2rem;
    font-weight: 200;
  }
}
</style>
