export default [
  {
    header: 'Tasks',
  },
  {
    title: 'Processes',
    icon: 'ActivityIcon',
    route: 'organization-process-list',
    children: [
      {
        title: 'List',
        route: 'organization-process-list',
      },
      {
        title: 'Controls',
        route: 'organization-control-list',
      },
      {
        title: 'Behaviours',
        route: 'organization-behaviour-list',
      },
      // {
      //   title: 'Crimes',
      //   route: 'organization-crime-list',
      // },
      // {
      //   title: 'Pending Confirmations',
      //   route: 'organization-pending-confirmations',
      // },
      // {
      //   title: 'Documents',
      //   route: 'organization-document-list',
      // },
    ],
  },
  {
    title: 'Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Confirmation Planning',
    route: 'organization-confirmation-planning-list',
    icon: 'ClipboardIcon',
  },
]
