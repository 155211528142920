import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { addCrime } from '@/@core/queries/crimes'
import i18n from '@/libs/i18n'
import axios from '@axios'

export default function useCrimeAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const userData = store.state?.userStore?.userData
  const crime = ref({})
  const isSubmitting = ref(false)

  function create(data) {
    isSubmitting.value = true
    const payload = {}
    if (data.name?.value) payload.name = data.name.value
    if (data.description?.value) payload.description = data.description.value
    if (data.lawNumber?.value) payload.lawNumber = data.lawNumber.value
    if (data.law?.value) payload.law = data.law.value
    if (data.article?.value) payload.article = data.article.value
    if (data.number?.value) payload.number = data.number.value
    payload.client_id = (userData.role !== 'admin') ? { link: userData.client?.$oid } : null

    axios
      .post('/graphql', {
        query: addCrime,
        variables: { data: payload },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.crime_added'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.crime_add_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-crime-list' })
        isSubmitting.value = false
      })
  }

  return {
    create,
    crime,
    isSubmitting,
  }
}
