export const getEmployeeSurveys = `query employee_surveys($query: Employee_surveyQueryInput, $limit: Int, $sortBy: Employee_surveySortByInput){
  employee_surveys(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    answer
  }
}
`

export const updateEmployeeSurveys = `mutation updateManyEmployee_surveys($query: Employee_surveyQueryInput, $data: Employee_surveyUpdateInput!) {
  updateManyEmployee_surveys(query: $query, set: $data) {
    matchedCount,
    modifiedCount,
  }
}
`

export const createEmployeeSurvey = `mutation insertOneEmployee_survey($data: Employee_surveyInsertInput!) {
  insertOneEmployee_survey(data: $data) {
    _id
  }
}
`