<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <add-co-logo />
      <h2 class="brand-text text-primary ml-1">
        AddCo
      </h2>
    </b-link>

    <!-- Loading -->
    <loading
      v-if="isLoading || isSubmitting"
      :active="true" 
      :is-full-page="true"
      color="#498ceb"
    />

    <div v-if="!isLoading" class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 v-if="locationNotFound" class="text-danger">
          {{ $t('location_not_found_check_url') }}
        </h2>
      
        <div v-else>
          <h5 v-if="!isSubmitted" class="mb-2 mb-sm-5">
            <u>{{ $t('label.Location') }}</u>: <b>{{ location }}</b>
          </h5>

          <h2 class="mb-sm-3">
            {{ isSubmitted ? $t('thank_you_submitting_response') : $t('how_are_you_feeling_today') }}
          </h2>

          <b-row v-if="isSubmitted">
            <b-col cols="12">
                <feather-icon :icon="iconNames[state]" :size="iconSize" :class="`my-3 my-sm-2 text-${variants[state]}`"></feather-icon>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col sm="4">
              <b-button class="mx-2 my-1" variant="outline-success" :pressed="state === 'happy'" @click="state = 'happy'">
                <feather-icon icon="SmileIcon" :size="iconSize" class="text-success"></feather-icon>
              </b-button>
            </b-col>
            <b-col sm="4">
              <b-button class="mx-2 my-sm-1" variant="outline-secondary" :pressed="state === 'neutral'" @click="state = 'neutral'">
                <feather-icon icon="MehIcon" :size="iconSize"></feather-icon>
              </b-button>
            </b-col>
            <b-col sm="4">
              <b-button class="mx-2 my-1" variant="outline-danger" :pressed="state === 'sad'" @click="state = 'sad'">
                <feather-icon icon="FrownIcon" :size="iconSize" class="text-danger"></feather-icon>
              </b-button>
            </b-col>
          </b-row>

          <b-button
            v-if="!isSubmitted"
            variant="primary"
            class="mt-1 mt-sm-3 btn-lg"
            :disabled="!state || isSubmitting"
            @click="submit"
          >
            {{ $t('message.submit') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton } from 'bootstrap-vue'
import AddCoLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { ref, onMounted } from "@vue/composition-api";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "@axios";
import useNotifications from "@/composables/useNotifications";
import { createEmployeeSurvey } from "@core/queries/employee-survey";
import { useRouter } from '@core/utils/utils'
import { getLocation } from '@/@core/queries/locations'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    AddCoLogo,
    BLink,
    BButton,
    Loading,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { route } = useRouter()
    const state = ref("")
    const location = ref("")
    const locationNotFound = ref(false)
    const isLoading = ref(true)
    const isSubmitting = ref(false)
    const isSubmitted = ref(false)
    const iconNames = { happy: 'SmileIcon', neutral: 'MehIcon', sad: 'FrownIcon' }
    const variants = { happy: 'success', neutral: 'secondary', sad: 'danger' }
    const widthBreakpoint = 576
    const iconSize = ref("120")

    onMounted(async () => {
      // Define locale based on the language of the browser UI
      if (window.navigator.language?.startsWith('es')) i18n.locale = 'es'

      // Adjust icon size depending on viewport width
      if (window.innerWidth < widthBreakpoint) iconSize.value = "100"

      try {
        // If user is not logged in (either as a real or anonymous user), then log in as an anonymous user
        if (!useJwt.realm.currentUser) await useJwt.loginAsAnonymousUser()

        // Get location name based on location id from URL param
        const { data } = await axios.post("/graphql", {
          query: getLocation,
          variables: { query: { _id: route.value.params.locationId } },
        })

        if (data.errors) throw new Error(data.errors[0].message)
        location.value = data.data?.location?.location

      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.location_fetch_error'))
        locationNotFound.value = true
        
      } finally {
        isLoading.value = false
      }
    })

    const submit = () => {
      isSubmitting.value = true

      const payload = {
        location: { link: route.value.params.locationId },
        createdAt: new Date(),
        answer: state.value,
        read: false
      }

      axios.post("/graphql", {
        query: createEmployeeSurvey,
        variables: { data: payload },
      })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          showSuccessMessage(i18n.t('message.answer_success'))
          isSubmitted.value = true
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.answer_submit_error'))
        })
        .finally(() => {
          isSubmitting.value = false
        })
    }

    return {
      state,
      location,
      locationNotFound,
      submit,
      isLoading,
      isSubmitting,
      isSubmitted,
      iconNames,
      variants,
      iconSize,
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>