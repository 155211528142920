<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Behaviour
        b-col(md="12")
          b-form-group(:label="$t('label.behaviour')", :label-for="$t('label.behaviour')", description="")
            validation-provider(:name="$t('label.behaviour')", rules="required", v-slot="{ errors }")
              quill-editor(
                v-model="data.name"
                autofocus
                :options="editorOption"
              )
              small(class="text-danger") {{ errors[0] }}

      b-row
        // Type
        b-col(md="3")
          b-form-group(
            :label="$t('label.Type')",
            :label-for="$t('label.Type')",
          )
            validation-provider(:name="$t('label.Type')", rules="required", v-slot="{ errors }")
              v-select(
                v-model="data.type",
                label="title",
                :options="types",
                :reduce="(type) => type.value",
              )
              small(class="text-danger") {{ errors[0] }}

        // Expected answer
        b-col(v-if="data.type === 'bool'", md="3")
          b-form-group(
            :label="$t('label.expected_answer')",
            :label-for="$t('label.expected_answer')",
          )
            validation-provider(:name="$t('label.expected_answer')", rules="required", v-slot="{ errors }")
              v-select(
                v-model="data.expectedAnswer",
                label="title",
                :options="yesNoOptions",
                :reduce="(option) => option.value",
              )
              small(class="text-danger") {{ errors[0] }}

      // Button
      b-row
        b-col(cols="12", class="text-right")
          b-button(
            variant="primary",
            :disabled="isSubmitting",
            @click="validateAndSubmit"
          )
            | {{ $t('message.submit') }}

</template>

<script>
import { ref, toRefs } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { types, yesNoOptions } from '@/constants'

export default {
  name: 'Form',

  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    quillEditor,
  },

  props: ['data', 'isSubmitting'],

  setup(props, { emit }) {
    const { showErrorMessage } = useNotifications()
    const form = ref(null)
    const editorOption = {
      modules: {
        toolbar: ['bold', 'italic', 'underline', { 'font': [] }],
      },
      placeholder: i18n.t('placeholder.behaviour'),
    }

    const validateForm = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateForm()
        .then(() => emit('submit', toRefs(props.data)))
        .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    return {
      required,
      form,
      validateAndSubmit,
      editorOption,
      types,
      yesNoOptions,
    }
  },
}
</script>

<style scoped>

</style>
