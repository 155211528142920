<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="flat-danger"
    class="btn-icon rounded-circle"
    v-b-tooltip.hover.v-danger.top="$t('Delete')"
    @click="$emit('clicked')"
  >
    <feather-icon icon="DeleteIcon" />
  </b-button>
</template>

<script >
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'DeleteButton',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
}
</script>

<style scoped>

</style>
