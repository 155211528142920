<template lang="pug">
div
  Form(:data="document", :isSubmitting="isSubmitting", @submit="update")
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useDocumentEdit from './useDocumentEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { show, update, document, isSubmitting } = useDocumentEdit()

    onMounted(show)

    return {
      document,
      update,
      isSubmitting,
    }
  },
}
</script>
