import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { updateRole, _getRoles } from '@/@core/queries/roles'
import { updateConfirmationPlannings } from '@/@core/queries/confirmation-planning'
import i18n from '@/libs/i18n'
import axios from "@axios";
import useCommon from '@/views/organization/useCommon'

export default function useRoleList() {
  const { client = null, role = null } = store.state?.userStore?.userData
  const { showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const refRoleListTable = ref(null)
  const roles = ref([])

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'code', label: i18n.t('message.tableHeader.code'), sortable: true },
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'description', label: i18n.t('message.tableHeader.description'), sortable: true },
      // { key: 'responsibilities', sortable: false },
      // { key: 'qualifications', sortable: false },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const totalRoles = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('code')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const isLoading = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value ? refRoleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const index = () => {
    isLoading.value = true
    store
      .dispatch('organization-role/index', {
        status: statusFilter.value,

        query: _getRoles,
        variables: {
          input: {
            limit: perPage.value,
            total: currentPage.value === 1,
            offset: (currentPage.value * perPage.value) - perPage.value,
            query: {},
            search: searchQuery.value,
            sortBy: sortBy.value,
            sortOrder: isSortDirDesc.value ? -1 : 1,
            client_id: (role !== 'admin') ? client.$oid : null,
          },
        },
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const {
          RolesData: {
            roles: w,
            totalRecords: total,
          },
        } = response.data.data || {}
        roles.value = w
        if (currentPage.value === 1) totalRoles.value = total
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.role_list_fetch_error') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteRole = (id, callback) => {
    store
      .dispatch('organization-role/deleteRole', {
        query: updateRole,
        variables: { query: { _id: id }, data: { deleted: true } },
      })
      .then(() => {
        callback(true)

        // Delete confirmation plannings associated to this role
        axios
          .post("/graphql", {
            query: updateConfirmationPlannings,
            variables: { query: { role: {_id: id } }, data: { deleted: true } },
          })
          .then(() => {})
          .catch((error) => console.log(error))
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.role_remove_error'))
      })
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (roles.value.length ? 1 : 0)
    const to = roles.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalRoles.value })
  })

  watch([currentPage, perPage, statusFilter], () => {
    index()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    index()
  })

  return {
    index,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    showingMessage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    roles,
    refRoleListTable,
    deleteRole,
    isLoading,
    role,
  }
}
