import axios from '@axios'
import { addEvent, updateEvent, queryEvent, queryEvents } from '@/@core/queries/calendar'
import { confirmProcess } from '@/@core/queries/confirmations'
import { addMeeting } from "@core/queries/meeting";
import { updateImprovement } from '@/@core/queries/improvements'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from "@/libs/i18n";
import awsConnection from './aws';
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import useCommon from '@/views/organization/useCommon'
import { get, set, getMany, del } from 'idb-keyval';

export default function onlineUpdate() {

  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { singleUpload, sendEmail } = awsConnection()
  const { updateDailyDialogueWithKey } = useCommonDashboards()
  const { getEmailTemplate, addTask } = useCommonTodo()
  const { formatDate, updateConfirmationWithKey, createImprovements } = useCommon()
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

  const postImprovement = (payload) => {
    return new Promise((resolve, reject) => {
      addTask(payload)
        .then(() => resolve())
        .catch(() => reject())
    })
  }

  const postDailyDialogue = (payload) => {
    let dataFromPayload = {
      imgData: payload.imgData,
      improvementsNotMapped: payload.improvementsNotMapped
    }
    delete payload.imgData
    delete payload.improvementsNotMapped

    return new Promise((resolve, reject) => {
      axios.post('/graphql', { query: addMeeting, variables: { data: payload } })
        .then(response => {
          if (response.data.errors) throw new Error()
          showSuccessMessage(i18n.t('message.daily_dialogue_created'))
          
          // Upload image to AWS and then update the daily dialogue in MongoDB with the AWS image key
          const dailyDialogueId = response.data.data.insertOneMeeting._id
          const { fileInfo, destinationFolder } = dataFromPayload.imgData || {}
          if (fileInfo) {
            singleUpload(fileInfo, destinationFolder)
              .then((key) => updateDailyDialogueWithKey(dailyDialogueId, key))
              .catch((err) => console.log(err))
          }

          // Send email to assignees with the details of the improvements opportunity created
          dataFromPayload.improvementsNotMapped?.forEach(e => {
            if (e.assignee?.email) {
              const subject = i18n.t('message.improvement_opportunity_assigned')
              let bodyData = {
                name: e.assignee?.name,
                title: e.note,
                tags: e.domain ? i18n.t(`domain.${e.domain}`) : '',
                creator: userData.fullName,
                dueDate: e.dueDate ? `${e.dueDate.slice(8, 10)}/${e.dueDate.slice(5, 7)}/${e.dueDate.slice(0, 4)}` : '',
              }
              if (e.metadata?.length) {
                e.metadata.forEach(e => {
                  if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
                  Object.assign(bodyData, {[e.name]: e.answer})
                })
              }
              const body = getEmailTemplate(bodyData)
              
              sendEmail([e.assignee.email], subject, body)
                .then((response) => {
                  if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_improvement_success'))
                })
                .catch((err) => {
                  console.log(err)
                  showErrorMessage(i18n.t('message.email_send_improvement_error'))
                })
            }
          })
          
          resolve()
        })
        .catch(() => {
          showErrorMessage(i18n.t('message.daily_dialogue_error'))
          reject()
        })
    })
  }
  
  const postEvent = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', { query: addEvent, variables: { data: payload } })
        .then(({data}) => {
          if (data.errors) throw new Error(data.errors[0].message)
          showSuccessMessage(i18n.t('message.confirmation_agenda'))

          // Get event to obtain the improvements ids and update each one to add origin and origin_id from event
          const newEventId = data.data.insertOneEvent._id
          if (newEventId) {
            axios
              .post('/graphql', {
                query: queryEvent,
                variables: { query: { _id: newEventId } }
              })
              .then((response) => {
                if (response.data.errors) throw new Error(response.data.errors[0].message)
                const improvementsToUpdate = response?.data?.data?.event?.improvements || []
                improvementsToUpdate.forEach(element => {
                  axios
                    .post('/graphql', {
                      query: updateImprovement,
                      variables: { query: { _id : element._id }, data: { origin_id: newEventId, origin: "event" } }
                    })
                    .then((response) => {
                      if (response.data.errors) throw new Error(response.data.errors[0].message)
                    })
                    .catch((err) => {
                      console.log(err)
                      showErrorMessage(i18n.t('message.improvement_update_error'))
                    })
                });
              })
              .catch((err) => {
                console.log(err)
                showErrorMessage(i18n.t('message.improvement_update_error'))
              })
          }

          resolve()
        })
        .catch((err) => {
          console.log(err)
          showErrorMessage(i18n.t('message.no_confirmation_agenda'))
          reject()
        })
    })
  }

  const fetchEvent = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/graphql', {
          query: queryEvent,
          variables: { query: { _id: id } },
        })
        .then((response) => {
          if (response.data.errors) throw new Error()
          resolve(response.data.data.event.confirmation)
        })
        .catch(() => {
          reject()
        })
    })
  }
  
  const schedulleEvent = async (calendar, confirmationId, dataFromPayload) => {
    const startDateTime = formatDate(dataFromPayload.eventDate)
    const endDateTime = formatDate(dataFromPayload.eventDate, 13)

    if (confirmationId) { 
      const firstDayOfCurrentMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), 1)
      const firstDayOfNextMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth() + 1, 1)
      const query = {
        extendedProps : { calendar_ne : "Realizadas"}, 
        start_gte : firstDayOfCurrentMonth, 
        end_lt : firstDayOfNextMonth,
        attendee: { _id: dataFromPayload.worker},
        process: { _id: dataFromPayload.processId}
      }
      try { const eventsToSearch = await axios.post('/graphql', {
            query: queryEvents,
            variables: {query, sortBy: "START_ASC"}
          })
          if (eventsToSearch.data.errors) throw new Error(eventsToSearch.data.errors[0].message)
          const eventExist = eventsToSearch.data?.data?.events
          if (eventExist?.length) {
            const queryDos = {
              query: updateEvent,
              variables: {
                query: {
                  _id: eventExist[0]._id
                },
                data: {
                  extendedProps: { calendar: "Realizadas"},
                  start: startDateTime,
                  end: endDateTime,
                  confirmation: { link: confirmationId }
                }
              }
            }

            // If there are new improvements, create improvements and merge them with old improvements from the event if there are any
          if (dataFromPayload.improvements?.length) {
            try {
              const newImprovementIds = await createImprovements(dataFromPayload.improvements, "event", eventExist[0]._id)
              const oldImprovementIds = eventExist[0].improvements?.map(e => e._id) || []
              queryDos.variables.data.improvements = {
                link: [...oldImprovementIds, ...newImprovementIds]
              }
            } catch (error) {
              console.log(error)
            }
          }

            try { 
            const eventToUpdate = await axios.post('/graphql', queryDos)
            if (eventToUpdate.data.errors) throw new Error(eventToUpdate.data.errors[0].message)
            return showSuccessMessage(i18n.t('message.Event_updated'))     
            } catch (error) {
              console.log(error)
              showErrorMessage(i18n.t('message.event_update_error'))
            }
          }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_update_error'))
      }
    }

    const payload = {
      client_id: { link: userData.client.$oid },
      process: { link: dataFromPayload.processId },
      attendee: dataFromPayload.worker ? { link: dataFromPayload.worker } : null,
      organizer: { link: dataFromPayload.supervisor },
      start: startDateTime,
      end: endDateTime,
      confirmation: confirmationId ? { link: confirmationId } : null,
      extendedProps: {
        calendar: calendar || 'Pendientes',
      },
    }

    if (dataFromPayload.improvements?.length) {
      // Upload images to AWS and then store image keys in an array
      const uploadImagePromises = dataFromPayload.improvements.map(i => {
        if (!i.imgData?.fileInfo) return null
        const { fileInfo, destinationFolder } = i.imgData
        return new Promise((resolve, reject) => {
          singleUpload(fileInfo, destinationFolder)
            .then((key) => resolve(key))
            .catch((err) => {
              console.log(err)
              resolve(null)
            })
        })
      })
      const imageKeys = await Promise.all(uploadImagePromises)

      // Set improvements property
      payload.improvements = {
        create: dataFromPayload.improvements.map((c, index) => {
          const newImprovement  = {
            client_id: c.client_id,
            assignee: c.assignee ? { link: c.assignee._id } : null,
            note: c.note,
            tags: c.tags,
            description: c.description,
            dueDate: c.dueDate ? new Date(`${c.dueDate.slice(0, 10)} 12:00:00`) : null,
            important: c.important,
          }
          if (c.imgData) newImprovement.imageKey = imageKeys[index]
          if (c.metadata?.length) {
            newImprovement.metadata = c.metadata.map(m => ({
              name: m.name,
              type: m.type,
              answer: m.answer,
            }))
          }
          return newImprovement
        })
      }
    }
  
    postEvent(payload)
  }

  const updateEventWithConfirmation = async (confirmationId, dataFromPayload) => {
    if (!confirmationId || !dataFromPayload.eventId) {
      schedulleEvent('Realizadas', confirmationId, dataFromPayload)
      return
    }

    // Fetch the event to see if its confirmation has already been done by another user
    try {
      const eventConfirmation = await fetchEvent(dataFromPayload.eventId)
      if (eventConfirmation) {
        schedulleEvent('Realizadas', confirmationId, dataFromPayload)
        return
      }
    } catch (error) {
      showErrorMessage(i18n.t('message.event_fetch_error'))
    }

    const payload = {
      confirmation: { link: confirmationId },
      process: { link: dataFromPayload.processId },
      extendedProps: {
        calendar: 'Realizadas',
      },
      attendee: { link: dataFromPayload.worker },
      start: formatDate(dataFromPayload.eventDate),
      end: formatDate(dataFromPayload.eventDate, 13),
    }

    // If there are new improvements, create improvements and get event to see if it already has improvements to merge them
    if (dataFromPayload.improvements) {
      try {
        const newImprovementIds = await createImprovements(dataFromPayload.improvements, "event", dataFromPayload.eventId)
  
        const eventResponse = await axios.post('/graphql', { query: queryEvent, variables: { query: { _id: dataFromPayload.eventId } } })
        if (eventResponse.data?.errors) throw new Error(eventResponse.data.errors[0].message)
        const oldImprovementIds = eventResponse.data?.data?.event?.improvements?.map(e => e._id) || []
        payload.improvements = {
          link: [...oldImprovementIds, ...newImprovementIds]
        }
      } catch (error) {
        console.log(error)
      }
    }
  
    axios
      .post('/graphql', {
        query: updateEvent,
        variables: {
          query: { _id: dataFromPayload.eventId },
          data: payload,
        },
      })
      .then((response) => {
        if (response.data.errors) throw new Error()
        showSuccessMessage(i18n.t('message.Event_updated'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.event_update_error'))
      })
  }

  const postConfirmation = (payload) => {
    const dataFromPayload = {
      processId: payload.process.link,
      worker: payload.worker.link,
      supervisor: payload.supervisor.link,
      eventDate: payload.date,
      imgData: payload.imgData,
      improvements: payload.improvements
    }
    delete payload.imgData
    delete payload.improvements
    if (payload.eventId) {
      dataFromPayload.eventId = payload.eventId
      delete payload.eventId
    }
    return new Promise((resolve, reject) => {
      axios
        .post('/graphql', {
          query: confirmProcess,
          variables: { data: payload },
        })
        .then(response => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          showSuccessMessage(i18n.t('message.confirmation_created'))
          
          const confirmationId = response.data.data.insertOneConfirmation._id
          
          updateEventWithConfirmation(confirmationId, dataFromPayload)

          // Upload image to AWS and then update the confirmation in MongoDB with the AWS image key
          const { fileInfo, destinationFolder } = dataFromPayload.imgData || {}
          if (fileInfo) {
            singleUpload(fileInfo, destinationFolder)
              .then((key) => updateConfirmationWithKey(confirmationId, key))
              .catch((err) => console.log(err))
          }

          resolve()
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.confirmation_error'))
          reject()
        })
    })
  }

  const updateLastSync = () => {
    const date = new Date()
    const actualDate = date.toLocaleDateString(`${default_language || 'en'}-US`)
    const actualTime = date.toLocaleTimeString(`${default_language || 'en'}-US`)
    localStorage.lastSync = `${actualDate} ${actualTime}`
  }

  const upload = async ({ category, key, storage }) => {
    let itemsStored = storage === "indexedDB" ? await get(key) : JSON.parse(localStorage[key])
    let itemsNotUploaded = []
    let uploadFunction
    switch (category) {
      case "dialogues":
        uploadFunction = (item) => postDailyDialogue(item)
        break;
      case "events":
        uploadFunction = (item) => postEvent(item)
        break;
      case "confirmations":
        uploadFunction = (item) => postConfirmation(item)
        break;
      case "improvements":
        uploadFunction = (item) => postImprovement(item)
        break;
      default:
        return;
    }
    for (let i = 0; i < itemsStored.length; i++) {
      const item = itemsStored[i]
      try {
        await uploadFunction(item)
        updateLastSync()
      } catch (error) {
        console.log(error)
        itemsNotUploaded.push(item)
      }
    }
    if (itemsNotUploaded.length) {
      if (storage === "indexedDB") {
        try {
          await set(key, itemsNotUploaded)
        } catch (error) {
          console.log(error)
        }
      } else {
        localStorage[key] = JSON.stringify(itemsNotUploaded)
      }
    } else {
      if (storage === "indexedDB") await del(key)
      else localStorage.removeItem(key)
    }
  }

  const uploadStorage = async (origin) => {
    const [dialogues, events, confirmations, improvements] = await getMany(['dialoguesToUpload', 'eventsToUpload', 'confirmationsToUpload', 'improvementsToUpload'])

    if (dialogues) await upload({ category: "dialogues", key: "dialoguesToUpload", storage: "indexedDB" })
    else if (localStorage.dialogues) await upload({ category: "dialogues", key: "dialogues", storage: "localStorage" })

    if (events) await upload({ category: "events", key: "eventsToUpload", storage: "indexedDB" })
    else if (localStorage.eventsForSchedule) await upload({ category: "events", key: "eventsForSchedule", storage: "localStorage" })

    if (confirmations) await upload({ category: "confirmations", key: "confirmationsToUpload", storage: "indexedDB" })
    else if (localStorage.confirmations) await upload({ category: "confirmations", key: "confirmations", storage: "localStorage" })

    if (improvements) await upload({ category: "improvements", key: "improvementsToUpload", storage: "indexedDB" })

    if (origin !== "forced") updateLastSync()
  }

  return {
    uploadStorage
  }
}
