export default [
  {
    header: 'PC Dashboards',
    // roles: {
    //   admin: ['read', 'write'],
    //   consultant: ['read', 'write'],
    //   supervisor: ['read', 'write'],
    //   client: ['read', 'write'],
    // },
  },
  {
    title: 'General',
    route: 'habit-general-dashboard',
    icon: 'GridIcon',
  },
  {
    title: 'Confirmations',
    icon: 'BarChartIcon',
    route: 'habit-confirmation-dashboard',
    children: [{
      title: 'Global',
      route: 'habit-confirmation-dashboard',
    },
    {
      title: 'Daily',
      route: 'habit-daily-dashboard',
    }],
  },
  {
    title: 'Adherence',
    icon: 'TrendingUpIcon',
    route: 'habit-adherence-dashboard',
    children: [{
      title: 'Processes',
      route: 'habit-adherence-dashboard',
    },
    {
      title: 'Behaviours',
      route: 'habit-behaviour-dashboard',
    },
    {
      title: 'Continuous Improvement',
      route: 'habit-commitment-dashboard'
    }],
  },
  {
    title: 'Case Management',
    route: 'organization-case-list',
    icon: 'ListIcon',
  },
]
