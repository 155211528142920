import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications"
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useControlList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { getItemsWithAggregate, updateItem, updateItems, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const controls = ref([])
  const collection = 'control'

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const perPage = ref(10)
  const totalControls = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchControls = async () => {
    isLoading.value = true

    try {
      const filter = {
        client_id: ObjectId(userData.client.$oid),
        deleted: { $ne: true }
      }
      
      if (searchQuery.value) filter.name = { $regex: searchQuery.value, $options: 'i' }
    
      const pipeline = [
        { $match: filter },
        { $project: { name: 1 } },
        { $facet: {
            count: [ { $count: 'totalItems' } ],
            pagination: [
              { $sort: { [sortBy.value]: isSortDirDesc.value ? -1 : 1, _id: isSortDirDesc.value ? -1 : 1 } },
              { $skip: (currentPage.value * perPage.value) - perPage.value || 0 },
              { $limit: perPage.value || 10 }
            ]
          }
        }
      ]
    
      const items = await getItemsWithAggregate({ collection, pipeline })

      controls.value = items[0]?.pagination || []
      totalControls.value = items[0]?.count[0]?.totalItems || 0
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.control_list_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  const deleteControl = async (id) => {
    try {
      const query = { _id: ObjectId(id) }
      const payload = { deleted: true }
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.control_remove'))
      fetchControls()

      // Delete control from the processes where it is associated
      const pullQuery = { controls: ObjectId(id) }
      const pullAction = { $pull: pullQuery }
      await updateItems({ collection: 'process', query: pullQuery, action: pullAction })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.something_went_wrong'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (controls.value.length ? 1 : 0)
    const to = controls.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalControls.value })
  })

  watch([currentPage, perPage], () => {
    fetchControls()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchControls()
  })

  return {
    fetchControls,
    tableColumns,
    perPage,
    currentPage,
    totalControls,
    showingMessage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    controls,
    deleteControl,
    isLoading,
  }
}