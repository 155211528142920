import axios from '@axios'
import { ref } from '@vue/composition-api'
import useNotifications from '@/composables/useNotifications'
import { queryCommitment } from '@/@core/queries/commitments'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'

export default function useCommitmentView() {
  const { showErrorMessage } = useNotifications()
  const { route } = useRouter()

  const commitment = ref({})
  const { commitmentId } = route.value.params

  const show = async () => {
    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: queryCommitment,
        variables: { query: { _id: commitmentId } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        commitment.value = data.data.commitment
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.confirmation_fetch_error'))
      })
  }

  return {
    show,
    commitment
  }
}
