<template>
  <div>
    <Form
      :data="item"
      :isSubmitting="isSubmitting"
      @submit="update"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { onMounted } from '@vue/composition-api/dist/vue-composition-api'
import Form from '../shared/Form'
// eslint-disable-next-line import/no-cycle
import useRoleEdit from './useRoleEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { update, show, item, isSubmitting } = useRoleEdit()

    onMounted(show)

    return {
      update,
      show,
      item,
      isSubmitting,
    }
  },

}
</script>
