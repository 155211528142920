<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :locationFilterShow="true"
          :roleFilterShow="true"
          :workerFilterShow="true"
          :dateRangeFilterShow="true"
          @changeFilter="updateFilter"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!attendeeGroup.length")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_workers_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total-workers(:statistic="totalEvents")
        b-col(sm="3")
          statistic-card-vertical-done-cero(:statistic="eventsDoneCero")
        b-col(sm="3")
          statistic-card-vertical-events-by-employees(:statistic="eventsByEmployees")
        b-col(sm="3")
          statistic-card-vertical-done(:statistic="eventsDone")
          
      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.worker') }}
              th {{ $t('message.tableHeader.complete_status') }}
              th {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(worker, index) in attendeeGroup", :key="index")
              b-td {{ worker.name }}
              b-td(:class="worker.numEventsDone <= 0 ? 'text-danger' : 'text-success'") {{ worker.numEventsDone }}
              b-td
                navigation-button(@clicked="openEventHandlerSidebar(worker)")
                view-button(v-if="worker.numEventsDone > 0" variant="flat-primary" @clicked="router.push({ name: 'apps-programming-team', params: { id: worker._id } })")
       
        b-col.d-flex.justify-content-between.flex-wrap(v-if='isOnline' cols='12')
          .mb-1
            b-form-group.mb-0
              label.d-inline-block.text-sm-left.mr-50 {{ $t('message.per_page') }}
              b-form-select#perPageSelect.w-50(
                v-model='perPage' 
                size='sm' 
                :options='[5,10,15,20,25,30,40,50,100]')
          .mb-1
            
            span.text-muted {{ showingMessage }}
          b-pagination(
            v-model='currentPage' 
            :total-rows='totalEvents' 
            :per-page='perPage' 
            first-number='' 
            last-number='' 
            prev-class='prev-item' 
            next-class='next-item')
          
  
  //- Sidebar Overlay: Event Handler
  div(
    class="body-content-overlay"
    :class="{'show': isCalendarOverlaySidebarActive}"
    @click="isCalendarOverlaySidebarActive = false"
  )
  new-confirmation-handler(
    v-model="isNewConfirmationHandlerSidebarActive"
    :event="event"
    :clear-event-data="clearEventData"
    :isOnline="isOnline"
  )
</template>

<script>
import { workersSupervisedWithEvents } from "@/@core/queries/workers"
import { onMounted, ref, computed, onUnmounted, watch } from "@vue/composition-api/dist/vue-composition-api";
import { useRouter } from '@core/utils/utils'
import axios from "@axios";
import store from "@/store";
import useNotifications from "@/composables/useNotifications";
import { BTableSimple, BThead, BTr, BTd, BCard, BPagination } from "bootstrap-vue";
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import StatisticCardVerticalTotalWorkers from './charts/StatisticCardVerticalTotalWorkers.vue'
import StatisticCardVerticalEventsByEmployees from './charts/StatisticCardVerticalEventsByEmployees.vue'
import StatisticCardVerticalDoneCero from './charts/StatisticCardVerticalDoneCero.vue'
import StatisticCardVerticalDone from './charts/StatisticCardVerticalDone.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import NewConfirmationHandler from '@/views/apps/pc-team/new-confirmation-handler/NewConfirmationHandler.vue'
import calendarStoreModule from '@/views/apps/calendar/calendarStoreModule'
import axiosIns from "@/libs/axios";
import useCommon from '@/views/organization/useCommon'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BPagination,
    StatisticCardVerticalTotalWorkers,
    StatisticCardVerticalDone,
    StatisticCardVerticalDoneCero,
    StatisticCardVerticalEventsByEmployees,
    Filters,
    ViewButton,
    NavigationButton,
    Loading,
    NewConfirmationHandler,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userId = userData.worker_id != null ? userData.worker_id.$oid : null
    const userLocationsQuery = userData.locations?.map(e => ({ _id: e.value }))

    const { handleError } = useCommon()
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { dateFromObjectId, getDatesFromRange, dateDiffInDays } = useCommonDashboards()
    const { router } = useRouter()
    const events = ref([]);
    const attendeeGroup = ref([])
    const eventsDone = ref(0)
    const eventsPending = ref(0)
    const eventsTotal = ref(0)
    const eventsDoneCero = ref(0)
    const eventsByEmployees = ref("")
    const locationFilter = ref([]);
    const roleFilter = ref([]);
    const workerFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const monthFilter = ref(now.getMonth())
    const yearFilter = ref(now.getFullYear())
    const isLoading = ref(true)
    const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false
    const isOnline = computed(() => store.state.app.isOnline)
    const isNewConfirmationHandlerSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    const dataEvents = ref([])
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)

    const totalEvents = ref(0)
    const perPage = ref(10)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const statusFilter = ref(null)

    const showingMessage = computed(() => {
      const from = ((currentPage.value * perPage.value) - perPage.value) + (dataEvents.value.length ? 1 : 0)
      const to = dataEvents.value.length + ((currentPage.value * perPage.value) - perPage.value)

      return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalEvents.value })
    })

    watch([currentPage, perPage, statusFilter], () => {
      listWorkersSupervisedWithEvents()
    })

    watch([searchQuery], () => {
      currentPage.value = 1
      listWorkersSupervisedWithEvents()
    })

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // Add event listener for connectivity status detection
    onMounted(() => {
      listWorkersSupervisedWithEvents()
    })

    // UnRegister and remove event listeners on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    });

    const blankEvent = {
      worker: '',
      process: '',
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    async function listWorkersSupervisedWithEvents() {

      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)
            
      const query = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        search: searchQuery.value,
        userId: userId,
        locations: locationFilter.value,
        roles: roleFilter.value,
        workers: workerFilter.value,
        privileges: userRole,
        client_id: client_id,
        date_gte: startFilter,
        date_lt: endFilter
      }

      // Aquí se debe cambiar la query para poder traer la paginación y adaptar las funciones si corresponde
      axios
      .post("/graphql", {
        query: workersSupervisedWithEvents,
        variables: { input: query },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        const dataQuery = data?.data?.EventsForSupervisedWorkers?.events
        const insideData = dataQuery[0]?.eventsData || []
        const total = dataQuery[0]?.totalEmployees
        const employeesWithEvents = dataQuery[0]?.totalNumEmployeesWithEvents
        const doneCero = dataQuery[0]?.totalNumEventsDoneCero
        const totalEventsDone = dataQuery[0]?.totalNumEventsDone
        dataEvents.value = insideData
        parseEventData(insideData, total, employeesWithEvents, doneCero, totalEventsDone)
      })
      .catch((error) => {
        console.log(error);
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      })
      .finally(() => {
        isLoading.value = false
      })
    }

    function parseEventData(insideData, total, employeesWithEvents, doneCero, totalEventsDone) {
      // let statisticsTotal = { done: 0, pending: 0}
      // let employees = total
      // let employeesWithEventsDone = employeesWithEvents
      if (currentPage.value === 1) totalEvents.value = total
      eventsDoneCero.value = doneCero
      eventsByEmployees.value = eventsByEmployeesTransform(employeesWithEvents,total)
      eventsDone.value = totalEventsDone
      attendeeGroup.value = insideData

      // if(insideData){
      //   console.log(insideData)
      //   insideData.forEach(worker => {
      //     statisticsTotal.pending+= worker.numEventsPending
      //     statisticsTotal.done+= worker.numEventsDone
      //   });
      //   eventsTotal.value = statisticsTotal.done + statisticsTotal.pending
      //   eventsPending.value = statisticsTotal.pending

      // }
    }
    
    function eventsByEmployeesTransform (employeesWithEventsDone, employees){
      if(employees <= 0) return "0%"
      const eventsEmployees = Math.round((employeesWithEventsDone/employees)*100)
      return `${eventsEmployees}%`
    }

    const openEventHandlerSidebar = (workerData) => {
      event.value = { worker: { title: workerData.name, value: workerData._id }, process: '' }
      isNewConfirmationHandlerSidebarActive.value = true
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      workerFilter.value = data.workerFilter;
      monthFilter.value = data.monthFilter;
      yearFilter.value = data.yearFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listWorkersSupervisedWithEvents();
    }

    return {
      events,
      eventsDone,
      eventsPending,
      eventsTotal,
      updateFilter,
      userRole,
      isLoading,
      zoneAgencyFilter,
      router,
      isOnline,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      isNewConfirmationHandlerSidebarActive,
      openEventHandlerSidebar,
      attendeeGroup,
      eventsDoneCero,
      eventsByEmployees,

      // Pagination
      totalEvents,
      perPage,
      currentPage,
      searchQuery,
      statusFilter,
      showingMessage
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
