<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Metadata
        b-col(md="4")
          b-form-group(
            :label="$t('label.Metadata')",
            :label-for="$t('label.Metadata')",
            description=""
          )
            validation-provider(:name="$t('label.Metadata')", rules="required", v-slot="{ errors }")
              v-select(
                v-model="data.nameId",
                :reduce="(metadata) => metadata.value",
                label="title",
                :options="metadataTypeSelect.value",
                :placeholder="$t('placeholder.metadata')",
                :disabled="metadataFieldDisabled"
              )
              small(class="text-danger") {{ errors[0] }}

        // Option
        b-col(v-if="!uploadMode", md="4")
          b-form-group(
            :label="$t('label.Option')",
            :label-for="$t('label.Option')",
            description=""
          )
            validation-provider(:name="$t('label.Option')", rules="required", v-slot="{ errors }")
              b-form-input(
                id="option",
                v-model="data.optionSelected",
                trim,
                :placeholder="$t('placeholder.option')"
              )
              small(class="text-danger") {{ errors[0] }}

        // Upload
        b-col(v-else, md="4")
          b-form-group(
            :label="$t('label.uploadExcelFile')",
            :label-for="$t('label.uploadExcelFile')",
            description=""
          )
            b-button.mr-1(
              type="button",
              variant="info",
              v-b-tooltip.hover.v-warning.topright="$t('message.metadata_upload_file_conditions')"
              @click="openFileInput"
            )
              | {{ $t('message.upload') }}
            input(
              style="display: none",
              type="file",
              ref="inputElement",
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
              @change="onFileChange"
            )
            small(v-if="file && file.name")
              | {{ file.name }}
            small(v-else, class="text-danger")
              | {{ $t('message.upload_file_required') }}
            xlsx-read(:file="file")
              xlsx-json(@parsed="updateItemList")

      b-row.my-1(v-if="itemList.length")
        b-col(md="6")
          b-form-group(
            :label="$t('label.optionsToUpload')",
            :label-for="$t('label.uploadExcelFile')",
            description=""
          )
            vue-perfect-scrollbar(
              :settings="perfectScrollbarSettings",
              class="scroll-area"
            )
              b-list-group
                b-list-group-item(
                  v-for="(item, index) in itemList",
                  :key="index",
                  class="py-0"
                )
                  span
                    | {{ item }}

      // Button
      b-row
        b-col(
          cols="12"
          class="text-right"
        )
          b-button(
            variant="primary"
            @click="validateAndSubmit"
            :disabled="isLoading"
          )
            | {{ $t('message.submit') }}
          b-modal(
            id="modal-upload-metadata"
            ref="uploadMetadataModal"
            centered
            no-close-on-backdrop
            modal-class="modal-primary"
            ok-variant="primary"
            cancel-variant="outline-secondary"
            :title="$t('message.confirm_action')"
            :ok-title="$t('message.upload')"
            :cancel-title="$t('message.cancel')"
            @ok="submit"
          )
            | {{ $t('message.confirm_metadata_upload') }}

</template>

<script>
// eslint-disable-next-line import/extensions
import { ref, toRefs, onMounted } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import vSelect from 'vue-select'
import { XlsxRead, XlsxTable, XlsxJson } from "vue-xlsx/dist/vue-xlsx.es.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    XlsxRead,
    XlsxTable,
    XlsxJson,
    VuePerfectScrollbar,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'metadataFieldDisabled', 'isLoading', 'uploadMode'],

  setup(props, { emit }) {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { getMetadataForDropDown, metadataTypeSelect } = useCommon()
    const form = ref(null)
    const file = ref(null)
    const inputElement = ref(null)
    const itemList = ref([])
    const perfectScrollbarSettings = { maxScrollbarLength: 150 }
    const uploadMetadataModal = ref(null)

    const validateForm = () => new Promise((resolve, reject) => {
      if (props.uploadMode && !file.value?.name) reject()
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateForm()
      .then(() => {
        if (props.uploadMode) {
          props.data.itemList = itemList.value
          uploadMetadataModal.value.show()
        } else {
          submit()
        }
      })
      .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    const openFileInput = () => {
      inputElement.value.click()
    }

    const onFileChange = (event) => {
      file.value = event.target.files ? event.target.files[0] : null;
    }

    const updateItemList = (data) => {
      itemList.value = data.map(row => Object.values(row)[0])
    }

    const submit = () => {
      emit('submit', toRefs(props.data))
    }

    onMounted(async () => {
      await getMetadataForDropDown({type: "select"})
    })

    return {
      required,
      form,
      validateAndSubmit,
      metadataTypeSelect,
      openFileInput,
      file,
      inputElement,
      onFileChange,
      updateItemList,
      perfectScrollbarSettings,
      itemList,
      uploadMetadataModal,
      submit,
    }
  },
}
</script>

<style scoped>
.scroll-area {
  position: relative;
  margin: auto;
  max-width: 400px;
  max-height: 280px;
}
</style>
