import { render, staticRenderFns } from "./UserButton.vue?vue&type=template&id=45823b4d&scoped=true&"
import script from "./UserButton.vue?vue&type=script&lang=js&"
export * from "./UserButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45823b4d",
  null
  
)

export default component.exports