<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @locations="updateLocations"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!meetings_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_daily_dialogues_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="happiness_index")
        b-col(sm="3")
          statistic-card-vertical-happy(:statistic="happinessHappy")
        b-col(sm="3")
          statistic-card-vertical-neutral(:statistic="happinessNeutral")
        b-col(sm="3")
          statistic-card-vertical-sad(:statistic="happinessSad")

      b-row
        b-col(cols="12")
          bar-chart-happiness(
            v-if="happinessPerDay.labels && happinessPerDay.labels.length",
            :happiness-data="happinessPerDay"
          )

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('happiness.date') }}
              th {{ $t('happiness.start_time') }}
              th {{ $t('happiness.end_time') }}
              th {{ $t('happiness.created_by') }}
              th {{ $t('happiness.location') }}
              th {{ $t('happiness.happy') }}
              th {{ $t('happiness.neutral') }}
              th {{ $t('happiness.sad') }}
              th {{ $t('happiness.mood') }}
              th {{ $t('happiness.image') }}
              th(v-if="userRole === 'consultant'") {{ $t('happiness.action') }}
            b-tr(v-for="(meeting, index) in meetings", :key="index")
              b-td {{ meeting.dateString }}
              b-td {{ meeting.start_time }}
              b-td {{ meeting.end_time }}
              b-td {{ meeting.created_by }}
              b-td {{ meeting.location }}
              b-td {{ meeting.happy }}
              b-td {{ meeting.neutral }}
              b-td {{ meeting.sad }}
              b-td {{ meeting.happiness_index_text() }}
              b-td(v-if="meeting.imageKey")
                b-button(
                    size="sm",
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                    type="button",
                    variant="primary",
                    @click="openImage(meeting.imageKey)"
                  )
                    | {{ $t('message.open') }}
              b-td(v-else) {{ $t('happiness.no_image') }}
              b-td(v-if="userRole === 'consultant'")
                delete-button(v-b-modal="`modal ${index}`")
                b-modal(
                  :id="`modal ${index}`",
                  centered,
                  no-close-on-backdrop,
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')",
                  :ok-title="$t('message.delete')",
                  :cancel-title="$t('message.cancel')",
                  @ok="deleteMeeting(meeting.id, deletedSuccess, meeting.improvements, meeting.recognitions)",
                )
                  | {{ $t('message.confirm_delete_daily_dialogue') }}

      b-button(:disabled="!meetings.length")
        json-excel(:data="meetings", :fields="excelFields")
          | {{ $t('download_xlsx') }}
  </template>
  
  <script>
  import { getMeetings, updateMeeting } from "@/@core/queries/meeting";
  import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api";
  import axios from "@axios";
  import store from "@/store";
  import useNotifications from "@/composables/useNotifications";
  import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BModal, VBModal } from "bootstrap-vue";
  import JsonExcel from "vue-json-excel";
  import StatisticCardVerticalHappy from './charts/StatisticCardVerticalHappy.vue'
  import StatisticCardVerticalNeutral from './charts/StatisticCardVerticalNeutral.vue'
  import StatisticCardVerticalSad from './charts/StatisticCardVerticalSad.vue'
  import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
  import BarChartHappiness from './charts/ChartjsBarChartHappiness.vue'
  import Filters from "@/views/organization/Filters.vue";
  import i18n from '@/libs/i18n'
  import Ripple from 'vue-ripple-directive'
  import awsConnection from '@/views/habit/aws';
  import endpoints from '@/libs/endpoints'
  import useCommonDashboards from '@/views/habit/useCommonDashboards'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
  import { updateImprovement } from "@/@core/queries/improvements";
  import { updateRecognition } from "@/@core/queries/recognitions";
  import useCommon from '@/views/organization/useCommon'

  export default {
    components: {
      BTable,
      BTableSimple,
      BThead,
      BTr,
      BTd,
      BCard,
      BModal,
      Filters,
      JsonExcel,
      StatisticCardVerticalHappy,
      StatisticCardVerticalNeutral,
      StatisticCardVerticalSad,
      StatisticCardVerticalIndex,
      BarChartHappiness,
      Loading,
      DeleteButton,
    },
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
    setup() {
      const userData = store.state?.userStore?.userData;
      const userRole = userData.role;
      const client_id = userRole !== "admin" ? userData.client.$oid : null;
      const userLocations = userData.locations?.length ? userData.locations : null;
      const { showSuccessMessage, showErrorMessage } = useNotifications();
      const { handleError } = useCommon()
      const { getFile } = awsConnection()
      const { getDatesFromRange, happinessIndex } = useCommonDashboards()
      const meetings = ref([]);
      const meetings_total = ref(0);
      const happiness_index = ref(0)
      const happinessPerDay = ref({})
      const happinessHappy = ref(0)
      const happinessNeutral = ref(0)
      const happinessSad = ref(0)
      // const compliance = ref(0)
      const locations = localStorage.locations ? ref(JSON.parse(localStorage.locations)) : ref([])
      const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
      const zoneFilter = ref([]);
      const agencyFilter = ref([]);
      const locationDialogues = ref({})
      const locationHappiness = ref({})
      const now = new Date()
      const currentMonth = now.getMonth()
      const currentYear = now.getFullYear()
      const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
      const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
      const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
      const isLoading = ref(true)
      const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
      const excelFields = ref({
        [i18n.t('happiness.date')]: "dateString",
        [i18n.t('happiness.start_time')]: "start_time",
        [i18n.t('happiness.end_time')]: "end_time",
        [i18n.t('happiness.created_by')]: "created_by",
        [i18n.t('happiness.location')]: "location",
        [i18n.t('happiness.happy')]: "happy",
        [i18n.t('happiness.neutral')]: "neutral",
        [i18n.t('happiness.sad')]: "sad",
        [i18n.t('happiness.mood')]: "happiness_index",
        [i18n.t('happiness.id')]: "id",
      })
  
      async function listMeetings() {
        isLoading.value = true
        const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)
  
        let conditions = {
          client_id: { _id: client_id },
          deleted_ne: true,
          date_gte: startFilter,
          date_lt: endFilter,
        };
  
        if (locationFilter.value.length > 0) {
          conditions.location = {_id_in: locationFilter.value}
        }
  
        if (zoneFilter.value.length > 0) {
          if (conditions.location) conditions.location.zone_in = zoneFilter.value
          else conditions.location = {zone_in: zoneFilter.value}
        }
  
        if (agencyFilter.value.length > 0) {
          if (conditions.location) conditions.location.agency_in = agencyFilter.value
          else conditions.location = {agency_in: agencyFilter.value}
        }
  
        axios
          .post("/graphql", {
            query: getMeetings,
            variables: { query: conditions, limit: 10000, sortBy: "DATE_ASC"},
          })
          .then(({ data }) => {
            if (data.errors) throw new Error(data.errors[0].message)
            parseMeetingData(data, startFilter, endFilter)
          })
          .catch((error) => {
            console.log(error);
            handleError({ error, defaultMessage: i18n.t('message.err_daily_dialogues_list') })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
  
      function parseMeetingData(data, startFilter, endFilter) {
        let dailyHappinessData = {}
        let dynamicDate = startFilter

        // Change the dates so that it matches the UTC time zone
        const timezoneOffsetHours = dynamicDate.getTimezoneOffset() / 60
        dynamicDate.setHours(dynamicDate.getHours() + timezoneOffsetHours)
        endFilter.setHours(endFilter.getHours() + timezoneOffsetHours)

        while (dynamicDate < endFilter) {
          const dateToAdd = dynamicDate.toLocaleDateString(`${default_language || 'en'}-US`)
          dailyHappinessData[dateToAdd] = { happy: 0, neutral: 0, sad: 0 }
          dynamicDate = new Date(dynamicDate.getFullYear(), dynamicDate.getMonth(), dynamicDate.getDate() + 1)
        }

        let meetings_data = []
        let happiness_data = { happy: 0, neutral: 0, sad: 0 }
        let locationData = {}

        for (const meeting of data.data.meetings) {
          const date = new Date(meeting.date)
          date.setHours(date.getHours() + timezoneOffsetHours)
          const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)
          meetings_data.push({
              date,
              dateString,
              start_time: meeting.start,
              end_time: meeting.end,
              created_by: meeting.created_by?.name,
              location: meeting.location?.location,
              happy: meeting.happy,
              neutral: meeting.neutral,
              sad: meeting.sad,
              happiness_index: happinessIndex(meeting.happy, meeting.neutral, meeting.sad),
              happiness_index_text: function() {
                if (!this.happy && !this.neutral && !this.sad) return "0%"
                return `${this.happiness_index}%`
              },
              imageKey: meeting.imageKey,
              improvements: meeting.improvements,
              recognitions: meeting.recognitions,
              id: meeting._id,
          });
          happiness_data.happy += meeting.happy
          happiness_data.neutral += meeting.neutral
          happiness_data.sad += meeting.sad
  
          // Data for bar chart
          dailyHappinessData[dateString].happy += meeting.happy
          dailyHappinessData[dateString].neutral += meeting.neutral
          dailyHappinessData[dateString].sad += meeting.sad
        }

        // Global values
        meetings.value = meetings_data
        meetings_total.value = meetings_data.length
        happinessHappy.value = happiness_data.happy
        happinessNeutral.value = happiness_data.neutral
        happinessSad.value = happiness_data.sad
        happiness_index.value = (!happiness_data.happy && !happiness_data.neutral && !happiness_data.sad)
          ? "-"
          : `${happinessIndex(happiness_data.happy, happiness_data.neutral, happiness_data.sad)}%`

        // Bar chart
        let dailyHappinessLabels = Object.keys(dailyHappinessData)
        let dailyHappinessValues = []

        for (const date in dailyHappinessData){
          dailyHappinessValues.push([dailyHappinessData[date].happy, dailyHappinessData[date].neutral, dailyHappinessData[date].sad])
        }

        happinessPerDay.value = {
          labels: dailyHappinessLabels,
          data: dailyHappinessValues,
        };
      }
      
      function updateFilter(data) {
        locationFilter.value = data.locationFilter;
        dateRangeFilter.value = data.dateRangeFilter;
        zoneFilter.value = data.zoneFilter;
        agencyFilter.value = data.agencyFilter;
        
        listMeetings();
      }
  
      function updateLocations(data) {
        if (!locations.value.length) {
          locations.value = data;
          listMeetings();
        }
      }
  
      const openImage = async (imageKey) => {
        let w = window.open("");
        w.document.write(`<h2>${i18n.t('message.loading_image')}</h2>`);
        try {
          const imgFromAWS = await getFile(endpoints.aws_bucket, imageKey)
          let image = new Image();
          image.src = imgFromAWS;
  
          w.document.body.innerHTML = "";
          w.document.write(image.outerHTML);
        } catch (error) {
          showErrorMessage(i18n.t('message.err_open_image'))
        }
      }
  
      const deletedSuccess = (success) => {
        if (success) {
          listMeetings();
          showSuccessMessage(i18n.t('message.daily_dialogue_remove'));
        } else {
          showErrorMessage(i18n.t('message.daily_dialogue_remove_error'));
        }
      };
  
      const deleteMeeting = (id, callback, improvements, recognitions) => {
        axios
          .post('/graphql', {
            query: updateMeeting,
            variables: { query: { _id: id }, data: { deleted: true } }
          })
          .then(() => {
            callback(true)
  
            // Delete improvements associated with daily dialogue
            if (improvements?.length) {
              improvements.forEach(e => {
                axios
                  .post('/graphql', {
                    query: updateImprovement,
                    variables: { query: { _id: e._id }, data: { deleted: true } }
                  })
                  .then(() => {})
                  .catch((error) => {
                    console.log(error)
                  })
              });
            }
  
            // Delete recognitions associated with daily dialogue
            if (recognitions?.length) {
              recognitions.forEach(e => {
                axios
                  .post('/graphql', {
                    query: updateRecognition,
                    variables: { query: { _id: e._id }, data: { deleted: true } }
                  })
                  .then(() => {})
                  .catch((error) => {
                    console.log(error)
                  })
              });
            }
          })
          .catch(() => {
            callback(false)
          })
      }
  
      onMounted(() => {
        listMeetings()
      });
  
      return {
        meetings,
        meetings_total,
        happiness_index,
        // compliance,
        updateFilter,
        updateLocations,
        excelFields,
        openImage,
        locationDialogues,
        locationHappiness,
        isLoading,
        zone_agency_filter,
        userRole,
        deletedSuccess,
        deleteMeeting,
        happinessPerDay,
        happinessHappy,
        happinessNeutral,
        happinessSad,
      };
    },
  };
  </script>
  