import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import { updateCrime, _getCrimes } from '@/@core/queries/crimes';
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'
import axios from '@axios'

export default function useCrimeList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { handleError } = useCommon()
  const refCrimeListTable = ref(null)
  const crimes = ref([])
  const userData = store.state?.userStore?.userData

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'name',
        label: i18n.t('message.tableHeader.name'),
        sortable: true,
      },
      {
        key: 'lawNumber',
        label: i18n.t('label.lawNumber'),
        sortable: true,
      },
      {
        key: 'law',
        label: i18n.t('label.lawName'),
        sortable: true,
      },
      {
        key: 'article',
        label: i18n.t('label.article'),
        sortable: true,
      },
      {
        key: 'number',
        label: i18n.t('label.number'),
        sortable: true,
      },
      {
        key: 'description',
        label: i18n.t('message.tableHeader.description'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions'),
      },
    ]
  })

  const perPage = ref(10)
  const totalCrimes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const isLoading = ref(true)

  const fetchCrimes = () => {
    isLoading.value = true

    const input = {
      limit: perPage.value,
      total: currentPage.value === 1,
      offset: (currentPage.value * perPage.value) - perPage.value,
      query: {},
      search: searchQuery.value,
      sortBy: sortBy.value,
      sortOrder: isSortDirDesc.value ? -1 : 1,
      client_id: ( userData.role != "admin" ) ? userData.client.$oid : null
    }

    axios
      .post('/graphql', {
        query: _getCrimes,
        variables: { input },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        const { CrimesData: { crimes: c, totalRecords } } = data.data || {}
        crimes.value = c
        if (currentPage.value === 1) totalCrimes.value = totalRecords
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.crime_list_fetch_error') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteCrime = (id) => {
    axios
      .post('/graphql', {
        query: updateCrime,
        variables: { query: { _id: id }, data: { deleted: true } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.crime_remove'))
        fetchCrimes()
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.something_went_wrong'))
      })
  }


  watch([currentPage, perPage, statusFilter], () => {
    fetchCrimes()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchCrimes()
  })

  return {
    fetchCrimes,
    tableColumns,
    perPage,
    currentPage,
    totalCrimes,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCrimeListTable,
    crimes,
    statusFilter,
    deleteCrime,
    isLoading,
  }
}