<template lang="pug">
div
  Form(:data="location", :isSubmitting="isSubmitting", @submit="update")
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useLocationEdit from './useLocationEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { show, update, location, isSubmitting } = useLocationEdit()

    onMounted(show)

    return {
      location,
      update,
      isSubmitting,
    }
  },
}
</script>
