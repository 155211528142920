import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { queryCasesData } from '@/@core/queries/cases'
import { updateImprovement } from '@/@core/queries/improvements'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import axios from '@axios'
import { EventBus } from "@/views/habit/EventBus.js";

export default function useCaseList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { client, worker_id, role } = store.state?.userStore?.userData
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
  const refCaseTable = ref(null)
  const cases = ref([])
  const caseStats = ref({})
  const perPage = ref(10)
  const totalCases = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)
  const workerFilter = ref([])
  const activeState = ref('total')
  const activeStage = ref(null)
  const pendingTasksFilter = ref(false)
  const statesData = ref({})
  const stagesData = ref({})
  const workersData = ref({})
  const clearFiltersDisabled = computed(() => !searchQuery.value && activeState.value === 'total' && !activeStage.value && !workerFilter.value?.length && !pendingTasksFilter.value)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'note',
        label: i18n.t('label.Title'),
        sortable: true,
      },
      {
        key: 'workerName',
        label: i18n.t('label.registration_responsible'),
        sortable: true,
      },
      {
        key: 'dueDate',
        label: i18n.t('label.due_date'),
        sortable: true,
      },
      {
        key: 'stage',
        label: i18n.t('improvement_opp.stage'),
        sortable: true,
      },
      {
        key: 'state',
        label: i18n.t('improvement_opp.state'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions'),
      },
    ]
  })

  const fetchCases = () => {
    isLoading.value = true

    const input = {
      client_id: client.$oid,
      workers: workerFilter.value,
      search: searchQuery.value,
      sortBy: sortBy.value,
      sortOrder: isSortDirDesc.value ? -1 : 1,
      limit: perPage.value,
      offset: (currentPage.value * perPage.value) - perPage.value,
      state: activeState.value,
      stage: activeStage.value,
      onlyPendingTasks: pendingTasksFilter.value,
      workerId: worker_id?.$oid,
      privileges: role
    }

    axios
      .post('/graphql', {
        query: queryCasesData,
        variables: { input },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)

        const { CasesData: { casesPaginated, casesStats } } = data.data || {}

        casesPaginated.forEach(e => {
          // Format date
          const date = e.dueDate ? new Date(e.dueDate) : undefined
          e.dueDate = date?.toLocaleDateString(`${default_language || 'en'}-US`) || ''

          // Set state
          e.stateComputed = computed(() => i18n.t(e.state))

          // Set stage
          e.stageComputed = computed(() => e.stage ? i18n.t(`caseStages.${e.stage}`) : '')
        })

        cases.value = casesPaginated
        caseStats.value = casesStats.stateCounts.statsForCards
        totalCases.value = casesStats.totalCount
        statesData.value = {
          labels: casesStats.stateCounts.labels,
          values: casesStats.stateCounts.values,
          total: casesStats.totalCount
        }
        stagesData.value = {
          labels: casesStats.stageCounts.labels,
          values: casesStats.stageCounts.values,
          total: casesStats.totalCount
        }
        workersData.value = {
          labels: casesStats.assigneeCounts.labels,
          values: casesStats.assigneeCounts.values,
          workerIds: casesStats.assigneeCounts.workerIds,
        }
      })
      .catch(error => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_improvement_opportunities_list') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteCase = (id) => {
    axios
      .post('/graphql', {
        query: updateImprovement,
        variables: { query: { _id: id }, data: { deleted: true, lastModifiedBy: worker_id?.$oid ? { link: worker_id.$oid } : null } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.improvement_remove'))
        fetchCases()
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_remove_error'))
      })
  }

  const updateFilter = (data) => {
    workerFilter.value = data.workerFilter
    pendingTasksFilter.value = data.pendingTasksFilter
    
    checkPageAndFetch()
  }

  const filterByStage = (stage) => {
    activeStage.value = activeStage.value === stage ? null : stage
    
    checkPageAndFetch()
  }

  const filterByState = (state, origin) => {
    if (activeState.value === state) {
      if (origin === 'fromChart') state = 'total'
      else return
    }
    activeState.value = state

    checkPageAndFetch()
  }

  const clearFilters = () => {
    const fetchData = !searchQuery.value && !workerFilter.value?.length && !pendingTasksFilter.value
    
    searchQuery.value = ''
    activeState.value = 'total'
    activeStage.value = null

    EventBus.$emit("resetFilters");

    if (!fetchData) return    // This is to avoid duplicate data fetches

    checkPageAndFetch()
  }

  const checkPageAndFetch = () => {
    if (currentPage.value === 1) fetchCases()
    else currentPage.value = 1
  }

  watch([currentPage], () => {
    fetchCases()
  })

  watch([searchQuery, sortBy, isSortDirDesc, perPage], () => {
    checkPageAndFetch()
  })

  return {
    fetchCases,
    tableColumns,
    perPage,
    currentPage,
    totalCases,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCaseTable,
    cases,
    deleteCase,
    isLoading,
    caseStats,
    updateFilter,
    filterByState,
    filterByStage,
    activeState,
    statesData,
    stagesData,
    workersData,
    clearFilters,
    clearFiltersDisabled,
  }
}
