import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws';
import endpoints from '@/libs/endpoints'
import useNotifications from '@/composables/useNotifications'

export default function useCommitmentSidebar(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const commitmentLocal = ref(JSON.parse(JSON.stringify(props.commitment.value)))
  const resetCommitmentLocal = () => {
    commitmentLocal.value = JSON.parse(JSON.stringify(props.commitment.value))
    commitmentLocal.value.description = commitmentLocal.value.description 
      ? commitmentLocal.value.description.replace(/<[^>]*>/g, '')
      : ''
    imgData.value = commitmentLocal.value.imgData || {}
  }
  watch(props.commitment, () => {
    resetCommitmentLocal()
  })

  const onSubmit = () => {
    const commitmentData = JSON.parse(JSON.stringify(commitmentLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (imgData.value.fileInfo) commitmentData.value.imgData = imgData.value
    if (props.commitmentIndex.value !== -1) emit('update-commitment', commitmentData.value, props.commitmentIndex.value)
    else {
      emit('add-commitment', commitmentData.value)
    }

    // Close sidebar
    emit('update:is-commitment-sidebar-active', false)
  }

  // Image Upload
  const userData = store.state?.userStore?.userData

  const compressor = ref(null)
  const imgData = ref({})
  const scale = ref(100)
  const quality = ref(50)
  const maxWidth = ref(640)

  const uploadImage = () => {
    compressor.value.$el.click()
  }

  const getImgCompressed = (obj) => {
    imgData.value = {
      fileInfo: obj.compressed,
      destinationFolder: `${userData.client.$oid}/improvements`
    }
  }

  const { showErrorMessage } = useNotifications()
  const { getFile } = awsConnection()


  const imageKey = ref("")
  const isLoading = ref(false)

  const openImage = async () => {
    isLoading.value = true
    let w = window.open("");
    w.document.write(`<h2>${i18n.t('message.loading_image')}</h2>`);
    try {
      const imgFromAWS = await getFile(endpoints.aws_bucket, commitmentLocal.value.imageKey)
      let image = new Image();
      image.src = imgFromAWS;

      w.document.body.innerHTML = "";
      w.document.write(image.outerHTML);
    } catch (error) {
      showErrorMessage(i18n.t('message.err_open_image'))
    } finally {
      isLoading.value = false
    }
  }

  return {
    commitmentLocal,
    resetCommitmentLocal,
    onSubmit,

    // Image upload
    uploadImage,
    getImgCompressed,
    compressor,
    imgData,
    scale,
    quality,
    maxWidth,
    openImage,
    imageKey,
    isLoading,
  }
}
