import axios from '@axios'
import { ref, set } from '@vue/composition-api'
import useNotifications from '@/composables/useNotifications'
import { getWorker } from '@/@core/queries/workers'
import { queryConfirmations } from '@/@core/queries/confirmations'
import { getRecognitionsId } from '@/@core/queries/recognitions'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import useCommonDashboards from '@/views/habit/useCommonDashboards'

export default function useProcessEdit() {
  const { showErrorMessage } = useNotifications()
  const { route } = useRouter()
  const { processAdherence, getDatesFromRange, monthsShort } = useCommonDashboards()

  const workerData = ref({})
  const confirmations = ref([]);
  const confirmations_total = ref(0);
  const adherence_index = ref(0);
  const recognitions_total = ref(0)
  const adherenceTimeSeries = ref({})
  const processFilter = ref([]);
  const now = new Date()
  const currentMonth = now.getMonth()
  const currentYear = now.getFullYear()
  const currentDay = now.getDate()
  const oneYearBeforeDate = new Date(currentYear, currentMonth, currentDay - 365)
  const dateRangeFilter = ref(`${oneYearBeforeDate.getDate()}-${oneYearBeforeDate.getMonth() + 1}-${oneYearBeforeDate.getFullYear()} to ${currentDay}-${currentMonth + 1}-${currentYear}`)
  const isLoading = ref(true)
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

  const show = async () => {
    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: getWorker,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        workerData.value = data.data.worker
        workerData.value.locations = workerData.value.locations?.map(e => e.location).join(" / ")
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.worker_fetch_error'))
      })
  }

  const getConfirmations = async () => {
    isLoading.value = true
    const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

    let conditions = {
      worker: { _id: route.value.params.id },
      date_gte: startFilter,
      date_lt: endFilter,
      pending_ne: true,
    }

    if (processFilter.value.length > 0) {
      conditions.process = {
        _id_in: processFilter.value,
      };
    }

    axios
      .post('/graphql', {
        query: queryConfirmations,
        variables: { query: conditions, limit: 10000, sortBy: "DATE_ASC" },
      })
      .then(({ data }) => {
        parseConfirmationData(data)
      })
      .catch((err) => {
        console.log(err)
        showErrorMessage(i18n.t('message.err_confirmation_list'))
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const parseConfirmationData = (data) => {
    let confirmationData = []
    let adherenceData = {score: 0, total: 0}
    let adherenceTimeSeriesLabelsSet = new Set()
    let adherenceTimeSeriesData = {}

    for (const confirmation of data.data.confirmations) {
      if (confirmation.total) {
        const date = new Date(confirmation.date)
        const process = confirmation.process?.name
        const score = confirmation.score
        const total = confirmation.total
        let payload = {
          date,
          dateString: date.toLocaleDateString(`${default_language || 'en'}-US`),
          observer: confirmation.supervisor?.name,
          process: process,
          score: score,
          total: total,
          adherence: processAdherence(score, total),
          adherenceText: function() {
            if (this.total === 0) return "-"
            return `${this.adherence}%`
          },
          id: confirmation._id,
        }
        
        confirmationData.push(payload);
        adherenceData.score += score
        adherenceData.total += total

        // Data for time series charts
        if (process) {
          const yearMonth = date.getMonth() < 10 ? `${date.getFullYear()}-0${date.getMonth()}` : `${date.getFullYear()}-${date.getMonth()}`
          adherenceTimeSeriesLabelsSet.add(yearMonth)
          if (adherenceTimeSeriesData.hasOwnProperty(process)) {
            if (adherenceTimeSeriesData[process].hasOwnProperty(yearMonth)) {
              adherenceTimeSeriesData[process][yearMonth].score += score
              adherenceTimeSeriesData[process][yearMonth].total += total
            }
            else adherenceTimeSeriesData[process][yearMonth] = {
              score: score,
              total: total
            }
          }
          else adherenceTimeSeriesData[process] = {
            [yearMonth]: {
              score: score,
              total: total
            }
          }
        }
      }
    }

    // Sort table data
    // confirmationData.sort(function (a, b) {
    //   if (b.score === 0 && a.score === 0) return a.total - b.total
    //   return b.adherence - a.adherence
    // })
    confirmations.value = confirmationData
    confirmations_total.value = confirmationData.length
    adherence_index.value = adherenceData.total === 0
      ? "-"
      : `${processAdherence(adherenceData.score, adherenceData.total)}%`

    // Sort time series chart data
    for (const process in adherenceTimeSeriesData) {
      for (const yearMonth in adherenceTimeSeriesData[process]) {
        adherenceTimeSeriesData[process][yearMonth].adherence = processAdherence(adherenceTimeSeriesData[process][yearMonth].score, adherenceTimeSeriesData[process][yearMonth].total)
      }
      let adherenceTimeSeriesDataSorted = Object.entries(adherenceTimeSeriesData[process])
      adherenceTimeSeriesDataSorted.sort(function (a, b) {
        if (a[0] < b[0]) return -1
        if (a[0] > b[0]) return 1
        return 0
      })
      adherenceTimeSeriesData[process] = adherenceTimeSeriesDataSorted
    }
    
    // Reformat labels
    for (const process in adherenceTimeSeriesData) {
      adherenceTimeSeriesData[process].forEach((e, i, arr) => {
        let label = reformatYearMonthLabel(e[0], monthsShort)
        arr[i] = {
          x: label,
          y: e[1].adherence
        }
      })
    }

    // Sort time series chart labels
    let adherenceTimeSeriesLabels = [...adherenceTimeSeriesLabelsSet]
    adherenceTimeSeriesLabels.sort()
    adherenceTimeSeriesLabels.forEach((e, i, arr) => {
      let label = reformatYearMonthLabel(e, monthsShort)
      arr[i] = label
    })

    adherenceTimeSeries.value = {
      labels: adherenceTimeSeriesLabels,
      data: adherenceTimeSeriesData,
    }
  }

  const reformatYearMonthLabel = (yearMonth, monthsShort) => {
    let [year, month] = yearMonth.split("-")
    year = year.slice(-2)
    month = monthsShort[parseInt(month)]
    return `${month}-${year}`
  }

  const getRecognitions = async () => {
    const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: getRecognitionsId,
        variables: {
          query: {
            worker: { _id: route.value.params.id },
            date_gte: startFilter,
            date_lt: endFilter,
            OR: [
              { deleted: false },
              { deleted_exists: false },
            ]
          }
        },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        recognitions_total.value = data.data?.recognitions?.length || 0
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.err_recognitions_list'))
      })
  }

  const updateFilter = (data) => {
    processFilter.value = data.processFilter
    dateRangeFilter.value = data.dateRangeFilter;

    getConfirmations()
    getRecognitions()
  }

  return {
    show,
    workerData,
    getConfirmations,
    confirmations,
    confirmations_total,
    adherence_index,
    getRecognitions,
    recognitions_total,
    updateFilter,
    adherenceTimeSeries,
    isLoading,
  }
}
