export const getMetadata = `query metadata($query: MetadatumQueryInput, $limit: Int, $sortBy: MetadatumSortByInput){
  metadata(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    name
    type
    options
  }
}`

export const updateMetadata = `mutation updateOneMetadatum($query: MetadatumQueryInput!, $data: MetadatumUpdateInput!) {
  updateOneMetadatum(query: $query, set: $data) {
    _id
  }
}
`

export const getSingleMetadata = `query metadatum($query: MetadatumQueryInput){
  metadatum(query: $query) {
    _id
    name
    options
  }
}`