import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { _getPendingConfirmations, deleteConfirmation } from "@/@core/queries/confirmations";
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'

export default function usePendingConfirmationsList() {
  const { role = null, worker_id = null } = store.state?.userStore?.userData
  const { showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const refPendingConfirmationsListTable = ref(null)
  const pendingConfirmations = ref([])
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'date', label: i18n.t('message.tableHeader.date') },
      { key: 'workerName.name', label: i18n.t('message.tableHeader.worker'), sortable: true },
      { key: 'processName.name', label: i18n.t('message.tableHeader.process'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const totalPendingConfirmations = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const index = () => {
    isLoading.value = true
    store
      .dispatch('organization-process/index', {
        query: _getPendingConfirmations,
        variables: {
          input: {
            limit: perPage.value,
            total: currentPage.value === 1,
            offset: (currentPage.value * perPage.value) - perPage.value,
            search: searchQuery.value,
            sortBy: sortBy.value || 'date',
            sortOrder: isSortDirDesc.value ? -1 : 1,
            worker_id: (role !== 'admin') ? worker_id.$oid : null,
          },
        },
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        let {
          PendingConfirmationsData: {
            pendingConfirmations: p,
            totalRecords: total,
          },
        } = response.data.data || {}
        p.forEach(e => {
          if (e.date) e.date = new Date(e.date).toLocaleDateString(`${default_language || 'en'}-US`)
        })
        pendingConfirmations.value = p
        if (currentPage.value === 1) totalPendingConfirmations.value = total
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_pending_confirmations') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deletePendingConfirmation = (id, callback) => {
    store
      .dispatch('organization-process/delete', {
        query: deleteConfirmation,
        variables: { query: { _id: id } },
      })
      .then(() => {
        callback(true)
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.pending_confirmation_remove_error'))
      })
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (pendingConfirmations.value.length ? 1 : 0)
    const to = pendingConfirmations.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalPendingConfirmations.value })
  })

  watch([currentPage, perPage], () => {
    index()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    index()
  })

  return {
    index,
    tableColumns,
    perPage,
    currentPage,
    totalPendingConfirmations,
    showingMessage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    pendingConfirmations,
    refPendingConfirmationsListTable,
    deletePendingConfirmation,
    isLoading,
  }
}
