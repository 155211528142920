import { post as $httpPost } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {
    getSystemRoles: state => state.roles,
  },
  mutations: {},
  actions: {
    fetchAudiences(ctx, ...query) {
      return new Promise((resolve, reject) => {
        $httpPost(...query).then(resolve).catch(reject)
      })
    },
    createAudience(ctx, ...query) {

      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    deleteAudience(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    showAudience(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    updateAudience(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    uploadDocument(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
  },
}