export const queryCasesData = `query CasesData($input: CasesDatum!) {
    CasesData(input: $input) {
        casesPaginated {
            _id
            note
            dueDate
            workerName
            state
            stage
            isCompletedOverdue
        }
        casesStats {
            stateCounts {
                labels
                values
                statsForCards {
                    completed
                    delayed
                    upToDate
                }
            }
            stageCounts {
                labels
                values
            }
            assigneeCounts {
                labels
                values
                workerIds
            }
            totalCount
        }
    }
  }
  `