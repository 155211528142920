import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getDocument, updateDocument } from '@/@core/queries/documents'
import i18n from '@/libs/i18n'

export default function useDocumentEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const document = ref({})
  const isSubmitting = ref(false)

  async function show() {
    isSubmitting.value = true
    store
      .dispatch('processStoreModule/show', {
        query: getDocument,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        document.value = data.data.document
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.document_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  function update(data) {
    isSubmitting.value = true
    const payload = {}
    if (data.name?.value) payload.name = data.name.value

    store
      .dispatch('processStoreModule/update', {
        query: updateDocument,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.document_updated'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.document_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-document-list' })
        isSubmitting.value = false
      })
  }

  return {
    update,
    show,
    document,
    isSubmitting,
  }
}
