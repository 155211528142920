<template>
  <div>
    <Form :isSubmitting="isSubmitting" @submit="storeData" />
  </div>
</template>

<script>
import Form from '../shared/Form.vue'
import useProcessAdd from './useProcessAdd'

export default {
  components: {
    Form,
  },
  setup() {
    const { storeData, isSubmitting } = useProcessAdd()

    return {
      storeData,
      isSubmitting,
    }
  },

}
</script>
