import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { addRole, insertDefaultPayLoad } from '@/@core/queries/roles'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'

export default function useRoleAdd() {
  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const isSubmitting = ref(false)

  const storeData = async payload => {
    isSubmitting.value = true

    // eslint-disable-next-line no-param-reassign
    payload.client_id = (userData.role !== 'admin') ? { link: userData.client.$oid } : null
    payload.subroles = { link: payload.subroles }

    store
      .dispatch('roleStoreModule/store', {
        query: addRole,
        variables: { data: Object.assign(insertDefaultPayLoad, payload) },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.form_success'))
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.role_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-role-list' })
        isSubmitting.value = false
      })
  }

  return {
    storeData,
    isSubmitting,
  }
}
