import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getSingleMetadata, updateMetadata } from '@/@core/queries/metadata'
import i18n from '@/libs/i18n'

export default function useMetadataAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const singleMetadata = ref({})
  const isLoading = ref(false)

  function create(data) {
    isLoading.value = true
    // Get metadata options
    store
      .dispatch('metadataStoreModule/show', {
        query: getSingleMetadata,
        variables: { query: { _id: data.nameId.value } },
      })
      .then(response => {
        const metadataOptionsSet = new Set(response?.data?.data?.metadatum?.options)
        metadataOptionsSet.add(data.optionSelected.value)
        const metadataOptions = Array.from(metadataOptionsSet)

        // Update metadata options with new option added
        store
          .dispatch('metadataStoreModule/update', {
            query: updateMetadata,
            variables: { query: { _id: data.nameId.value }, data: { options: metadataOptions } },
          })
          .then(() => {
            showSuccessMessage(i18n.t('message.metadata_option_added'))
          })
          .catch(() => {
            showErrorMessage(i18n.t('message.metadata_option_add_error'))
          })
          .finally(() => {
            router.push({ name: 'organization-metadata-list' })
            isLoading.value = false
          })
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.metadata_option_add_error'))
        router.push({ name: 'organization-metadata-list' })
        isLoading.value = false
      })
  }

  return {
    create,
    singleMetadata,
    isLoading
  }
}
