import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useBehaviourEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const behaviour = ref({})
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'behaviour'

  const show = async () => {
    isSubmitting.value = true

    try {
      const item = await getItem({ collection, query })
      behaviour.value = item
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.behaviour_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async (data) => {
    isSubmitting.value = true

    try {
      const payload = {
        name: data.name.value,
        type: data.type.value,
      }

      if (payload.type === 'bool') payload.expectedAnswer = data.expectedAnswer.value

      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.behaviour_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.behaviour_update_error'))
    } finally {
      router.push({ name: 'organization-behaviour-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    behaviour,
    isSubmitting,
  }
}
