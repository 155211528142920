<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          :label="$t('label.subroles')"
          :label-for="$t('label.subroles')"
          :description="$t('message.subroles_rol')"
        >
          <v-select
            v-model="subrolesData"
            :reduce="(roles) => roles.value"
            :multiple="true"
            label="title"
            :options="roles"
            :placeholder="$t('placeholder.subroles')"
            @input="sendSubroles"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { reactive, watch } from '@vue/composition-api'
import _ from 'lodash'
import i18n from '@/libs/i18n'
import useCommon from "@/views/organization/useCommon";
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api/dist/vue-composition-api'

export default {
  name: i18n.t('label.subroles'),

  components: {
    vSelect,
  },

  props: ['subroles'],

  setup(props, { emit }) {

    const subrolesData = ref([])

    watch(() => props.subroles, subroles => {
      if (subroles) subrolesData.value = subroles
    }, { deep: true, immediate: true })

    const sendSubroles = () => {
        emit("input", subrolesData.value)
    }

    const { getRolesForDropDown, roles } = useCommon()

    onMounted(async () => {
      await getRolesForDropDown()
    })

    return {
        roles,
        subrolesData,
        sendSubroles,
    }
  },
}
</script>

<style scoped>

</style>
