import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BCard,
  BFormSelect,
  BFormDatepicker
} from 'bootstrap-vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-button', BButton)
Vue.component('b-form', BForm)
Vue.component('b-card', BCard)
Vue.component('b-form-select', BFormSelect)
