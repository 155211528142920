export const insertDefaultPayLoad = {
  deleted:false
}


export const addAudience = `mutation insertOneAudience($data:  AudienceInsertInput!){
    insertOneAudience(data: $data) {
      _id
    }
  }`

export const queryAudiences = `query audiences($query: AudienceQueryInput, $limit: Int, $sortBy: AudienceSortByInput){
  audiences(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    institution
    institutionServant
    topic
    date
    deleted
  }
}`
  export const _getAudiences = `query AudiencesData($input: AudiencesDatum!){
  AudiencesData(input: $input) {
      totalRecords
      audiences {
        _id
        institution
        institutionServant
        topic
        date
      }
  }
}`;

export const getAudience = `query getAudience($query: AudienceQueryInput){
  audience(query: $query) {
    _id
    institution
    institutionServant
    topic
    date
    documentKey
  }
}`

export const updateAudience = `mutation updateOneAudience($query: AudienceQueryInput!, $data: AudienceUpdateInput!) {
  updateOneAudience(query: $query, set: $data) {
    _id
  }
}
`