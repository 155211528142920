<template lang="pug">
#filters-wrapper
  b-row
    //- Pending tasks
    b-col(md="4", class="pb-2 d-flex align-items-center", v-if="pendingTasksFilterShow")
      b-form-checkbox(
        id="checkbox-1"
        v-model="pendingTasksFilter"
        name="checkbox-1"
        :value="true"
        :unchecked-value="false"
      )
        | {{ $t('label.filterByPendingTasks') }}

    //- Zone
    b-col(md="4", class="pb-2", v-if="zoneFilterShow")
      v-select(
        v-model="zoneFilter",
        :multiple="true",
        :options="metadataFilterOptions.zone",
        :placeholder= "$t('zone_filter')"
      )

    //- Agency
    b-col(md="4", class="pb-2", v-if="agencyFilterShow")
      v-select(
        v-model="agencyFilter",
        :multiple="true",
        :options="metadataFilterOptions.agency",
        :placeholder= "$t('agency_filter')"
      )

    //- Location
    b-col(md="4", class="pb-2", v-if="locationFilterShow")
      v-select(
        v-model="locationFilter",
        :reduce="(locations) => locations.value",
        :multiple="true",
        label="title",
        :options="locations",
        :placeholder= "$t('location_filter')"
      )

    //- Role
    b-col(md="4", class="pb-2", v-if="roleFilterShow")
      v-select(
        v-model="roleFilter",
        :reduce="(roles) => roles.value",
        :multiple="true",
        label="title",
        :options="roles",
        :placeholder="$t('role_filter')",
      )

    //- Process
    b-col(md="4", class="pb-2", v-if="processFilterShow")
      v-select(
        v-model="processFilter",
        :reduce="(processes) => processes.value",
        :multiple="true",
        label="title",
        :options="processes",
        :placeholder="$t('process_filter')",
      )

    //- Worker
    b-col(md="4", class="pb-2", v-if="workerFilterShow")
      v-select(
        v-model="workerFilter",
        :reduce="(workersSupervised) => workersSupervised.value",
        :multiple="true",
        label="title",
        :options="workersSupervised",
        :placeholder="$t('worker_filter')",
      )

    //- Supervisor
    b-col(md="4", class="pb-2", v-if="supervisorFilterShow")
      v-select(
        v-model="supervisorFilter",
        :reduce="(supervisors) => supervisors.value",
        :multiple="true",
        label="title",
        :options="supervisors",
        :placeholder="$t('supervisor_filter')",
      )

    //- Observer
    b-col(md="4", class="pb-2", v-if="observerFilterShow")
      v-select(
        v-model="observerFilter",
        :reduce="(supervisors) => supervisors.value",
        :multiple="true",
        label="title",
        :options="supervisors",
        :placeholder="$t('observer_filter')",
      )

    //- Creator
    b-col(md="4", class="pb-2", v-if="creatorFilterShow")
      v-select(
        v-model="creatorFilter",
        :reduce="(supervisors) => supervisors.value",
        :multiple="true",
        label="title",
        :options="supervisors",
        :placeholder="$t('creator_filter')",
      )

    //- Month
    b-col(md="4", class="pb-2", v-if="monthFilterShow")
      v-select(
        v-model="monthFilter",
        :reduce="(months) => months.value",
        label="title",
        :options="months",
        :placeholder="$t('month_filter')",
      )

    //- Year
    b-col(md="4", class="pb-2", v-if="yearFilterShow")
      v-select(
        v-model="yearFilter",
        :options="years",
        :placeholder="$t('year_filter')",
      )

    //- Date range
    b-col(:md="resetDateButtonShow ? '3' : '4'", :class="`pb-2 ${resetDateButtonShow ? 'pr-0' : ''}`", v-if="dateRangeFilterShow")
      flat-pickr(
        v-model="dateRangeFilter",
        class="form-control",
        :config="datePickerConfig",
        :placeholder="resetDateButtonShow ? $t('due_date_filter'): ''"
      )
    b-col(md="1", class="pb-2 pl-0", v-if="resetDateButtonShow")
      delete-button(@clicked="resetDate", v-b-tooltip.hover.v-danger.top="$t('reset_date_filter')")

    
    //- Metadata of type 'select'
    b-col(md="4", class="pb-2", v-if="metadataFilterShow")
      v-select(
        v-model="metadataFilter",
        :reduce="(metadata) => metadata.value",
        label="title",
        :options="metadataTypeSelect.value",
        :placeholder="$t('metadata_filter')",
      )

    //- Metadata filters for continuous improvement tab
    //- Instance
    b-col(md="4", class="pb-2", v-if="instanceFilterShow")
      v-select(
        v-model="instanceFilter",
        :multiple="true",
        :options="optionsMetadata.instance",
        :placeholder="$t('instance_filter')",
      )
    //- Instance Leader
    b-col(md="4", class="pb-2", v-if="instanceLeaderFilterShow")
      v-select(
        v-model="instanceLeaderFilter",
        :multiple="true",
        :options="optionsMetadata.instance_leader",
        :placeholder="$t('instance_leader_filter')",
      )
    //- Zone
    b-col(md="4", class="pb-2", v-if="zoneImprovementFilterShow")
      v-select(
        v-model="zoneImprovementFilter",
        :multiple="true",
        :options="optionsMetadata.zone",
        :placeholder="$t('zone_filter')",
      )
</template>

<script>
import { onMounted, ref, watch, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import useCommon from '@/views/organization/useCommon'
import i18n from "@/libs/i18n";
import flatPickr from "vue-flatpickr-component";
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
import { VBTooltip, BFormCheckbox } from 'bootstrap-vue'
import store from '@/store'
import { EventBus } from "@/views/habit/EventBus.js";

export default {
  components: {
    vSelect,
    flatPickr,
    DeleteButton,
    BFormCheckbox
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    locationFilterShow: {
      type: Boolean,
      default: false
    },
    roleFilterShow: {
      type: Boolean,
      default: false
    },
    processFilterShow: {
      type: Boolean,
      default: false
    },
    workerFilterShow: {
      type: Boolean,
      default: false
    },
    supervisorFilterShow: {
      type: Boolean,
      default: false
    },
    observerFilterShow: {
      type: Boolean,
      default: false
    },
    creatorFilterShow: {
      type: Boolean,
      default: false
    },
    monthFilterShow: {
      type: Boolean,
      default: false
    },
    yearFilterShow: {
      type: Boolean,
      default: false
    },
    pendingTasksFilterShow: {
      type: Boolean,
      default: false
    },
    dateRangeFilterShow: {
      type: Boolean,
      default: false
    },
    calendarMonth: {
      type: Number,
    },
    calendarYear: {
      type: Number,
    },
    dateRangeDaysLimit: {
      type: Number,
      default: 90
    },
    resetDateButtonShow: {
      type: Boolean,
      default: false
    },
    instanceFilterShow: {
      type: Boolean,
      default: false
    },
    instanceLeaderFilterShow: {
      type: Boolean,
      default: false
    },
    zoneImprovementFilterShow: {
      type: Boolean,
      default: false
    },
    zoneFilterShow: {
      type: Boolean,
      default: false
    },
    agencyFilterShow: {
      type: Boolean,
      default: false
    },
    metadataFilterShow: {
      type: Boolean,
      default: false
    },
    locationDefaultValue: {
      type: Boolean,
      default: false
    },
    workerDefaultValue: {
      type: String,
      default: ""
    }
  },

  setup(props, { emit }) {
    
    const {
      getRolesForDropDown, roles, getSupervisorsForDropDown, supervisors, getLocationsForDropDown, locations, getProcessesForDropDown, processes, getWorkersSupervisedForDropDown, workersSupervised, getMetadataForDropDown, metadataFilterOptions, metadataTypeSelect
    } = useCommon()

    const userData = store.state?.userStore?.userData;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const locationFilter = ref(props.locationDefaultValue && userLocations ? userLocations.map(e => e.value) : [])
    const roleFilter = ref([])
    const processFilter = ref([])
    const workerFilter = ref(props.workerDefaultValue ? [props.workerDefaultValue] : [])
    const supervisorFilter = ref([])
    const observerFilter = ref([])
    const creatorFilter = ref([])
    const instanceFilter = ref([])
    const instanceLeaderFilter = ref([])
    const zoneImprovementFilter = ref([])
    const isDatePickerOpen = ref(false)
    const optionsMetadata = computed(() => store.state["app-todo"].optionsMetadata)
    const zoneFilter = ref([])
    const agencyFilter = ref([])
    const metadataFilter = ref("")
    const pendingTasksFilter = ref(false)
    
    const datePickerConfig = ref({
      mode: "range",
      dateFormat: default_language === "en" ? "m-d-Y" : "d-m-Y",
      minDate: null,
      maxDate: null,
      onOpen: function(selectedDates, dateStr, instance) {
        isDatePickerOpen.value = true
      },
      onClose: function(selectedDates, dateStr, instance) {
        isDatePickerOpen.value = false
      },
      onChange: function(selectedDates, dateStr, instance) {
        if (!isDatePickerOpen.value) emitChangeFilter()
      },
      errorHandler: function(error) {
        // Don't show error message in console if date has been cleared
        if (dateRangeFilter.value) console.log(error)
      }
    })

    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const currentDay = now.getDate()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const oneYearBeforeDate = new Date(currentYear, currentMonth, currentDay - 365)

    const months = computed(() => {
      return [
        { title: i18n.t('month.january'), value: 1 },
        { title: i18n.t('month.february'), value: 2 },
        { title: i18n.t('month.march'), value: 3 },
        { title: i18n.t('month.april'), value: 4 },
        { title: i18n.t('month.may'), value: 5 },
        { title: i18n.t('month.june'), value: 6 },
        { title: i18n.t('month.july'), value: 7 },
        { title: i18n.t('month.august'), value: 8 },
        { title: i18n.t('month.september'), value: 9 },
        { title: i18n.t('month.october'), value: 10 },
        { title: i18n.t('month.november'), value: 11 },
        { title: i18n.t('month.december'), value: 12 }
      ]
    })
    
    let years = []
    for (let i = 2022; i < currentYear + 2; i++) {
      years.push(i)
    }

    const monthFilter = ref(currentMonth + 1)
    const yearFilter = ref(currentYear)
    const dateRangeFilter = props.resetDateButtonShow
      ? ref("")
      : props.dateRangeDaysLimit === 365
        ? default_language === "en"
          ? ref(`${oneYearBeforeDate.getMonth() + 1}-${oneYearBeforeDate.getDate()}-${oneYearBeforeDate.getFullYear()} to ${currentMonth + 1}-${currentDay}-${currentYear}`)
          : ref(`${oneYearBeforeDate.getDate()}-${oneYearBeforeDate.getMonth() + 1}-${oneYearBeforeDate.getFullYear()} to ${currentDay}-${currentMonth + 1}-${currentYear}`)
        : default_language === "en"
          ? ref(`${currentMonth + 1}-01-${currentYear} to ${currentMonth + 1}-${lastDayOfMonthNumber}-${currentYear}`)
          : ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)

    watch(() => props.calendarMonth, (newVal) => {
      monthFilter.value = newVal + 1
    })

    watch(() => props.calendarYear, (newVal) => {
      yearFilter.value = newVal
    })

    watch([locationFilter, roleFilter, processFilter, workerFilter, supervisorFilter, observerFilter, creatorFilter, monthFilter, yearFilter, pendingTasksFilter, instanceFilter, instanceLeaderFilter, zoneImprovementFilter, zoneFilter, agencyFilter, metadataFilter], () => {
      emitChangeFilter()
    })

    const changeDateRangeFormat = (range) => {
      if (!range) return range
      const datesArray = range.split(" ")
      const dashSeparator = datesArray[0][2] === "-" || datesArray[0][1] === "-"
      let startArray = dashSeparator ? datesArray[0].split("-") : datesArray[0].split("/")
      startArray = [startArray[1], startArray[0], startArray[2]].join(dashSeparator ? "-" : "/")
      if (!datesArray[2]) return startArray
      let endArray = dashSeparator ? datesArray[2].split("-") : datesArray[2].split("/")
      endArray = [endArray[1], endArray[0], endArray[2]].join(dashSeparator ? "-" : "/")
      return `${startArray} ${datesArray[1]} ${endArray}`
    }

    const emitChangeFilter = () => {
      emit('changeFilter', {
        locationFilter: locationFilter.value,
        roleFilter: roleFilter.value,
        processFilter: processFilter.value,
        workerFilter: workerFilter.value,
        supervisorFilter: supervisorFilter.value,
        observerFilter: observerFilter.value,
        creatorFilter: creatorFilter.value,
        monthFilter: monthFilter.value - 1,
        yearFilter: yearFilter.value,
        pendingTasksFilter: pendingTasksFilter.value,
        dateRangeFilter: default_language === "en" ? changeDateRangeFormat(dateRangeFilter.value) : dateRangeFilter.value,
        instanceFilter: instanceFilter.value,
        instanceLeaderFilter: instanceLeaderFilter.value,
        zoneImprovementFilter: zoneImprovementFilter.value,
        zoneFilter: zoneFilter.value,
        agencyFilter: agencyFilter.value,
        metadataFilter: metadataFilter.value,
      })
    }

    watch(dateRangeFilter, () => {
      // Limit the date range to the days limit received by props
      const datesArray = dateRangeFilter.value.split(" ")
      if (datesArray[2]) {
        datePickerConfig.value.minDate = null
        datePickerConfig.value.maxDate = null
      }
      else {
        const startArray = datesArray[0].split("-")
        const startDate = new Date(startArray[2], startArray[default_language === "en" ? 0 : 1] - 1, startArray[default_language === "en" ? 1 : 0])
        const startYear = startDate.getFullYear()
        const startMonth = startDate.getMonth()
        const startDay = startDate.getDate()
        const minDate = new Date(startYear, startMonth, startDay - props.dateRangeDaysLimit)
        const maxDate = new Date(startYear, startMonth, startDay + props.dateRangeDaysLimit)
        datePickerConfig.value.minDate = minDate
        datePickerConfig.value.maxDate = maxDate
      }
    })

    watch(locations, () => {
      emit('locations', locations.value)
    })

    watch(supervisors, () => {
      emit('supervisors', supervisors.value)
    })

    watch(metadataTypeSelect, () => {
      if (metadataTypeSelect.value?.value?.length) {
        metadataFilter.value = metadataTypeSelect.value.value[0].value
        emit('setAddMetadataButtonStatus', true)
      }
      else {
        metadataFilter.value = ""
        emit('setAddMetadataButtonStatus', false)
      }
    })

    const resetDate = () => {
      dateRangeFilter.value = ""
    }

    onMounted(() => {
      if (props.roleFilterShow) getRolesForDropDown()
      if (props.supervisorFilterShow || props.observerFilterShow || props.creatorFilterShow) getSupervisorsForDropDown()
      if (props.locationFilterShow) getLocationsForDropDown()
      if (props.processFilterShow) getProcessesForDropDown()
      if (props.workerFilterShow) getWorkersSupervisedForDropDown()
      if (props.zoneFilterShow || props.agencyFilterShow) getMetadataForDropDown({category: "filter"})
      if (props.metadataFilterShow) getMetadataForDropDown({type: "select"})

      // Filter data when a point from a chart is clicked
      EventBus.$on('filterByLocation', locationId => {
        locationFilter.value = locationFilter.value.length === 1 && locationFilter.value[0] === locationId
          ? []
          : [locationId]
      });
      EventBus.$on('filterByProcess', processId => {
        processFilter.value = processFilter.value.length === 1 && processFilter.value[0] === processId
          ? []
          : [processId]
      });
      EventBus.$on('filterByWorker', workerId => {
        workerFilter.value = workerFilter.value.length === 1 && workerFilter.value[0] === workerId
          ? []
          : [workerId]
      });
      EventBus.$on('filterBySupervisor', supervisorId => {
        supervisorFilter.value = supervisorFilter.value.length === 1 && supervisorFilter.value[0] === supervisorId
          ? []
          : [supervisorId]
      });
      EventBus.$on('filterByCreator', creatorId => {
        creatorFilter.value = creatorFilter.value.length === 1 && creatorFilter.value[0] === creatorId
          ? []
          : [creatorId]
      });
      EventBus.$on('filterByDate', date => {
        dateRangeFilter.value = `${date} to ${date}`
      });
      EventBus.$on('filterBySupervisorAndProcess', (supervisorId, processId) => {
        const reset = supervisorFilter.value.length === 1 && supervisorFilter.value[0] === supervisorId && processFilter.value.length === 1 && processFilter.value[0] === processId
        supervisorFilter.value = reset ? [] : [supervisorId]
        processFilter.value = reset ? [] : [processId]
      });
      EventBus.$on('resetFilters', () => {
        workerFilter.value = []
        pendingTasksFilter.value = false
      });
    })

    return {
      locationFilter,
      roleFilter,
      processFilter,
      workerFilter,
      supervisorFilter,
      observerFilter,
      creatorFilter,
      monthFilter,
      yearFilter,
      pendingTasksFilter,
      dateRangeFilter,
      instanceFilter,
      instanceLeaderFilter,
      zoneImprovementFilter,
      locations,
      roles,
      processes,
      workersSupervised,
      supervisors,
      months,
      years,
      datePickerConfig,
      resetDate,
      optionsMetadata,
      zoneFilter,
      agencyFilter,
      metadataFilter,
      metadataFilterOptions,
      metadataTypeSelect
    }
  },
}
</script>

<style lang="scss" scoped>

</style>