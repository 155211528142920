export const getRecognitions = `query recognitions($query: RecognitionQueryInput, $limit: Int, $sortBy: RecognitionSortByInput){
    recognitions(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      motive {
        _id
        label
        value
      }
      note
      worker {
        _id
        name
        locations {
          _id
          location
        }
        supervisors {
          name
        }
        rut
      }
      deleted
      created_by {
        _id
        name
      }
      date
    }
  }`

export const getRecognitionsId = `query recognitions($query: RecognitionQueryInput){
    recognitions(query: $query) {
      _id
    }
  }`

export const updateRecognition = `mutation updateOneRecognition($query: RecognitionQueryInput!, $data: RecognitionUpdateInput!) {
  updateOneRecognition(query: $query, set: $data) {
    _id
  }
}`

export const insertRecognition = `mutation insertOneRecognition($data: RecognitionInsertInput!){
  insertOneRecognition(data: $data) {
    _id
  }
}`