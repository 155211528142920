<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemsToRender"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref, computed, onMounted, onUnmounted } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import store from '@/store'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    provide('openGroups', ref([]))

    // Show only specific menu items when user is offline
    const isOnline = computed(() => store.state.app.isOnline)

    const headersOffline = new Set(["Apps", "Organization", "Settings"])
    const routesOffline = new Set(["apps-calendar", "habit-meeting-new", "pages-sync-status"])
    const childRoutesOffline = new Set(["organization-process-list"])

    const itemsOffline = props.items?.filter((e) => {
      // Filter parent items that will be visible while offline
      if (e.header) return headersOffline.has(e.header)
      if (e.children) {
        for (const child of e.children) {
          if (childRoutesOffline.has(child.route)) return true
        }
        return false
      }
      return routesOffline.has(e.route)
    }).map(e => {
      // Map the remaining parent items and filter child items that will be visible while offline
      if (e.children) {
        const routeCopy = {...e}
        routeCopy.children = routeCopy.children.filter(child => {
          return childRoutesOffline.has(child.route)
        })
        return routeCopy
      }
      return e
    })

    const itemsToRender = computed(() => isOnline.value ? props.items : itemsOffline)

    return {
      resolveNavItemComponent,
      itemsToRender,
    }
  },
}
</script>
