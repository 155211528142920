export default [
  {
    header: ' ',
  },
  {
    title: 'Settings',
    route: 'pages-account-setting',
    icon: 'SettingsIcon',
  },
  {
    title: 'Help',
    route: 'misc-coming-soon',
    icon: 'MessageCircleIcon',
  },
]
