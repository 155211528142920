<template>
  <b-nav-item @click="$emit('add-improvement')">
    <feather-icon
      size="21"
      icon="CheckCircleIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
}
</script>
