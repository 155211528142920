import axios from '@axios'
import { updateImprovement } from '@/@core/queries/improvements'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import store from '@/store'
import awsConnection from '@/views/habit/aws'
import { getWorkersWithEmails } from '@/@core/queries/workers'
import realmConnection from '@/views/habit/realm'

export default function useCommonTodo() {

  const userData = store.state?.userStore?.userData;
  const commitmentFunctionality = localStorage.getItem("clientData")
    ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
    : false

  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { singleUpload, sendEmail } = awsConnection()
  const { getItem, ObjectId } = realmConnection()

  const tagColors = {
    people: "primary",
    place: "info",
    processes: "warning",
    customer_need: "danger",
    tools_team: "info",
    positive_client_voice: "primary",
    negative_client_voice: "danger",
    safety: "primary",
    processes_productivity: "info",
    assets_management: "warning",
    quality: "danger",
    sustainability: "secondary",
    costs: "dark",
    rdp: "primary",
  }

  const resolveTagVariant = tag => {
    return tagColors[tag] || "primary"
  }
  
  const resolveAvatarVariant = tags => {
    if (!tags || !tags.length) return 'secondary'
    for (const tag of tags) {
      if (tagColors.hasOwnProperty(tag)) return tagColors[tag]
    }
    return 'primary'
  }

  const updateImprovementWithKey = (improvementId, key) => {
    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: updateImprovement,
        variables: {
          query: { _id: improvementId },
          data: {
            imageKey: key,
          },
        },
      })
      .then(() => {})
      .catch(() => {
        showErrorMessage(i18n.t('message.improvement_update_error'))
      })
  }

  const getEmailTemplate = ({ name, title, description, stage, textMessage }) => {
    // Logo
    // <tr>
    //   <td style="background-color: #ecf0f1; text-align: center; padding: 0">
    //     <img width="20%" style="margin: 2% 0% 1%" src="https://production-habit.s3.amazonaws.com/habit/AddCo+Logo.png" alt="Addco Logo">
    //   </td>
    // </tr>

    // Button link to AddCo app
    // <div style="width: 100%; text-align: center; margin: 7% 0%;">
    //   <a style="text-decoration: none; border-radius: 5px; padding: 11px 23px; color: white; background-color: #498ceb; font-size: 17px" href="https://habit.addval.io/apps/todo">
    //     ${commitmentFunctionality ? i18n.t('message.see_commitment_in_habit') : i18n.t('message.see_improvement_in_habit')}
    //   </a>
    // </div>
    
    return `
      <html>
        <table style="max-width: 600px; padding: 10px; margin:0 auto; border-collapse: collapse; border-radius: 1em; overflow: hidden;">
          <tr>
            <td style="background-color: #ecf0f1">
              <div style="color: #34495e; margin: 2% 10%; font-family: sans-serif">
                <h2 style="color: #e67e22; margin: 0 0 7px">
                  ${i18n.t('message.hello')}${name ? ` ${name}` : ''},
                </h2>

                <p style="margin: 2px; font-size: 15px">
                  ${textMessage}:
                </p>

                <ul style="font-size: 15px;  margin: 10px 0">
                  ${title ? `<li><u>${i18n.t('label.Title')}</u>: <b>${title}</b></li>` : ''}
                  ${description ? `<li><u>${i18n.t('label.description')}</u>: <b>${description}</b></li>` : ''}
                  ${stage ? `<li><u>${i18n.t('label.stage')}</u>: <b>${stage}</b></li>` : ''}
                </ul>
              </div>
            </td>
          </tr>
        </table>
      </html>
    `
  }

  const sendImprovementEmail = (improvement) => {
    const subject = i18n.t('message.improvement_opportunity_assigned')
    const bodyData = {
      name: improvement.assignee?.name,
      title: improvement.note,
      tags: improvement.tags?.map(e => i18n.t(`domain.${e}`)).join(" / "),
      creator: userData.fullName,
      dueDate: `${improvement.dueDate.slice(8, 10)}/${improvement.dueDate.slice(5, 7)}/${improvement.dueDate.slice(0, 4)}`,
      description: improvement.description?.replace(/<[^>]*>/g, ''),
    }
    if (improvement.metadata?.length) {
      improvement.metadata.forEach(e => {
        if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
        Object.assign(bodyData, {[e.name]: e.answer})
      })
    }
    const body = getEmailTemplate(bodyData)

    sendEmail([improvement.assignee.email], subject, body)
      .then((response) => {
        if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_improvement_success'))
      })
      .catch((err) => {
        console.log(err)
        showErrorMessage(i18n.t('message.email_send_improvement_error'))
      })
  }

  const addTask = val => {
    return new Promise((resolve, reject) => {
      store.dispatch('app-todo/addTask', {...val})
        .then((response) => {
          if (response.data.errors) throw new Error()
          showSuccessMessage(commitmentFunctionality ? i18n.t('message.commitment_created') : i18n.t('message.improvement_created'))

          // Upload documents to AWS and then update the improvement in MongoDB with the AWS document key
          if (val.documentFile?.name) {
            const improvementId = response.data.data.insertOneImprovement._id
            uploadFile(val.documentFile, "documents", improvementId)
          }
  
          // Send email to assignee with the details of the improvement opportunity created
          if (val.assignee?.email) sendImprovementEmail(val)

          resolve()
        })
        .catch((error) => {
          showErrorMessage(commitmentFunctionality ? i18n.t('message.commitment_error') : i18n.t('message.improvement_error'))
          console.log(error)
          reject(error)
        })
    })
  }

  const uploadImprovementFile = (file, folder, improvementId) => {
    const destinationFolder = `${userData.client.$oid}/${folder}`
    singleUpload(file, destinationFolder)
      .then((key) => updateImprovementWithKey(improvementId, key))
      .catch((err) => console.log(err))
  }
  const uploadAction_planFile = (file, folder) => {
    const destinationFolder = `${userData.client.$oid}/${folder}`
    return singleUpload(file, destinationFolder)
      .then((key) => {return key})
      .catch((err) => console.log(err))
  }

  const sendEmailImprovements = async (improvements, state, improvementsToUpdate, improvementsToDelete) => {
    const emailPromises = []
    for (const i of improvements) {  
      // If improvement is not new or flagged for update/delete or assigne doesn/t have email then continue (don't send email)
      if (!state && i._id && !improvementsToUpdate?.find(e => e._id === i._id) && !improvementsToDelete?.find(e => e._id === i._id)) {
        continue
      }

      let recipients = []

      if (i.assignee?.email) recipients.push({ name: i.assignee.name, email: i.assignee.email, category: "assignee" })

      if (i.subscribers?.length) {
        try {
          const subscribersData = i.subscribers[0]?._id ? i.subscribers : await getWorkersEmails(i.subscribers)
          recipients = [...recipients, ...subscribersData]
        } catch (error) {
          console.log(error)
        }
      }

      if (!recipients.length) continue

      const updatedState = state
          ? state
          : !i._id
            ? "add"
            : improvementsToDelete?.find(e => e._id === i._id)
              ? "delete"
              : "update"
  
        let subject
        switch (updatedState) {
          case "update":
            subject = commitmentFunctionality ? i18n.t('message.commitment_updated') : i18n.t('message.improvement_opportunity_updated')
            break;
          case "complete":
            subject = commitmentFunctionality ? i18n.t('message.commitment_completed') : i18n.t('message.improvement_opportunity_completed')
            break;
          case "delete":
            subject = commitmentFunctionality ? i18n.t('message.commitment_deleted') : i18n.t('message.improvement_opportunity_deleted')
            break;
          default:
            subject = commitmentFunctionality ? i18n.t('message.commitment_assigned') : i18n.t('message.improvement_opportunity_assigned')
            break;
        }
        const bodyData = {
          title: i.note,
          assignee: i.assignee?.name,
          tags: i.tags?.map(e => i18n.t(`domain.${e}`)).join(" / "),
          creator: userData.username,
          dueDate: i.dueDate instanceof Date?i.dueDate.toLocaleString():`${i.dueDate?.slice(8, 10)}/${i.dueDate?.slice(5, 7)}/${i.dueDate?.slice(0, 4)}`,
          description: i.description?.replace(/<[^>]*>/g, ''),
          commitmentFunctionality,
          updatedState,
          completed: i.completed,
          deleted: i.deleted,
        }
        if (i.metadata?.length) {
          i.metadata.forEach(e => {
            if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
            Object.assign(bodyData, {[e.name]: e.answer})
          })
        }

      recipients.forEach(r => {
        bodyData.name = r.name
        bodyData.category = r.category

        const body = getEmailTemplate(bodyData)
  
        const emailPromise = new Promise((resolve, reject) => {
          sendEmail([r.email], subject, body)
            .then(res => {
              res.MessageId && resolve(res.MessageId)
            })
            .catch(err => console.log(err))
        })
  
        emailPromises.push(emailPromise)
      })
    }

    return Promise.all(emailPromises)
      .then(emailResponses => {
        const cantResponses = emailResponses.filter(res => !!res)
        if (cantResponses.length > 0) showSuccessMessage(`${cantResponses.length} ${commitmentFunctionality ? i18n.t('message.email_send_commitment_success') : i18n.t('message.email_send_improvement_success')}`) 
      })
      .catch((err) => {
        console.log(err)
        showErrorMessage(commitmentFunctionality ? i18n.t('message.email_send_commitment_error') : i18n.t('message.email_send_improvement_error'))
      })
  }

  const sendEmailCase = async (caseData, state) => {
    console.log(caseData)
    try {
      const query = { _id: ObjectId(caseData.assignee) }
      const registrationResponsibleData = await getItem({ collection: 'worker', query })

      const messages = {
        add: {
          subject: i18n.t('message.improvement_opportunity_created'),
          text: i18n.t('message.assigned_responsible_improvement'),
        },
        update: {
          subject: i18n.t('message.improvement_opportunity_updated'),
          text: i18n.t('message.improvement_assigned_updated'),
        },
        complete: {
          subject: i18n.t('message.improvement_opportunity_completed'),
          text: i18n.t('message.improvement_assigned_completed'),
        },
        delete: {
          subject: i18n.t('message.improvement_opportunity_deleted'),
          text: i18n.t('message.improvement_assigned_deleted'),
        },
      }

      const bodyData = {
        name: registrationResponsibleData.name,
        title: caseData.note,
        description: caseData.description,
        stage: caseData.stage,
        textMessage: messages[state].text,
      }

      const body = getEmailTemplate(bodyData)

      sendEmail([registrationResponsibleData.email], messages[state].subject, body)
        .then(res => {
          showSuccessMessage(i18n.t('message.email_send_improvement_success'))
        })
        .catch(err => {
          console.log(err)
          showErrorMessage(i18n.t('message.email_send_improvement_error'))
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getWorkersEmails = (workerIds) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: getWorkersWithEmails,
          variables: { query: { _id_in: workerIds } },
        })
        .then(({data}) => {
          if (data.errors) throw new Error(data.errors[0].message)
          resolve(data.data.workers)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
    
  return {
    resolveTagVariant,
    resolveAvatarVariant,
    updateImprovementWithKey,
    getEmailTemplate,
    addTask,
    sendImprovementEmail,
    uploadImprovementFile,
    sendEmailImprovements,
    uploadAction_planFile,
    sendEmailCase,
  }
}