<template lang="pug">
b-row
  b-col(cols="12")
    div
      form-wizard.wizard-vertical.mb-3(
        color="#498ceb",
        :title="null",
        :subtitle="null",
        layout="horizontal",
        :finish-button-text="$t('message.submit')",
        :back-button-text="$t('message.previous')",
        :next-button-text="$t('message.next')",
        @on-complete="formSubmitted",
        @on-change="handleTabChange",
        ref="myForm"
      )
        // Welcome
        tab-content(:title="$t('message.welcome')", :before-change="validateWelcomeForm")
          validation-observer(ref="welcomeTab", tag="form") 
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.welcome_to_daily_dialogue') }}
                small.text-muted
                  | {{ $t('message.fill_to_start_meeting') }}
              b-col(md="6")
                b-form-group(
                  :label="$t('message.tableHeader.date')",
                  label-for="v-date"
                )
                  b-form-datepicker#v-date(v-model="meeting.date", :disabled="dateDisabled")
              b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.start') }}
                validation-provider(:name="$t('label.start')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingStart",
                      type="time",
                    )
                  small(class="text-danger") {{ errors[0] }}

              //- End time moved to Finish tab
              //- b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.end') }}
                validation-provider(:name="$t('label.end')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingEnd",
                      type="time",
                    )
                  small(class="text-danger") {{ errors[0] }}

              b-col(md="6")
                b-form-group(
                  :label="$t('label.Location')",
                  label-for="v-location",
                  :description="$t('message.where_meeting_held')"
                )
                  validation-provider(:name="$t('label.Location')", rules="required", v-slot="{ errors }")
                    v-select#v-location(
                      v-model="meeting.location",
                      :options="locations",
                      :selectable="(option) => !option.value.includes('select_value')",
                      label="title"
                    )
                    small(class="text-danger") {{ errors[0] }}

        // Happiness
        tab-content(:title="$t('message.happiness')", :before-change="validateHappinessForm")
          validation-observer(ref="happinessTab", tag="form") 
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.how_team_feeling') }}
                small.text-muted
                  | {{ $t('message.count_amount_happiness') }}
              b-col(md="4", class="d-flex flex-column")
                feather-icon.text-success.stroke-current(icon="SmileIcon", size="100", class="align-self-center")
                b-form-group(:label="$t('label.happy')", label-for="v-happy")
                  validation-provider(:name="$t('label.happy')", rules="required|integer|min_value:0|max_value:100", v-slot="{ errors }")
                    b-input-group
                      b-input-group-prepend
                        b-button(variant="outline-primary", @click="updateHappiness('happy','remove')")
                          feather-icon(icon="MinusIcon")
                      b-form-input(type="number", v-model="meeting.happy", style="text-align:center")
                      b-input-group-append
                        b-button(variant="outline-primary", @click="updateHappiness('happy','add')")
                          feather-icon(icon="PlusIcon")
                    small(class="text-danger") {{ errors[0] }}
                div(class="align-self-center")
                  | {{ happyPercentage }}%
              b-col(md="4", class="d-flex flex-column my-1 my-md-0")
                feather-icon.stroke-current(icon="MehIcon", size="100", class="align-self-center")
                b-form-group(:label="$t('label.neutral')", label-for="v-neutral")
                  validation-provider(:name="$t('label.neutral')", rules="required|integer|min_value:0|max_value:100", v-slot="{ errors }")
                    b-input-group
                      b-input-group-prepend
                        b-button(variant="outline-primary", @click="updateHappiness('neutral','remove')")
                          feather-icon(icon="MinusIcon")
                      b-form-input(type="number", v-model="meeting.neutral", style="text-align:center")
                      b-input-group-append
                        b-button(variant="outline-primary", @click="updateHappiness('neutral','add')")
                          feather-icon(icon="PlusIcon")
                    small(class="text-danger") {{ errors[0] }}
                div(class="align-self-center")
                  | {{ neutralPercentage }}%
              b-col(md="4", class="d-flex flex-column")
                feather-icon.text-danger.stroke-current(icon="FrownIcon", size="100", class="align-self-center")
                b-form-group(:label="$t('label.Sad_Angry')", label-for="v-sad-angry")
                  validation-provider(:name="$t('label.Sad_Angry')", rules="required|integer|min_value:0|max_value:100", v-slot="{ errors }")
                    b-input-group
                      b-input-group-prepend
                        b-button(variant="outline-primary", @click="updateHappiness('sad','remove')")
                          feather-icon(icon="MinusIcon")
                      b-form-input(type="number", v-model="meeting.sad", style="text-align:center")
                      b-input-group-append
                        b-button(variant="outline-primary", @click="updateHappiness('sad','add')")
                          feather-icon(icon="PlusIcon")
                    small(class="text-danger") {{ errors[0] }}
                div(class="align-self-center")
                  | {{ sadPercentage }}%
              b-col.my-1(v-if="isOnline", cols="12")
                b-button(variant="info", :disabled="!meeting.location || isFetchingSurveyResults", @click="getSurveyResults")
                  | {{ $t('message.get_survey_results') }}
                span.ml-1(v-show="isFetchingSurveyResults")
                  | {{ $t('message.loading') }}

        // attendees
          tab-content(title="Attendees")
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | Attendees
                small.text-muted Enter the correspoding user(s) for each level of responsability.

              add-attendee(@update-attendees="updateAttendees")

        // Recognitions
        tab-content(:title="$t('label.recognitions')", :before-change="validateRecognitionsForm")
          validation-observer(ref="recognitionsTab", tag="form")  
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.team_done_well') }}
                small.text-muted
                  | {{ $t('message.recognition_for_efforts') }}

              add-recognition(
                :workers="workersTotalNotMapped",
                :motives="motives",
                @update-recognitions="updateRecognitions",
              )

        // Improvements
        tab-content(:title="$t('label.improvements')", :before-change="validateImprovementsForm")
          validation-observer(ref="improvementsTab", tag="form")   
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.what_improved') }}
                small.text-muted
                  | {{ $t('message.get_feedback') }}

              add-improvement(
                :domains="domains",
                :workers="workersTotalNotMapped",
                :metadata="metadataNotMapped"
                @update-improvements="updateImprovements",
              )

        // Finish
        tab-content(:title="$t('message.finish')", :before-change="validateFinishForm", :id="'finishTab'", :tab-id="'finishTab'")
          validation-observer(ref="finishTab", tag="form") 
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.daily_dialogue_end') }}
                small.text-muted
                  | {{ $t('message.complete_end_time') }}
              b-col(md="6")
                b-form-group(
                  :label="$t('message.tableHeader.date')",
                  label-for="v-date"
                )
                  b-form-datepicker#v-date(v-model="meeting.date", :disabled="true")

              b-col(md="6")
                b-form-group(
                  :label="$t('Image')",
                  label-for="v-image",
                )
                  b-button.mr-1(
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                    type="button",
                    variant="info",
                    @click="uploadImage()"
                  )
                    | {{ $t('message.upload') }}
                  image-compressor(
                    style="display: none",
                    :done="getImgCompressed",
                    :scale="scale",
                    :quality="quality",
                    :maxWidth="maxWidth",
                    ref="compressor"
                  )
                  small(v-if="imgData.fileInfo")
                    | {{imgData.fileInfo.name}}
                  small(v-else-if="imageRequired", class="text-danger")
                    | {{ $t('message.upload_image_required') }}
                  
              b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.start') }}
                validation-provider(:name="$t('label.start')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingStart",
                      type="time",
                      :disabled="true"
                    )
                  small(class="text-danger") {{ errors[0] }}
              b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.end') }}
                validation-provider(:name="$t('label.end')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingEnd",
                      type="time",
                    )
                  small(class="text-danger") {{ errors[0] }}
</template>

<script>
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormDatepicker,
  BFormTimepicker,
  BFormSpinbutton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import AddAttendee from "./AddAttendee.vue";
import AddRecognition from "./AddRecognition.vue";
import AddImprovement from "./AddImprovement.vue";
import i18n from "@/libs/i18n";
import { ref, onMounted, onUnmounted, watch, computed } from "@vue/composition-api";
import useCommon from "../../organization/useCommon";
import store from "@/store";
import { addMeeting } from "@core/queries/meeting";
import axios from "@axios";
import { useRouter } from '@core/utils/utils'
import useNotifications from '@/composables/useNotifications'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer, min_value, max_value } from "@validations";
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Ripple from 'vue-ripple-directive'
import ImageCompressor from '@/views/habit/ImageCompressor.vue';
import awsConnection from '@/views/habit/aws';
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import { getEmployeeSurveys, updateEmployeeSurveys } from "@core/queries/employee-survey";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormTimepicker,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AddAttendee,
    AddRecognition,
    AddImprovement,
    ValidationObserver,
    ValidationProvider,
    ImageCompressor,
  },
  directives: {
    Ripple,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const worker_id = userData.role !== "admin" ? userData.worker_id.$oid : null;
    const client_id = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocation = (userData.role !== "admin" && userData.locations?.length) ? userData.locations[0] : null;
    const imageRequired = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).daily_dialogue_image_required
      : false
    const dateDisabled = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).daily_dialogue_date_disabled
      : false
    const { router } = useRouter()
    const isSubmitting = ref(false)
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const welcomeTab = ref(null)
    const happinessTab = ref(null)
    const recognitionsTab = ref(null)
    const improvementsTab = ref(null)
    const finishTab = ref(null)
    const actualDate = new Date()
    const actualDatePlus15Min = ref(new Date())
    // actualDatePlus15Min.value.setMinutes(actualDate.getMinutes() + 15 );
    const isOnline = computed(() => store.state.app.isOnline)
    const compressor = ref(null)
    const imgData = ref({})
    const scale = ref(100)
    const quality = ref(50)
    const maxWidth = ref(640)
    const startTimeError = ref("")
    const endTimeError = ref("")
    const isFetchingSurveyResults = ref(false)

    const { singleUpload, sendEmail } = awsConnection()

    const { getEmailTemplate } = useCommonTodo()
    const { getDomains, updateDailyDialogueWithKey } = useCommonDashboards()
    const domains = computed(() => getDomains(client_id))
    
    
    const meeting = ref({
      date: actualDate,
      happy: 0,
      neutral: 0,
      sad: 0,
      recognitions: [],
      improvements: [],
      location: userLocation,
    });

    // These variables are defined separately in order to be watched
    const meetingStart = ref(
      actualDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )

    const meetingEnd = ref(
      actualDatePlus15Min.value.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }))
    
    

    const totalHappiness = computed(() => parseInt(meeting.value.happy) + parseInt(meeting.value.neutral) + parseInt(meeting.value.sad))
    const happyPercentage = computed(() => totalHappiness.value ? Math.round(meeting.value.happy / totalHappiness.value * 100) : 0)
    const neutralPercentage = computed(() => totalHappiness.value ? Math.round(meeting.value.neutral / totalHappiness.value * 100) : 0)
    const sadPercentage = computed(() => totalHappiness.value ? Math.round(meeting.value.sad / totalHappiness.value * 100) : 0)

    const {
      getWorkersTotalForDropDown,
      workersTotalNotMapped,
      getLocationsForDropDown,
      locations,
      getMotivesForDropDown,
      motives,
      getMetadataForDropDown,
      metadataNotMapped,
      getSupervisorsForDropDown,
      supervisorsOnlyNames,
      handleError,
      storeDataToUpload,
      formatDate
    } = useCommon();

    function handleTabChange(oldTab, newTab) {
      const tabs = this.$refs.myForm.tabs;
      const lastIndex = tabs.length - 1;
      if (lastIndex === newTab) {
        actualDatePlus15Min.value = new Date();
        meetingEnd.value = actualDatePlus15Min.value.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      }
    }

    watch(locations, () => {
      if (!meeting.value.location && locations.value.length) meeting.value.location = locations.value[0]
    })

    watch(meetingStart, () => {
        // Update meeting end time
        const [hoursString, minutesString] = meetingStart.value.split(":")
        const hours = parseInt(hoursString)
        const minutes = parseInt(minutesString)
        let endHours = minutes < 45 ? hours : hours + 1
        let endMinutes = minutes < 45 ? minutes + 15 : minutes - 45
        if (endHours === 24) endHours = 0
        if (endHours < 10) endHours = `0${endHours}`
        if (endMinutes < 10) endMinutes = `0${endMinutes}`
        const endTime = `${endHours}:${endMinutes}`
        meetingEnd.value = endTime
    })
    
    onMounted(async () => {
      await getLocationsForDropDown();
      await getWorkersTotalForDropDown("notMapped");
      await getMotivesForDropDown();
      await getSupervisorsForDropDown("onlyNames")
      await getMetadataForDropDown({category: "improvement", option: "notMapped"})
    })

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date and column width for UI purposes
          if (e.name === "creation_date") {
            e.answer = `${actualDate.getFullYear()}-${actualDate.getMonth() < 9 ? "0" + (actualDate.getMonth() + 1) : actualDate.getMonth() + 1}-${actualDate.getDate() < 10 ? "0" + actualDate.getDate() : actualDate.getDate()}`
            e.columnWidth = 2
          }
          // Set default instance leader and update instance leader options with supervisors
          if (e.name === "instance_leader") {
            e.answer = userData.username
            e.options = supervisorsOnlyNames.value
          }
        })
      }
    })

    watch(supervisorsOnlyNames, val => {
      if (metadataNotMapped.value && metadataNotMapped.value.length) {
        metadataNotMapped.value.forEach(e => {
          // Update instance leader options with supervisors
          if (e.name === "instance_leader") {
            e.options = val
          }
        })
      }
    })

    function updateRecognitions(recognitions) {
      meeting.value.recognitions = recognitions;
    }

    function updateImprovements(improvements) {
      meeting.value.improvements = improvements;
    }

    function updateHappiness(state, change) {
      if (change === "add" && meeting.value[state] < 100) meeting.value[state]++
      else if (change === "remove" && meeting.value[state] > 0) meeting.value[state]--
    }

    function formSubmitted() {
      if (isSubmitting.value) return
      isSubmitting.value = true

      const dateTime = formatDate(meeting.value.date)

      const payload = {
        client_id: { link: client_id },
        created_by: { link: worker_id },
        date: dateTime,
        start: meetingStart.value.length === 4 ? `0${meetingStart.value}` : meetingStart.value,
        end: meetingEnd.value.length === 4 ? `0${meetingEnd.value}` : meetingEnd.value,
        happy: meeting.value.happy,
        neutral: meeting.value.neutral,
        sad: meeting.value.sad,
        location: { link: meeting.value.location.value },
        improvements: {
          create: meeting.value.improvements.map((i) => {
            return {
              client_id: { link: client_id },
              note: i.note,
              domain: i.domain,
              assignee: i.assignee ? { link: i.assignee._id } : null,
              dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
              description: '',
              tags: [i.domain],
              completed: false,
              deleted: false,
              important: false,
              metadata: i.metadata && i.metadata.length
                ? i.metadata.map(metadata => ({
                    name: metadata.name,
                    type: metadata.type,
                    answer: metadata.answer,
                  }))
                : null
            };
          }),
        },
        recognitions: {
          create: meeting.value.recognitions.map((i) => {
            return {
              client_id: { link: client_id },
              note: i.note,
              worker: { link: i.worker?._id },
              motive: { link: i.motive },
              created_by: { link: worker_id },
              date: dateTime
            };
          }),
        },
      };

      if (isOnline.value) {
        postDailyDialogue(payload)  
      }
      else {
        payload.imgData = imgData.value
        payload.improvementsNotMapped = meeting.value.improvements
        storeDataToUpload('dialoguesToUpload', payload)
        // let dialoguesStored = localStorage.dialogues ? JSON.parse(localStorage.dialogues) : []
        // dialoguesStored.push({...payload})
        // localStorage.dialogues = JSON.stringify(dialoguesStored)
        showSuccessMessage(i18n.t('message.form_stored'))
        router.push({ name: 'apps-calendar' })
      }
    }

    function postDailyDialogue(payload) {
      axios.post("/graphql", {
        query: addMeeting,
        variables: {
          data: payload,
        },
      })
      .then((response) => {
        if (response.data.errors) throw new Error()
        showSuccessMessage(i18n.t('message.daily_dialogue_created'))

        // Upload image to AWS and then update the daily dialogue in MongoDB with the AWS image key
        const dailyDialogueId = response.data.data.insertOneMeeting._id
        const {fileInfo, destinationFolder} = imgData.value
        if (fileInfo) {
          singleUpload(fileInfo, destinationFolder)
            .then((key) => updateDailyDialogueWithKey(dailyDialogueId, key))
            .catch((err) => console.log(err))
        }

        // Send email to assignees with the details of the improvements opportunity created
        meeting.value.improvements?.forEach(e => {
          if (e.assignee?.email) {
            const subject = i18n.t('message.improvement_opportunity_assigned')
            let bodyData = {
              name: e.assignee?.name,
              title: e.note,
              tags: e.domain ? i18n.t(`domain.${e.domain}`) : '',
              creator: userData.fullName,
              dueDate: e.dueDate ? `${e.dueDate.slice(8, 10)}/${e.dueDate.slice(5, 7)}/${e.dueDate.slice(0, 4)}` : '',
            }
            if (e.metadata?.length) {
              e.metadata.forEach(e => {
                if (e.name === "creation_date") e.answer = `${e.answer.slice(8, 10)}/${e.answer.slice(5, 7)}/${e.answer.slice(0, 4)}`
                Object.assign(bodyData, {[e.name]: e.answer})
              })
            }
            const body = getEmailTemplate(bodyData)
            
            sendEmail([e.assignee.email], subject, body)
              .then((response) => {
                if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_improvement_success'))
              })
              .catch((err) => {
                console.log(err)
                showErrorMessage(i18n.t('message.email_send_improvement_error'))
              })
          }
        })

        // Mark location's employee survey results as read
        axios
          .post("/graphql", {
            query: updateEmployeeSurveys,
            variables: {
              query: { location: {_id: meeting.value.location.value } },
              data: { read: true }
            },
          })
          .then(() => {})
          .catch((error) => console.log(error))
        
        // Redirect to happiness dashboard
        router.push({ name: 'habit-happiness-dashboard' })
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.daily_dialogue_error'))
        isSubmitting.value = false
      })
    }

    const uploadImage = () => {
      compressor.value.$el.click()
    }

    const getImgCompressed = (obj) => {
      imgData.value = {
        fileInfo: obj.compressed,
        destinationFolder: `${userData.client.$oid}/daily-dialogues`
      }
    }

    const getSurveyResults = () => {
      isFetchingSurveyResults.value = true

      const conditions = {
        location: { _id: meeting.value.location.value },
        read: false
      }

      axios.post("/graphql", {
        query: getEmployeeSurveys,
        variables: { query: conditions },
      })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          updateHappinessFromSurvey(data.data.employee_surveys)
          showSuccessMessage(i18n.t('message.survey_results_fetch_successful'))
        })
        .catch((error) => {
          console.log(error)
          handleError({ error, defaultMessage: i18n.t('message.survey_results_fetch_error') })
        })
        .finally(() => {
          isFetchingSurveyResults.value = false
        })
    }

    const updateHappinessFromSurvey = (surveyData) => {
      const categories = { happy: 0, neutral: 0, sad: 0 }
      surveyData.forEach(e => {
        categories[e.answer]++
      })
      meeting.value.happy = categories.happy
      meeting.value.neutral = categories.neutral
      meeting.value.sad = categories.sad
    }

    const validateWelcomeForm = async () => new Promise((resolve, reject) => {
      if (imageRequired && !imgData.value.fileInfo) reject()
      welcomeTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateHappinessForm = async () => new Promise((resolve, reject) => {
      happinessTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateRecognitionsForm = async () => new Promise((resolve, reject) => {
      recognitionsTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateImprovementsForm = async () => new Promise((resolve, reject) => {
      improvementsTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateFinishForm = async () => new Promise((resolve, reject) => {
      finishTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    return {
      meeting,
      meetingStart,
      meetingEnd,
      updateImprovements,
      updateRecognitions,
      updateHappiness,
      formSubmitted,
      locations,
      workersTotalNotMapped,
      motives,
      welcomeTab,
      happinessTab,
      recognitionsTab,
      improvementsTab,
      finishTab,
      validateWelcomeForm,
      validateHappinessForm,
      validateRecognitionsForm,
      validateImprovementsForm,
      validateFinishForm,
      required,
      integer,
      min_value,
      max_value,
      domains,
      uploadImage,
      getImgCompressed,
      compressor,
      imgData,
      scale,
      quality,
      maxWidth,
      metadataNotMapped,
      imageRequired,
      dateDisabled,
      getSurveyResults,
      isFetchingSurveyResults,
      isOnline,
      happyPercentage,
      neutralPercentage,
      sadPercentage,
      handleTabChange
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
